import React, { useState, useEffect } from "react"; // , useCallback
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form"; 
import {
  SET_USER,
  PUT_USER,
  GET_USER,
  EDIT_USER,
//   SET_PROGRAM,
  GET_ORDER_OPTION, GET_CITY, GET_MONTH_OPTION, GET_DAY_OPTION, SET_PROGRAM
} from "../../redux/actionTypes/registration";

import { SET_REMOVE_VENDOR_STORE, SET_LOC_SHOWHIDE, getMyStoreDetails, getVendorInfoList } from "../../redux/actionTypes/vendordetails"; 
import { mapStateToProps } from "../../redux/reducers";
// import styled from "styled-components";
import SidebarNavigation from "../common/sidebar/sidebar-navigation";
import { ProgramSectionData } from "../user/signup/data/program-section-format";
import { signupLanguageLabels } from "../user/signup/data/signup-multiLanguage-labels";
import sections from "../user/signup/data/signup-section";
import { locTypeProgramQuestionsSections } from "../user/signup/signup-actions";
import SignupForm from "../user/signup/signup-form";
import { GET_LOC_TYPE, GET_LOC_TYPE_QUES } from "../../redux/actionTypes/dropdown";
import _ from "lodash";
import { popUpAlert, sortName, uuidv4 } from "../../utils/globalMethods";
import CommonLoading from "../common/components/commonLoading";

let language = localStorage.getItem("selectedLanguage");
if (language === null) {
  language = "en-US";
}

const dynamicMenuList = [{ id: "csaPickupSite", menu: "menuStep4" },
{ id: "farmStand", menu: "menuStep5" },
{ id: "farmersMarketBooth", menu: "menuStep6" },
{ id: "mobileMarket", menu: "menuStep7" },
{ id: "farmersMarket", menu: "menuStep8" }];

const locationTypeCollection = [
  {
    id: "csaPickupSite",
    value: "HIP CSA Program"
  }, {
    id: "farmStand",
    value: "HIP Farm Stand"
  }, {
    id: "farmersMarketBooth",
    value: "HIP Farmers Market Booth"
  }, {
    id: "mobileMarket",
    value: "HIP Mobile Market Stop"
  }, {
    id: "farmersMarket",
    value: "HIP Farmers Market"
  }
];

let staticMenu = [];
function VendorLocDetails(props) {
//   const navigate = useNavigate();
const [locType, setLocType] = useState({
  "csaPickupSite": false,
  "farmStand": false,
  "farmersMarketBooth": false,
  "mobileMarket": false,
  "farmersMarket": false
});
  const [isDisblOthrChkbx, setIsDisblOthrChkbx] = useState(false);
  const [isDisblMrkMngrChkbx, setIsDisblMrkMngrChkbx] = useState(false);
  const [activeTab, setActiveTab] = useState("menuStep3");
  const [activeTabId, setActiveTabId] = useState();
  const [enableMenuList, setEnableMenuList] = useState([]);
  const [programArray, setProgramArray] = useState([]);
const [newmenus, setNewMenus] = useState([]);
const [existPgmData, setExistPgmData] = useState();
const [isLoading, setIsLoading]=useState(true);
const [isStoreUpdate, setIsStoreUpdate] = useState(false);
// eslint-disable-next-line no-unused-vars
const [restoreLocType, setRestoreLocType] = useState(false);
const [vendorInfo, setVendorInfo] = useState(props?.vendorInfo);

staticMenu = [
    {
        label: signupLanguageLabels(language).locType,
        className: "list-group-item menu-list disabled",
        id: "menuStep3",
        name: "loc_type",
        liClassName: "disabled",
        activeTab: activeTab,
      }
];
 
  useEffect(() => {
    props.locTypeProgramQuestionsSections(sections);
  }, []);

  const filterTypes = (storeData,type,repType="") =>{
    let data = {};
    if(repType){
      data[repType] = [];
    }else{
      data[type] = [];
    }
    storeData.map(item => {
      if(item[type]=="Y"){
        if(repType){
          data[repType].push(item);
        }else{
          data[type].push(item);
        }
      }
    })
    return data;
  }
 
useEffect(()=>{
    setEnableMenuList([]);
    const data = vendorInfo;
    const storeData = props.myStoreDetails;
    if(storeData.length!=0){
      manageStoreData(data,storeData);
    }
    setIsLoading(props.isLoading);
},[vendorInfo,props.myStoreDetails]);

  useEffect(() => {
    if (enableMenuList) {
      let dummy = [];
      dummy = locationTypeCollection.filter((item) => enableMenuList.includes(item.id));
      dummy = dummy.map((item, i) => {
        let menus = {
          id: item.id,
          menu: `menuStep${i + 4}`,
          values: locationTypeCollection.filter((val) => val.id === item.id)
        };
        return menus;
      });
      setNewMenus(dummy);
    }
  }, [enableMenuList]);
//   const {
//     formState: {  },//errors
//   } = useForm();

const setProgramData=(data,type)=>{
    const prgmData = [];
    
    if (data[type] && data[type].length) {
        data[type].map((item)=>{
            const fields = _.cloneDeep(item);
            if(type=="csaPickupSite"){
              fields.pickup_site_name = item.siteName
            }else if(type=="farmersMarketBooth" || type=="farmersMarket"){
              fields.farmer_market_name = item.siteName  
            }else if(type=="mobileMarket"){
              fields.location_name = item.siteName
            }
            fields.location_name = item.siteName
            fields.address_1 = item.addressDetail.addressLine1;
            fields.address_2 = item.addressDetail.addressLine2;
            fields.city = { values: item.addressDetail.city, name: item.addressDetail.city };
            fields.zipcode = item.addressDetail.zipcode;
            fields.latitude = item.addressDetail.latitude;
            fields.longitude = item.addressDetail.longitude;
            fields.special_instructions = item.specialInstructions;
            fields.months_operations = []; 
            for (let month in item.storeOperationMonth) {
              fields.months_operations.push(item.storeOperationMonth[month]=="Y")
            }            
            fields.distribution_option = []
            props.mapdata.orderOptions.map(order => {
              fields.distribution_option.push(item.storeOrderTypeDetail[order.id]=="Y")
            })
            fields.othertext = item?.storeOrderTypeDetail?.['otherTypeDescription'];
            fields.working_hours = [];
            const storeHours = item.storehours.sort((a,b) => a.weekdayCode - b.weekdayCode);
            storeHours.map((day)=>{
              fields.working_hours.push({
                day: day.weekdayDescription,
                open_time: day.startTime.split(" ")[0],
                open_time_meridian: day.startTime.split(" ")[1],
                close_time: day.endTime.split(" ")[0],
                close_time_meridian: day.endTime.split(" ")[1],
                holiday: day.isWorkingDay=="N",
              })
            })
            fields.is_specialhours = item.specialOperationFlag == "Y"
            const shData = item.storeSpecialOperation.map((speitem)=>{
                let shObj = {};
                shObj.open_time = speitem.startTime.split(" ")[0];
                shObj.open_time_meridian = speitem.startTime.split(" ")[1];
                shObj.close_time = speitem.endTime.split(" ")[0];
                shObj.close_time_meridian = speitem.endTime.split(" ")[1];
                shObj.day = props.mapdata.days.map(day=>({
                    label: day.name,
                    value: day.values,
                    checked: speitem.dayInfo.split(",").includes(day.weekDay),
                    id: day.id,
                }));
                shObj.month = props.mapdata.months.map(month=>({
                    label: month.values,
                    value: month.values,
                    checked: speitem.monthInfo.split(",").includes(month.month),
                    id: month.id,
                }));
                shObj.status= speitem.specialStatusDescription;
                shObj.time_filter = speitem.allDayOperation == "Y" ? "All Day" : "Custom";
                shObj.week= speitem.weekInfo;
                return shObj;
            });
            let prgmObj = {
                name: fields,
                special_hoursOfOperation: shData
            };         

            prgmData.push(prgmObj);
        });                
    }
    setExistPgmData((prevState)=>({
        ...prevState,
        [type]:prgmData
    }));
};

  useEffect(()=>{
    if (activeTabId) { 
      setProgramArray(getProgramArray(activeTabId));
    }
  },[existPgmData]);

  const {
    register: register2,
    // formState: { errors: errors2 },
    handleSubmit: handleSubmit2,
    // getValues,
    // control,
    // watch,
    // formState: { errors },
  } = useForm({
    defaultValues: locType
  });

  const onLocTypeSubmit = () => {
    setActiveTab(newmenus?.[0]?.menu);
    setActiveTabId(newmenus?.[0]?.id);
  };

//   const onSaveAndNext = () => {
//     const menuIndex = newmenus?.findIndex((list) => list?.menu === activeTab);
//     if (menuIndex >= 0) {
//       setActiveTab(newmenus[menuIndex + 1]?.menu);
//       setActiveTabId(newmenus[menuIndex + 1]?.id);
//     }
//   };

  const getLocType = (e) => {
    setRestoreLocType(false);
    const values = {...locType};
    let locName = e.target.name;
    if (e.target.name === "farmersMarketBooth") {
      locName = "farmersMarket";
    } else if (e.target.name==="farmersMarket") {
      locName = "farmersMarketManagers";
    }
    if (values[e.target.name] && vendorInfo?.[locName] === "Y" && e.target.id === e.target.name+"_no") {
      popUpAlert({
        icon: "warning",
        title: "Are you sure?",
        text: "Remove this location of this type? Your data will be deleted." ,
        color:"#545454",
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonClass: "btn-success",
        confirmButtonText: "Yes",
        cancelButtonText:"No",
        cancelButtonColor: "#AAAAAA",
        closeOnConfirm: false,
        onConfirmed: true,
        handleConfirm:()=>{
          setRestoreLocType(false);
          const data = {
            name: {
              vendorId: vendorInfo?.vendorId,
              csaPickupSite: e.target.name === "csaPickupSite" ? "Y" :"N",
              farmStand: e.target.name === "farmStand" ? "Y" :"N",
              farmersMarket: e.target.name === "farmersMarketBooth" ? "Y" :"N",
              farmersMarketManagers: e.target.name === "farmersMarket" ? "Y" :"N",
              mobileMarket: e.target.name === "mobileMarket" ? "Y" :"N",
              id: 0
            }
          }
          props.deleteStore(data);
          setLocType((prevState)=>({
            ...prevState,
            [e.target.name]: false
          }));
        },
        handleDismiss: ()=>{
          setRestoreLocType(true);
        }
      })
    } else {
        values[e.target.name] = e.target.value === "true" || e.target.value === true ? true : false;
        setLocType((prevState)=>({
            ...prevState,
            [e.target.name]: values[e.target.name]
        }));
        if (e.target.name === "farmersMarket") {
          if (e.target.value === true || e.target.value === "true") {
            setIsDisblOthrChkbx(true);
            setIsDisblMrkMngrChkbx(false);
          } else {
            setIsDisblOthrChkbx(false);
            setIsDisblMrkMngrChkbx(false);
            setEnableMenuList([]);
          }
          setEnableMenuList([e.target.name]);
        } else {
          const menuItem = Object.entries(values).filter(([, v]) => (v === true || v === "true")).map(([k]) => k);
          if (menuItem.length > 0 && menuItem.indexOf("farmersMarket") === -1) {
            setIsDisblMrkMngrChkbx(true);
          } else {
            setIsDisblMrkMngrChkbx(false);
          }
          if (e.target.value === true || e.target.value === "true") {
            setIsDisblMrkMngrChkbx(true);
          }
          setEnableMenuList(menuItem);
      }
    }
  };

  const getSelectedSection = (id) => {
    const section = sections.find(item => item.id === id);
    return section;   
  };

  const addProgramData=(data)=>{
    props.addProgram({vendorStores:data});
    setIsLoading(true);
    // if(type === "finish") {
    //     navigate("/");
    // } else {
    //     onSaveAndNext();
    // }
  };

  useEffect(()=>{
    if (props?.user?.program && props?.user?.program?.code === "200") {
      setIsLoading(false);
    }
  },[props?.user])

  const removeVendorStore=(id)=>{
    props.removeVendorStore(id);
  };

  const locShowHide=(id,showHide)=>{
    props.locShowHide(id,showHide);
  };

  const getProgramArray = (actvTabId) =>{
    let programArray = [];
    const clone = _.cloneDeep(ProgramSectionData);
    if (existPgmData && existPgmData[actvTabId]) {
      existPgmData[actvTabId].map((item)=>{
          let programObj = item;
          item.id = uuidv4();
          if (Array.isArray(item.special_hoursOfOperation) && item.special_hoursOfOperation.length === 0) {
              programObj.special_hoursOfOperation = [{...clone[actvTabId].special_hoursOfOperation[0]}];
          }
          programObj.updated = item.updated;
          programArray.push(programObj);
      });
    } else {
      programArray = [{
          name: { working_hours: clone[actvTabId]?.working_hours, storeAvailFlag: true},
          special_hoursOfOperation: [{...clone[actvTabId]?.special_hoursOfOperation[0]}],
          updated: false
      }];
    }
    return programArray;
  }

  const cityList = sortName(props.city.map((item) => ({"name":item.cityName,"values":item.cityName})));

  const manageStoreData = (data, storeData) =>{
    if (data?.csaPickupSite=="Y") {
      let typeData = filterTypes(storeData,"csaPickupSite");
      if(typeData["csaPickupSite"].length){
        setLocType((prevState)=>({
          ...prevState,
          csaPickupSite: true
        }));
        enableMenuList.push("csaPickupSite");
        setProgramData(typeData,"csaPickupSite");
      }
      setIsDisblMrkMngrChkbx(true);
    }
    if (data?.farmStand=="Y") {
      setIsDisblMrkMngrChkbx(true);
      let typeData = filterTypes(storeData,"farmStand");
      if(typeData["farmStand"].length){
        setLocType((prevState)=>({
          ...prevState,
          farmStand: true
        }));
        enableMenuList.push("farmStand");
        setProgramData(typeData,"farmStand");
      }
    } 
    if (data?.farmersMarket == "Y") {
      setIsDisblMrkMngrChkbx(true);
      let typeData = filterTypes(storeData,"farmersMarket","farmersMarketBooth")
      if(typeData["farmersMarketBooth"].length){
        enableMenuList.push("farmersMarketBooth");
        setLocType((prevState)=>({
            ...prevState,
            farmersMarketBooth: true
        }));
        setProgramData(typeData,"farmersMarketBooth");
      }  
    }
    if (data?.mobileMarket=="Y") {
      setIsDisblMrkMngrChkbx(true);
      let typeData = filterTypes(storeData,"mobileMarket");
      if(typeData["mobileMarket"].length){
        enableMenuList.push("mobileMarket");
      setLocType((prevState)=>({
          ...prevState,
          mobileMarket: true
      }));
      setProgramData(typeData,"mobileMarket");
      }
    } 
    if (data?.farmersMarketManagers == "Y") { 
      setIsDisblOthrChkbx(true);
      let typeData = filterTypes(storeData,"farmersMarketManagers","farmersMarket");
      if(typeData["farmersMarket"].length){ 
        enableMenuList.push("farmersMarket");
        setLocType((prevState)=>({
            ...prevState,
            farmersMarket: true
        }));
        setProgramData(typeData,"farmersMarket");
      }
    }
    setEnableMenuList([...new Set(enableMenuList)]);
    if (enableMenuList) {
        let enableList = [...new Set(enableMenuList)];
      let dummy = [];
      dummy = locationTypeCollection.filter((item) => enableList.includes(item.id));
      dummy = dummy.map((item, i) => {
        let menus = {
          id: item.id,
          menu: `menuStep${i + 4}`,
          values: locationTypeCollection.filter((val) => val.id === item.id)
        };
        return menus;
      });
      setNewMenus(dummy);
    }
  }

  useEffect(() => {
    if (isStoreUpdate && props?.vendorDetails?.myStoreDetailsList && 'data' in props?.vendorDetails?.myStoreDetailsList && props?.vendorDetails?.myStoreDetailsList?.data?.length) {
      const data = enableMenuList.reduce((a, v) => ({ ...a, [v]: 'Y'}), {}); //vendorInfo; 
      const storeData = props?.vendorDetails?.myStoreDetailsList?.data;
      if(storeData?.length!=0){
        // manageStoreData(data,storeData);

        if (data?.csaPickupSite=="Y") {
          let typeData = filterTypes(storeData,"csaPickupSite");
          if(typeData["csaPickupSite"].length){
            setLocType((prevState)=>({
              ...prevState,
              csaPickupSite: true
            }));
            setProgramData(typeData,"csaPickupSite");
          }
          setIsDisblMrkMngrChkbx(true);
        }
        if (data?.farmStand=="Y") {
          setIsDisblMrkMngrChkbx(true);
          let typeData = filterTypes(storeData,"farmStand");
          if(typeData["farmStand"].length){
            setLocType((prevState)=>({
              ...prevState,
              farmStand: true
            }));
            setProgramData(typeData,"farmStand");
          }
        } 
        if (data?.farmersMarketBooth == "Y") {
          setIsDisblMrkMngrChkbx(true);
          let typeData = filterTypes(storeData,"farmersMarket","farmersMarketBooth")
          if(typeData["farmersMarketBooth"].length){
            setLocType((prevState)=>({
                ...prevState,
                farmersMarketBooth: true
            }));
            setProgramData(typeData,"farmersMarketBooth");
          }  
        }
        if (data?.mobileMarket=="Y") {
          setIsDisblMrkMngrChkbx(true);
          let typeData = filterTypes(storeData,"mobileMarket");
          if(typeData["mobileMarket"].length){
            setLocType((prevState)=>({
                ...prevState,
                mobileMarket: true
            }));
            setProgramData(typeData,"mobileMarket");
          }
        } 
        if (data?.farmersMarket == "Y") { 
          setIsDisblOthrChkbx(true);
          let typeData = filterTypes(storeData,"farmersMarketManagers","farmersMarket");
          if(typeData["farmersMarket"].length){ 
            setLocType((prevState)=>({
                ...prevState,
                farmersMarket: true
            }));
            setProgramData(typeData,"farmersMarket");
          }
        }
      }
      setIsStoreUpdate(false);
    }
}, [props?.vendorDetails?.myStoreDetailsList]);

  useEffect(()=>{
    setIsStoreUpdate(true);
    console.log("After store add/update", props?.user?.program)
    const payload = {
      "vendorId": vendorInfo?.vendorId
    }
    props.getMyStoreDetails(payload);
    props.getVendorInfoList(payload);    
  },[props?.user?.program]);

  useEffect(() => {
    setVendorInfo(props.vendorDetails.vendorInfoList);
  }, [props.vendorDetails.vendorInfoList]);

  return (
    <div className="container-fluid ven-reg-pg">
      { isLoading && 
        <CommonLoading />
      }
      <div className="row">
        <div className="col-md-12">
          <div className="row">
            <SidebarNavigation
              staticMenu={staticMenu}
              newmenus={newmenus}
              dynamicMenu={props.loctype}
              dynamicMenuEnable={locType}
              activeTab={activeTab}
              activeTabId={activeTabId}
              onMenuClick={(v, id, activeMenus) => {
                setActiveTab(v);
                setActiveTabId(id);
                if (activeMenus) {
                  setEnableMenuList(activeMenus);
                } 
                setProgramArray(getProgramArray(id));          
              }}
            />
            <div className="col-md-9">           
              <section
                className={`dh-reg-wizards dh-reg-step-2 mt-3 mt-sm-0 mt-md-0 mt-lg-0 ${activeTab === "menuStep3" ? "" : "hide"
                  }`}
                id="reg-menuStep3"
              >
                <form onSubmit={handleSubmit2(onLocTypeSubmit)}>
                  <div className="dh-head">
                    <div className="row">
                      <div className="col-md-12">
                        <h4>Location Types</h4>
                      </div>
                    </div>
                  </div>

                  <div className="dh-content">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="dh-box-mr">
                          {props.mapdata.vendorType &&
                            props.mapdata.vendorType.map((ques, i) => (
                              <div
                                key={`ques_${i}`}
                                className="form-text text-muted dh-short-title"
                              >
                                {ques.dec}
                                <br />
                                <div className="hip-pgm-btn">
                                  <div
                                    className={`dh-opt-round csa-program-btn store-program ${(isDisblOthrChkbx &&
                                      ques.name !==
                                      "farmersMarket") ||
                                      (isDisblMrkMngrChkbx &&
                                        ques.name === "farmersMarket")
                                      ? "dh-opt-disabled"
                                      : ""
                                      }  ${locType[ques.name] === "true" ||
                                        locType[ques.name] === true
                                        ? "dh-opt-active"
                                        : ""
                                      }`}
                                    id={`btn_${ques.name}_yes`}
                                  >
                                    <div className="custom-control custom-radio custom-control-inline p-0 m-0">
                                      <input
                                        {...register2(ques.name)}
                                        onClick={getLocType}
                                        type="radio"
                                        value={true}
                                        id={`${ques.name}_yes`}
                                        className="form-check-input"
                                        aria-label={ques.name}
                                        disabled={
                                          (isDisblOthrChkbx &&
                                            ques.name !==
                                            "farmersMarket") ||
                                          (isDisblMrkMngrChkbx &&
                                            ques.name ===
                                            "farmersMarket")
                                        }
                                      />
                                      <label
                                        className="custom-control-label"
                                        htmlFor={`${ques.name}_yes`}
                                      >
                                        Yes
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    className={`dh-opt-round ${ques.name
                                      }_btn store-program  ${(isDisblOthrChkbx &&
                                        ques.name !==
                                        "farmersMarket") ||
                                        (isDisblMrkMngrChkbx &&
                                          ques.name === "farmersMarket")
                                        ? "dh-opt-disabled"
                                        : ""
                                      } ${locType[ques.name] === "false" ||
                                        locType[ques.name] === false
                                        ? "dh-opt-active"
                                        : ""
                                      }`}
                                    id={`btn_${ques.name}_no`}
                                  >
                                    <div className="custom-control custom-radio custom-control-inline p-0 m-0">
                                      <input
                                        {...register2(ques.name)}
                                        onClick={getLocType}
                                        type="radio"
                                        id={`${ques.name}_no`}
                                        className="form-check-input"
                                        value={false}
                                        aria-label={ques.name}
                                        disabled={
                                          (isDisblOthrChkbx &&
                                            ques.name !==
                                            "farmersMarket") ||
                                          (isDisblMrkMngrChkbx &&
                                            ques.name ===
                                            "farmersMarket")
                                        }
                                      />
                                      <label
                                        className="custom-control-label"
                                        htmlFor={`${ques.name}_no`}
                                      >
                                        No
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </section>
              {
                enableMenuList && enableMenuList.length > 0 && enableMenuList.indexOf(activeTabId) != -1 &&  
                    dynamicMenuList.find(data=>data.id === activeTabId).id === activeTabId ? (
                    <section
                        className={`dh-reg-wizards dh-reg-step-2 mt-3 mt-sm-0 mt-md-0 mt-lg-0`}
                        id={`reg-${dynamicMenuList.find(data=>data.id === activeTabId).menu }`}
                    >
                        {
                            props.sections && props.sections.length > 0 ?
                                <SignupForm
                                    type={"edit"}
                                    vendorType={props.mapdata.vendorType}
                                    location_types_name={activeTabId}
                                    existPgmData = {existPgmData}
                                    programData= { ProgramSectionData }
                                    enableMenuList={enableMenuList}
                                    section={getSelectedSection(activeTabId)}
                                    city={cityList}
                                    days={props.mapdata.days}
                                    months={props.mapdata.months}
                                    order_option={props.mapdata.orderOptions}
                                    activeTabId = {activeTabId}
                                    addProgramData={addProgramData}
                                    removeVendorStore={removeVendorStore}
                                    locShowHide={locShowHide}
                                    farmermarkets={props?.farmersList}
                                    vendorInfo={vendorInfo}
                                    userAuth={props?.userAuth}
                                    user={props?.user}
                                    deleteStore = {props?.deleteStore}
                                    programArray = { programArray }
                                />
                            : ""                
                        }                    
                    </section>
                  ) : ""
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

VendorLocDetails.propTypes = {
    vendorDetails: PropTypes.object,
    addUser: PropTypes.func.isRequired,
    updateUser: PropTypes.func.isRequired,
    user: PropTypes.object,
    getLocTypeQues: PropTypes.func.isRequired,
    locTypeQues: PropTypes.object.isRequired,
    getLocTypeValues: PropTypes.func.isRequired,
    loctype: PropTypes.object.isRequired,
    selectedLanguage: PropTypes.string,
    sections: PropTypes.array,
    locTypeProgramQuestionsSections: PropTypes.func.isRequired,
    getUser: PropTypes.func.isRequired,
    userinfo: PropTypes.object.isRequired,
    getCityList: PropTypes.func.isRequired,
    // regCity: PropTypes.object,
    getMonthOption: PropTypes.func.isRequired,
    regDays: PropTypes.object,
    getDayOption: PropTypes.func.isRequired,
    regMonths: PropTypes.object,
    getOrderOption: PropTypes.func.isRequired,
    reg_orderoption: PropTypes.object,
    addProgram: PropTypes.func.isRequired,
    removeVendorStore: PropTypes.func.isRequired,
    locShowHide: PropTypes.func.isRequired,
    // updateProgram: PropTypes.func.isRequired,
    myStoreDetails:PropTypes.array,
    isLoading: PropTypes.bool,
    vendorInfo: PropTypes.object,
    farmersList:PropTypes.array,
    city:PropTypes.array,
    mapdata:PropTypes.object,
    userAuth: PropTypes.object,
    deleteStore: PropTypes.func,
    getMyStoreDetails: PropTypes.func,
    getVendorInfoList: PropTypes.func
  };

const mapDispatchToProps = (dispatch) => {
  return {
    locTypeProgramQuestionsSections: (val) => dispatch(locTypeProgramQuestionsSections(val)),
    getMyStoreDetails:(payload) => dispatch(getMyStoreDetails(payload)),
    getVendorInfoList:(payload) => dispatch(getVendorInfoList(payload)),
    addUser: (user) => {
      dispatch({ type: SET_USER, value: user });
    },
    getUser: () => {
      dispatch({ type: GET_USER });
    },
    editUser: (userId) => {
      dispatch({ type: EDIT_USER, value: userId });
    },
    updateUser: (user) => {
      dispatch({ type: PUT_USER, value: user });
    },
    getLocTypeValues: () => {
      dispatch({ type:GET_LOC_TYPE  });
    },
    getLocTypeQues: () => {
      dispatch({ type: GET_LOC_TYPE_QUES });
    },
    getCityList: () => {
        dispatch({ type: GET_CITY });
    },
    getOrderOption: () => {
         dispatch({ type: GET_ORDER_OPTION });
    },
    getMonthOption: () => {
        dispatch({ type: GET_MONTH_OPTION });
    },
    getDayOption: () => {
        dispatch({ type: GET_DAY_OPTION });
    },
    addProgram: (program) => {
      dispatch({ type: SET_PROGRAM, value: program });
    },
    removeVendorStore: (id) => {
        dispatch({ type: SET_REMOVE_VENDOR_STORE, value: id });
    },
    locShowHide: (id,flag)=>{
        dispatch({ type: SET_LOC_SHOWHIDE, value: {id:id,show:flag} });
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VendorLocDetails);
