// Vendor Details View/Update

// export const GET_VENDOR_PUBLIC_DETAILS = "GET_VENDOR_PUBLIC_DETAILS";
// export const GET_VENDOR_PUBLIC_DETAILS_ASYNC = "GET_VENDOR_PUBLIC_DETAILS_ASYNC";
// export const GET_VENDOR_PUBLIC_DETAILS_SUCCESS = "GET_VENDOR_PUBLIC_DETAILS_SUCCESS";
// export const GET_VENDOR_PUBLIC_DETAILS_FAILURE = "GET_VENDOR_PUBLIC_DETAILS_FAILURE";
export const GET_USER_DETAILS = "GET_USER_DETAILS";
export const GET_USER_DETAILS_SUCCESS = "GET_USER_DETAILS_SUCCESS";
export const GET_USER_DETAILS_FAILURE = "GET_USER_DETAILS_FAILURE";
// export const UPDATE_VENDOR_STORE_DETAILS = "UPDATE_VENDOR_STORE_DETAILS";
// export const UPDATE_VENDOR_STORE_DETAILS_SUCCESS = "UPDATE_VENDOR_STORE_DETAILS_SUCCESS";
// export const UPDATE_VENDOR_STORE_DETAILS_FAILURE = "UPDATE_VENDOR_STORE_DETAILS_FAILURE";
export const GET_VENDORID_SUCCESS = "GET_VENDORID_SUCCESS";
export const GET_VENDORID_FAILURE = "GET_VENDORID_FAILURE";
export const GET_VENDORID = "GET_VENDORID";
export const GET_VENDOR_LOC_DETAILS_SUCCESS = "GET_VENDOR_LOC_DETAILS_SUCCESS";
export const GET_VENDOR_LOC_DETAILS_FAILURE = "GET_VENDOR_LOC_DETAILS_FAILURE";
export const GET_VENDOR_LOC_DETAILS = "GET_VENDOR_LOC_DETAILS";
export const SET_REMOVE_VENDOR_STORE = "SET_REMOVE_VENDOR_STORE";
export const GET_REMOVE_STORE_SUCCESS = "GET_REMOVE_STORE_SUCCESS";
export const GET_REMOVE_STORE_FAILURE = "GET_REMOVE_STORE_FAILURE";

export const UPDATE_VENDOR_DETAILS_SUCCESS = "UPDATE_VENDOR_DETAILS_SUCCESS";
export const UPDATE_VENDOR_DETAILS_FAILURE = "UPDATE_VENDOR_DETAILS_FAILURE";
export const UPDATE_PUBLIC_DETAILS_SUCCESS = "UPDATE_PUBLI_DETAILS_SUCCESS";
export const UPDATE_PUBLIC_DETAILS_FAILURE = "UPDATE_PUBLIC_DETAILS_FAILURE";
export const UPDATE_VENDOR = "UPDATE_VENDOR";
export const UPDATE_PUBLIC = "UPDATE_PUBLIC";

export const SET_PROGRAM_UPDATE = "SET_PROGRAM_UPDATE";
export const SET_LOC_SHOWHIDE = "SET_LOC_SHOWHIDE";
export const GET_LOC_SHOWHIDE_SUCCESS = "GET_LOC_SHOWHIDE_SUCCESS";
export const GET_LOC_SHOWHIDE_FAILURE = "GET_LOC_SHOWHIDE_FAILURE";

export const actionTypes = {
    GET_MANAGE_VENDOR_LIST:"GET_MANAGE_VENDOR_LIST",
    SET_MANAGE_VENDOR_LIST:"SET_MANAGE_VENDOR_LIST",
    REFRESH_KEY: "REFRESH_KEY",
    MANAGE_DELETE_VENDOR_DETAILS: " MANAGE_DELETE_VENDOR_DETAILS",
    GET_FOLLOW_VENDOR_DETAILS:"GET_FOLLOW_VENDOR_DETAILS",
    SET_FOLLOW_VENDOR_DETAILS:"SET_FOLLOW_VENDOR_DETAILS",
    UPDATE_FOLLOW_VENDOR_DETAILS:"UPDATE_FOLLOW_VENDOR_DETAILS",
    UPDATE_VENDOR_BULK:"UPDATE_VENDOR_BULK",
    GET_VENDOR_HISTORY_LIST:"GET_VENDOR_HISTORY_LIST",
    SET_VENDOR_HISTORY_LIST:"SET_VENDOR_HISTORY_LIST",
    GET_STORE_HISTORY_LIST:"GET_STORE_HISTORY_LIST",
    SET_STORE_HISTORY_LIST:"SET_STORE_HISTORY_LIST",
    EXPORT_VENDOR_REPORT:"EXPORT_VENDOR_REPORT",
    EXPORT_VENDOR_LAST_UPDATE_REPORT:"EXPORT_VENDOR_LAST_UPDATE_REPORT",
    EXPORT_VENDOR_LOGIN_REPORT:"EXPORT_VENDOR_LOGIN_REPORT",
    GET_VENDOR_INFO_LIST:"GET_VENDOR_INFO_LIST",
    VERIFY_VENDOR: "VERIFY_VENDOR",
    SET_VENDOR_INFO_LIST:"SET_VENDOR_INFO_LIST",
    GET_MY_STORE_DETAILS:"GET_MY_STORE_DETAILS",
    SET_MY_STORE_DETAILS:"SET_MY_STORE_DETAILS",
    GET_REMOVE_VENDOR_STORE: "GET_REMOVE_VENDOR_STORE",
    SET_REMOVE_VENDOR_STORE: "SET_REMOVE_VENDOR_STORE",
    UPDATE_PRIMARY_EMAIL: "UPDATE_PRIMARY_EMAIL",
};

export  function getManageVendorList(payload) {
    return {
      type: actionTypes.GET_MANAGE_VENDOR_LIST,
      payload: payload
    };
}

export  function refreshKey() {
  return {
    type: actionTypes.REFRESH_KEY,
  };
}

export  function manageDeleteVendorDetails(payload) {
  return {
    type: actionTypes.MANAGE_DELETE_VENDOR_DETAILS,
    payload: payload
  };
}

export function getFollowVendorDetails(payload) {
  return {
    type: actionTypes.GET_FOLLOW_VENDOR_DETAILS,
    payload: payload
  };
}

export function updateFollowUpFlag(payload) {
  return {
    type: actionTypes.UPDATE_FOLLOW_VENDOR_DETAILS,
    payload: payload
  };
}

export function updatePrimaryEmail(payload) {
  return {
    type: actionTypes.UPDATE_PRIMARY_EMAIL,
    payload: payload
  };
}

export  function exportVendorReport(payload) {
  return {
    type: actionTypes.EXPORT_VENDOR_REPORT,
    payload: payload
  };
}

export  function exportVendorLastUpdateReport(payload) {
  return {
    type: actionTypes.EXPORT_VENDOR_LAST_UPDATE_REPORT,
    payload: payload
  };
}

export  function exportVendorLoginReport(payload) {
  return {
    type: actionTypes.EXPORT_VENDOR_LOGIN_REPORT,
    payload: payload
  };
}

export  function updateVendorBulk(payload) {
  return {
    type: actionTypes.UPDATE_VENDOR_BULK,
    payload: payload
  };
}

export  function getVendorHistoryList(payload) {
  return {
    type: actionTypes.GET_VENDOR_HISTORY_LIST,
    payload: payload
  };
}

export  function getStoreHistoryList(payload) {
  return {
    type: actionTypes.GET_STORE_HISTORY_LIST,
    payload: payload
  };
}

export function getVendorInfoList(payload) {
  return {
    type: actionTypes.GET_VENDOR_INFO_LIST,
    payload: payload
  };
}

export function verifyVendor(payload) {
  return {
    type: actionTypes.VERIFY_VENDOR,
    payload: payload
  };
}

export function getMyStoreDetails(payload) {
  return {
    type: actionTypes.GET_MY_STORE_DETAILS,
    payload: payload
  };
}

export function removeVendorStore(payload) {
  return {
    type: actionTypes.GET_REMOVE_VENDOR_STORE,
    payload: payload
  };
}

