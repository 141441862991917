// import React from "react";
import React, { useCallback, useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
// import { GET_MAPDATA } from "../../redux/actionTypes";
import PropTypes from "prop-types";
import { mapStateToProps } from "../../redux/reducers";
import MapSearchResultComponent from "./mapsearchresult-component";
import Geocode from "react-geocode";
import spin_loader from "../../assets/images/spin-loader.svg";
import Typography from "@mui/material/Typography";
import ModalComponent from "../common/components/modal";
import "../../styles/dashboard.scss";
// import ReactLoading from "react-loading";
// import BlockUi from "react-block-ui";
// import "react-block-ui/style.css";
import LoadingComponent from "../common/components/loadingComponent";

import { GoogleMap, useJsApiLoader, MarkerClusterer, Marker, Circle, InfoWindow } from "@react-google-maps/api";

import user_marker from "../../assets/images/marker/user-marker.png";
import { GET_SPECIAL_HOUR, GET_STORE_DETAILS } from "../../redux/actionTypes";
import { GOOGLE_KEY } from "../../config";
import csa_farms from "../../assets/images/marker/csa-farms.png";
import farm_stands from "../../assets/images/marker/farm-stands.png";
import farmers_market from "../../assets/images/marker/farmers-market-managers.png";
import farmers_market_managers from "../../assets/images/marker/market-managers.png";
import mobile_market from "../../assets/images/marker/mobile-market.png";
import blue_cluster from "../../assets/images/marker/blue-cluster.png";
import yellow_cluster from "../../assets/images/marker/yellow-cluster.png";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";

var center = { lat: 42.2625932, lng: -71.8022934, address: "Massachusetts Center", city: "Boston" };

const centerLocation = {
  lat: 42.2625932,
  lng: -71.8022934,
  address: "Massachusetts Center",
  city: "Boston",
  located: false
};
const circleOptions = {
  strokeColor: "grey",
  strokeOpacity: 0.8,
  strokeWeight: 0,
  fillColor: "#2e6e53",
  fillOpacity: 0.35,
  // center: center,
  map: center,
  radius: 8046.7,
};

const LOCATION_TYPES = {
  csaPickupSite: {
    loc_type_nam: "HIP CSA Program",
    loc_type_img: "./marker/csa-farms.png",
  },
  farmStand: {
    loc_type_nam: "HIP Farm Stand",
    loc_type_img: "./marker/farm-stands.png",
  },
  farmersMarket: {
    loc_type_nam: "HIP Farmers' Market Booth",
    loc_type_img: "./marker/farmers-market-managers.png",
  },
  farmersMarketManagers: {
    loc_type_nam: "HIP Farmers Market",
    loc_type_img: "./marker/market-managers.png",
  },
  mobileMarket: {
    loc_type_nam: "HIP Mobile Market Stop",
    loc_type_img: "./marker/mobile-market.png",
  },
};

const getIcon = (location) => {
  let icon =LOCATION_TYPES[location["location_types_name"]]?.loc_type_img;
  return icon;
};

Geocode.setApiKey(GOOGLE_KEY);
Geocode.enableDebug();

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

const getImageTooltip = (name, lang)=>{
  switch (name) {
    case "csaPickupSite":
      return {
          img:csa_farms,
          tooltip: lang["CSA Program Tooltip"]//"A place where you can pick up farm-fresh HIP produce through a weekly membership program. You must sign up with the farm in advance."
      };
    case "farmersMarketManagers":
      return {
          img:farmers_market_managers,
          tooltip: lang["Farmer Market Tooltip"] //"A place where you can buy HIP produce from multiple farm tables by checking out at a central location."
      };
    case "farmStand":
      return  {
          img:farm_stands,
          tooltip: lang["Farm Stand Tooltip"] //"A stand or store where a farm sells HIP produce, usually located at or near the farm."
      };
    case "mobileMarket":
      return  {
          img:mobile_market,
          tooltip: lang["Market Stop Tooltip"]//"A pop-up location where you can buy fresh HIP produce delivered by a farm retailer."
      };
    case "farmersMarket":
      return  {
          img:farmers_market,
          tooltip: lang["Market Booth Tooltip"] //"A table at a farmers&apos; market where you can buy HIP produce directly from a farm. Some booths at the market may not process HIP – check to make sure!"
      };
    default:
      return {
          img:csa_farms,
          tooltip:"A place where you can pick up farm-fresh HIP produce through a weekly membership program. You must sign up with the farm in advance."
      };
  }
};



const MapWithMarkerClusterer = (props) => {
  const [mapZoom, setMapZoom] = useState(11);
  const [loadingStatus, setLoadingStatus] = useState(true); //console.log(mapLoadingStatus);
  const [mapCenterChanged, setMapCenterChanged] = useState(true);
  const [activeMarker, setActiveMarker] = useState(null);
  const [coordinates, setCoordinates] = useState(centerLocation);
  const [mapWidth, setMapWidth] = useState("100%");
  const [locationId, setLocationId] = useState(null);
  const [storeSingleSection, setSingleSection] = useState(false);
  const [searchStoreResultView, setSearchResultView] = useState(false);
  const [clickedMarkerClusterer, setClickedMarkerClusterer] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [mapRef, setMapRef] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [backBtn, setBackBtn] = useState(false);
  const [modalShown, toggleModal] = useState(false);
  const [mapZoomLevel, setMapZoomLevel] = useState([]);
  const [map, setMap] = useState(null);
  const [singleSectionClick,setSingleSelectionClick]=useState(false);
  const [circleOption, setCircleOption] = useState(circleOptions);
  const [initStoreView, setInitStoreView] = useState(false);
  const [mapKeyTooltip, setMapKeyTooltip] = useState();
  const mapKeyRef = useRef(null);
  const executeScroll = () => mapKeyRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  const wrapperRef = useRef(null);

  /**
   * Hook that alerts clicks outside of the passed ref
   */
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setMapKeyTooltip(0);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_KEY
  });

  useEffect(()=>{
    setInitStoreView(props.initialStoreView);
  },[props.initialStoreView]);

  useEffect(() => {
    if (props.searchProperties.city === "all") {
        setMapZoom(8);
    }  else {
        setMapZoom(11);
    }
    if (props?.mapResult && props?.mapResult.length) {
      if (windowDimensions.width >= 768) {
        setMapWidth("60%");
      }
     setSingleSection(false);
     setSearchResultView(true);

    //  alert(props.searchProperties?.vendor_name +"||"+ props.searchProperties?.city)

     console.log("+++++++++++00000+++++++++++")
     console.log(props.searchProperties);
     console.log("+++++++++++00000+++++++++++")

      if (props.searchProperties?.vendor_name !== 'all' && props.searchProperties?.city === 'all') {
        console.log("+++++++++++1111111+++++++++++")
        console.log(coordinates);
        console.log("+++++++++++11111111+++++++++++")
        setCoordinates((prevState) => ({
          ...prevState,
          lat: props?.mapResult?.[0].latitude ? parseFloat(props?.mapResult?.[0].latitude) : "",
          lng: props?.mapResult?.[0].longitude ? parseFloat(props?.mapResult?.[0].longitude) : "",
          city: "Boston",
          located: false,
          user_marker: props.searchProperties.is_mylocation,
        }));
      } else {
        console.log("+++++++++++222222+++++++++++")
        console.log(coordinates);
        console.log("+++++++++++22222+++++++++++")
        setCoordinates((prevState) => ({
          ...prevState,
          lat: parseFloat(props.searchProperties.latitude),
          lng: parseFloat(props.searchProperties.longitude),
          city: (props.searchProperties.city === "all") ? "Boston" : props.searchProperties.city,
          located: (props.searchProperties.city === "all") ? false : true,
          user_marker: props.searchProperties.is_mylocation,
        }));
      }
    } else {
      console.log("+++++++++++3333333+++++++++++")
      console.log(coordinates);
      console.log("+++++++++++3333333+++++++++++")
      setCoordinates((prevState) => ({
        ...prevState,
        lat: parseFloat(props.searchProperties.latitude),
        lng: parseFloat(props.searchProperties.longitude),
        city: (props.searchProperties.city === "all") ? "Boston" : props.searchProperties.city,
        located: (props.searchProperties.city === "all") ? false : true,
        user_marker: props.searchProperties.is_mylocation,
      }));
    }
    setActiveMarker(null);
    setClickedMarkerClusterer([]);  
    
  }, [
    props?.mapResult, props.searchProperties
]);

  useEffect(()=>{
    const timeoutLimit = (props?.mapResult && props?.mapResult.length) ? 2000 : 0;
    if (props.mapLoad === true) {
      setTimeout(()=>{
        props.setMapLoad(false);
      },timeoutLimit);
    }
  },[props?.mapResult]);

  // useEffect(() => {
  //   if (props.searchProperties.city === "all") {
  //       setMapZoom(8);
  //   }  else {
  //       setMapZoom(11);
  //   }
  //  setCoordinates((prevState) => ({
  //       ...prevState,
  //       lat: parseFloat(props.searchProperties.latitude),
  //       lng: parseFloat(props.searchProperties.longitude),
  //       city: (props.searchProperties.city === "all") ? "Boston" : props.searchProperties.city,
  //       located: (props.searchProperties.city === "all") ? false : true,
  //       user_marker: props.searchProperties.is_mylocation,
  //   }));
  // }, [props.searchProperties]);


  useEffect(() => {
    let radius = 8046.7;
    let area = "";
    if (props?.searchProperties?.area && props?.searchProperties?.area.name !== "") {
        radius = parseInt(props?.searchProperties?.area.values.split(" ")[0]) * 1609.34;
        area = parseInt(props?.searchProperties?.area.values.split(" ")[0]);
    }
    setCircleOption((prevState)=>({
        ...prevState,
        radius: radius
    }));

    if (map) {
        map.setZoom(null);
        let zoom = props?.searchProperties?.city === 'all' ? 8 : 11;
      
        if (area && area > 5) {
            switch(area) {
                case 10:
                    zoom = 10;
                    break;
                case 15:
                    zoom = 10;
                    break;
                case 20:
                    zoom = 9;
                    break;
                case 25:
                    zoom = 9;
                    break;
                case 30:
                    zoom = 9;
                    break;
                default:
                    zoom = 11;
            }          
            map.setZoom(zoom);
        } else {
            switch(area) {
                case 5:
                    zoom = 11;
                    break;
                case 2:
                    zoom = 12;
                    break;
                case 1:
                    zoom = 13;
                    break;                
                default:
                    zoom = 11;
            }
            zoom = props?.searchProperties?.city === 'all' ? 8 : zoom;
            map.setZoom(zoom);
        }
    }
  }, [props?.searchProperties?.area, props.mapResult]);//props.searchProperties.open_today,props.searchProperties.days

  const closeResultSection = (width) => {
    setMapWidth(width);
    setSingleSection(false);
    setSearchResultView(false);
  };

  const storeDetails = (id)=>{   
    if(id){
      setSingleSection(true);
      setActiveMarker(id);
      setLocationId(id);
      setSearchResultView(false);
      setSingleSelectionClick(true);
      let payload = {
        "cityName": "",
        "miles": "",
        "lat": "",
        "lon": "",
        "allCityFlag": "N",
        "vendorId": "",
        "csaPickupSite": "Y",
        "farmStand": "Y",
        "farmersMarket": "Y",
        "farmersMarketBooth": "Y",
        "mobileMarket": "Y",
        "monthsOperation": {
            "janOperation": "Y",
            "febOperation": "Y",
            "marOperation": "Y",
            "aprOperation": "Y",
            "mayOperation": "Y",
            "junOperation": "Y",
            "julOperation": "Y",
            "augOperation": "Y",
            "sepOperation": "Y",
            "octOperation": "Y",
            "novOperation": "Y",
            "decOperation": "Y"
        },
        "distributionOption": {
            "delivery": "Y",
            "curbsidePickup": "Y",
            "onlinePreselection": "Y",
            "phonePreselection": "Y",
            "inPerson": "Y",
            "otherType": "Y"
        },
        "monOperation": "Y",
        "tueOperation": "Y",
        "wedOperation": "Y",
        "friOperation": "Y",
        "thuOperation": "Y",
        "satOperation": "Y",
        "sunOperation": "Y",
        "openToday": "N",
        "storeId": id
      }
      props.getStoreDetails(payload);
    } else {
      setActiveMarker(null);
      setSingleSection(false);
      setSearchResultView(true);
    }
  };

  const specialHourData=(id)=>{
    props.getSpecialHourData(id);
  };

  const onLoad = () => {
    // console.log("Circle onLoad circle: ", circle);
  };

  const onUnmount = () => { //map
//console.log(map);
  };

  const onMapLoad = useCallback((map) => {//map
    setLoadingStatus(false);  
    setMap(map);  
  },[]);

  // useEffect(()=>{
  //   setTimeout(()=>{
  //     setMapCenterChanged(false);
  //   },1000);
  // },[props.mapResult]);

  // useEffect(()=>{
  //   setMapCenterChanged(false);
  // },[props.mapdata.storeDetails]);

  const handleMarkerClustererClick=useCallback((markerClusterer)=>{
    setInitStoreView(true);
    const clickedMarkers = markerClusterer.getMarkers();
    const ids = clickedMarkers.map(m => m.id);
    if (mapZoomLevel) {
        setClickedMarkerClusterer(ids);
        setSearchResultView(true);
        setSingleSection(false);
        if (windowDimensions.width >= 768) {
          setMapWidth("60%");
        }
        const mapZoom = map.getZoom();
        setMapZoomLevel(oldArray => [{clickedMarkers:ids,zoom:mapZoom}, ...oldArray]);
    }
    props.setResultCollapsed(false);
  },[map]);

const handleClick=()=>{  
    setBackBtn(true);
    if(mapZoomLevel.length ){
        map.setZoom(mapZoomLevel[0].zoom);
        setClickedMarkerClusterer(mapZoomLevel[1]?.clickedMarkers ? mapZoomLevel[1]?.clickedMarkers : []);
        setMapZoomLevel(mapZoomLevel.filter(item => item.zoom !== mapZoomLevel[0].zoom));
    }else{
        map.setZoom(map.getZoom() - 2);
    }
    if (windowDimensions.width >= 768) {
      setMapWidth("60%");
    }
    setSingleSection(false);
    setSearchResultView(true);   
    props.setResultCollapsed(false);
};
  // We have to create a mapping of our places to actual Marker objects
  const markerLoadHandler = () => {//marker, place
    //   console.log(marker);
    //   console.log(place);
    // return setMarkerMap(prevState => {
    //   return { ...prevState, [place.id]: marker };
    // });
  };

  const renderModal = () => {
    return (
      <Typography mb={2} lineHeight="lg">
          <Typography variant="body2" display="inline" sx={{fontFamily:'Texta-book' , fontSize:'18px'}}>{"Farmers' markets can have multiple HIP vendors. Click on each farmers' market result to see how many HIP vendors sell there."}</Typography>
      </Typography>
    )
  }

   
  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    if (windowDimensions.width >= 768) {
      setMapWidth("60%");
    } else {
      props.setResultCollapsed(true);
    }
    storeDetails(marker)
  };

  const getVendorName = (loc) => {
    if (!(loc.location_types_name === "farmersMarket" || loc.location_types_name === "farmersMarketManagers")) {
      return loc?.vendorName ? loc?.vendorName?.replace(/&#38;/g,"’") : "";
    } else {
      return loc?.store?.siteName ? loc?.store?.siteName?.replace(/&#38;/g,"’") : "";
    }   
  }

  const renderMap = () => {
    return (<GoogleMap
      id="googleMap"
      //   ref={map => setMapRef(map)}
      ref={(ref) => setMapRef(ref)}
      // ref={map => map && map.fitBounds(bounds)}
      mapContainerStyle={{
        width:
        props.mapResult === undefined ||
        props.mapResult.length === 0
            ? "100%"
            : mapWidth,
        minHeight: "500px",
      }}
      zoom={mapZoom}
      //   onLoad={(map) => onMapLoad(map)}
      // Do stuff on map initial laod
      onLoad={onMapLoad}
      onUnmount={onUnmount}
      // Save the current center position in state
      // onCenterChanged={() => setCenter(mapRef.getCenter().toJSON())}
      center={coordinates}
      // onCenterChanged={()=>{setMapCenterChanged(true);}}
      options={{
        // zoom:11,
        // center:{center},
        zoomControl: true,
        draggable: true,
        scrollwheel: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: true,
        styles: [
          {
            featureType: "administrative",
            elementType: "geometry",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "poi",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "road",
            elementType: "labels.icon",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "transit",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
        ],
      }}
      
    >
      {props.mapResult !== undefined &&
      props.mapResult.length ? (
        <MarkerClusterer
          //   options={options}
          imagePath={
            "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m"
          }
          gridSize={40}
          maxZoom={16}
          zoomOnClick={true}
          onClick={handleMarkerClustererClick}
          //   noClustererRedraw={false}
        >
          {(clusterer) =>
            props.mapResult.map((location) => (
              // mapData.map((location) => (
              <Marker
                options={{ id: location.store.id }}
                key={location.store.id} //createKey(location)
                position={{
                  lat: parseFloat(location.latitude),
                  lng: parseFloat(location.longitude),
                }}
                clusterer={clusterer}
                title={location?.vendorName?.replace(/&#38;/g, "’")}
                animation={2}
                icon={getIcon(location)}
                id={location.store.id.toString()}
                onClick={() => {
                  setInitStoreView(true);
                  handleActiveMarker(location.store.id, location.vendorId)
                }}
                onLoad={(marker) => markerLoadHandler(marker, location)}
                // label={"new"}
                
              >
                {activeMarker === location.store.id ? (
                  <InfoWindow
                    position={{
                      lat: parseFloat(location.latitude),
                      lng: parseFloat(location.longitude),
                    }}
                    onCloseClick={() => {
                      setActiveMarker(null);
                    }}
                  >
                    <div id="content">
                      <div id="siteNotice"></div>
                      <h4
                        id="firstHeading"
                        className="text-color p-none mt-4"
                      >
                        {" "}
                        {getVendorName(location)}{" "}
                      </h4>
                      <div id="bodyContent">
                        <small>
                          {
                            LOCATION_TYPES[location["location_types_name"]]
                            ?.loc_type_nam
                          }{" "}
                        </small>
                      </div>
                    </div>
                  </InfoWindow>
                ) : null}
              </Marker>
            ))
          }
        </MarkerClusterer>
      ) : coordinates ? (
        <Marker
          position={coordinates}
          icon={coordinates?.user_marker ? user_marker : ""}
          animation={2}
        />
      ) : (
        ""
      )}
      {!(props.searchProperties.city === "all") && (
        <Circle
          onLoad={onLoad}
          // optional
          onUnmount={onUnmount}
          // required
          center={coordinates}
          // required
          options={circleOption}
        />
      )}
    </GoogleMap>)
  }

  return (
    <>
      {(props.mapLoad) && <div className="notificationPanel">
          <div className="alert alert-info">
              <div className="loading">
                  <img src={spin_loader} alt="spin-loader"/>
              </div>{" "}
              <div className="loading-process">
                Processing...
              </div>
          </div>
      </div>}
      
        {modalShown &&
          <ModalComponent 
            shown = {modalShown}
            close = {() => {
              toggleModal(false);
            }}
            title = {"INFORMATION"}
            modalBody = {renderModal}
            size={"lg"}
            centered={true}
          />
        }
        
        <div className="searchMessage bg-white">
        {!props.loading && ( <small id="allSearchRslt" className="boldFont text-brand">
            <i className="fa fa-map-marker mr-1"></i>
            <span id="rangeMsg">
              {props.searchProperties.city === "all"
                ? "Statewide"
                : "Within " + props.searchProperties.area.values}
            </span>
            &nbsp;from&nbsp;
            <span id="cityMsg">
              {props.searchProperties.city === "all"
                ? "Massachusetts"
                : props.searchProperties.is_mylocation
                ? "your location"
                : props.searchProperties.city}
            </span>
            ,
            <strong>
              <span id="storeCountMsg">
                {props.mapResult === undefined ||
                props.mapResult.length  === 0
                  ? " no vendor available"
                  : " " + props.mapdata.storesTotalCount +
                    " HIP result(s) at " + props.mapdata.storesLocationCount + " location(s)"}
              </span>
            </strong>{" "}
            <span id="storeLocation"> </span>
            {props.mapResult  !== undefined &&
              props.mapResult.length > 0 && (
                <span
                  className="resultInfo"
                  onClick={() => {
                    toggleModal(!modalShown);
                  }}
                >
                  <a data-toggle="tooltip" title="Information" href="javascript:void(0)">
                    <i
                      className="fa fa fa-info-circle ml-2"
                      aria-hidden="true"
                    ></i>
                  </a>
                </span>
              )}
        </small>)}
        </div>
        
        <div
          className={`resetZoomFilter ${
            clickedMarkerClusterer.length > 0 ? "" : "d-none"
          } `}
          id="showResetZoom"
          style={{ right: mapWidth === "60%" && windowDimensions.width >= 768? "40.3%" : "4px" }}
        >
          <button
            className="btn btn-default back-btn btn-block btn-lg reSizeFont text-overflow searchBtn filterHover"
            onClick={handleClick}
            title="Back"
          >
            <i className="fa fa-arrow-left mr-1"></i>
            Back
          </button>
        </div>      
        { windowDimensions.width < 768 && (
          <div className="legendButtonSm d-md-none d-lg-none ">
            <button onClick={executeScroll} className="btn btn-default back-btn btn-block btn-lg reSizeFont searchBtn filterHover"
                    title={props.language["Legend"]} style={{padding:"3px 26px"}}><i
                    className="fa fa-long-arrow-down mr-1"></i>
                {props.language["Legend"]}
            </button>
          </div> 
        )}
        
        <MapSearchResultComponent
          mapResult = {props.mapResult.length ? props.mapResult : []}
          searchCoordinates={coordinates}
          storeSingleSection={storeSingleSection}
          searchStoreResultView={searchStoreResultView}
          mapWidth={mapWidth}
          locationId={locationId}
          closeResultSection={closeResultSection}
          filterClusterer={clickedMarkerClusterer}
          openToday={props?.searchProperties?.open_today}
          filterDays={props?.searchProperties?.days}
          filterAreaWithin={props?.searchProperties?.area}
          singleSectionClick = {singleSectionClick}
          getStoreDetails={storeDetails}
          storesReport={props.mapdata.storesReport}
          storeDetailsData={props.mapdata.storeDetails.length > 0 ? props.mapdata.storeDetails : []}
          getSpecialHourData={specialHourData}
          specialHourData={props.mapdata.specialHour ? props.mapdata.specialHour : {}}
          orderOptions={props.mapdata.orderOptions}
          language={props.language}
          mapSearch={props.mapdata.mapSearchPayload}
          initialStoreView={initStoreView}
          windowDimensions = {windowDimensions}
          isResultCollapsed = {props?.isResultCollapsed}
          setResultCollapsed={props.setResultCollapsed}
        />{" "}

        {isLoaded ? renderMap() : ""}
        { windowDimensions.width < 768 && (
          <div ref={mapKeyRef} className="col-md-12 d-md-none d-lg-none d-sm-block bg-white legendSection" id="legendCollapse">
            {/* <div className="card card-body  border-bottom-0 p-0"> */}
                {/* <div className="row"> */}
                    {/* <div className="col-md-12 d-md-none d-lg-none d-sm-block bg-white legendSection"> */}
                      <h4 className="resText mt-3" style={{marginLeft:'10px'}}>Map Key</h4>
                      <ul className="nav navbar-nav m-0 p-0">
                        <li className="dropdown nav-item pb-3">
                          <ul className="p-0 legendsMenu typesSection typesSection-sm" ref={wrapperRef}>
                          { 
                          props.mapdata.vendorType !== undefined &&  props.mapdata.vendorType.map((item,i)=>(

                              <li tabIndex={0} key={i} onKeyPress={(e)=>{
                                  // if(e.key === "Enter") {
                                  //     props.onMapKeyClick(item.name);
                                  // }
                              }}  onClick={()=>{
                                  setMapKeyTooltip(item.addId);
                                  // props.onMapKeyClick(item.name);
                              }} className="p-2 border-bottom cursor-pointer tooltip">
                                <div className="row m-0">
                              {/* <OverlayTrigger
                                  key={i}
                                  placement="right"
                                  overlay={
                                          <Tooltip id={`tooltip-${i}`}>
                                              <div>{getImageTooltip(item.addId,props.language)["tooltip"]}</div>
                                          </Tooltip>
                              
                                  }
                                  > */}
                                  <Button variant="light" className="btn-to-div">
                                  <div className="p-0">
                                      <span className="p-2">
                                      <img src={getImageTooltip(item.addId,props.language)["img"]} width="24" className="mx-2"  alt="img"/> </span>
                                      <small className="boldFont ">{props.language[item.values]}</small>
                                      <span className="pull-right text-muted info-icon p-2">
                                        <i className="fa fa-info-circle" aria-hidden="true"></i>
                                      </span>
                                      <span className={`tooltiptextMobile ${mapKeyTooltip != item.addId || mapKeyTooltip == 0 ? 'd-none' : '' }`}>{getImageTooltip(item.addId,props.language)["tooltip"]}</span>
                                    </div>
                                    </Button>
                                  {/* </OverlayTrigger> */}
                                  </div>
                              </li>
                          ))
                       }
                       <li >
                      <div className="pt-2">
                          <div className="d-inline-block text-center pl-2 float-left">
                              <img src={blue_cluster} width="34" className="mr-1 ml-2" aria-label="Blue Cluster" alt="img" />
                              <p className="mb-0 text-center font-14">(2-9)</p>
                          </div>
                          <div className="d-inline-block text-center float-left">
                              <img src={yellow_cluster} width="34" className="mr-2" aria-label="Yellow Cluster" alt="img"/>
                              <p className="mb-0 text-center font-14">(10+)</p>
                          </div>

                          <div className="d-inline-block text-center float-left  verticalAlign">
                              <p className="mt-3 font-16">
                              {props.language["Number of HIP vendor locations"]}</p>
                          </div>
                      </div>
                      </li>
                       </ul>
                      </li>
                      </ul>
                    {/* </div> */}
                 {/* </div> */}
            {/* </div> */}
          </div>
        )}
    </>
  );
};

MapWithMarkerClusterer.propTypes = {
  mapdata: PropTypes.object.isRequired,
  //   loaddata: PropTypes.object,
  loading: PropTypes.bool,
  //   getMapData: PropTypes.func.isRequired,
  mapResult: PropTypes.array,
  searchProperties: PropTypes.object.isRequired,
  //   resultMsg: PropTypes.string,
  getStoreDetails: PropTypes.func,
  getSpecialHourData: PropTypes.func,
  language:PropTypes.object,
  initialStoreView: PropTypes.bool,
  isResultCollapsed: PropTypes.bool,
  setResultCollapsed: PropTypes.func,
  mapLoad: PropTypes.bool,
  setMapLoad: PropTypes.func
  // storeDetails: PropTypes.object
};

const mapDispatchToProps = (dispatch) => {
    return {
      getStoreDetails: (payload) =>
        dispatch({ type: GET_STORE_DETAILS, payload: payload }),
      getSpecialHourData: (id) =>
        dispatch({ type: GET_SPECIAL_HOUR, value: id }),
    };   
  };

export const LoadMapComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(MapWithMarkerClusterer));
