import {  all } from "redux-saga/effects"; //fork,
import { watchGetArea, watchGetCityList, watchGetDayOption,watchGetSpecialHourData, watchGetMapStoresList, watchGetStoresReport, watchGetMapData,watchGetMonthOption,watchGetOrderOption,watchGetType, watchGetVendorList, watchGetStoreDetails } from "./mapdata";
import { watchGetLanguageData } from "./language";
import { watchGetFarmersMarketSaga, watchGetAccessLogsSaga, watchGetLoginLogsSaga,watchGetUserListSaga,watchGetCoordinatesListSaga, watchAddFarmersMarketSaga, watchEditFarmersMarketSaga,watchDeleteFarmersMarketSaga,
    watchAddUserListSaga, watchEditUserListSaga,watchDeleteUserListSaga,watchEditCoordinatesSaga, watchExportFarmersMarketSaga, watchBulkUpdateFarmersMarketsaga,watchSendFeedback} from "./administration";
import { 
    watchPostUser,
    watchPutUser, watchGetRegCityList, watchGetLocType, 
    watchGetLocTypeQues,watchPostProgram,watchGetRegMonthOption,watchGetRegDayOption, watchSendSupportEmail
 } from "./registration";

 
// import { watchGetLocType, watchGetLocTypeQues } from "./dropdown";

import {watchGetVendorId,watchGetUserDetails,watchUpdateVendorDetails,watchUpdatePublicDetails,watchGetVendorLocDetails, //watchRemoveVendorStore,
    watchUpdateProgram,watchLocShowHide,watchGetManageVendorListSaga,watchManageDeleteVendorDetailsSaga,watchGetFollowVendorDetailsSaga, 
    watchUpdateVendorBulkSaga, watchGetVendorHistoryListSaga,watchGetVendorInfoListSaga,watchVerifyVendorSaga,watchGetVendorStoreListSaga,watchExportVendorReportSaga, 
    watchExportVendorLoginReportSaga, watchExportVendorLateUpdateReportSaga, watchGetMyStoreDetailsSaga,watchRemoveVendorStoreSaga, watchUpdateFollowVendorDetailsSaga,
    watchUpdatePrimaryEmailSaga } from "./vendordetails";
import { watchGetStoreStatsSage, watchGetVendorStatsSaga } from "./adminDashboard";
import { watchGetAdminTokenSaga, watchGetUserTokenSaga, watchRefreshAdminTokenSaga, watchGetAuthUserDetails , watchTokenRevokeSaga } from "./auth";
import {watchAddCommentSaga, watchDeleteCommentSaga, watchGetCommentListSaga, watchUpdateCommentSaga} from "./comments";
import { watchBannerSaga } from "./banner";


export default function* () {
    // console.log("watchGetLanguageData");
    // yield all([
    //     fork(watchGetMapData()),
    //     fork(watchGetLanguageData()),
    //     fork(watchUserRegistration())
    // ]);
    yield all([
        watchGetMapData(),
        watchGetLanguageData(),
        watchPostUser(),
        watchPutUser(),
        watchGetLocType(),
        watchGetLocTypeQues(),
        watchPostProgram(),
        watchGetCityList(),
        watchGetArea(),
        watchGetType(),
        watchGetOrderOption(),
        watchGetMonthOption(),
        watchGetDayOption(),
        watchGetVendorList(),
        watchGetRegCityList(),
        watchGetRegMonthOption(),
        watchGetRegDayOption(),
        watchSendSupportEmail(),
        // watchGetVendorPublic(),
        watchGetVendorId(),
        // watchGetVendorStoreDetails(),
        watchGetUserDetails(),
        watchUpdateVendorDetails(),
        watchUpdatePublicDetails(),
        // watchGetVendorStoreDetails(),
        watchGetMapStoresList(),
        watchGetStoresReport(),
        watchGetStoreDetails(),
        watchGetSpecialHourData(),
        watchGetVendorLocDetails(),
        // watchRemoveVendorStore(),
        watchUpdateProgram(),
        watchLocShowHide(),
        watchGetFarmersMarketSaga(),
        watchExportFarmersMarketSaga(),
        watchAddFarmersMarketSaga(),
        watchEditFarmersMarketSaga(),
        watchBulkUpdateFarmersMarketsaga(),
        watchDeleteFarmersMarketSaga(),
        watchGetAccessLogsSaga(),
        watchGetLoginLogsSaga(),
        watchGetUserListSaga(),
        watchAddUserListSaga(),
        watchEditUserListSaga(),
        watchDeleteUserListSaga(),
        watchGetCoordinatesListSaga(),
        watchGetStoreStatsSage(),
        watchGetVendorStatsSaga(),
        watchEditCoordinatesSaga(),
        watchGetManageVendorListSaga(),
        watchManageDeleteVendorDetailsSaga(),
        watchGetFollowVendorDetailsSaga(),
        watchUpdateFollowVendorDetailsSaga(),
        watchUpdateVendorBulkSaga(),
        watchExportVendorReportSaga(),
        watchExportVendorLateUpdateReportSaga(),
        watchExportVendorLoginReportSaga(),
        watchGetVendorHistoryListSaga(),
        watchGetVendorInfoListSaga(),
        watchVerifyVendorSaga(),
        watchGetMyStoreDetailsSaga(),
        watchGetVendorStoreListSaga(),
        watchGetAdminTokenSaga(),
        watchGetUserTokenSaga(),
        watchRefreshAdminTokenSaga(),
        watchGetAuthUserDetails(),
        watchGetCommentListSaga(),
        watchAddCommentSaga(),
        watchUpdateCommentSaga(),
        watchDeleteCommentSaga(),
        watchRemoveVendorStoreSaga(),
        watchTokenRevokeSaga(),
        watchSendFeedback(),
        watchUpdatePrimaryEmailSaga(),
        watchBannerSaga()
    ]);
    // yield all([ fork(watchGetLanguageData) ]);
}