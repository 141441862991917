/* eslint-disable */

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import PropTypes from "prop-types";
import { mapStateToProps } from "../../redux/reducers";
import door_closed from "../../assets/images/marker/door-closed.png";
import door_open from "../../assets/images/marker/open-door.png";
import soh_warning from "../../assets/images/soh-warning.png";
import harvest from "../../assets/images/harvest.svg";
import xls_export from "../../assets/images/marker/xls-export.png";
import CommonLoading from "../common/components/commonLoading";
import moment from "moment";
import { Accordion } from "react-bootstrap";
import "../../styles/dashboard.scss";
import { GET_STORE_REPORT } from "../../redux/actionTypes";
import ModalComponent from "../common/components/modal";
const LOCATION_TYPES = {
  csaPickupSite: {
    loc_type_nam: "HIP CSA Program",
    loc_type_img: "./marker/csa-farms.png",
  },
  farmStand: {
    loc_type_nam: "HIP Farm Stand",
    loc_type_img: "./marker/farm-stands.png",
  },
  farmersMarket: {
    loc_type_nam: "HIP Farmers' Market Booth",
    loc_type_img: "./marker/farmers-market-managers.png",
  },
  farmersMarketManagers: {
    loc_type_nam: "HIP Farmers Market",
    loc_type_img: "./marker/market-managers.png",
  },
  mobileMarket: {
    loc_type_nam: "HIP Mobile Market Stop",
    loc_type_img: "./marker/mobile-market.png",
  },
};

const getAddress = (val) => {
  var addr = "";
  if (val?.addressLine1) {
    addr += val.addressLine1 + " ";
  }
  if (val?.addressLine2) {
    addr += val.addressLine2 + " ";
  }
 
  if (val?.city) {
    addr += val.city + " ";
  }
  if (val?.state) {
    addr += val.state+" ";
  }
  if (val?.zipcode) {
    if (val?.zipcode?.toString()?.length == 4) {
      addr += "0" + val.zipcode;
    } else {
      addr += val.zipcode;
    }
  }
  return addr;
};

const getMiles = (currentObj, searchCoordinates) => {
  /// Calculating miles between two latlng
  const R = 6371e3; // metres
  const φ1 = searchCoordinates.lat * Math.PI / 180; // φ, λ in radians
  const φ2 = currentObj.latitude * Math.PI / 180;
  const Δφ = (currentObj.latitude - searchCoordinates.lat) * Math.PI / 180;
  const Δλ = (currentObj.longitude - searchCoordinates.lng) * Math.PI / 180;

  const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
    Math.cos(φ1) * Math.cos(φ2) *
    Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  var miles = R * c; // in metres
  miles = ((miles * 0.000621371).toFixed(0)) == 0 ? (miles * 0.000621371).toFixed(0) : (miles * 0.000621371).toFixed(2); // in miles
  return miles;
};

const getLocationImg = (location) => {
  let icon = LOCATION_TYPES[location["location_types_name"]]?.loc_type_img;
  return icon;
};
const getMapURL = (data) => {
  return `https://www.google.com/maps/search/?api=1&query=${data.store?.addressDetail?.latitude},${data.store?.addressDetail?.longitude}`;
};

const getSiteUrl = (url) => {
  return (url.indexOf("http://") == 0 || url.indexOf("https://") == 0) ? url : `//${url}`;
};

const monthNumber = [{ "name": "January", "value": "1","id":"janOperation" }, { "name": "February", "value": "2","id":"febOperation" }, { "name": "March", "value": "3","id":"marOperation" }, { "name": "April", "value": "4","id":"aprOperation" }, { "name": "May", "value": "5","id":"mayOperation" }, { "name": "June", "value": "6","id":"junOperation" }, { "name": "July", "value": "7","id":"julOperation" }, { "name": "August", "value": "8","id":"augOperation" }, { "name": "September", "value": "9","id":"sepOperation" }, { "name": "October", "value": "10","id":"octOperation" }, { "name": "November", "value": "11","id":"novOperation" }, { "name": "December", "value": "12","id":"decOperation" }];

const getWeekOfMonth = (date) => {
  let monthStartDate = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
  monthStartDate = new Date(monthStartDate);
  const day = monthStartDate.getDay();
  date = new Date(date);
  date = date.getDate();

  const weekNumber = Math.ceil((date + (day)) / 7);
  return (weekNumber == 0) ? 1 : weekNumber;
};

const MapSearchResultComponent = ({
  locationId,
  mapResult,
  storeSingleSection,
  searchStoreResultView,
  closeResultSection,
  searchCoordinates,
  filterClusterer,
  mapWidth,
  singleSectionClick,
  getStoreDetails,
  storesReport,
  storeDetailsData,
  getSpecialHourData,
  specialHourData,
  orderOptions,
  language,
  mapSearch,
  getStoresReport,
  initialStoreView,
  windowDimensions,
  isResultCollapsed,
  setResultCollapsed
}) => {
  const [searchResultView, setSearchResultView] = useState(false);
  const [originalArray, setOriginalArray] = useState([]);
  const [exportReport, setExportReport] = useState([]);
  const [marketNames, setMarketNames] = useState({});
  const [currentMarketObj, setCurrentMarketObj] = useState([]);
  const [currentVendorStoreDetail, setCurrentVendorStoreDetail] = useState([]);
  const [currentVendor, setCurrentVendor]=useState();
  const [singleSection, setSingleSection] = useState(false);
  const [sortBy, setSortBy] = useState("asc");
  const [isMarket, setIsMarket] = useState(false);
  const [removeDetail, setRemoveDetail] = useState(false);
  const [specialHourId,setSpecialHourId]=useState();
  const [storeSpecialOperation, setStoreSpecialOperation] = useState();
  const [specialHourInfo, setSpecialHourInfo]=useState();
  const [accordStoreId,setAccordStoreId]=useState();
  const [accordActiveKey, setAccordActiveKey]=useState([]);
  const [selectedStoreIds, setSelectedStoreIds]=useState([]);
  const [isLoading, setIsLoading]=useState(false);
  const [showModal, setShowModal] = useState(false);
  // const [language, setLanguage] = useState({});

  // useEffect(() => {
  //   setLanguage(props.language)
  // }, [props.language])

  const mergeFarmersMarket = (result) => {
    let commonNameList = {};
    result.map((item)=>{
      if (item.store.storeType==="HIP Farmers Market Booth")
        item.farmersMarket = "Y";
      else if (item.store.storeType==="HIP Farmers Market")
        item.farmersMarketManagers = "Y";
      else if(item.store.storeType ==="HIP CSA PROGRAM")
        item.csaPickupSite = "Y";
      else if (item.store.storeType==="HIP Farm Stand")
        item.farmStand = "Y";
      else if (item.store.storeType === "HIP Mobile Market Stop")
        item.mobileMarket = "Y";
      
      if(item.store.storeType=="HIP Farmers Market Booth" || item.store.storeType=="HIP Farmers Market"){
        if(item.store.siteName in commonNameList){
          if(item.store.storeType=="HIP Farmers Market Booth"){
            commonNameList[item.store.siteName].farmersMarket = "Y";
          }else{
            commonNameList[item.store.siteName].farmersMarketManagers = "Y";
          }
        }else if(!(item.store.siteName in commonNameList)){
          commonNameList[item.store.siteName] = item;
        }
      }else{
        commonNameList[item.store.id] = item;
      }
    })
    return Object.values(commonNameList);
  }

  useEffect(() => {
    if (mapResult && mapResult.length > 0 && (initialStoreView ||  windowDimensions.width < 768)) {
      let CommonNameArray = mergeFarmersMarket(mapResult);
      if (CommonNameArray.length === 0) {
        setSearchResultView(false);
        setSingleSection(false);
      } else {
        setSingleSection(false);
        setSearchResultView(true);
      }
      if(singleSectionClick){
        setSingleSection(true);
        setSearchResultView(false);
      }

      if (filterClusterer.length) {
        CommonNameArray = CommonNameArray.filter(item => filterClusterer.includes(item.store.id));
      }
      
      const data = mapResult.find((location) => location.store.id === locationId); //.vendor?

      if (data) {
        setCurrentVendor(data)
      }
      //// grouping market names
      let marketNames = {};
      let storeList = [];
      let marketList = [];
      if (CommonNameArray.length) {
        for (let i = 0; i < CommonNameArray.length; i++) {
          let obj = CommonNameArray[i];
          if (obj.location_types_name == "farmersMarket" || obj.location_types_name == "farmersMarketManagers") {
            CommonNameArray[i]["displayName"] = obj.store.siteName;
          } else {
            CommonNameArray[i]["displayName"] = obj.vendorName;
          }
        }
      }
      marketNames = _.groupBy(
        _.sortBy(CommonNameArray, "farmer_market_name"),
        "farmer_market_name"
      );
      for (let property in marketNames) {
        for (let i = 0; i < marketNames[property].length; i++) {
          const element = marketNames[property][i];
          if (
            element.farmer_market_name
          ) {
          } else {
            storeList.push(element);
            delete marketNames[property][i];
          }
        }
      }
      if (Object.keys(marketNames).length > 0) {
        for (let property in marketNames) {
          let obj = marketNames[property][0];
          if (obj && property != "") {
            marketList.push(obj);
          }
        }
        setMarketNames(marketNames);
      }
      marketList = marketList.length ? marketList : [];
      storeList = storeList.length ? storeList : [];
      const vendorData = _.union(marketList, storeList);
      setOriginalArray(vendorData);
    } else {
      setSearchResultView(false);
      setSingleSection(false);
      // mapWidth ="100%";
      closeResultSection("100%");
      setOriginalArray([]);
    }
  }, [locationId, filterClusterer, mapResult, storeSingleSection, searchStoreResultView, searchCoordinates, mapWidth]);

  useEffect(()=>{
    setSingleSection(storeSingleSection);
    setSearchResultView(searchStoreResultView);
  },[closeResultSection,searchStoreResultView,storeSingleSection]);

  useEffect(()=>{
    if (storesReport.length && filterClusterer.length) {
      storesReport = storesReport.filter(item => filterClusterer.includes(item.store.id));
    }
    if(mapResult.length && storesReport.length){
      let reportData = [];
      storesReport.map((item,i) => {
        for (let j = 0; j < mapResult.length; j++) {
          if(mapResult[j].store.id === storesReport[i].store.id){
            storesReport[i]['location_types_name'] = mapResult[j]['location_types_name'];
            storesReport[i]['address'] = mapResult[j]['address'];
            reportData.push(storesReport[i]);
          }
        }
      })
      setExportReport(reportData);
    }
    setIsLoading(false);
  },[storesReport,mapResult,filterClusterer]);

  useEffect(() => {
    if (storeDetailsData.length > 0) {
      let storeIds = [];
      storeDetailsData.forEach(item=>{
        item['location_types_name'] = currentVendor['location_types_name'];
        storeIds.push(item.store.id);
      })
      setSelectedStoreIds(storeIds)
      setCurrentVendorStoreDetail(storeDetailsData);
      setSingleSection(true);
      if (currentVendor['location_types_name'] === 'farmersMarket' || currentVendor['location_types_name'] === 'farmersMarketManagers') {
        setIsMarket(true);
      } else {
        setIsMarket(false);
      }      
    }
  },[storeDetailsData,locationId]);

  useEffect(()=>{
      if (specialHourData) {
        if (specialHourId === specialHourData?.id) {
            // setIsSplHrLoaded(true);
            setSpecialHourInfo(specialHourData.specialhour);
        } else {
            // setIsSplHrLoaded(false);
            setSpecialHourInfo([]);
        }
      }
    //   setSingleSection(true);
  },[specialHourData]);

  const handleClick = () => {//e
    setSearchResultView(false);
    setSingleSection(false);
    closeResultSection("100%");
  };

  const closeResult = () => { //e
    setSearchResultView(false);
    setSingleSection(false);
    // mapWidth = "100%";
    closeResultSection("100%");
    setAccordActiveKey([]);
  };

  const openMarketStoreDetail = (loc) => {
      setCurrentVendor(loc);
      getStoreDetails(loc.store.id);
      // setCurrentMarketObj(marketNames[loc.store.siteName]);
      // setSingleSection(true);
      setIsMarket(true);
      setAccordStoreId("");
      setRemoveDetail(true);
  };

  const openVendorStoreDetail = (loc) => {
    setAccordStoreId("");
    setIsMarket(false);
    setCurrentVendor(loc);
    getStoreDetails(loc.store.id);
    setRemoveDetail(false);
  };

  const backToResults = () => {
    setAccordActiveKey([]);
    getStoreDetails(null);
  };

  const ascendingOrder = (a, b) => {
    // Use toUpperCase() to ignore character casing
    const bandA = a.displayName.toUpperCase();
    const bandB = b.displayName.toUpperCase();

    let comparison = 0;
    if (bandA > bandB) {
      comparison = 1;
    } else if (bandA < bandB) {
      comparison = -1;
    }
    return comparison;
  };

  const descendingOrder = (a, b) => {
    // Use toUpperCase() to ignore character casing
    const bandA = a.displayName.toUpperCase();
    const bandB = b.displayName.toUpperCase();

    let comparison = 0;
    if (bandA > bandB) {
      comparison = 1;
    } else if (bandA < bandB) {
      comparison = -1;
    }
    return comparison * -1;
  };

  const sortResults = (sortBy) => {
    if (sortBy === "asc") {
      const data = originalArray.sort(ascendingOrder);
      setOriginalArray(data);
    } else {
      const data = originalArray.sort(descendingOrder);
      setOriginalArray(data);
    }
  };

  useEffect(()=>{
    sortResults('asc');
    setSortBy("asc");
  },[originalArray]);

  // const hasDuplicates = (listOfTags)=> {
  //     const keys = ["day", "month", "week", "open_time", "close_time"];
  //     const filtered = listOfTags.filter(
  //         (s => o =>
  //             (k => !s.has(k) && s.add(k))
  //                 (keys.map(k => o[k]).join("|"))
  //         )
  //             (new Set)
  //     );
  //     return filtered;
  // }

  const getTodayStatus = (item) => {
    let displayWorkingClass = "btnLinkRed";
    let checkHoliday = "Closed Today"
    if(item.openToday === "Y"){
      displayWorkingClass = "btnLink";
      checkHoliday = "Open Today";
      if((new Date().getTime() > new Date(moment(item.todayStartTime, "hh:mm a")).getTime() && new Date().getTime() < new Date(moment(item.todayEndTime, "hh:mm a")).getTime())){
        checkHoliday = "Open Now";
      }else if(new Date().getTime() > new Date(moment(item.todayEndTime, "hh:mm a")).getTime()){
        checkHoliday = "Closed Now";
        displayWorkingClass = "btnLinkRed";
      }
    }

    const renderModal =() =>{
      return (
        <div className="row" style={{padding:'12px 13px',marginBottom:'30px', overflow:"scroll"}}>
          <div className="row">
            <div className="col-md-12 p-0">
              <div className="special-timings" id="specialTiming_1ce781cf-dc40-15c9-5ae8-90246d330745">
                {
                  storeSpecialOperation && storeSpecialOperation.length &&
                  storeSpecialOperation.map((item,i)=>(
                    getSpecialHoursRows(item,i)
                  ))
                }
              </div>
            </div>
          </div>
        </div>
      )
    }
    return (<>
      <div className="row">
        {showModal[item.store.id] &&
          <ModalComponent 
            shown = {showModal[item.store.id]}
            close = {() => {
               setShowModal({[item.store.id]: false});              
              }}
            title={"Special Hour(s) of Operation"}
            modalBody = {renderModal} 
                    size ={"lg"}
                  />
        }
        <div className="col-md-5 col-lg-5 col-sm-11 ml-3 mr-2">
        <small className={`${displayWorkingClass} mb-2 mt-2`} style={{ display: "inline-block" }}>{checkHoliday}</small>
      </div>
      { windowDimensions.width < 768 ? ( 
        item.store.specialOperationFlag === "Y" && (
          <div className="col-md-2 col-sm-1 p-0 popover__wrapper m-0">
            <img src={soh_warning} className="soh-warning" data-toggle="tooltip" onClick={()=>{
                if (specialHourId !==item.store.id) {
                  setShowModal({[item.store.id]:true});                 
                  setSpecialHourId(item.store.id);
                  setStoreSpecialOperation(item.store.storeSpecialOperation);
                }  
            }} data-placement="bottom" style={{ color: "#006e52", display: "inline-block"}} />
          </div>
        )
      ):(
      item.store.specialOperationFlag === "Y" && (
        <div className="col-md-2 col-sm-1 p-0 popover__wrapper m-0">
          <img src={soh_warning} className="soh-warning" data-toggle="tooltip" onMouseOver={()=>{
               if (specialHourId !==item.store.id) {
                  setSpecialHourId(item.store.id);
                  // getSpecialHourData(item.store.id); 
               }                     
          }} data-placement="bottom" style={{ color: "#006e52", display: "inline-block"}} />
          <div className={`${item.store.storeSpecialOperation && item.store.storeSpecialOperation.length ? "popover__content" : "d-none"}`}>
            <div className="row">
              <div className="col-md-12 p-0">
                <div className="special-timings" id="specialTiming_1ce781cf-dc40-15c9-5ae8-90246d330745"><small className="boldFont resText">Special Hour(s) of Operation</small>
                  {
                    item.store.storeSpecialOperation && item.store.storeSpecialOperation.length &&
                    item.store.storeSpecialOperation.map((item,i)=>(
                      getSpecialHoursRows(item,i)
                    ))
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      )
      )}
      </div>
      {item.openToday  === "Y" && item.todayStartTime && item.todayEndTime && 
        <div className={`col-md-6 col-sm-12`}>
          <button aria-label="button" aria-labelledby="button" className="btn btn-default cursor-none btn-rounded btn-block btn-lg mt-2 btn-font reSizeFont" style={{ padding: "12px 10px", display: "block" }}>{item.todayStartTime+" - "+ item.todayEndTime}</button> 
        </div>
      }
    </>)
  }

  const getStoreOpenStatus = (obj, storeViewDetail = false, marketIndex = 0, resultList = true) => {
    // setAccordActiveKey([marketIndex]);
    /**
     * Working hours key is not present.
     * Zip code is not there in address.
     */
    
    let monthsOperation = false;
    let month = moment().month();
    const workingHourObj = []
    // obj.workinghour.filter(function (obj) {
    //   if (moment().day(moment(new Date()).day()).format("dddd") == obj.day) {
    //     return obj;
    //   }
    // });
    const mnthList = []; //obj.store.storeSpecialOperation.monthInfo.filter(item => item.isenabled).map(item => item.value);
    const mnOpr = monthNumber.filter(item => {
      if (mnthList.includes(item.name)) {
        return item.value;
      }
    }).map(item => item.value);
    for (let j = 0; j < mnOpr.length; j++) {
      if (month + Number(1) === Number(mnOpr[j])) {
        monthsOperation = true;
      }
    }

    let checkHoliday = "";
    let displayWorkingHours = "";
    let working_time = "";
    let displayWorkingClass = "";
    let specialInfo = "";
    let displayHours = "";
    let reassignClose = "";
    let displayDuration = "none";
    if (workingHourObj.length && workingHourObj[0].open_time && workingHourObj[0].close_time) {
      displayDuration = "block";
    }
    if (obj.is_specialhours && obj.specialhour && obj.specialhour.length) {
      specialInfo = resultList ===true ? "inline-block" : "none";
      const finalArray = obj.specialhour; //hasDuplicates(obj.specialhour);
      for (let q = 0; q < finalArray.length; q++) {
        let singleObj = finalArray[q];
        let monthName = "";
        let monthStringArray = [];
        const mnth = (singleObj.specialMonth && singleObj.specialMonth.length) ? singleObj.specialMonth.filter(item => item.isenabled).map(item => item.month) : [];
        const specialMonth = monthNumber.filter(item => {
          if (mnth.includes(item.name)) {
            return item.value;
          }
        }).map(item => item.value);
        for (let ms = 0; ms < specialMonth.length; ms++) {
          monthStringArray.push(Number(specialMonth[ms]));
        }
        monthStringArray.filter((obj) => {
          if (month + Number(1) === obj) {
            monthName = obj;
          }
        });

        let currentMonth = specialMonth;
        let checkMonth = currentMonth.includes(monthName.toString()); // Month

        let matchToday = moment().format("dddd"); // day
        let currentDay = (singleObj.specialDay && singleObj.specialDay.length) ? singleObj.specialDay.filter(item => item.isenabled).map(item => item.day) : [];
        let checkDay = currentDay.includes(matchToday.toString());

        let currentTime = moment().format("YYYY-MM-DD"); // time
        let date = new Date(currentTime);
        let checkCurrentWeek = getWeekOfMonth(date);

        let weekOperation = true;
        if (checkCurrentWeek === Number(singleObj.week)) { //singleObj.week
          weekOperation = true;
        } else {
          weekOperation = false;
        }
        let reassignClose = " ";
        let reassignStatus = false;
        if (singleObj.open_time === "12:00 am" && singleObj.close_time === "12:00 am") {
          reassignClose = "11:59 pm";
          reassignStatus = true;
        } else {
          reassignClose = singleObj.close_time;
          reassignStatus = false;
        }
        if (checkMonth && checkDay && weekOperation && (new Date().getTime() > new Date(moment(singleObj.open_time, "hh:mm a")).getTime() && new Date().getTime() < new Date(moment(reassignClose, "hh:mm a")).getTime())) {
          if (singleObj.status === "Available") {

            if (reassignStatus) {
              checkHoliday = "Open Today";
              displayWorkingHours = "block";
              working_time = singleObj.open_time + " - " + singleObj.close_time;
            } else {
              checkHoliday = "Open Now";
              displayWorkingHours = "block";
              working_time = singleObj.open_time + " - " + singleObj.close_time;
            }
            displayWorkingClass = "btnLink";
            displayHours = "";
          } else {
            if (reassignStatus) {
              checkHoliday = "Closed Today";
              displayWorkingHours = "none";
              working_time = "";
            } else {
              checkHoliday = "Closed Now";
              displayWorkingHours = "block";
              working_time = singleObj.open_time + " - " + singleObj.close_time;
            }
            displayWorkingClass = "btnLinkRed";
            displayHours = "";
          }
          break;
        } else {
          let reassignStatus = false;
          if (workingHourObj.length) {
            if (workingHourObj[0].open_time === "12:00 am" && workingHourObj[0].close_time === "12:00 am") {
              reassignClose = "11:59 pm";
              reassignStatus = true;
            } else {
              reassignClose = workingHourObj[0].close_time;
              reassignStatus = false;
            }
            if (workingHourObj.length && !workingHourObj[0].holiday && monthsOperation) {
              if (new Date().getTime() > new Date(moment(workingHourObj[0].open_time, "hh:mm a")).getTime() && new Date().getTime() < new Date(moment(reassignClose, "hh:mm a")).getTime() && monthsOperation) {
                if (reassignStatus) {
                  checkHoliday = "Open Today";
                  displayWorkingHours = "block";
                  working_time = workingHourObj[0].open_time + " - " + workingHourObj[0].close_time;
                } else {
                  checkHoliday = "Open Now";
                  displayWorkingHours = "block";
                  working_time = workingHourObj[0].open_time + " - " + workingHourObj[0].close_time;
                }
                displayWorkingClass = "btnLink";
                displayWorkingHours = "block";
                displayHours = "";
              } else {
                checkHoliday = "Closed Now";
                displayWorkingClass = "btnLinkRed";
                displayWorkingHours = "block";
                displayHours = "";
              }
            } else {
              checkHoliday = "Closed Today";
              displayWorkingClass = "btnLinkRed";
              displayWorkingHours = "none";
              displayHours = "d-none";
            }
            working_time = workingHourObj[0].open_time + " - " + workingHourObj[0].close_time;
          } else {
            checkHoliday = "Closed Today";
            displayWorkingClass = "btnLinkRed";
            displayWorkingHours = "none";
            displayHours = "d-none";
          }
        }
      }
    } else {

      specialInfo = (obj.is_specialhours && resultList ===true ) ? "inline-block" : "none";
      let reassignStatus = false;

      if (workingHourObj.length) {
        if (workingHourObj[0].open_time === "12:00 am" && workingHourObj[0].close_time === "12:00 am") {
          reassignClose = "11:59 pm";
          reassignStatus = true;
        } else {
          reassignClose = workingHourObj[0].close_time;
          reassignStatus = false;
        }
        if (!workingHourObj[0].holiday && monthsOperation) {
          if (new Date().getTime() > new Date(moment(workingHourObj[0].open_time, "hh:mm a")).getTime() && new Date().getTime() < new Date(moment(reassignClose, "hh:mm a")).getTime() && monthsOperation) {
            if (reassignStatus) {
              checkHoliday = "Open Today";
              displayWorkingHours = "block";
              working_time = workingHourObj[0].open_time + " - " + workingHourObj[0].close_time;
            } else {
              checkHoliday = "Open Now";
              displayWorkingHours = "block";
              working_time = workingHourObj[0].open_time + " - " + workingHourObj[0].close_time;
            }
            displayWorkingClass = "btnLink";
            displayWorkingHours = "block";
            displayHours = "";
          } else {
            checkHoliday = "Closed Now";
            displayWorkingClass = "btnLinkRed";
            displayWorkingHours = "block";
            displayHours = "";
          }
        } else {
          checkHoliday = "Closed Today";
          displayWorkingClass = "btnLinkRed";
          displayWorkingHours = "none";
          displayHours = "d-none";
        }
        working_time = workingHourObj[0].open_time + " - " + workingHourObj[0].close_time;
      } else {
        checkHoliday = "Closed Today";
        displayWorkingClass = "btnLinkRed";
        displayWorkingHours = "none";
        displayHours = "d-none";
      }
    }

    return (
      <>
        {storeViewDetail && (
          <>
            <Accordion.Header>
              <div
                style={{ position: "relative",width: "100%" }}
                className="panel-heading n_panel-heading cursor"
                data-toggle="collapse"
                data-parent="#storeDetailsAccordion"
                href={"#" + marketIndex + "_card"}
                onClick={()=>{
                    setSingleSection(true);
                    if (obj.store.id !== accordStoreId) {
                        setAccordStoreId(obj.store.id);
                        // getStoreDetails(obj.store.id);
                    }
                }}
              >
                <p className="panel-title n_panel-title p-none d-inline-block">
                  {" "}
                  <span
                    data-toggle="collapse"
                    data-parent="#storeDetailsAccordion"
                    className="text-black text-overflow store-details-accordion-title collapsed"
                    aria-controls={marketIndex + "_card"}
                    role="button"
                  >
                    {" "}
                  </span>
                </p>
                <div className="text-overflow-div-style">
                  <span
                    data-toggle="collapse"
                    data-parent="#storeDetailsAccordion"
                    className="text-black text-overflow store-details-accordion-title collapsed"
                    aria-controls={marketIndex + "_card"}
                    role="button"
                  >
                    {obj.vendorName?.replace(/&#38;/g, "’")}
                  </span>
                </div>
                <span
                  data-toggle="collapse"
                  data-parent="#storeDetailsAccordion"
                  className="text-black text-overflow store-details-accordion-title collapsed"
                  aria-controls={marketIndex + "_card"}
                  role="button"
                >
                  <div
                    title={displayWorkingClass === "btnLink" ? "Opened Now" : "Closed Now"}
                    style={{ display: "inline-block" }}
                  >
                    <img
                      src={displayWorkingClass === "btnLink" ? door_open : door_closed}
                      className="door-style"
                      aria-label={displayWorkingClass === "btnLink" ? "Door Open" : "Door Close"}
                      alt="img"
                      role="none"
                    />
                  </div>{" "}
                  <i className="n_nav-right-icon fa fa-chevron-up pull-right cusIcon mt-2 accordion-icon"></i>
                  {obj?.is_specialhours && (
                    <div className="market_popover__wrapper">
                      <img
                        src={soh_warning}
                        className="soh-warning"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        style={{
                          color: "#006e52",
                          verticalAlign: "middle",
                          margin: 0,
                        }}
                        onMouseOver={()=>{
                            // alert(singleSection);
                            // if (specialHourId !==obj.id) {
                            //    setSpecialHourId(obj.id);
                            //    getSpecialHourData(obj.id);
                            // }
                            if (obj.store.id !== accordStoreId) {
                                setSingleSection(true);
                                    setAccordStoreId(obj.store.id);
                                    // getStoreDetails(obj.store.id);
                                }
                       }}
                      />
                      <div className={`${obj.specialhour && obj.specialhour.length ? "market_popover_content" : "d-none"}`}>
                        <div className="row">
                          <div className="col-md-12 p-0">
                            <div
                              className="special-timings"
                              id="specialTiming_1ce781cf-dc40-15c9-5ae8-90246d330745"
                            >
                              <small className="boldFont resText">
                                Special Hour(s) of Operation
                              </small>
                              { 
                                // specialHourInfo && specialHourInfo.length &&
                                // specialHourInfo.map((item,i)=>(
                                //     <div key={i}>
                                //      {getSpecialHoursRows(item)}
                                //      </div>
                                // ))
                                obj.specialhour && obj.specialhour.length &&
                                obj.specialhour.map(item=>(
                                    getSpecialHoursRows(item)
                                 ))
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </span>
                <p></p>
              </div>
            </Accordion.Header>
            <Accordion.Body id={marketIndex + "_card"} className={marketIndex + "_card body bg-light panel-collapse"}>
              <div className="card"> <div className="card-body"><div className="row">
                <div className="col-md-5 col-sm-12">
                  <small className={`${displayWorkingClass} mb-2 mt-2`} style={{ display: "inline-block" }}>{checkHoliday}</small>
                </div>
                {obj.is_specialhours && (
                  <div className={`col-md-7 col-sm-12 ${displayHours}`} style={{ display: displayWorkingHours }}>
                    <button aria-label="button" aria-labelledby="button" className="btn btn-default cursor-none btn-rounded btn-block btn-lg mt-2 btn-font reSizeFont" style={{ padding: "12px 10px", display: displayDuration }}>{working_time}</button>
                  </div>
                )}
                {getMoreDetails(obj)}
              </div>
              </div>
              </div>
            </Accordion.Body>
          </>
        )}
        {!storeViewDetail && (
          <>
            <div className="col-md-4 col-lg-4 p-0 col-sm-11 ml-3 mr-2">
              <small className={`${displayWorkingClass} mb-2 mt-2`} style={{ display: "inline-block" }}>{checkHoliday}</small>
            </div>
            {obj.is_specialhours && (
              <div className="col-md-2 col-sm-1 p-0 popover__wrapper m-0">
                <img src={soh_warning} className="soh-warning" data-toggle="tooltip" onMouseOver={()=>{
                     if (specialHourId !==obj.store.id) {
                        setSpecialHourId(obj.store.id);
                        getSpecialHourData(obj.store.id);
                     }                     
                }} data-placement="bottom" style={{ color: "#006e52", display: specialInfo}} />
                <div className={`${specialHourInfo && specialHourInfo.length ? "popover__content" : "d-none"}`}>
                  <div className="row">
                    <div className="col-md-12 p-0">
                      <div className="special-timings" id="specialTiming_1ce781cf-dc40-15c9-5ae8-90246d330745">
                        <small className="boldFont resText">Special Hour(s) of Operation</small>
                        { 
                            specialHourInfo && specialHourInfo.length &&
                            specialHourInfo.map(item=>(
                                getSpecialHoursRows(item)
                            ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className={`col-md-7 col-sm-12 ${displayHours}`} style={{ display: displayWorkingHours }}>
            <button aria-label="button" aria-labelledby="button" className="btn btn-default cursor-none btn-rounded btn-block btn-lg mt-2 btn-font reSizeFont" style={{ padding: "12px 10px", display: displayDuration }}>{working_time}</button> </div>
          </>
        )}
      </>
    );
  };

  const getSpecialHoursRows = (row,i) => {
    let status = "", weeks = "", day = "", month = "", start_time = "", end_time = "";
    if (row) {
        let elementDay = row;
        status = elementDay.specialStatusDescription ? elementDay.specialStatusDescription : "-";
        start_time = elementDay.startTime ? elementDay.startTime : "-";
        end_time = elementDay.endTime ? elementDay.endTime : "-";
        let dayInfo = elementDay.dayInfo ? elementDay.dayInfo.split(",") : '';
        let monthInfo = elementDay.monthInfo.split(",")

        let week = elementDay.weekInfo;
      
      
        for (let d = 0; d < dayInfo.length; d++) {
          let number = dayInfo[d]
          switch (number) {
              case "01":
                  day += "Sunday "
                  break;
              case "02":
                  day += "Monday "
                  break;
              case "03":
                  day += "Tuesday "
                  break;
              case "04":
                  day += "Wednesday "
                  break;
              case "05":
                  day += "Thursday "
                  break;
              case "06":
                  day += "Friday "
                  break;
              case "07":
                  day += "Saturday "
                  break;
              default:
                  day += "-";
          }
        }

      if(day && day!== "-"){
        day = day.match(/[^ ,]+/g).join(', ');
      }
      
      if (elementDay.monthInfo && monthInfo.length > 0) {              
              let splMonth = monthNumber.filter(item => {
                if (monthInfo.includes(item.value)) {
                  return item.name;
                }
              }).map(item => item.name);

          if (month !== '-') {
            month = splMonth.join(", ")
          }
      }
      
      let text = "";
      if (week === "1") {
          text = 'st'
      } else if (week === "2") {
          text = 'nd'
      } else if (week === "3") {
          text = 'rd'
      } else if (week === "4" || week === "5") {
          text = 'th'
      }
      weeks = week + text
      if (weeks == "") {
          weeks = "-";
      }
        return (
          <div key={i}>
            <p className="boldFont boldFont mb-0 mt-3" style={{ color: "#ca0000" }}>{status} </p>
            <table
              className="table border special-timings"
              style={{
                border: "none !important",
                background: "#fff",
                marginBottom: "10px !important",
              }}
            >
              <thead>
                <tr>
                  <th
                    style={{ width: "10%" }}
                    className="specialTime-table"
                  >
                    Week
                  </th>
                  <th
                    style={{ width: "40%" }}
                    className="specialTime-table"
                  >
                    Day(s)
                  </th>
                  <th
                    style={{ width: "40%" }}
                    className="specialTime-table"
                  >
                    Month(s)
                  </th>
                  <th
                    style={{ width: "10%" }}
                    className="drop-down-menu specialTime-table"
                  >
                    Time
                  </th>
                </tr>
              </thead>
              <tbody
                id="special_not_1ce781cf-dc40-15c9-5ae8-90246d330745"
                style={{ padding: "15px 10px", borderTop: 0 }}
              >
                <tr style={{ borderBottom: "1px solid #ddd" }}>
                  <td>{weeks}</td>
                  <td>{day}
                  </td>
                  <td>{month}
                  </td>
                  <td style={{ whiteSpace: "nowrap" }}>
                    <div className="row">
                      <div className="col-md-12 p-0">{start_time} - </div>
                      <div
                        className="col-md-12 p-0"
                        style={{ wordBreak: "break-all" }}
                      >
                        {end_time}
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        );
      }
  };

  const getMoreDetails = (currentDetail) => {
    const mnOpr = monthNumber.filter(item => {
      if(currentDetail.store.storeOperationMonth[item.id]==="Y"){
        return item.name;
      }
    }).map(item => item.name);

    const oderOperation = orderOptions.filter(item => {
      if(currentDetail.store.storeOrderTypeDetail[item.id]==="Y"){
        return item.values;
      }
    }).map(item => item.values);
    //currentDetail.store.storeOrderTypeDetail.map(item => (item.name === "Other") ? item.value :item.name);

    return (
      <>
        {(currentDetail.publicEmail ||
          currentDetail.publicMobile ||
          currentDetail.publicWebsite) && (
            <div className="col-md-12 mt-3 ml-2">
              {currentDetail.publicEmail && removeDetail && (
                <>
                  <small>
                    <i className="fa fa-envelope text-muted cusIcon"></i>{" "}
                    <a
                      href={"mailto:" + currentDetail.publicEmail}
                      style={{ textDecoration: "underline" }}
                    >
                      {currentDetail.publicEmail}
                    </a>
                  </small>
                  <br />
                </>
              )}
              {currentDetail.publicMobile && removeDetail && (
                <>
                  <small>
                    <i className="fa fa-phone text-muted cusIcon"></i>{" "}
                    <a
                      href={"tel:" + currentDetail.publicMobile}
                      style={{ textDecoration: "underline" }}
                    >
                      {currentDetail.publicMobile}
                    </a>
                  </small>
                  <br />
                </>
              )}
              {currentDetail.publicWebsite && removeDetail && (
                <>
                  <small>
                    <i className="fa fa-globe text-muted cusIcon"></i>{" "}
                    <a
                      href={currentDetail.publicWebsite}
                      target="_blank"
                      rel="noreferrer"
                      style={{ textDecoration: "underline" }}
                    >
                      Go to store website
                    </a>
                  </small>
                  <br />
                </>
              )}
            </div>
          )}
        {currentDetail.businessDesc && (
          <div className="col-md-12 ml-2 mt-3">
            <p className="p-none smallTitle" aria-label="Vendor Description">
              <i className="fa fa-home cusIcon"></i> Vendor Description
            </p>
            <div className="mt-1">
              {" "}
              <small aria-label={currentDetail.businessDesc}>{currentDetail.businessDesc}</small>{" "}
            </div>
          </div>
        )}
        {currentDetail.store.specialInstructions && (
          <div className="col-md-12 ml-2 mt-3 ">
            <p className="p-none smallTitle" aria-label="Special Instructions">
              <i className="fa fa-info-circle cusIcon"></i> Special Instructions{" "}
            </p>
            <div className="mt-1">
              <small aria-label={currentDetail.store.specialInstructions}>{currentDetail.store.specialInstructions}</small>
            </div>
          </div>
        )}
        <div className="col-md-12">
          <div className="ml-2 mt-3">
            <p
              title="Regular Hour(s) of Operation"
              className="soh-text  p-none smallTitle"
              aria-label="Regular Hour(s) of
              Operation"
            >
              <i className="fa fa-clock-o cusIcon"></i> Regular Hour(s) of
              Operation
            </p>
            {currentDetail.specialOperationFlag === "Y" && (
              <div className="single_popover__wrapper">
                <img
                  src={soh_warning}
                  className="soh-warning"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  style={{
                    color: "#006e52",
                    verticalAlign: "baseline",
                    margin: 0,
                  }}
                />
                <div className="single_popover__content">
                  <div className="row">
                    <div className="col-md-12 p-0">
                      <div
                        className="special-timings"
                        id="specialTiming_1ce781cf-dc40-15c9-5ae8-90246d330745"
                      >
                        <small className="boldFont resText" aria-label="Special Hour(s) of Operation">
                          Special Hour(s) of Operation
                        </small>
                        {/* {getSpecialHoursRows(currentDetail)} */}
                        { 
                            currentDetail.store.specialhour && currentDetail.store.specialhour.length &&
                            currentDetail.store.specialhour.map(item=>(
                                getSpecialHoursRows(item)
                            ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="mt-1">
              {currentDetail.store.storehours.length &&
                currentDetail.store.storehours.map((item, i) => (
                  <div className="row" key={i}>
                    <div className="col-md-6">
                      <small className="mt-2">{item.weekdayDescription}</small>
                    </div>
                    <div className="col-md-6">
                      <small
                        style={{
                          color: `${item.isWorkingDay === "Y" ? "" : "red"}`,
                        }}
                        aria-label={item.isWorkingDay === "Y"
                        ? item.startTime + " - " + item.endTime
                        : "Closed"}
                      >
                        {item.isWorkingDay === "Y"
                          ? item.startTime + " - " + item.endTime
                          : "Closed"}
                      </small>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
        {currentDetail.store.storeOperationMonth && (
          <div className="col-md-12 mt-3">
            <p className="p-none ml-2 smallTitle">
              <i className="fa fa-calendar cusIcon"></i> Month(s) of Operation
            </p>
            <div className="row mt-2">
              <div
                className="col-md-12 p-none"
                style={{ padding: "0 20px 0 43px" }}
              >
                <ul
                  className="row ml-2"
                  style={{
                    listStyle: "none",
                    padding: "0",
                    margin: "0 !important",
                  }}
                >
                  {mnOpr.map(
                    (item, i) =>
                      <li
                        key={i}
                        style={{ background: "white" }}
                        className="col-md-4 col-6 monthBorder store-operations"
                      >
                        <small>{item}</small>
                      </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        )}
        {currentDetail.store.storeOrderTypeDetail && (
          <div className="col-md-12 mt-3">
            <p className="p-none ml-2 smallTitle">
              <i className="fa fa-check-square-o cusIcon"></i> Order Option(s)
            </p>
            <div className="row mt-2">
              <div
                className="col-md-12 p-none"
                style={{ padding: "0 20px 0 43px" }}
              >
                <ul
                  className="row ml-2"
                  style={{
                    listStyle: "none",
                    padding: "0",
                    margin: "0 !important",
                  }}
                >
                  {oderOperation.sort().map((item, i) => (
                    <li
                      key={i}
                      style={{ background: "white" }}
                      className="col-md-12 col-12 monthBorder store-operations"
                    >
                      <small>
                        {item}
                      </small>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        )}
        {currentDetail.products && (
          <div className="col-md-12 ml-2 mt-3">
            <p className="p-none smallTitle">
              <img
                className="harvest-results"
                src={harvest}
                aria-label="harvest"
                aria-labelledby="harvest"
                alt="img"
                role="none"
              />
              &nbsp;<span>Products</span>
            </p>{" "}
            <small>{currentDetail.products} </small>
          </div>
        )}
        {currentDetail?.publicPhoto && (
        <div className="col-md-12 mb-2 mt-3 ml-2">
          <p className="p-none smallTitle">
            <i className="fa fa-image cusIcon"></i> Vendor photos
          </p>
          <div className="mt-1 text-center">
            <div className="mt-1">
              {" "}
              <img
                src={currentDetail.publicPhoto}
                className="img-thumbnail"
                aria-label="harvestGreen"
                aria-labelledby="harvestGreen"
                alt="img"
                role="none"
                style={{
                  marginBottom: "5px",
                  marginTop: "5px",
                  width: "250px",
                }}
              />
              {" "}
            </div>
          </div>
        </div>
        )}
      </>
    );
  };

  const getGroupFarmerMarket = (obj) => {
    let storeTypeElements = "";
    let storeMarketElements = "";
    let isMarketManagerIcon = false;
    for (let property in LOCATION_TYPES) {
      if (obj.location_types_name === property) {
        if (property === "farmersMarket" || property === "farmersMarketManagers") {
          isMarketManagerIcon = true;
          let isMarketBooth = obj.farmersMarket === "Y";
          let isMarketManager = obj.farmersMarketManagers === "Y";
          if(isMarketBooth){
            storeMarketElements = LOCATION_TYPES["farmersMarket"];
          }
          if(isMarketManager){
            storeTypeElements = LOCATION_TYPES["farmersMarketManagers"];
          }
        }else{
          storeTypeElements = LOCATION_TYPES[property];
        }
      }
    }

      return (<> { Object.keys(storeMarketElements).length ? (
        <div className="col-md-12">
          <small className="boldFont">
            {storeMarketElements.loc_type_nam}
          </small>{" "}
          <img
            src={storeMarketElements.loc_type_img} //getLocationImg(obj)
            width="24"
            title={storeMarketElements.loc_type_nam}
            aria-label={
              storeMarketElements.loc_type_nam
            }
            alt="img"
            role="none"
          />{" "}
        </div>) : ""}

        {Object.keys(storeTypeElements).length ? (
          <div className="col-md-12">
            <small className="boldFont">
              {storeTypeElements.loc_type_nam}
            </small>{" "}
            <img
              src={storeTypeElements.loc_type_img} //getLocationImg(obj)
              width="24"
              title={storeTypeElements.loc_type_nam}
              aria-label={
                storeTypeElements.loc_type_nam
              }
              alt="img"
              role="none"
            />{" "}
          </div>
        ): ""}
      </>)
  };

  return (
    <div>
      { isLoading && 
        <CommonLoading />
      }

      {windowDimensions.width >= 768 && (
        <div
          className={`searchResultsSection bg-white active ${!searchResultView && !singleSection
            ? "d-none"
            : "d-md-block d-lg-block"
            }`}
          id="setHeight"
          tabIndex="0"
        >
          {" "}
          <div
            className={`row showResultExport ${!singleSection ? "" : "d-none"}`}
          >
            <div className="col-md-5  resultLabel">
              <h4 className="resText" aria-label={language["Results"]}>{language["Results"]}</h4>
            </div>
            <div className="col-md-7 text-right resultLabel">
              <span style={{ marginRight: "10px", fontSize: "18px" }}>
                <a href="javascript:void(0)" aria-label="sort descending" onClick={() => {
                    sortResults("desc");
                    setSortBy("desc");
                  }}>
                  <i
                  id="upArrow"
                  className={`fa fa-long-arrow-up ${sortBy === "desc" ? "sortColorGreen" : "sortColor"
                    }`}
                  
                  
                ></i>
                </a>
                &nbsp;
                  
                <a href="javascript:void(0)" aria-label="sort ascending"  onClick={() => {
                    sortResults("asc");
                    setSortBy("asc");
                  }}>
                <i
                  id="downArrow"
                  className={`fa fa-long-arrow-down ${sortBy === "asc" ? "sortColorGreen" : "sortColor"
                    }`}
                  style={{ color: "#2a6e52" }}
                  
                ></i>
                </a>
              </span>
              <a href="javascript:void(0)" className="mr-3 cursor" onClick={()=>{
                getStoresReport(mapSearch);
                setIsLoading(true);
                }} >
                  <img src={xls_export} width="26" aria-label="Export" /><small className="boldFont"><u>{language.Export}</u></small>       
              </a>
              <a
                className="cursor btn btn-rounded btn-light btn-sm btn-bg btn-font"
                onClick={handleClick}
                href="javascript:void(0)"
              >
                <i className="fa fa-close cusIcon cursor" aria-label="close"></i>
              </a>
            </div>
          </div>
          <div className={`row storeList ${!singleSection ? "" : "d-none"}`}>
            {originalArray.length > 0 &&
              originalArray.map((location) => {
                return (
                  <div
                    className="col-md-12 p-none"
                    key={location.store.id}
                    id={location.store.id}
                  >
                    <div className="card cardShadow cardHover mt-2">
                      <div className="row mr-0 ml-0">
                        <div className="col-10 card-body ">
                          <div className="row">
                            <div className="col-md-12">
                              <h4 className="text-color p-none" aria-label={ (location.location_types_name === "farmersMarket" || location.location_types_name === "farmersMarketManagers") && location.store.siteName
                                  ? location.store.siteName.replace(
                                    /&#38;/g,
                                    "’"
                                  )
                                  : location.vendorName.replace(
                                    /&#38;/g,
                                    "’"
                                  )}>
                                { (location.location_types_name === "farmersMarket" || location.location_types_name === "farmersMarketManagers") && location.store.siteName
                                  ? location.store.siteName.replace(
                                    /&#38;/g,
                                    "’"
                                  )
                                  : location.vendorName.replace(
                                    /&#38;/g,
                                    "’"
                                  )}
                              </h4>
                            </div>
                            {getGroupFarmerMarket(location)}
                            {/* <div className="col-md-12"> </div> */}
                            <div className="col-md-12">
                              {" "}
                              <small className="boldFont" aria-label={(location?.miles ? location?.miles : "0") +
                                  " miles away"}>
                                <i className="fa fa-location-arrow cusIcon"></i>{" "}
                                {(location?.miles ? location?.miles : "0") +
                                  " miles away"}
                              </small>
                              <br />
                              {
                                  (!(location.location_types_name === "farmersMarket" || location.location_types_name === "farmersMarketManagers") && location.store.siteName && location.store.siteName && location.store.siteName !== "") ? (
                                    <> 
                                      <small aria-label={location?.store?.siteName?.toUpperCase()}>
                                          <i className="fa fa-map-pin cusIcon"></i>{" "}
                                          {location?.store?.siteName?.toUpperCase()}
                                      </small>
                                      <br />
                                    </>
                                  ) : ""
                              }                          
                              <small aria-label={location?.address.replace(/\s+/g, " ")}>
                                <i className="fa fa-map-signs text-muted"></i>{" "}
                                {location?.address.replace(/\s+/g, " ")}  {/*<!-- getAddress(location.store.addressDetail) -->*/}
                              </small>
                            </div>
                            {(location?.publicMobile ||
                              location?.publicEmail) &&
                              (!location.store.siteName) ? (
                              <div className="col-md-12 ytytytyt">
                                <small aria-label={location?.publicMobile}>
                                  <i className="fa fa-phone cusIcon"></i>{" "}
                                  {location?.publicMobile}
                                </small>{" "}
                                &nbsp;
                                <small aria-label={location?.publicEmail}>
                                  <i className="fa fa-envelope cusIcon"></i>{" "}
                                  {location?.publicEmail}
                                </small>
                                <br />
                              </div>
                            ) : (
                              ""
                            )}
                            {location.location_types_name === "farmersMarket" ||
                              location.location_types_name ===
                              "farmersMarketManagers"
                              ? "" : getTodayStatus(location)}
                          </div>
                        </div>
                        <div className="col-2">
                          <button
                          tabIndex={0}
                            className="btn btn-outline-primary right-arrow"
                            onClick={() => {
                              (location.location_types_name === "farmersMarket" ||
                              location.location_types_name ===
                              "farmersMarketManagers")
                                ? openMarketStoreDetail(location)
                                : openVendorStoreDetail(location);                         
                            }}
                          >
                            <i className="n_nav-right-icon fa fa-chevron-down"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
          <div
            className={`singleSection  ${!singleSection ? "d-none" : ""}`}
          >
            {!isMarket && currentVendorStoreDetail.length !== 0 && currentVendorStoreDetail[0].store.id === currentVendor.store.id ? (
              <>
                <div className="row totalblHeight m-0">
                  <div
                    className="col-md-10 text-left p-none resultLabel"
                    onClick={backToResults}
                  >
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <a className="cursor" href="javascript:void(0)" style={{textDecoration:'none'}}>
                            <i className="fa fa-arrow-left cusIcon"></i> &nbsp;
                            <span className="result-breadcrumb">{language["Results"]}</span>
                          </a>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          title={currentVendorStoreDetail[0].vendorName}
                          aria-label={currentVendorStoreDetail[0].vendorName.replace(
                            /&#38;/g,
                            "’"
                          )}
                          aria-current="page"
                        >
                          {currentVendorStoreDetail[0].vendorName.replace(
                            /&#38;/g,
                            "’"
                          )}
                        </li>
                      </ol>
                    </nav>
                  </div>
                  <div className="col-md-2 text-right p-none resultClose">
                    <a
                      href="javascript:void(0)"
                      className="cursor btn btn-rounded btn-light btn-sm btn-bg btn-font"
                      onClick={closeResult}
                    >
                      <i className="fa fa-close cusIcon cursor"></i>
                    </a>
                  </div>
                </div>
                <div className="row m-0">
                  <div
                    className="col-md-12 singleStoreView"
                    style={{
                      overflow: "auto",
                      marginTop: "7px",
                      height: "400.828px",
                    }}
                  >
                    <div className="row card-overflow">
                      <div
                        style={{
                          border: "1px solid #006e52",
                          background: "#e8f1ec",
                          boxShadow: "0 1px 11px 1px rgba(0, 0, 0, 0.2)",
                          borderBottom: "none",
                        }}
                        className="card cardHover cursor bg mt-2"
                      >
                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-12">
                              <h4 className="text-color p-none" aria-label={currentVendorStoreDetail[0].vendorName.replace(
                            /&#38;/g,
                            "’"
                          )}>
                                {" "}
                                {currentVendorStoreDetail[0].vendorName.replace(
                                  /&#38;/g,
                                  "’"
                                )}
                              </h4>
                            </div>
                            {/* <div className="col-md-12">
                              <small className="boldFont">
                                {" "}
                                {
                                  LOCATION_TYPES[
                                    currentVendorStoreDetail[0][
                                    "location_types_name"
                                    ]
                                  ].loc_type_nam
                                }
                              </small>
                              &nbsp;
                              <img
                                src={getLocationImg(currentVendorStoreDetail[0])}
                                width="24"
                                aria-label="storePhoto"
                                aria-labelledby="storePhoto"
                                alt="img"
                                role="none"
                              />{" "}
                            </div> */}
                            {(currentVendorStoreDetail[0].store.storeType === "HIP CSA PROGRAM" || currentVendorStoreDetail[0].store.storeType === "HIP Mobile Market Stop") && <div className="col-md-12">
                              <small aria-label={currentVendorStoreDetail[0].store.siteName}>
                                <i className="fa fa-map-pin cusIcon"></i>{" "}
                                {currentVendorStoreDetail[0].store.siteName}
                              </small>
                            </div>}
                            <div className="col-md-12">
                              {" "}
                              <i className="fa fa-map-signs cusIcon"></i>{" "}
                              <small aria-label={getAddress(currentVendorStoreDetail[0].store.addressDetail)}>
                                {getAddress(currentVendorStoreDetail[0].store.addressDetail)}
                              </small>{" "}
                            </div>
                            <div className="col-md-12">
                              {
                              currentVendorStoreDetail[0]?.publicMobile && (
                                  <>
                                      <i className="fa fa-phone cusIcon"></i>{" "}
                                      <small aria-label={currentVendorStoreDetail[0]?.publicMobile}>
                                      {currentVendorStoreDetail[0]?.publicMobile}
                                      </small> 
                                  </> 
                              )}
                              { 
                                  currentVendorStoreDetail[0]?.publicEmail && (
                                      <>
                                        &nbsp;&nbsp;
                                      <i className="fa fa-envelope cusIcon"></i>{" "}
                                      <small aria-label={currentVendorStoreDetail[0]?.publicEmail}>
                                      {currentVendorStoreDetail[0]?.publicEmail}
                                      </small>
                                      </>
                              )}                           
                            </div>

                            {/* <div className="col-md-5 col-sm-12">
                              {" "}
                              <small
                                className="btnLinkRed mb-2 mt-2"
                                style={{ display: "inline-block" }}
                              >
                                Closed today
                              </small>{" "}
                            </div> */}
                            {getTodayStatus(currentVendorStoreDetail[0])}
                          </div>
                        </div>
                      </div>
                      <div
                        className="card bg-light"
                        style={{
                          border: "1px solid #006e52",
                          background: "#e8f1ec",
                          borderTop: "none",
                        }}
                      >
                        <div className="card-body">
                          <div className="col-md-12 ml-2">
                            <small>
                              <i className="fa fa-map text-muted cusIcon"></i>{" "}
                              <a
                                href={getMapURL(currentVendorStoreDetail[0])}
                                style={{ textDecoration: "underline" }}
                                target="_blank"
                                rel="noreferrer"
                              >
                                Navigate to location
                              </a>
                            </small>
                            {currentVendorStoreDetail[0]?.publicWebsite && (
                              <>
                                <br />
                                <small>
                                  <i className="fa fa-globe text-muted cusIcon"></i>{" "}
                                  <a
                                    href={getSiteUrl(
                                      currentVendorStoreDetail[0]?.publicWebsite
                                    )}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ textDecoration: "underline" }}
                                  >
                                    Go to store website
                                  </a>
                                </small>
                              </>
                            )}

                            {/* <br><small class="d-md-none d-lg-none d-sm-block">  </small> */}
                          </div>
                          {getMoreDetails(currentVendorStoreDetail[0])}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : isMarket && currentVendorStoreDetail.length !== 0 && selectedStoreIds.includes(currentVendor.store.id) ? (
              <>
                <div className="col-md-12 totalLabelHeight">
                  <div className="row p-none">
                    <div
                      className="col-md-10 text-left p-none resultLabel singleView"
                      onClick={backToResults}
                    >
                      <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                          <li className="breadcrumb-item">
                            <a className="cursor" style={{textDecoration:'none'}}>
                              <i className="fa fa-arrow-left cusIcon"></i> &nbsp;
                              <span className="result-breadcrumb">Results</span>
                            </a>
                          </li>
                          <li
                            className="breadcrumb-item active result-list"
                            title={currentVendorStoreDetail[0].store.siteName}
                            aria-current="page"
                            aria-label={currentVendorStoreDetail[0].store.siteName}
                          >
                            {currentVendorStoreDetail[0].store.siteName}
                          </li>
                        </ol>
                      </nav>
                    </div>
                    <div className="col-md-2 text-right p-none resultClose">
                      <a
                        className="cursor btn btn-rounded btn-default btn-sm btn-bg btn-font"
                        onClick={closeResult}
                      >
                        <i className="fa fa-close cusIcon cursor"></i>
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  className="col-md-12 singleStoreView"
                  style={{
                    overflowX: "hidden",
                    marginTop: "13px",
                    height: "394.531px",
                  }}
                >
                  <div className="row">
                    {/* <div className="col-md-12 mt-3">
                      {" "}
                      <h4 className="text-color p-none">
                        {currentVendorStoreDetail[0].vendorName}
                      </h4>{" "}
                    </div> */}
                    <div className="col-md-12">
                      {" "}
                      <small className="boldFont" aria-label={getMiles({'latitude':currentVendorStoreDetail[0].store.addressDetail.latitude,'longitude':currentVendorStoreDetail[0].store.addressDetail.longitude}, searchCoordinates) +
                          " miles away"}>
                        <i className="fa fa-location-arrow cusIcon"></i>{" "}
                        {getMiles({'latitude':currentVendorStoreDetail[0].store.addressDetail.latitude,'longitude':currentVendorStoreDetail[0].store.addressDetail.longitude}, searchCoordinates) +
                          " miles away"}
                      </small>
                      <br />
                      <small aria-label={getAddress(currentVendorStoreDetail[0].store.addressDetail)}>
                        <i className="fa fa-map-signs"></i>{" "}
                        {getAddress(currentVendorStoreDetail[0].store.addressDetail)}
                      </small>
                      {/* <br />
                      {currentVendorStoreDetail[0]?.publicMobile && 
                        <div>
                              <i className="fa fa-phone cusIcon"></i> {currentVendorStoreDetail[0].publicMobile}
                        </div>
                      }
                      {currentVendorStoreDetail[0]?.publicEmail && (
                          <div>
                            <i className="fa fa-envelope cusIcon"></i> {currentVendorStoreDetail[0].publicEmail}
                          </div>
                      )} */}
                      <div>
                        <i className="fa fa-map cusIcon"></i> 
                        <a
                          href={getMapURL(currentVendorStoreDetail[0])}
                          style={{ textDecoration: "underline" }}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Navigate to location
                        </a>
                      </div>
                      {/* {currentVendorStoreDetail[0]?.publicWebsite && (
                        <div>
                          <i className="fa fa-globe text-muted cusIcon"></i>{" "}
                          <a
                            href={getSiteUrl(
                              currentVendorStoreDetail[0]?.publicWebsite
                            )}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ textDecoration: "underline" }}
                          >
                            Go to store website
                          </a>
                        </div>
                      )} */}
                    </div>
                    <div className="col-md-12">
                      <div
                        className="panel-group mb-3"
                        id="storeDetailsAccordion"
                      >
                        <Accordion>
                          {currentVendorStoreDetail.map((item, i) => (
                            <div
                              key={i}
                              className="panel panel-default n_panel-default mt-2 accor"
                            >
                              {getStoreOpenStatus(item, true, i)}
                            </div>
                          ))}
                        </Accordion>

                          {/* <Accordion activeKey={accordActiveKey}>
                              {currentVendorStoreDetail.map((item, i) => (
                              <Accordion.Item eventKey={i}
                              onClick={()=>{
                                  if(accordActiveKey?.includes(i)) {
                                      setAccordActiveKey([]);
                                  } else {
                                      setAccordActiveKey([i]);
                                  }
                              }}
                              >
                                  {getMoreDetails(item)}
                              </Accordion.Item>
                              ))}
                          </Accordion> */}
                        
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className="storeSingleSection"><i className="fa fa-spinner fa-spin"></i></div>
            )}
          </div>
        </div>
      )}
      <div
        className={`${windowDimensions.width < 768 && isResultCollapsed && originalArray.length > 0 ? 'activate' : 'unactivate'} result-sidebar-panel sidebar-result smSearch`}
        id="setHeight"
        tabIndex="0"
      >
        {" "}
        <div
          className={`showMobileResultExport ${!singleSection ? "" : "d-none"}`}
        >
          <div className="pull-left resultLabel">
            <h4 className="resText" aria-label={language["Results"]}>{language["Results"]}</h4>
          </div>
          <div className="text-right resultLabel">
            <span style={{ marginRight: "10px", fontSize: "18px" }}>
              <a href="javascript:void(0)" aria-label="sort descending" onClick={() => {
                  sortResults("desc");
                  setSortBy("desc");
                }}>
                <i
                id="upArrow"
                className={`fa fa-long-arrow-up ${sortBy === "desc" ? "sortColorGreen" : "sortColor"
                  }`}
                
                
              ></i>
              </a>
              &nbsp;
                
              <a href="javascript:void(0)" aria-label="sort ascending"  onClick={() => {
                  sortResults("asc");
                  setSortBy("asc");
                }}>
              <i
                id="downArrow"
                className={`fa fa-long-arrow-down ${sortBy === "asc" ? "sortColorGreen" : "sortColor"
                  }`}
                style={{ color: "#2a6e52" }}
                
              ></i>
              </a>
            </span>
            <a href="javascript:void(0)" className="mr-3 cursor" onClick={()=>{
              getStoresReport(mapSearch);
              setIsLoading(true);
              }} >
                <img src={xls_export} width="26" aria-label="Export" /><small className="boldFont"><u>{language.Export}</u></small>       
            </a>
            <a
              className="cursor btn btn-rounded btn-light btn-sm btn-bg btn-font"
              onClick={()=>{
                handleClick();
                setResultCollapsed(false);
              }}
              href="javascript:void(0)"
            >
              <i className="fa fa-close cusIcon cursor" aria-label="close"></i>
            </a>
          </div>
        </div>
        <div className={`row storeList ${!singleSection ? "" : "d-none"}`}>
          {originalArray.length > 0 &&
            originalArray.map((location, i) => {
              return (
                <div
                  className="col-md-12 p-none"
                  key={location.store.id}
                  id={location.store.id}
                >
                  <div className="card cardShadow cardHover mt-2">
                    <div className="row mr-0 ml-0">
                      <div className="col-10 card-body ">
                        <div className="row">
                          <div className="col-md-12">
                            <h4 className="text-color p-none" aria-label={ (location.location_types_name === "farmersMarket" || location.location_types_name === "farmersMarketManagers") && location.store.siteName
                                ? location.store.siteName.replace(
                                  /&#38;/g,
                                  "’"
                                )
                                : location.vendorName.replace(
                                  /&#38;/g,
                                  "’"
                                )}>
                              { (location.location_types_name === "farmersMarket" || location.location_types_name === "farmersMarketManagers") && location.store.siteName
                                ? location.store.siteName.replace(
                                  /&#38;/g,
                                  "’"
                                )
                                : location.vendorName.replace(
                                  /&#38;/g,
                                  "’"
                                )}
                            </h4>
                          </div>
                          {getGroupFarmerMarket(location)}
                          <div className="col-md-12">
                            {" "}
                            <small className="boldFont" aria-label={(location?.miles ? location?.miles : "0")+
                                " miles away"}>
                              <i className="fa fa-location-arrow cusIcon"></i>{" "}
                              {(location?.miles ? location?.miles : "0") +
                                " miles away"}
                            </small>
                            <br />
                            {
                                (!(location.location_types_name === "farmersMarket" || location.location_types_name === "farmersMarketManagers") && location.store.siteName && location.store.siteName && location.store.siteName !== "") ? (
                                   <> 
                                    <small aria-label={location?.store?.siteName?.toUpperCase()}>
                                        <i className="fa fa-map-pin cusIcon"></i>{" "}
                                        {location?.store?.siteName?.toUpperCase()}
                                    </small>
                                    <br />
                                   </>
                                ) : ""
                            }                          
                            <small aria-label={location?.address.replace(/\s+/g, " ")}>
                              <i className="fa fa-map-signs text-muted"></i>{" "}
                              {location?.address.replace(/\s+/g, " ")}
                            </small>
                          </div>
                          {(location?.publicMobile ||
                            location?.publicEmail) &&
                            (!location.store.siteName) ? (
                            <div className="col-md-12 ytytytyt">
                              <small aria-label={location?.publicMobile}>
                                <i className="fa fa-phone cusIcon"></i>{" "}
                                {location?.publicMobile}
                              </small>{" "}
                              &nbsp;
                              <small aria-label={location?.publicEmail}>
                                <i className="fa fa-envelope cusIcon"></i>{" "}
                                {location?.publicEmail}
                              </small>
                              <br />
                            </div>
                          ) : (
                            ""
                          )}
                          {location.location_types_name === "farmersMarket" ||
                            location.location_types_name ===
                            "farmersMarketManagers"
                            ? "" : getTodayStatus(location)}
                        </div>
                      </div>
                      <div className="col-2">
                        <button
                        tabIndex={0}
                          className="btn btn-outline-primary right-arrow"
                          onClick={() => {
                            (location.location_types_name === "farmersMarket" ||
                            location.location_types_name ===
                            "farmersMarketManagers")
                              ? openMarketStoreDetail(location)
                              : openVendorStoreDetail(location);                         
                          }}
                        >
                          <i className="n_nav-right-icon fa fa-chevron-down"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
        <div
          className={`singleSection  ${!singleSection ? "d-none" : ""}`}
        >
          {!isMarket && currentVendorStoreDetail.length !== 0 && currentVendorStoreDetail[0].store.id === currentVendor.store.id ? (
            <>
              <div className="row totalblHeight m-0">
                <div
                  className="p-none resultLabel"
                  onClick={backToResults}
                >
                  <nav aria-label="breadcrumb" className="pull-left">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <a className="cursor" href="javascript:void(0)" style={{textDecoration:'none'}}>
                          <i className="fa fa-arrow-left cusIcon"></i> &nbsp;
                          <span className="result-breadcrumb">{language["Results"]}</span>
                        </a>
                      </li>
                      <li
                      style={{"marginTop": "3px"}}
                        className="breadcrumb-item active"
                        title={currentVendorStoreDetail[0].vendorName}
                        aria-label={currentVendorStoreDetail[0].vendorName.replace(
                          /&#38;/g,
                          "’"
                        )}
                        aria-current="page"
                      >
                        {currentVendorStoreDetail[0].vendorName.replace(
                          /&#38;/g,
                          "’"
                        )}
                      </li>
                    </ol>
                  </nav>
                  {/* <div className="text-right p-none resultClose">
                    <a
                      href="javascript:void(0)"
                      className="cursor btn btn-rounded btn-light btn-sm btn-bg btn-font"
                      onClick={closeResult}
                    >
                      <i className="fa fa-close cusIcon cursor"></i>
                    </a>
                  </div> */}
                </div>               
              </div>
              <div className="row m-0">
                <div
                  className="col-md-12 singleStoreView"
                  style={{
                    // overflow: "auto",
                    // marginTop: "7px",
                    // height:"calc(100vh - 160px)",
                    height:'599px',
                    marginBottom:'100px',
                    marginTop:'10px',
                    overflowY:'auto'
                  }}
                >
                  <div className="row card-overflow">
                    <div
                      style={{
                        border: "1px solid #006e52",
                        background: "#e8f1ec",
                        boxShadow: "0 1px 11px 1px rgba(0, 0, 0, 0.2)",
                        borderBottom: "none",
                      }}
                      className="card cardHover cursor bg mt-2"
                    >
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-12">
                            <h4 className="text-color p-none" aria-label={currentVendorStoreDetail[0].vendorName.replace(
                          /&#38;/g,
                          "’"
                        )}>
                              {" "}
                              {currentVendorStoreDetail[0].vendorName.replace(
                                /&#38;/g,
                                "’"
                              )}
                            </h4>
                          </div>                         
                          {(currentVendorStoreDetail[0].store.storeType === "HIP CSA PROGRAM" || currentVendorStoreDetail[0].store.storeType === "HIP Mobile Market Stop") && <div className="col-md-12">
                            <small aria-label={currentVendorStoreDetail[0].store.siteName}>
                              <i className="fa fa-map-pin cusIcon"></i>{" "}
                              {currentVendorStoreDetail[0].store.siteName}
                            </small>
                          </div>}
                          <div className="col-md-12">
                            {" "}
                            <i className="fa fa-map-signs cusIcon"></i>{" "}
                            <small aria-label={getAddress(currentVendorStoreDetail[0].store.addressDetail)}>
                              {getAddress(currentVendorStoreDetail[0].store.addressDetail)}
                            </small>{" "}
                          </div>
                          <div className="col-md-12">
                            {
                             currentVendorStoreDetail[0]?.publicMobile && (
                                <>
                                    <i className="fa fa-phone cusIcon"></i>{" "}
                                    <small aria-label={currentVendorStoreDetail[0]?.publicMobile}>
                                    {currentVendorStoreDetail[0]?.publicMobile}
                                    </small> 
                                </> 
                            )}
                            { 
                                currentVendorStoreDetail[0]?.publicEmail && (
                                    <>
                                      &nbsp;&nbsp;
                                    <i className="fa fa-envelope cusIcon"></i>{" "}
                                    <small aria-label={currentVendorStoreDetail[0]?.publicEmail}>
                                    {currentVendorStoreDetail[0]?.publicEmail}
                                    </small>
                                    </>
                            )}                           
                          </div>

                          {getTodayStatus(currentVendorStoreDetail[0])}
                        </div>
                      </div>
                    </div>
                    <div
                      className="card bg-light"
                      style={{
                        border: "1px solid #006e52",
                        background: "#e8f1ec",
                        borderTop: "none",
                      }}
                    >
                      <div className="card-body">
                        <div className="col-md-12 ml-2">
                          <small>
                            <i className="fa fa-map text-muted cusIcon"></i>{" "}
                            <a
                              href={getMapURL(currentVendorStoreDetail[0])}
                              style={{ textDecoration: "underline" }}
                              target="_blank"
                              rel="noreferrer"
                            >
                              Navigate to location
                            </a>
                          </small>
                          {currentVendorStoreDetail[0]?.publicWebsite && (
                            <>
                              <br />
                              <small>
                                <i className="fa fa-globe text-muted cusIcon"></i>{" "}
                                <a
                                  href={getSiteUrl(
                                    currentVendorStoreDetail[0]?.publicWebsite
                                  )}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{ textDecoration: "underline" }}
                                >
                                  Go to store website
                                </a>
                              </small>
                            </>
                          )}
                        </div>
                        {getMoreDetails(currentVendorStoreDetail[0])}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : isMarket && currentVendorStoreDetail.length !== 0 && selectedStoreIds.includes(currentVendor.store.id) ? (
            <>
              <div className="col-md-12 totalLabelHeight">
                <div className="row p-none">
                  <div
                    className="p-none resultLabel singleView"
                    onClick={backToResults}
                  >
                    <nav aria-label="breadcrumb" className="pull-left">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <a className="cursor" style={{textDecoration:'none'}}>
                            <i className="fa fa-arrow-left cusIcon"></i> &nbsp;
                            <span className="result-breadcrumb">Results</span>
                          </a>
                        </li>
                        <li
                         style={{"marginTop": "3px"}}
                          className="breadcrumb-item active result-list"
                          title={currentVendorStoreDetail[0].store.siteName}
                          aria-current="page"
                          aria-label={currentVendorStoreDetail[0].store.siteName}
                        >
                          {currentVendorStoreDetail[0].store.siteName}
                        </li>
                      </ol>
                    </nav>
                    <div className="text-right p-none resultClose">
                      <a
                        className="cursor btn btn-rounded btn-default btn-sm btn-bg btn-font"
                        onClick={closeResult}
                      >
                        <i className="fa fa-close cusIcon cursor"></i>
                      </a>
                    </div>
                  </div>                 
                </div>
              </div>
              <div
                className="col-md-12 singleStoreView"
                style={{
                  overflowX: "hidden",
                  marginTop: "13px",
                  height:"calc(100vh - 115px)"
                }}
              >
                <div className="row">
                  <div className="col-md-12">
                    {" "}
                    <small className="boldFont" aria-label={getMiles({'latitude':currentVendorStoreDetail[0].store.addressDetail.latitude,'longitude':currentVendorStoreDetail[0].store.addressDetail.longitude}, searchCoordinates) +
                        " miles away"}>
                      <i className="fa fa-location-arrow cusIcon"></i>{" "}
                      {getMiles({'latitude':currentVendorStoreDetail[0].store.addressDetail.latitude,'longitude':currentVendorStoreDetail[0].store.addressDetail.longitude}, searchCoordinates) +
                        " miles away"}
                    </small>
                    <br />
                    <small aria-label={getAddress(currentVendorStoreDetail[0].store.addressDetail)}>
                      <i className="fa fa-map-signs"></i>{" "}
                      {getAddress(currentVendorStoreDetail[0].store.addressDetail)}
                    </small>                  
                    <div>
                      <i className="fa fa-map cusIcon"></i> 
                      <a
                        href={getMapURL(currentVendorStoreDetail[0])}
                        style={{ textDecoration: "underline" }}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Navigate to location
                      </a>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div
                      className="panel-group mb-3"
                      id="storeDetailsAccordion"
                    >
                      <Accordion>
                        {currentVendorStoreDetail.map((item, i) => (
                          <div
                            key={i}
                            className="panel panel-default n_panel-default mt-2 accor"
                          >
                            {getStoreOpenStatus(item, true, i)}
                          </div>
                        ))}
                      </Accordion>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="storeSingleSection"><i className="fa fa-spinner fa-spin"></i></div>
          )}
        </div>
      </div>
    </div>
  );
};

MapSearchResultComponent.propTypes = {
  mapResult: PropTypes.array,
  filterClusterer: PropTypes.array,
  storeSingleSection: PropTypes.bool,
  searchStoreResultView: PropTypes.bool,
  locationId: PropTypes.number,
  closeResultSection: PropTypes.func.isRequired,
  searchCoordinates: PropTypes.object.isRequired,
  mapWidth: PropTypes.string.isRequired,
  singleSectionClick:PropTypes.bool,
  getStoreDetails: PropTypes.func,
  storeDetailsData: PropTypes.array,
  storesReport: PropTypes.array,
  getSpecialHourData: PropTypes.func,
  orderOptions: PropTypes.array,
  specialHourData: PropTypes.object,
  language: PropTypes.object,
  mapSearch: PropTypes.object,
  getStoresReport: PropTypes.func.isRequired,
  initialStoreView: PropTypes.bool,
  windowDimensions: PropTypes.object,
  isResultCollapsed: PropTypes.bool,
  setResultCollapsed: PropTypes.func
};

const mapDispatchToProps = (dispatch) => {
  return {
    getStoresReport: (payload) =>
        dispatch({ type: GET_STORE_REPORT, payload: payload }),
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(MapSearchResultComponent);
