/*
  Constants specific to the INPUT question type. See the `types.js` file containing
  all of the possible question types for full documentation of what properties
  are allowed for each question type in the questions data file.
 */

// Optional
export const PLACEHOLDER = "placeholder";
export const CLEAR_LABEL = "inputClearLabel";
export const INPUT_LABEL = "inputLabel";
