import * as React from "react";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";

const PageHeaderComponent = (props) => {
    return (
        <Typography component="span" mb={2} maxWidth={400} lineHeight="lg">
            <Typography variant="h6" display="inline" className="resText">{props.title}</Typography>{' '}
            {props.showSubtitle && `| ${props.subtitle}: `}
            {props.showSubtitle && <b>{props.count}</b>}
        </Typography>
    )
}

PageHeaderComponent.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    showSubtitle: PropTypes.bool.isRequired,
    count:PropTypes.number,
};

export default PageHeaderComponent;