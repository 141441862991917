import React from "react";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import "../../../styles/home.scss"


const ModalComponent = ({shown,close,title,modalBody,size,centered=false,dialogClassName="", id=""}) => {

  return (
      <Modal
        show={shown}
        onHide={() => close()}
        backdrop="static"
        keyboard={false}
        animation={1}
        size={size}
        centered={centered}
        dialogClassName={dialogClassName} 
        id={ id ? id : "modal-popup"}     
      >
        <Modal.Header closeButton>
          <h4 className="modal-title modalTitle resText">{title}</h4>
          </Modal.Header>
        <Modal.Body>
          <div className="modalBody">
            {modalBody()}
          </div>
        </Modal.Body>
      </Modal>
  );
};

ModalComponent.propTypes = {
  shown: PropTypes.bool,
  close: PropTypes.func,
  title: PropTypes.string,
  modalBody: PropTypes.func, 
  size: PropTypes.string,
  centered: PropTypes.bool,
  dialogClassName: PropTypes.string,
  id: PropTypes.string
};

export default ModalComponent;
