import React, { useState, useEffect  } from "react";
import {
  NavLink,
  useNavigate,
} from "react-router-dom";
import logo from "../../../assets/images/dta-finder.svg";
import LoginComponent from "../../user/login/login";
import AdminLoginComponent from "../../user/login/admin-login";
import ForgotPwdComponent from "../../user/forgotpassword/forgotpassword";
import LanguageSelect from "../language/languageSelect";
import { mapStateToProps } from "../../../redux/reducers";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  Dropdown
} from "react-bootstrap";
import HelpComponent from "../../user/help";
import { tokenRevoke } from "../../../redux/actionTypes/auth";

//azure
// import { useMsal } from "@azure/msal-react";
import { authUserDetails } from "../../../redux/actionTypes/auth";
import { pageRefresh } from "../../../utils/globalMethods";
// import { azureLogin } from '../../redux/user/loginActions';
// import { onAzureLogOut } from '../../utils/helper/accountNavigationActions';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}
const Header = (props) => {
  const navigate = useNavigate();

  const [modalShown, toggleModal] = useState(false);
  const [modalShownAdmin, toggleModalAdmin] = useState(false);
  const [forgotShown, toggleForgotModal] = useState(false);
  const [helpModalShow, setHelpModalShow] = useState(false);
  // const [state, dispatch] = useReducer(reducer, initialState);
  const [fullName, setFullName] = useState(null);
  const [menuList, SetMenuList] = useState(false);
  const [language, setLanguage] = useState({});
  const [pathname,setPathName] = useState(window.location.pathname);

  const [isNavCollapsed, setIsNavCollapsed] = useState(true);

  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const {authenticated, user} = props.userAuth;
 
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  
  useEffect(() => {
    if(authenticated && Object.keys(user).length==0){
      const payload = {
        "primaryEmailAddr":authenticated,
        navigate:navigate
      }
      props.authUserDetails(payload)
    }
  }, [props.userAuth.authenticated])
  
  useEffect(() => {
    if(Object.keys(user).length!=0){
      setFullName(user.Name)
    }
  }, [props.userAuth.user])

  useEffect(() => {
    setLanguage(props.language)
  }, [props.language])

  useEffect(() => {
    setPathName(window.location.pathname)
  }, [window.location.pathname])

  useEffect(() => {
    const pathList = pathname.split('/')
    SetMenuList(pathList.includes("dtahip"));
    let path = pathList[pathList.length-1]
    if(pathname.slice(-1)=="/"){
      path = pathList[pathList.length-2]
    }
    
    if(user.Role==="VENDOR"){
      if(!(path == "" || path=="dtahip" || path=="mystore" )){
        navigate("/dtahip");
      }
    }else if(Object.keys(user).length==0){
      if(!(path == "" || path=="dtahip" || path=="registration" )){
        navigate("/dtahip");
      }
    }

  }, [pathname]);

  const openLogin = () => {
    // console.log(msalIns);
    
    // const loginReq = {...loginRequest,extraQueryParameters:{...loginRequest.extraQueryParameters,ui_locales:props.selectedLanguage}}
      // msalIns.loginRedirect(loginRequest).then().catch(e => {
      //   console.error(e);
      // });
    toggleModal(true);
  };

  const openLoginAdmin = () => {
    // console.log(msalIns);
    
    // const loginReq = {...loginRequest,extraQueryParameters:{...loginRequest.extraQueryParameters,ui_locales:props.selectedLanguage}}
      // msalIns.loginRedirect(loginRequest).then().catch(e => {
      //   console.error(e);
      // });
    toggleModalAdmin(true);
  };

  const toggleHelpModal = () => {
    setHelpModalShow(true);
  } 

  const openForgotPwd = () => {
    toggleForgotModal(true);
  };

  const tokenRevoke = ()=>{
    const payload = {
      navigate:navigate
    };
    props.tokenRevoke(payload);   
  }

  useEffect(()=>{
    if (props?.userAuth?.tokenRevoke && props?.userAuth?.tokenRevoke?.status == "SUCCESS") {
      pageRefresh();
    }
  },[props.userAuth.tokenRevoke]);
 

  return (
    <>
      {forgotShown ?
        (
          <ForgotPwdComponent
            shown={forgotShown}
            close={() => {
              toggleForgotModal(false);
            }}
          />
        )
        : (
          ""
        )}
        {helpModalShow ?
        (
          <HelpComponent
            shown={helpModalShow}
            close={() => {
              setHelpModalShow(false);
            }}
            language={language}
          />
        )
        : (
          ""
        )}
      <div className="header">
      {modalShown && (
            <LoginComponent
              shown={modalShown}
              close={(forgotpwd = "") => {
                toggleModal(false);
                if (forgotpwd) {
                  openForgotPwd();
                }
              }}
              language={language}
            />
          )}
          {modalShownAdmin && (
            <AdminLoginComponent
              shown={modalShownAdmin}
              close={(forgotpwd = "") => {
                toggleModalAdmin(false);
                if (forgotpwd) {
                  openForgotPwd();
                }
              }}
              language={language}
            />
          )}
        {(authenticated && menuList) ? (
          <nav className="navbar navbar-expand-lg navbar-light">
            <NavLink to="/" reloadDocument="true">
              <img
                src={logo}
                alt="DTA Finder Logo"
                className="header-logo-responsive mr-0"
                title="DTA Service"
                aria-label="DTA Service"
              />
              <span className="boldFont page-title header-title">
                {language.HealthyIncentivesProgram} {language.hip}
              </span>
           </NavLink>
           
            {/* <button className="navbar-toggler" type="button" aria-expanded={!isNavCollapsed ? true : false} aria-label="Toggle navigation" onClick={handleNavCollapse}>
              <span className="navbar-toggler-icon"></span>
            </button> */}
            {/* <div className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`}  id="navbarSupportedContent">
              <ul className="navbar-nav topMenu ml-auto">
              {(user.Role==="ADMIN" || user.Role==="HIPCRD") &&
                <>
                  <li className="nav-item dropdown">
                    <a
                      // onClick={openLogin}
                      href="/dtahip/admin-dashboard"
                      className="btn btn-outline-primary btn-block pl-3 text-left width-100"
                    >
                      Dashboard
                    </a>
                  </li>
                  <li className="nav-item dropdown">
                    <a
                      // onClick={openLogin}
                      href="/dtahip/manage-vendors"
                      className="btn btn-outline-primary btn-block pl-3 text-left width-100"
                    >
                      Vendors
                    </a>
                  </li>
                  {user.Role==="ADMIN" && <li className="nav-item dropdown">
                    <Dropdown>
                      < Dropdown.Toggle className="nav-text">
                        <span className="d-inline-block mr-3">Administration</span>
                        <small className="fa fa-chevron-down mt-2"></small>
                      </Dropdown.Toggle>
                      <Dropdown.Menu style={{border:'1px solid #006e52'}}>
                        <Dropdown.Item href="/dtahip/manage-users">
                          Manage Users
                        </Dropdown.Item>
                        <Dropdown.Item href="/dtahip/manage-farmer-markets">
                          Manage Farmer Markets
                        </Dropdown.Item>
                        <Dropdown.Item  href="/dtahip/manage-address">
                          Manage Coordinates
                        </Dropdown.Item>
                        <Dropdown.Item href="/dtahip/audit-logs">
                          Audit Logs
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </li>}
                </>}
                <li className="nav-item dropdown">
                  <Dropdown>
                    <Dropdown.Toggle className="nav-text">
                      <span className="d-inline-block mr-3" >
                        <i className="fa fa-user" aria-hidden="true"></i>{" "}
                        {fullName}</span> {"  "}
                      <small className="fa fa-chevron-down mt-2"></small>
                    </Dropdown.Toggle>
                    <Dropdown.Menu style={{border:'1px solid #006e52'}}>
                      {user.Role==="VENDOR" && <Dropdown.Item href="/dtahip/mystore">My Business</Dropdown.Item>}
                      <Dropdown.Item onClick={() => {tokenRevoke();}}>Logout</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
              </ul>
            </div> */}
          </nav>
        ) : (
          <nav className="navbar navbar-expand-lg navbar-light">
            <NavLink to="/" reloadDocument="true">
              <img
                src={logo}
                alt="DTA Finder Logo"
                className="header-logo-responsive mr-0"
              />
              
              <span className="boldFont page-title header-title">
              {menuList ? language.HealthyIncentivesProgram+" "+language.hip : language["DTA Finder"]}
              </span>
            </NavLink>
            {/* <button className="navbar-toggler" type="button" aria-expanded={!isNavCollapsed ? true : false} aria-label="Toggle navigation" onClick={handleNavCollapse}>
              <span className="navbar-toggler-icon"></span>
            </button> */}
            {/* <div className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`} id="navbarSupportedContent">
              <ul className="navbar-nav topMenu ml-auto">
                <li className="nav-item dropdown">
                  <Dropdown>
                    < Dropdown.Toggle className="nav-text width-100 text-left">
                      <span className="d-inline-block mr-3">{language.Services}</span>
                      <small className="fa fa-chevron-down mt-2"></small>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item href="/dtahip">
                      {language.HealthyIncentivesProgram} {language.hip}
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
                <li className="nav-item dropdown">
                  <LanguageSelect className="form-control language-select border-brand" />
                </li>
                {menuList && (
                  <>
                    <li className="nav-item dropdown">
                      <Dropdown>
                        < Dropdown.Toggle className="nav-text width-100 text-left">
                          <span className="d-inline-block mr-3  ">{language["For Providers"]}</span>
                          <small className="fa fa-chevron-down mt-2"></small>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item onClick={() => {openLogin()}}>
                          {language.HealthyIncentivesProgram} {language.hip}
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </li>
                    <li className="nav-item">
                      <button 
                        tabIndex="0"
                        onClick={() => {openLoginAdmin()}}
                        className="btn button-outline btn-block pl-3 text-left width-100"
                      >
                        {language.AdminLogin}
                      </button>
                    </li>
                    <li className="nav-item">
                      <button
                        tabIndex="0"
                        onClick={toggleHelpModal}
                        className="btn button-outline btn-block pl-3 text-left width-100"
                      >
                        <span className="d-inline-block mr-3">{language.help}</span> 
                        <small className="fa fa-question-circle mt-2"></small>
                      </button>
                    </li>
                  </>
                )}
              </ul>
            </div> */}
          </nav>
        )}
      </div>
    </>
  );
};

Header.propTypes = {
  vendorDetails: PropTypes.object,
  language: PropTypes.object,
  mapdata: PropTypes.object,
  selectedLanguage:PropTypes.string,
  userAuth:PropTypes.object,
  authUserDetails:PropTypes.func,
  tokenRevoke: PropTypes.func.isRequired
};

function mapDispatchToProps(dispatch) {
  return {
    authUserDetails: (payload) => dispatch(authUserDetails(payload)),
    tokenRevoke:(payload) => dispatch(tokenRevoke(payload))
    };
}

export default connect(
  mapStateToProps,mapDispatchToProps
)(Header);
