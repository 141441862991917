import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { mapStateToProps } from "../../redux/reducers";
import { connect } from "react-redux";
import { Grid } from '@mui/material'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TabPanel from '../common/components/tabPanel';
import { getVendorHistoryList } from '../../redux/actionTypes/vendordetails';
import PageHeaderComponent from "../common/components/pageHeader";
import TableComponent from "../common/components/table";
import SearchComponent from "../common/components/searchComponent";
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import AuditStoreHistoryComponent from './auditStoreHistoryComponent';
import VendorDetails from './vendorDetails';
import { convertUTCDateToLocalDate } from "../../utils/globalMethods";
import { CustomButton } from "../common/components/customButton";

function createVendorHistoryData(vendorName, fnsNum, isActive, lastUpdtUidNam, lastUpdtTs, adminLastUpdtTs, firstName, lastName, primaryEmail,
  publicPhoto, primaryMobile, createTs, businessDesc, publicMobile, publicEmail, additionalContactInfo, publicWebsite, products, row) {
  const isVendorActive = () => {
    if (isActive === 'Y') {
      return (<span className="badge badge-success">Active</span>)
    } else {
      return (<span className="badge badge-danger">Inactive</span>)
    }
  }
  return {
    vendorName,
    fnsNum,
    isActive: isVendorActive(),
    lastUpdtUidNam,
    lastUpdtTs: convertUTCDateToLocalDate(lastUpdtTs),
    adminLastUpdtTs: adminLastUpdtTs ? convertUTCDateToLocalDate(adminLastUpdtTs) : "-",
    firstName, lastName, primaryEmail,
    publicPhoto, primaryMobile, createTs: convertUTCDateToLocalDate(createTs), businessDesc, publicMobile, publicEmail, additionalContactInfo, publicWebsite, products, row
  }
}

const AuditHistoryComponent = (props) => {
  const { row } = props;
  const [tabValue, setTabValue] = React.useState(0);
  const [vendorHistoryListTotal, setVendorHistoryListTotal] = useState([]);
  const [rows, setRows] = useState([]);
  const [size, setSize] = useState(10);
  const [from, setFrom] = useState(0);
  const [search, setSearch] = useState("");
  const [tableSort, setTableSort] = useState({ "lastUpdtTs": "DESC" });
  const [vendorInfo, setVendorInfo] = useState({});
  const [showLoader, setShowLoader] = useState(true);
  const [refreshKey, setRefreshKey] = useState(0);

  const vendorHistoryColumns = [
    {
      id: 'details', label: 'Details'
    },
    {
      id: 'vendorName', label: 'Vendor Name',
      cell: (item) => {
        return <> 
          {item.vendorName ? item.vendorName?.replace(/&#38;/g, "’") : ""}
        </>
      }
    },
    {
      id: 'fnsNum', label: 'FNS Number',
      cell: (item) => {
        return <> 
          {item.fnsNum ? item.fnsNum.split(",").join(", ") : ""}
        </>
      }
    },
    {
      id: 'isActive', label: 'Status'
    },
    {
      id: 'lastUpdtUidNam', label: 'Last Updated By',
    },
    {
      id: 'lastUpdtTs', label: 'Vendor Last Updated Time',
      sort: "lastUpdtTs" in tableSort ? tableSort["lastUpdtTs"] : ""
    },
    {
      id: 'adminLastUpdtTs', label: 'Admin Last Updated Time',
      sort: "adminLastUpdtTs" in tableSort ? tableSort["adminLastUpdtTs"] : "",
    },
  ];

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
    };
  }
  useEffect(() => {
    const payload = {
      "vendorId": row.id,
      "lastUpdtUidNam": search.trim(),
      "size": size,
      "from": from,
      "lastUpdtTsSort": "lastUpdtTs" in tableSort ? tableSort["lastUpdtTs"] : "",
      "adminLastUpdtTsSort": "adminLastUpdtTs" in tableSort ? tableSort["adminLastUpdtTs"] : "",
    }
    props.getVendorHistoryList(payload);
  }, [size, from, refreshKey, tableSort, search]);

  useEffect(() => {
    const { vendorHistoryList } = props.vendorDetails;
    console.log(vendorHistoryList);
    let vendorHistoryListData = [];
    setRows([]);
    setTimeout(()=>{
      vendorHistoryList?.list?.forEach(element => {
        vendorHistoryListData.push(createVendorHistoryData(element.vendorName,
          element.fnsNum,
          element.isActive,
          element.lastUpdtUidNam,
          element.lastUpdtTs,
          element.adminLastUpdtTs,
          element.firstName, element.lastName, element.primaryEmail,
          element.publicPhoto, element.primaryMobile, element.createTs, element.businessDesc, element.publicMobile,
          element.publicEmail, element.additionalContactInfo, element.publicWebsite, element.products, element))
      });    
      setRows(vendorHistoryListData);
    },300);
    setVendorHistoryListTotal(vendorHistoryList.total);
    setShowLoader(vendorHistoryList.loading);
  }, [props.vendorDetails.vendorHistoryList]);


  const handleNext = (page, rowSize) => {
    (size != rowSize) && setSize(rowSize);
    setFrom(rowSize * page);
  }
  const handleSearch = (e) => {
    setSearch(e.target.value.toLowerCase());
    setFrom(0);
  }


  const handleSort = (item) => {
    let sortitem = {}
    if (item.sort == "" || item.sort == "DESC") {
      sortitem[item.id] = "ASC"
    } else {
      sortitem[item.id] = "DESC"
    }
    setTableSort(sortitem)
  }
  const renderDetails = (row) => {
    return (<VendorDetails
      row={row.row}
      showVendorHistoryDetails={true} 
      vendorAddtInfo= {vendorInfo}
      setVendorAddtInfo={(data)=>{
        setVendorInfo(data)
      }}
      />)
  }

  return (
    // <Grid className="container-block" rowSpacing={2}>
    
    <Box sx={{ width: '100%'}}>
      <Box sx={{ borderBottom: 1 }}>
      
        <Tabs indicatorColor="" TabIndicatorProps={{style: {background:'#ffffff',height: 4,borderTop:'0px'}}} sx={{marginBottom:-0.5}}  value={tabValue} onChange={handleTabChange}>
          <Tab 
            
            sx={{
              '&.Mui-selected': {
                borderTop: 1,
                borderLeft: 1,
                borderRight: 1,
                borderColor:'#495057 !important',
                borderWidth:'1',
                color:'#495057',
                bottomBorder:'1px'
                
              
              },
              '&:not(.Mui-selected)': {
                color: '#006e52',
                borderBottom:'0'
               
              }
            }} label="Vendor History"  {...a11yProps(0)}>

          </Tab>
          <Tab sx={{
              '&.Mui-selected': {
                borderTop: 1,
                borderLeft: 1,
                borderRight: 1,
                borderColor:'#495057',
                color:'#495057',
                 
                borderWidth:'1px',
                
              },
              '&:not(.Mui-selected)': {
                color: '#006e52',
              }
            }} label="Store History" {...a11yProps(1)} >
          </Tab>
         
        {/* <Tab sx={{color:'red'}}>Ventor History</Tab>
        <Tab sx={{color:'red'}}>Store History</Tab> */}
     
        </Tabs>
       
      </Box>
      <TabPanel value={tabValue} index={0}>
        <Grid  container className="container-block" rowSpacing={2}>
          <Grid item xs={12}>
            <PageHeaderComponent
              title={"VENDOR HISTORY"}
              showSubtitle={true}
              subtitle={"Total"}
              count={vendorHistoryListTotal}
            />
          </Grid>
          <Grid item xs={12} sx={{ flexGrow: 1 }}>
            <Stack direction="row" spacing={0.5} justifyContent="flex-end">
            <Button variant="outlined" sx={{ height: '38px', border: "1px solid #006e52", textTransform:'capitalize', color: '#006e52', ':hover': { backgroundColor: '#006e52', color: 'white' }}} startIcon={<i className="fa fa-refresh" aria-hidden="true"></i>} onClick={() => {
            setRefreshKey(refreshKey+1);
            setShowLoader(true);
            setSearch("");
            }}>
              Refresh
          </Button>
            </Stack>
          </Grid>
          <Grid item xs={12} sx={{ flexGrow: 1 }}>
            <SearchComponent
              placeholder={"Last Updated By"}
              handleChange={handleSearch}
              searchText={search}
            />
          </Grid>
          <Grid item xs={12}>
            <TableComponent
              columns={vendorHistoryColumns}
              rows={rows}
              showDetails={true}
              renderDetails={renderDetails}
              handlePagination={handleNext}
              total={vendorHistoryListTotal}
              handleSort={handleSort}
              page={Math.floor(from/size)}
              rowsPerPage={size}
              dataLoading={showLoader}
            />
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <AuditStoreHistoryComponent
          row={props.row}
        />
      </TabPanel>
    </Box>
    // </Grid>
  )
}

AuditHistoryComponent.propTypes = {
  vendorDetails: PropTypes.object,
  getVendorHistoryList: PropTypes.func.isRequired,
  row: PropTypes.object,
  onAudit: PropTypes.bool,
  // getVendorInfo: PropTypes.func,
  // getVendorInfoList:PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    getVendorHistoryList: (payload) => dispatch(getVendorHistoryList(payload)),
    // getVendorInfoList:(payload) => dispatch(getVendorInfoList(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AuditHistoryComponent);