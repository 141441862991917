import React, { useState, useEffect, useRef } from "react";
import MapKeyComponent from "./mapkey-component";
import FilterMapDataComponent from "./filtermapdata-component";
import SelectDropdown from "../common/selectdropdown-component";
import Modal from "../common/modal";
// import MultiSelectAll from "../common/multiselect-component";
import { GET_MAPDATA,GET_AREA, GET_TYPE, GET_DAY_OPTION, GET_MONTH_OPTION, GET_ORDER_OPTION, GET_VENDOR_LIST, GET_COUNTYCITY, GET_STORE_LIST, SET_MAP_SEARCH } from "../../redux/actionTypes";//,
import { mapStateToProps } from "../../redux/reducers";
import PropTypes from "prop-types";
import {sortName, getNextDate} from "../../utils/globalMethods";
import { connect } from "react-redux";
import Geocode from "react-geocode";
import moment from "moment";
import "../../styles/dashboard.scss";
import { GOOGLE_KEY } from "../../config";
import ModalComponent from "../common/components/modal";
import { useCookies } from 'react-cookie';

// const userLocationDefault = {
//     lat: 42.3600825,
//     lng: -71.0588801,
//     address: "Boston, MA",
//     city: "Boston",
// };
const centerLocation = {
    lat: 42.2625932,
    lng: -71.8022934,
    address: "Massachusetts Center",
    city: "Boston",
    located: false
};

const defaultLocation = {
    lat: 42.3600825 ,
    lng: -71.0588801,
    address: "Boston, MA, USA",
    city: "Boston",
    located: false
};

Geocode.setApiKey(GOOGLE_KEY);
Geocode.enableDebug();

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

const SearchComponent = (props) => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [language, setLanguage] = useState({});
  const [cookies, setCookie] = useCookies(['name']);
  const nodeType = useRef();
  const nodeOrder = useRef();
  const nodeMonths = useRef();
  const nodeDays = useRef();
  const [openType, setTypeOpen] = useState(false);
  const [openOrder, setOrderOpen] = useState(false);
  const [openMonths, setMonthsOpen] = useState(false);
  const [openDays, setDaysOpen] = useState(false);
  const [openToday, setOpenToday] = useState(false);
  const [coordinates, setCoordinates] = useState(defaultLocation);  
  const [cityList, setCityList] = useState([]);
  const [areaList, setAreaList] = useState([]);
  const [vendorList, setVendorList] = useState([]);
  const [selectedCity, setSelectedCity] = useState({name: "myLocation",values: "Use My Location"});
  const [selectedArea, setSelectedArea] = useState({name:"5",values:"5 miles"});
  const [isSearchOpened, setIsSearchOpened] = useState(true);
  const [initialStoreView, setInitialStoreView] = useState(true);
  const [showMapKeyList, setShowMapKeyList] = useState(true);
  const [initialMapLoad, setInitialMapLoad] = useState(true);
  const [selectedType, setSelectedType] = useState({
    "csaPickupSite": "Y",
    "farmStand": "Y",
    "farmersMarket": "Y",
    "farmersMarketBooth": "Y",
    "mobileMarket": "Y"
  });
  const [selectedOrderOption, setSelectedOrderOption] = useState({
    "curbsidePickup": "Y",
    "delivery": "Y",
    "inPerson": "Y",
    "onlinePreselection": "Y",
    "phonePreselection": "Y",
    "otherType": "Y"
  });
  const [selectedMonths, setSelectedMonths] = useState({
    "janOperation": "Y",
    "febOperation": "Y",
    "marOperation": "Y",
    "aprOperation": "Y",
    "mayOperation": "Y",
    "junOperation": "Y",
    "julOperation": "Y",
    "augOperation": "Y",
    "sepOperation": "Y",
    "octOperation": "Y",
    "novOperation": "Y",
    "decOperation": "Y"
  }); 
  const [selectedDays, setSelectedDays] = useState({
    "monOperation": "Y",
    "tueOperation": "Y",
    "wedOperation": "Y",
    "thuOperation": "Y",
    "friOperation": "Y",
    "satOperation": "Y",
    "sunOperation": "Y"
  }); 
  const [selectedVendors, setSelectedVendors] = useState({name:"all",values:"All Vendors"});
  const [changeFilter, setChangeFilter] = useState("");
  const [mobileSearch, setMobileSearch] = useState(false);
  const [myLocation, setMyLocation] = useState(false);
  const [selectAll,setSelectAll] = useState({
    vendorType: true,
    orderOptions: true,
    months: true,
    days: true,
  });
  const [searchProperties, setSearchProperties] = useState({
    address: coordinates.address,
    city: "all",
    latitude: parseFloat(coordinates.lat),
    longitude: parseFloat(coordinates.lng),
    area: selectedArea,
    vendorType: "all",
    orderOptions: "all",
    months: "all",
    days: "all",
    vendor_name: "all",
    open_today: openToday,
    is_mylocation: false
  });
  const [modalShown, toggleModal] = useState(false);
  const [showLocationModal, setShowLocationModal] = useState(false);
  const [isSearchCollapsed, setIsSearchCollapsed] = useState(windowDimensions.width < 768 ? false : true);
  const [isResultCollapsed, setIsResultCollapsed] = useState(false);
  
  const handleSearchCollapse = () => setIsSearchCollapsed(!isSearchCollapsed);
  const handleResultCollapse = () => setIsResultCollapsed(!isResultCollapsed);
  const [mapLoad, setMapLoad] = useState();

  useEffect(() => {
    setLanguage(props.language)
    if(props.language){
      getGeocode(selectedCity,"fullWidth");
      props.getCityList();
    }
  }, [props.language]);

  const checkIfClickedOutside = (e) => {
    if (nodeType.current && nodeType.current.contains(e.target)) { 
      setOrderOpen(false);
      setMonthsOpen(false);
      setDaysOpen(false);
      return;
    }
    setTypeOpen(false);

    if (nodeOrder.current && nodeOrder.current.contains(e.target)) { 
      setTypeOpen(false);
      setMonthsOpen(false);
      setDaysOpen(false);
      return;
    }
    setOrderOpen(false);

    if (nodeMonths.current && nodeMonths.current.contains(e.target)) { 
      setTypeOpen(false);
      setOrderOpen(false);
      setDaysOpen(false);
      return;
    }
    setMonthsOpen(false);

    if (nodeDays.current && nodeDays.current.contains(e.target)) { 
      setTypeOpen(false);
      setOrderOpen(false);
      setMonthsOpen(false);
      return;
    }
    setDaysOpen(false);
  };

  // const fetchVendorList = (city,miles) => {
  //   props.getVendorList({
  //     "cityName": city === "all" ? "" : city,
  //     "miles": miles === "" ? "" : miles,
  //     "lat": "",
  //     "lon": "",
  //     "allCityFlag": city === "all" ? "Y" : "N"
  //   });
  // }

  useEffect(() => {
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  },[]);

  useEffect(() => {
    mapCityList(props.mapdata.city);
    mapAreaList(props.mapdata.area);
    mapVendorList(props.mapdata.vendor);
  },[props.mapdata.city, props.mapdata.area, props.mapdata.vendor, props.language]);

  // useEffect(() => {
  //   setTimeout(()=>{
  //       const searchOptions = {
  //           address: searchProperties?.address,
  //           city: searchProperties?.city,
  //           latitude: parseFloat(searchProperties?.lat),
  //           longitude: parseFloat(searchProperties?.lng),
  //           vendorType: searchProperties?.vendorType,
  //           orderOptions:  searchProperties?.orderOptions,
  //           months:  searchProperties?.months,
  //           days: searchProperties?.days,
  //           vendor_name: searchProperties?.vendor_name,
  //           open_today: searchProperties?.open_today,
  //         };
        
  //         if (searchProperties.open_today) {
  //             searchOptions.days = [moment().format("dddd")];
  //             searchOptions.months = [moment().format("MMMM")];
  //         }

  //       // props.getMapData(searchOptions);  
  //   },500);  
  // }, [
  //   searchProperties.city,
  //   searchProperties.vendorType,
  //   searchProperties.orderOptions,
  //   searchProperties.months,
  //   searchProperties.days,
  //   searchProperties.vendor_name,
  //   searchProperties.open_today]);

  function toggleSearch() {
    setIsSearchOpened(isSearchOpened => !isSearchOpened);
  }

const mapCityList =(city)=>{
  let cityOption = city?.map(item => {
    return {
      name: item.cityName,
      values: item.cityName,
      isActive: 1
    };
  });
  let defaultData = [{name:"myLocation", values:props.language["Use My Location"], isActive: 1, default: true},{name:"all", values:props.language["All Counties & Cities"], isActive: 1}];
  if (cityOption) {
    setCityList(defaultData.concat(sortName(cityOption)));
  }
  (selectedCity.name == "myLocation")  && setSelectedCity({name: "myLocation",values: props.language["Use My Location"]});
  (selectedCity.name == "all")  && setSelectedCity({name:"all", values:language["All Counties & Cities"]});    
};

const mapAreaList =(area)=>{
  const defaultData = [{name:"", values:props.language["Statewide"], isActive: 1, default: true}];
  setAreaList(defaultData.concat(area));
  (selectedArea.name == "")  && setSelectedArea({name:"",values:language["Statewide"]});
};

const mapVendorList = (vendors) =>{ 
  if(vendors.length){
    let vendorOptions = vendors.map((item) => {
      return {
        id: item.vendorId,
        name: item.vendorName, 
        values: item.vendorName,
        isActive: 1
      };
    })
    const defaultData = [{name:"all",values:props.language["All Vendors"]}];
    vendorOptions.forEach(item => {
      item.name = item.name.replace("&#38;", "'");
      item.values = item.values.replace("&#38;", "'");
  });
  
    setVendorList(defaultData.concat(sortName(vendorOptions)));
    (selectedVendors.name == "all")  && setSelectedVendors({name:"all", values:language["All Vendors"]});
  }
};

useEffect(() => {
  if(props.language){
    if(changeFilter==""){
      setShowMapKeyList(true);
    }else{
      setShowMapKeyList(false);
    }
    if (changeFilter != "") {

      // initial map processing
      if (initialMapLoad) {
        setMapLoad(true);
        setInitialMapLoad(false);
      }

      // setMapLoad(true);
      let payload = {        
        "cityName": (selectedCity.name === "myLocation" || selectedCity.name === "all") ? (selectedCity.name === "myLocation" && searchProperties.city !== "all" ? searchProperties.city : "") : selectedCity.name,
        "miles": (selectedCity.name === "all" || searchProperties.city === "all") ? "" : selectedArea.name,
        "lat": parseFloat(coordinates.lat),
        "lon": parseFloat(coordinates.lng),
        "allCityFlag": (selectedCity.name === "all") ? "Y" : "N",
        "vendorId": selectedVendors.name === "all" ? "" : selectedVendors.id,
        "csaPickupSite": selectedType.csaPickupSite,
        "farmStand": selectedType.farmStand,
        "farmersMarket": selectedType.farmersMarket,
        "farmersMarketBooth": selectedType.farmersMarketBooth,
        "mobileMarket": selectedType.mobileMarket,
        "monthsOperation": {
            "janOperation": selectedMonths.janOperation,
            "febOperation": selectedMonths.febOperation,
            "marOperation": selectedMonths.marOperation,
            "aprOperation": selectedMonths.aprOperation,
            "mayOperation": selectedMonths.mayOperation,
            "junOperation": selectedMonths.junOperation,
            "julOperation": selectedMonths.julOperation,
            "augOperation": selectedMonths.augOperation,
            "sepOperation": selectedMonths.sepOperation,
            "octOperation": selectedMonths.octOperation,
            "novOperation": selectedMonths.novOperation,
            "decOperation": selectedMonths.decOperation
        },
        "distributionOption": {
            "delivery": selectedOrderOption.delivery,
            "curbsidePickup": selectedOrderOption.curbsidePickup,
            "onlinePreselection": selectedOrderOption.onlinePreselection,
            "phonePreselection": selectedOrderOption.phonePreselection,
            "inPerson": selectedOrderOption.inPerson,
            "otherType": selectedOrderOption.otherType
        },
        "monOperation": selectedDays.monOperation,
        "tueOperation": selectedDays.tueOperation,
        "wedOperation": selectedDays.wedOperation,
        "thuOperation": selectedDays.thuOperation,
        "friOperation": selectedDays.friOperation,
        "satOperation": selectedDays.satOperation,
        "sunOperation": selectedDays.sunOperation,
        "openToday": openToday ? "Y":"N",
      };
      props.mapSearchPayload(payload);
      props.getMapStoresList(payload);
      props.getVendorList({
        "cityName": (selectedCity.name === "myLocation" || selectedCity.name === "all") ? "" : selectedCity.name,
        "miles": selectedArea.name === "" ? "" : selectedArea.name,
        "lat": parseFloat(coordinates.lat),
        "lon": parseFloat(coordinates.lng),
        "allCityFlag": (selectedCity.name === "all") ? "Y" : "N"
      });
    }
  }
},[changeFilter,coordinates,mobileSearch]);

const getGeocode = (city,modalSize) => {
    if (city.name ==="myLocation") {
        navigator.permissions.query({
            name: "geolocation"
        }).then(function(result) {
            if (result.state == "granted" || result.state == "prompt") {
                getCurrentLocation();
            } else if (result.state == "denied") {
              setInitialStoreView(false);
              setSelectedCity({name:"all", values:language["All Counties & Cities"], isActive: 1});
              setSelectedArea({name:"",values:props?.language["Statewide"]});
              setSearchProperties((prevState) => ({
                ...prevState,
                address: coordinates.address,
                city: "all",
                latitude: parseFloat(coordinates.lat),
                longitude: parseFloat(coordinates.lng),
                area: selectedArea,
                vendorType: "all",
                orderOptions: "all",
                months: "all",
                days: "all",
                vendor_name: "all",
                open_today: openToday,
                is_mylocation: false
              }));
              setSelectedVendors({name:"all", values:language["All Vendors"]});
              if(modalSize=="modal"){
                setShowLocationModal(true);
              }else if(modalSize=="fullWidth" && !cookies.loc_permission_modal){
                toggleModal(true);
              }
              setChangeFilter("getGeocode denied"+(Math.floor(Math.random() * 90000) + 10000));
            }
        });
        
    } else if (city.name === "all") {
        setChangeFilter("getGeocode all county" + Math.floor(Math.random() * 90000) + 10000);
        setCoordinates({ 
            lat: centerLocation.lat,
            lng: centerLocation.lng,
            address: centerLocation.address,
            city: centerLocation.city,
            located: false
        });
        setMyLocation(false);
        // if (windowDimensions.width >= 768) {
          setSearchProperties((prevState) => ({
              ...prevState,
              latitude: centerLocation.lat,
              longitude: centerLocation.lng,
              address: centerLocation.address,
              city: city.name,
              is_mylocation: false
          }));
        // }
    } else {
        const address = city.name + ",MA";
        Geocode.fromAddress(address).then(
            (response) => {
              const { lat, lng } = response.results[0].geometry.location;
              setCoordinates({ 
                      lat: lat,
                      lng: lng,
                      address: response.results[0].formatted_address,
                      city: city.name,
                      located: true
              });
              setMyLocation(false);
              setSearchProperties((prevState) => ({
                  ...prevState,
                  latitude: lat,
                  longitude: lng,
                  address: response.results[0].formatted_address,
                  city: city.name,
                  is_mylocation: false
                }));
            },
            (error) => {
              setCoordinates((prevState) => ({
                ...prevState,
                address: address,
                city: city.name,
                located: true
              }));        
            }
        );
    }  
  }; 

  const getCurrentLocation=()=>{
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
                Geocode.fromLatLng(position.coords.latitude, position.coords.longitude)
                .then( (result) => {
                    if (result.status === "OK") {
                        const city = result?.results?.[0]?.address_components?.find(item=>item?.types?.indexOf("locality") !== -1);
                        setCoordinates((prevState) => ({
                            ...prevState,
                            address: result.results[0].formatted_address,
                            city: city?.long_name ? city?.long_name : "",
                            located:true
                        }));
                        setChangeFilter("use my location"+(Math.floor(Math.random() * 90000) + 10000));
                        // if (windowDimensions.width >= 768) {
                          setSearchProperties((prevState) => ({
                              ...prevState,
                              address: result.results[0].formatted_address,
                              city: city?.long_name ? city?.long_name : "",
                              is_mylocation: true
                          }));
                        // }
                        setMyLocation(true);
                    }                        
                });
                setCoordinates((prevState) => ({
                    ...prevState,
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                }));
                // if (windowDimensions.width >= 768) {
                  setSearchProperties((prevState) => ({
                      ...prevState,
                      latitude: position.coords.latitude,
                      longitude: position.coords.longitude
                  }));
                // }
        },
        ()=>{
          setCoordinates((prevState) => ({
            ...prevState,
            located:false
          }));
      });
    }
  };
const resetSearch = ()=>{
    setInitialStoreView(false);
    setCoordinates(defaultLocation);
    setSelectedCity({name:"all", values:language["All Counties & Cities"], isActive: 1});
    setSelectedArea({name:"",values:language["Statewide"]});
    setSelectedVendors({name:"all", values:language["All Vendors"]});
    
    setSelectAll({
      vendorType: true,
      orderOptions: true,
      months: true,
      days: true,
    });
    
    props.mapdata.vendorType = props.mapdata.vendorType.map((prevState) => ({
        ...prevState,
        isActive: 1
    }));
    setSelectedType({"csaPickupSite": "Y",
    "farmStand": "Y",
    "farmersMarket": "Y",
    "farmersMarketBooth": "Y",
    "mobileMarket": "Y"});
    
    props.mapdata.orderOptions = props.mapdata.orderOptions.map((prevState) => ({
        ...prevState,
        isActive: 1
    }));
    setSelectedOrderOption({
      "curbsidePickup": "Y",
      "delivery": "Y",
      "inPerson": "Y",
      "onlinePreselection": "Y",
      "phonePreselection": "Y",
      "otherType": "Y"
    });

    setSelectedMonths({
      "janOperation": "Y",
      "febOperation": "Y",
      "marOperation": "Y",
      "aprOperation": "Y",
      "mayOperation": "Y",
      "junOperation": "Y",
      "julOperation": "Y",
      "augOperation": "Y",
      "sepOperation": "Y",
      "octOperation": "Y",
      "novOperation": "Y",
      "decOperation": "Y"
    });
    props.mapdata.months = props.mapdata.months.map((prevState) => ({
        ...prevState,
        isActive: 1
    }));
    
    setSelectedDays({
      "monOperation": "Y",
      "tueOperation": "Y",
      "wedOperation": "Y",
      "thuOperation": "Y",
      "friOperation": "Y",
      "satOperation": "Y",
      "sunOperation": "Y"
    });
    props.mapdata.days = props.mapdata.days.map((prevState) => ({
        ...prevState,
        isActive: 1
    }));
    setMyLocation(false);
    setSearchProperties((prevState)=>({
        ...prevState,
        latitude: parseFloat(defaultLocation?.lat),
        longitude: parseFloat(defaultLocation?.lng),
        city: "all",
        address: defaultLocation?.address,
        vendorType: "all",
        orderOptions: "all",
        months: "all",
        days: "all",
        vendor_name: "all",
        is_mylocation: false
    }));
    // setSelectedType(props.mapdata.vendorType.length);
    // setSelectedOrderOption(props.mapdata.orderOptions.length);
    // setSelectedMonths(props.mapdata.months.length);
    // setSelectedDays(props.mapdata.days.length);
    setOpenToday(false);
    setChangeFilter("reset"+(Math.floor(Math.random() * 90000) + 10000));
};

const onSelectAllChange=(e="",type)=>{
    if (e && e.target.tagName.toUpperCase() !== "INPUT") {
        e.preventDefault();
    }
    if (selectAll[type]) {
        props.mapdata[type] = props.mapdata[type].map(obj => {
            obj.isActive = 0;
            return obj;
        });
    } else {
        props.mapdata[type] = props.mapdata[type].map(obj => {
            obj.isActive = 1;
            return obj;
        });
    }
    
    const selectedList = props.mapdata[type].filter(item=>item.isActive ===1).map(item => item.name);
    
    const selectedOptions = {};
    for (const key of props.mapdata[type]) {
      selectedOptions[key.name] = key.isActive === 1 ? "Y" : "N";
    }
    
    const calculateSelection = selectedList.length === props.mapdata[type].length ? "all" : selectedList;
    // if (windowDimensions.width >= 768) {
      setSearchProperties((prevState) => ({
          ...prevState,
          [type]: calculateSelection
      }));
    // }

    if (type === "vendorType") {
        setSelectedType(selectedOptions);
    } else if(type === "orderOptions") {
        setSelectedOrderOption(selectedOptions);
    } else if(type === "months") {
        setSelectedMonths(selectedOptions);
    } else if(type === "days") {
        setSelectedDays(selectedOptions);
    }
};

const setSingleSelect = (e, item,type)=> {
    if (e.target.tagName.toUpperCase() !== "INPUT") {
        e.preventDefault();
    }

    item.isActive = item.isActive === 1? 0 : 1;
    // console.log(e);
    // props.mapdata.vendorType = props.mapdata.vendorType.map((obj) => {
    //     if(obj.name === item.name ) {
    //       obj.isActive = 0;
    //       console.log(obj, item,'TEST');  
    //     }
    //     return obj;
    // });
    const check =  props.mapdata[type].find(obj => obj.isActive === 0);
    if (check) {
        // setSelectAll(false);
        setSelectAll((prevState) => ({
            ...prevState,
            [type]: false
        }));
    } else {
        // setSelectAll(true);
        setSelectAll((prevState) => ({
            ...prevState,
            [type]: true
        }));
    }

    const selectedList = props.mapdata[type].filter(item=>item.isActive ===1).map(item => item.name);
    const selectedOptions = {};
    for (const key of props.mapdata[type]) {
      selectedOptions[key.name] = key.isActive === 1 ? "Y" : "N";
    }
    const calculateSelection = selectedList.length === props.mapdata[type].length ? "all" : selectedList;
    // if (windowDimensions.width >= 768) {
      setSearchProperties((prevState) => ({
          ...prevState,
          [type]: calculateSelection
      }));
    // }

    if (type === "vendorType") {
        setSelectedType(selectedOptions);
    } else if(type === "orderOptions") {
        setSelectedOrderOption(selectedOptions);
    } else if(type === "months") {
        setSelectedMonths(selectedOptions);
    } else if(type === "days") {
        setSelectedDays(selectedOptions);
    }
};

const renderModal = () => {
  return language.LOC_PERMISSION_DENIED;
}

// const getResult=(data)=>{
//     console.log(data);
// //    let data = props?.mapdata?.loaddata !== undefined  ? props?.mapdata?.loaddata : [];
//    if (searchProperties.open_today) {
//         data = getOpenTodayList(data);
//         console.log(data);
//     }
//     if(searchProperties?.area !== language["Statewide"]) {
//         data = getFilterAreaWithin(data);
//     }
//     if(searchProperties.days !== "all") {
//         data = getFilteredDaysList(data);
//     }
//     return data;
// };
function truncateText(text, length) {
  if (text.length <= length) {
    return text;
  }

  return text.substr(0, length) + '\u2026'
}

const toggleLocation = () => {
  setCookie('loc_permission_modal', true, { expires: getNextDate()});
  toggleModal(false);
}


const handleKeyPress = (event,type) => {
  if (event.key === 'Enter') {
    if(type === "vendorType") {
      setTypeOpen(!openType);
    } else if (type === "order") {
      setOrderOpen(!openOrder);
    } else if (type === "monthsOfOperation") {
      setMonthsOpen(!openMonths);
    } else if (type === "daysOfOperation") {
      setDaysOpen(!openDays);
    }
  }
};
const handleKeyPressList = (event, item, type) => {
  if (event.key === 'Enter') {
    if(type === "vendorType") {
      setInitialStoreView(true);
      setSingleSelect(event, item, "vendorType"); //
      setChangeFilter("vendorType"+(Math.floor(Math.random() * 90000) + 10000));
    } else if (type === "order") {
      setInitialStoreView(true);
      setSingleSelect(event, item, "orderOptions"); //
      setChangeFilter("orderOptions"+(Math.floor(Math.random() * 90000) + 10000));
    } else if (type === "monthsOfOperation") {
      setInitialStoreView(true);
      setSingleSelect(event, item, "months"); //
      setChangeFilter("months"+(Math.floor(Math.random() * 90000) + 10000));
    } else if (type === "daysOfOperation") {
      setInitialStoreView(true);
      setSingleSelect(event, item, "days"); //
      setChangeFilter("days"+(Math.floor(Math.random() * 90000) + 10000));
    }
  }
};
const handleKeyPressSelectAll  = (event, type) => { 
  if (event.key === 'Enter') {
    if(type === "vendorType") {
        setInitialStoreView(true);
        setSelectAll((prevState) => ({
            ...prevState,
            vendorType: !selectAll["vendorType"]
        }));
      onSelectAllChange(event, "vendorType");
      setChangeFilter("vendorType"+(Math.floor(Math.random() * 90000) + 10000));
    } else if (type === "order") {
        setInitialStoreView(true);
        setSelectAll((prevState) => ({
              ...prevState,
              orderOptions: !selectAll["orderOptions"]
          }));
        onSelectAllChange(event, "orderOptions");
        setChangeFilter("orderOptions"+(Math.floor(Math.random() * 90000) + 10000));
    } else if (type === "monthsOfOperation") {
        setInitialStoreView(true);
        setSelectAll((prevState) => ({
              ...prevState,
              months: !selectAll["months"]
        }));
        onSelectAllChange(event, "months");
        setChangeFilter("months"+(Math.floor(Math.random() * 90000) + 10000));
    } else if (type === "daysOfOperation") {
        setInitialStoreView(true);
        setSelectAll((prevState) => ({
              ...prevState,
              days: !selectAll["days"]
        }));
        onSelectAllChange(event, "days");
        setChangeFilter("days"+(Math.floor(Math.random() * 90000) + 10000));
    }
  }
}
  return (
    <>
      <div className="row">
      {showLocationModal &&
          <ModalComponent 
            shown = {showLocationModal}
            close = {() => {
              setShowLocationModal(false);
              setChangeFilter('location denied'+(Math.floor(Math.random() * 90000) + 10000))
            }}
            title = {language["Uses Location Services"]}
            modalBody = {renderModal}
            size={"lg"}
          />
        }
      
        <Modal
            shown={modalShown}
            close={() => {
                toggleModal(false);
            }}
        >
            {/* <h1>Look! I&apos;m inside the modal!</h1> */}
            <div>
                <div className="modal-header">
                    <span><i className="fa fa-map-pin"></i></span>
                    <span> {language["Uses Location Services"]}</span>
                </div>
                <div className="modal-body" style={{paddingTop: "10px"}}>
                    <div className="row">
                        <div className="col-md-12">
                            <p className="f_weight" style={{fontSize: "20px"}}>{language.LOC_PERMISSION_DENIED}</p>
                        </div>
                    </div>
                </div>
                <button type="button" className="btn btn-outline-primary" onClick={toggleLocation} data-dismiss="modal" aria-label="Close">
                    Close
                </button>
            </div>
        </Modal>
        <div className="col-md-12">
          <button className="opennavsearch btn btn-primary d-md-none d-lg-none  mt-2 mb-2" onClick={handleSearchCollapse}>
            <i
              className="fa fa-search cusIcon text-white"
              aria-hidden="true"
            ></i>{" "}
            Search
          </button>
          <button className="opennavresult btn btn-default d-md-none d-lg-none mt-2 mb-2 pull-right cusBtnResults" onClick={handleResultCollapse}>
            <i className="fa fa-eye cusIcon" aria-hidden="true"></i> View List
          </button>
          { windowDimensions.width >= 768 && (
            <div 
            className={`panel-group d-none d-sm-none d-md-block d-lg-block`}
                      id="accordionSearch"
              data-select2-id="accordionSearch"
            >            
              <div className="p-none pull-right searchAccordion">
                <a
                  data-toggle="collapse"
                  aria-expanded="true"
                  aria-controls="searchPanel"
                  aria-label="accordionSearchs"
                  className={`text-black accordionArrow ${
                    !isSearchOpened ? "collapsed" : ""
                  }`}
                  role="none"
                  title="Search Results"
                  onClick={toggleSearch}
                >
                  <i className="n_nav-right-icon fa fa-chevron-down pull-right cusIcon mt-2"></i>{" "}
                  <span id="searchContent"></span>
                </a>
              </div>
              <div
                id="searchPanel"
                className={`panel-collapse ${
                  isSearchOpened ? "collapse show" : "collapse"
                }`}
              >
                <div className="row p-0">
                  <div className="col-md-12 mb-2">
                    <div className="row">
                      <div className="col-md-3 mt-2 mb-2">
                        <label htmlFor="deliveryCity">
                          <small className="boldFont">{language.County}/{language.City}</small>
                        </label>
                        <SelectDropdown
                          // placeholder="County/City"
                          value={selectedCity}
                          ariaLabel = 'County/City'
                          onChange={(v) => {
                            setMapLoad(true);
                            setInitialStoreView(true);
                            let miles = "";
                            // setChangeFilter("city"+v.name);
                            // setSearchProperties((prevState) => ({
                            //   ...prevState,
                            //   vendor_name: "all",
                            //   // city:v.name
                            // }));
                            // setSelectedVendors({name:"all", values:language["All Vendors"]});
                            getGeocode(v,"modal");
                            if (v.name !== "all") {
                                if (selectedArea.name === "" ) {
                                    miles = "5";
                                    setSelectedArea({name:"5",values:"5 miles"});
                                      setSearchProperties((prevState) => ({
                                          ...prevState,
                                          area: {name:"5",values:"5 miles"},
                                      }));
                                }
                            } else {
                              setSelectedArea({name:"",values:language["Statewide"]});
                              setSearchProperties((prevState) => ({
                                  ...prevState,
                                  area: {name:"",values:language["Statewide"]},
                                  latitude: parseFloat(defaultLocation?.lat),
                                  longitude: parseFloat(defaultLocation?.lng),
                                  city: "all",
                                  address: defaultLocation?.address
                              }));
                              setCoordinates(defaultLocation);
                            }
                            setSelectedCity(v);
                            // fetchVendorList(v.name,miles)
                          }}
                          options={cityList}
                          // keyValue={{ key: "id", value: "county" }}
                          isSearch={true}
                          defaultValueProps={{
                            default: true,
                            defaultOption: {
                              name: "myLocation",
                              values: language["Use My Location"],
                            },
                            cssProps: {
                              fa: "fa fa-map-pin",
                              spanClass: "sys-color",
                            },
                          }}
                        />
                      </div>
                      

                      <div className="col-md-3 mt-2 mb-2">
                        <label htmlFor="deliveryCity">
                          <small className="boldFont">{language["Area Within"]}</small>
                        </label>
                        <SelectDropdown
                          // placeholder="Area Within"
                          value={selectedArea}
                          ariaLabel = 'Area Within'
                          // disabled={ selectedCity.name ==="all" || selectedCity?.name?.includes("County") ? true : false}
                          onChange={(v) => {
                            setMapLoad(true);
                            setInitialStoreView(true);
                            let city = selectedCity.values;
                            setChangeFilter("area"+(Math.floor(Math.random() * 90000) + 10000));
                            setSelectedArea(v);
                            if (v.name === "") {
                              city = "all";
                              setSelectedCity({name:"all", values:language["All Counties & Cities"], isActive: 1});
                              setSearchProperties((prevState) => ({
                                  ...prevState,
                                  city: "all",
                                }));
                            }
                            // fetchVendorList(city,v.name)
                            setSearchProperties((prevState) => ({
                              ...prevState,
                              area: v,
                            }));
                          }}
                          disabled={selectedArea.name === ""}
                          options={areaList}
                          isSearch={true}
                          // keyValue={{ key: "id", value: "area" }}
                          defaultValueProps={{
                            default: true,
                            defaultOption: { selectedArea },
                          }}
                        />
                      </div>

                      
                      <div className="col-md-3 mt-2 mb-2">
                        <label htmlFor={"vendorType"}>
                          <small className="boldFont">{language.Type}</small>
                        </label>
                        <div
                          className="multiselect-wrapper"
                          id={"type_multiSelect"}
                          ref={nodeType}                
                        >
                          <div 
                              className="multiselect-input-div"                          
                          >
                            <input
                              id={"type_input"}
                              className="multiselect-input"
                              autoComplete="off"
                              aria-label={"type dropdown"}
                              aria-labelledby={"type"}
                              readOnly="readonly"
                              type="readonly"
                              onKeyPress={(event) => handleKeyPress(event, "vendorType")}
                              onClick={() => {
                                  setTypeOpen(!openType);
                              }}
                              placeholder={`${openType ? "" : (searchProperties.vendorType === "all" ? language["All selected"] : truncateText(props.mapdata.vendorType.filter(item=>item.isActive === 1).map(item => item.values).join(', '),40))}`}
                            />
                            <label
                              id={"type_inputCount"}
                              className="multiselect-count"
                              htmlFor={"type_input"}
                              // style={ (navHeight==0) ? styles.bg1 : styles.bg2}
                              style={{
                                visibility:
                                // props?.mapdata?.type?.filter(item=>item.isActive === 1).length === 0 ? "hidden" : "unset",
                                props.mapdata.vendorType.filter(item=>item.isActive === 1).length === 0 ? "hidden" : "unset",
                              }}
                            >
                              {props.mapdata.vendorType.filter(item=>item.isActive === 1).length}
                            </label>
                            <span
                              className={`spanBlock upArrow ${openType ? "down" : "up"}`}
                              htmlFor={"type_input"} onClick={() => {
                                setTypeOpen(!openType);
                            }}
                            ></span>
                          </div>
                          <div
                            id={"type_itemList"}
                            className={`multiselect-list ${
                              openType ? "active" : "d-none"
                            }`}
                          >
                            <div
                              onKeyPress={(event) => handleKeyPressSelectAll(event, "vendorType")}
                              onClick={(e) => {
                                setInitialStoreView(true);
                                setSelectAll((prevState) => ({
                                      ...prevState,
                                      vendorType: !selectAll["vendorType"]
                                  }));
                                onSelectAllChange(e, "vendorType");
                                setChangeFilter("vendorType"+(Math.floor(Math.random() * 90000) + 10000));
                              }}
                            >
                              <label>
                                <input
                                  className="multiselect-checkbox"
                                  type="checkbox"
                                  id={"type_selectall"}
                                  checked={selectAll["vendorType"]}
                                  onChange={(e) => {
                                    e.stopPropagation();
                                  }}
                                />
                                <span className="multiselect-text wh-space">
                                  {language["Select all"]}
                                </span>
                              </label>
                            </div>
                            <hr />
                            <ul>
                              {props.mapdata.vendorType !== undefined && props.mapdata.vendorType.map((item, i) => (
                                <li
                                  key={i + "_" + item.id}
                                  id={i + "_" + item.id}
                                  className={`${item.isActive ? "active dropdown-bg" : ""}`}
                                  onKeyPress={(event) => handleKeyPressList(event, item, "vendorType")}
                                  onClick={(e) => {
                                    setMapLoad(true);
                                    setInitialStoreView(true);
                                    setSingleSelect(e, item, "vendorType"); //
                                    setChangeFilter("vendorType"+(Math.floor(Math.random() * 90000) + 10000));
                                  }}
                                >
                                  <label>
                                    <input
                                      id={`type_${item.id}`}
                                      className="multiselect-checkbox"
                                      type="checkbox"
                                      checked={item.isActive ===1? true : false}
                                      onChange={(e) => {
                                        e.stopPropagation();
                                      }}
                                    />
                                    <span className="multiselect-text wh-space">
                                      {language[item.values]}
                                    </span>
                                  </label>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3 mt-2 mb-2">
                        <label htmlFor="orderOption">
                          <small className="boldFont">{language["Order Option(s)"]}</small>
                        </label>
                        <div
                          className="multiselect-wrapper"
                          id={"order_multiSelect"}
                          ref={nodeOrder}                
                        >
                          <div 
                              className="multiselect-input-div"                          
                          >
                            <input
                              id={"order_input"}
                              className="multiselect-input"
                              autoComplete="off"
                              aria-label={"order options dropdown"}
                              aria-labelledby={"order options"}
                              readOnly="readonly"
                              type="readonly"
                              onKeyPress={(event) => handleKeyPress(event, "order")}
                              onClick={() => {
                                  setOrderOpen(!openOrder);
                              }}
                              placeholder={`${openOrder ? "" : (searchProperties.orderOptions === "all" ? language["All selected"] : truncateText(props.mapdata.orderOptions.filter(item=>item.isActive === 1).map(item => item.values).join(', '),40))}`}
                            />
                            <label
                              id={"order_inputCount"}
                              className="multiselect-count"
                              htmlFor={"order_input"}
                              // style={ (navHeight==0) ? styles.bg1 : styles.bg2}
                              style={{
                                visibility:
                                props.mapdata.orderOptions.filter(item=>item.isActive === 1).length === 0 ? "hidden" : "unset",
                              }}
                            >
                              {props.mapdata.orderOptions.filter(item=>item.isActive === 1).length}
                            </label>
                            <span
                              className={`spanBlock upArrow ${openOrder ? "down" : "up"}`}
                              htmlFor={"order_input"} onClick={() => {
                                setOrderOpen(!openOrder);
                            }}
                            ></span>
                          </div>
                          <div
                            id={"order_itemList"}
                            className={`multiselect-list ${
                              openOrder ? "active" : "d-none"
                            }`}
                          >
                            <div
                              onKeyPress={(event) => handleKeyPressSelectAll(event, "order")}
                              onClick={(e) => {
                                setInitialStoreView(true);
                                setSelectAll((prevState) => ({
                                      ...prevState,
                                      orderOptions: !selectAll["orderOptions"]
                                  }));
                                onSelectAllChange(e, "orderOptions");
                                setChangeFilter("orderOptions"+(Math.floor(Math.random() * 90000) + 10000));
                              }}
                            >
                              <label>
                                <input
                                  className="multiselect-checkbox"
                                  type="checkbox"
                                  id={"order_selectall"}
                                  checked={selectAll["orderOptions"]}
                                  onChange={(e) => {
                                    e.stopPropagation();
                                  }}
                                />
                                <span className="multiselect-text wh-space">
                                  {language["Select all"]}
                                </span>
                              </label>
                            </div>
                            <hr />
                            <ul>
                              {props.mapdata.orderOptions !== undefined && props.mapdata.orderOptions.map((item, i) => (
                                <li
                                  key={i + "_" + item.id}
                                  id={i + "_" + item.id}
                                  className={`${item.isActive ? "active dropdown-bg" : ""}`}
                                  onKeyPress={(event) => handleKeyPressList(event, item, "order")}
                                  onClick={(e) => {
                                    setMapLoad(true);
                                    setInitialStoreView(true);
                                    setSingleSelect(e, item, "orderOptions"); //
                                    setChangeFilter("orderOptions"+(Math.floor(Math.random() * 90000) + 10000));
                                  }}
                                >
                                  <label>
                                    <input
                                      id={`order_${item.name}`}
                                      className="multiselect-checkbox"
                                      type="checkbox"
                                      checked={item.isActive ===1? true : false}
                                      onChange={(e) => {
                                        e.stopPropagation();
                                      }}
                                    />
                                    <span className="multiselect-text wh-space">
                                      {language[item.values]}
                                    </span>
                                  </label>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3 mt-2 mb-2">
                        <label htmlFor="month">
                          <small className="boldFont">{language["Month(s) of Operation"]}</small>
                        </label>
                        <div
                          className="multiselect-wrapper"
                          id={"month_multiSelect"}
                          ref={nodeMonths}                
                        >
                          <div 
                              className="multiselect-input-div"                          
                          >
                            <input
                              id={"month_input"}
                              className="multiselect-input"
                              autoComplete="off"
                              aria-label={"months of opertaions dropdown"}
                              aria-labelledby={"months of opertaions"}
                              readOnly="readonly"
                              disabled={openToday}
                              type="readonly"
                              onKeyPress={(event) => handleKeyPress(event, "monthsOfOperation")}
                              onClick={() => {
                                  setMonthsOpen(!openMonths);
                              }}
                              placeholder={`${openMonths ? "" : (searchProperties.months === "all" ? language["All selected"] : truncateText(props.mapdata.months.filter(item=>item.isActive === 1).map(item => item.values).join(', '),40))}`}
                            />
                            <label
                              id={"month_inputCount"}
                              className="multiselect-count"
                              htmlFor={"months of opertaions"}
                              // style={ (navHeight==0) ? styles.bg1 : styles.bg2}
                              style={{
                                visibility:
                                props?.mapdata?.months?.filter(item=>item.isActive === 1).length === 0 ? "hidden" : "unset",
                              }}
                            >
                              {props?.mapdata?.months?.filter(item=>item.isActive === 1).length}
                            </label>
                            <span
                              className={`spanBlock upArrow ${openMonths ? "down" : "up"}`}
                              htmlFor={"months of opertaions"} onClick={() => {
                                setMonthsOpen(!openMonths);
                            }}
                            ></span>
                          </div>
                          <div
                            id={"month_itemList"}
                            className={`multiselect-list ${
                              openMonths ? "active" : "d-none"
                            }`}
                          >
                            <div
                              onKeyPress={(event) => handleKeyPressSelectAll(event, "monthsOfOperation")}
                              onClick={(e) => {
                                setMapLoad(true);
                                setInitialStoreView(true);
                                setSelectAll((prevState) => ({
                                      ...prevState,
                                      months: !selectAll["months"]
                                }));
                                onSelectAllChange(e, "months");
                                setChangeFilter("months"+(Math.floor(Math.random() * 90000) + 10000));
                              }}
                            >
                              <label>
                                <input
                                  className="multiselect-checkbox"
                                  type="checkbox"
                                  id={"month_selectall"}
                                  checked={selectAll["months"]}
                                  onChange={(e) => {
                                    e.stopPropagation();
                                  }}
                                />
                                <span className="multiselect-text wh-space">
                                {language["Select all"]}
                                </span>
                              </label>
                            </div>
                            <hr />
                            <ul>
                              {props.mapdata.months !== undefined && props.mapdata.months.map((item, i) => (
                                <li
                                  key={i + "_" + item.id}
                                  id={i + "_" + item.id}
                                  className={`${item.isActive ? "active dropdown-bg" : ""}`}
                                  onKeyPress={(event) => handleKeyPressList(event, item,"monthsOfOperation")}
                                  onClick={(e) => {
                                    setMapLoad(true);
                                    setInitialStoreView(true);
                                    setSingleSelect(e, item, "months"); //
                                    setChangeFilter("months"+(Math.floor(Math.random() * 90000) + 10000));
                                  }}
                                >
                                  <label>
                                    <input
                                      id={`month_${item.name}`}
                                      className="multiselect-checkbox"
                                      type="checkbox"
                                      checked={item.isActive ===1? true : false}
                                      onChange={(e) => {
                                        e.stopPropagation();
                                      }}
                                    />
                                    <span className="multiselect-text wh-space">
                                      {language[item.values]}
                                    </span>
                                  </label>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3 mt-2 mb-2">
                        <label htmlFor="day">
                          <small className="boldFont">{language["Day(s) of Operation"]}</small>
                        </label>
                        <div
                          className="multiselect-wrapper"
                          id={"day_multiSelect"}
                          ref={nodeDays}                
                        >
                          <div 
                              className="multiselect-input-div"                          
                          >
                            <input
                              id={"day_input"}
                              className="multiselect-input"
                              autoComplete="off"
                              aria-label={"days of opertaion dropdown"}
                              aria-labelledby={"days of opertaion"}
                              readOnly="readonly"
                              type="readonly"
                              disabled={openToday}
                              onKeyPress={(event) => handleKeyPress(event, "daysOfOperation")}
                              onClick={() => {
                                  setDaysOpen(!openDays);
                              }}
                              placeholder={`${openDays ? "" : (searchProperties.days === "all" ? language["All selected"] : truncateText(props.mapdata.days.filter(item=>item.isActive === 1).map(item => item.values).join(', '),40))}`}
                            />
                            <label
                              id={"day_inputCount"}
                              className="multiselect-count"
                              htmlFor={"days of opertaion"}
                              // style={ (navHeight==0) ? styles.bg1 : styles.bg2}
                              style={{
                                visibility:
                                props?.mapdata?.days?.filter(item=>item.isActive === 1).length === 0 ? "hidden" : "unset",
                              }}
                            >
                              {props?.mapdata?.days?.filter(item=>item.isActive === 1).length}
                            </label>
                            <span
                              className={`spanBlock upArrow ${openDays ? "down" : "up"}`}
                              htmlFor={"days of opertaion"} onClick={() => {
                                setDaysOpen(!openDays);
                              }}
                            ></span>
                          </div>
                          <div
                            id={"day_itemList"}
                            className={`multiselect-list ${
                              openDays ? "active" : "d-none"
                            }`}
                          >
                            <div
                              onKeyPress={(event) => handleKeyPressSelectAll(event, "daysOfOperation")}
                              onClick={(e) => {
                                setMapLoad(true);
                                setInitialStoreView(true);
                                setSelectAll((prevState) => ({
                                      ...prevState,
                                      days: !selectAll["days"]
                                }));
                                onSelectAllChange(e, "days");
                                setChangeFilter("days"+(Math.floor(Math.random() * 90000) + 10000));
                              }}
                            >
                              <label>
                                <input
                                  className="multiselect-checkbox"
                                  type="checkbox"
                                  id={"day_selectall"}
                                  checked={selectAll["days"]}
                                  onChange={(e) => {
                                    e.stopPropagation();
                                  }}
                                />
                                <span className="multiselect-text wh-space">
                                {language["Select all"]}
                                </span>
                              </label>
                            </div>
                            <hr />
                            <ul>
                              {props.mapdata.days !== undefined && props.mapdata.days.map((item, i) => (
                                <li
                                  key={i + "_" + item.id}
                                  id={i + "_" + item.id}
                                  className={`${item.isActive ? "active dropdown-bg" : ""}`}
                                  onKeyPress={(event) => handleKeyPressList(event, item, "daysOfOperation")}
                                  onClick={(e) => {
                                    setMapLoad(true);
                                    setInitialStoreView(true);
                                    setSingleSelect(e, item, "days"); //
                                    setChangeFilter("days"+(Math.floor(Math.random() * 90000) + 10000));
                                  }}
                                >
                                  <label>
                                    <input
                                      id={`month_${item.name}`}
                                      className="multiselect-checkbox"
                                      type="checkbox"
                                      checked={item.isActive ===1? true : false}
                                      onChange={(e) => {
                                        e.stopPropagation();
                                      }}
                                    />
                                    <span className="multiselect-text wh-space">
                                      {language[item.values]}
                                    </span>
                                  </label>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>

                      </div>
                      <div className="col-md-3 mt-2 mb-2">
                        <label htmlFor="deliveryCity">
                          <small className="boldFont" >{language["Vendor Name"]}</small>
                        </label>
                        <SelectDropdown 
                          // placeholder="Vendor Name"
                          value={selectedVendors}
                          ariaLabel = 'Vendor Name'
                          onChange={(v) => {
                            setMapLoad(true);
                            setInitialStoreView(true);
                            setChangeFilter("vendor"+(Math.floor(Math.random() * 90000) + 10000));
                            setSelectedVendors(v);
                            setSearchProperties((prevState) => ({
                              ...prevState,
                              vendor_name: v.name,
                            }));
                            getGeocode(selectedCity)
                          }}
                          options={vendorList}
                          isSearch={true}
                          // keyValue={{ key: "vendor_name", value: "vendor_name" }}
                          defaultValueProps={{
                            default: true,
                            defaultOption: { selectedVendors },
                          }}
                        />
                      </div>
                      <div className="col-md-3 mt-2 mb-2">
                        <div className="row">
                          <div className="col-md-4">
                            <label className="d-block">
                              <small className="boldFont d-block mt-2">
                                {language["Open Today"]}
                              </small>{" "}
                            </label>
                            <input
                              type="checkbox"
                              id="availableShop"
                              checked={openToday}
                              aria-label="Open Today"
                              aria-labelledby="Open Today"
                              onKeyPress={(event)=>{
                                if (event.key === 'Enter') {
                                  setMapLoad(true);
                                  setInitialStoreView(true);
                                  setChangeFilter("openToday"+(Math.floor(Math.random() * 90000) + 10000));
                                  setOpenToday(!openToday);
                                  setSearchProperties((prevState) => ({
                                      ...prevState,
                                      open_today: !openToday
                                  }));                                
                                  setSearchProperties((prevState) => ({
                                      ...prevState,                                        
                                      // months: "all",
                                      days: "all"
                                  }));
                                  props.mapdata.days = props.mapdata.days.map((prevState) => ({
                                      ...prevState,
                                      isActive: 1
                                  }));
                                  props.mapdata.months = props.mapdata.months.map((prevState) => ({
                                      ...prevState,
                                      isActive: 1
                                  }));
                                  setSelectAll((prevState) => ({
                                      ...prevState,
                                      days: true,
                                      months: true
                                  }));
                              }} }
                                onChange={()=>{
                                  setMapLoad(true);
                                  setInitialStoreView(true);
                                  setChangeFilter("openToday"+(Math.floor(Math.random() * 90000) + 10000));
                                  setOpenToday(!openToday);
                                  setSearchProperties((prevState) => ({
                                      ...prevState,
                                      open_today: !openToday
                                  }));                                
                                  setSearchProperties((prevState) => ({
                                      ...prevState,                                        
                                      // months: "all",
                                      days: "all"
                                  }));
                                  props.mapdata.days = props.mapdata.days.map((prevState) => ({
                                      ...prevState,
                                      isActive: 1
                                  }));
                                  props.mapdata.months = props.mapdata.months.map((prevState) => ({
                                      ...prevState,
                                      isActive: 1
                                  }));
                                  setSelectAll((prevState) => ({
                                      ...prevState,
                                      days: true,
                                      months: true
                                  }));
                                  // onSelectAllChange("", "days");
                                  // onSelectAllChange("", "months");
                              }}
                            />
                          </div>
                          <div className="col-md-8 mt-3">
                            <button
                              className="btn btn-outline-primary btn-block btn-lg reSizeFont text-overflow searchBtn filterHover"
                              onClick={resetSearch}
                              title="Clear Search"
                            >
                              <i className="fa fa-refresh"></i> {language["Clear Search"]}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )} 
          { windowDimensions.width < 768 && isSearchCollapsed ?
          <div className="activate overflowX-hidden sidebar-panel sidebar-search d-md-none d-lg-none d-sm-block smSearch px-3">
            <div className="row">
              <div className="col-6">
                <div className="mt-2 mr-3 mb-2 searchLabelSection" onClick={handleSearchCollapse}>
                  <h4 className="resText ml-3" style={{display: "inline",zIndex: "9999"}}> Search </h4>
                </div>
              </div>
              <div className="col-6">
                <a style={{marginTop: "14px", padding: "0.25rem 0.5rem", borderRadius: "0.2rem"}} onClick={handleSearchCollapse} 
                className="closeingsearch text-right btn-rounded btn-default btn-sm btn-bg btn-font">
                  <i className="fa fa-times cusIcon cursor" aria-hidden="true"></i>
                </a>
                <a style={{background: "#006e52 !important",color: "#fff !important",fontSize: "13px !important"}} onClick={()=>{
                  setMobileSearch(!mobileSearch)
                  handleSearchCollapse();
                  setMapLoad(true);
                  // setSearchProperties(
                  //   {
                  //     address: coordinates.address,
                  //     city: selectedCity?.name ? selectedCity?.name : "all",
                  //     latitude: parseFloat(coordinates.lat),
                  //     longitude: parseFloat(coordinates.lng),
                  //     // area: selectedArea.name == "" ? {name:"5",values:"5 miles"} : {name:"",values:language["Statewide"]},
                  //     area: selectedCity?.name != "all" ?  (selectedArea.name == "" ? {name:"5",values:"5 miles"} : selectedArea) : {name:"",values:language["Statewide"]},
                  //     vendorType: selectedType? selectedType: "all",
                  //     orderOptions: selectedOrderOption? selectedOrderOption: "all",
                  //     months: selectedMonths? selectedMonths: "all",
                  //     days: selectedDays? selectedDays: "all",
                  //     vendor_name: selectedVendors?.name? selectedVendors?.name : "all",
                  //     open_today: openToday,
                  //     is_mylocation: myLocation
                  //   }
                  // );
                  setChangeFilter("mobileSearch"+(Math.floor(Math.random() * 90000) + 10000));
                }} className="mobile-search btn-rounded btn-default btn-sm btn-bg btn-font">
                  <i className="fa fa-search cusIcon cursor text-white" aria-hidden="true"></i>
                </a>
              </div>
            </div>              
            <div
              id="searchPanel"
              className={`panel-collapse ${
                isSearchOpened ? "collapse show" : "collapse"
              }`}
            >
              <div className="row p-0">
                <div className="col-md-12 mb-2">
                  <div className="row">
                    <div className="col-md-3 mt-2 mb-2">
                      <label htmlFor="deliveryCity">
                        <small className="boldFont">{language.County}/{language.City}</small>
                      </label>
                      <SelectDropdown
                        // placeholder="County/City"
                        value={selectedCity}
                        ariaLabel = 'County/City'
                        onChange={(v) => {                        
                          setInitialStoreView(true);
                          let miles = "";
                          getGeocode(v,"modal");
                          if (v.name !== "all") {
                              if (selectedArea.name === "" ) {
                                  miles = "5";
                                  setSelectedArea({name:"5",values:"5 miles"});
                                    setSearchProperties((prevState) => ({
                                        ...prevState,
                                        area: {name:"5",values:"5 miles"},
                                    }));
                              }
                          } else {
                            setSelectedArea({name:"",values:language["Statewide"]});
                            setSearchProperties((prevState) => ({
                                ...prevState,
                                area: {name:"",values:language["Statewide"]},
                                latitude: parseFloat(defaultLocation?.lat),
                                longitude: parseFloat(defaultLocation?.lng),
                                city: "all",
                                address: defaultLocation?.address
                            }));
                            setCoordinates(defaultLocation);
                          }
                          setSelectedCity(v);
                          // fetchVendorList(v.name,miles)
                        }}
                        options={cityList}
                        // keyValue={{ key: "id", value: "county" }}
                        isSearch={true}
                        defaultValueProps={{
                          default: true,
                          defaultOption: {
                            name: "myLocation",
                            values: language["Use My Location"],
                          },
                          cssProps: {
                            fa: "fa fa-map-pin",
                            spanClass: "sys-color",
                          },
                        }}
                      />
                    </div>
                    

                    <div className="col-md-3 mt-2 mb-2">
                      <label htmlFor="deliveryCity">
                        <small className="boldFont">{language["Area Within"]}</small>
                      </label>
                      <SelectDropdown
                        value={selectedArea}
                        ariaLabel = 'Area Within'
                        onChange={(v) => {                         
                          setInitialStoreView(true);
                          let city = selectedCity.values;
                          setChangeFilter("area"+(Math.floor(Math.random() * 90000) + 10000));
                          setSelectedArea(v);
                          if (v.name === "") {
                            city = "all";
                            setSelectedCity({name:"all", values:language["All Counties & Cities"], isActive: 1});
                            setSearchProperties((prevState) => ({
                                ...prevState,
                                city: "all",
                              }));
                          }
                          setSearchProperties((prevState) => ({
                            ...prevState,
                            area: v,
                          }));
                        }}
                        disabled={selectedArea.name === ""}
                        options={areaList}
                        isSearch={true}
                        defaultValueProps={{
                          default: true,
                          defaultOption: { selectedArea },
                        }}
                      />
                    </div>

                    
                    <div className="col-md-3 mt-2 mb-2">
                      <label htmlFor={"vendorType"}>
                        <small className="boldFont">{language.Type}</small>
                      </label>
                      <div
                        className="multiselect-wrapper"
                        id={"type_multiSelect"}
                        ref={nodeType}                
                      >
                        <div 
                            className="multiselect-input-div"                          
                        >
                          <input
                            id={"type_input"}
                            className="multiselect-input"
                            autoComplete="off"
                            aria-label={"type dropdown"}
                            aria-labelledby={"type"}
                            readOnly="readonly"
                            type="readonly"
                            onKeyPress={(event) => handleKeyPress(event, "vendorType")}
                            onClick={() => {
                                setTypeOpen(!openType);
                            }}
                            placeholder={`${openType ? "" : (searchProperties.vendorType === "all" ? language["All selected"] : truncateText(props.mapdata.vendorType.filter(item=>item.isActive === 1).map(item => item.values).join(', '),40))}`}
                          />
                          <label
                            id={"type_inputCount"}
                            className="multiselect-count"
                            htmlFor={"type_input"}
                            // style={ (navHeight==0) ? styles.bg1 : styles.bg2}
                            style={{
                              visibility:
                              // props?.mapdata?.type?.filter(item=>item.isActive === 1).length === 0 ? "hidden" : "unset",
                              props.mapdata.vendorType.filter(item=>item.isActive === 1).length === 0 ? "hidden" : "unset",
                            }}
                          >
                            {props.mapdata.vendorType.filter(item=>item.isActive === 1).length}
                          </label>
                          <span
                            className={`spanBlock upArrow ${openType ? "down" : "up"}`}
                            htmlFor={"type_input"} onClick={() => {
                              setTypeOpen(!openType);
                          }}
                          ></span>
                        </div>
                        <div
                          id={"type_itemList"}
                          className={`multiselect-list ${
                            openType ? "active" : "d-none"
                          }`}
                        >
                          <div
                            onKeyPress={(event) => handleKeyPressSelectAll(event, "vendorType")}
                            onClick={(e) => {
                              setInitialStoreView(true);
                              setSelectAll((prevState) => ({
                                    ...prevState,
                                    vendorType: !selectAll["vendorType"]
                                }));
                              onSelectAllChange(e, "vendorType");
                              setChangeFilter("vendorType"+(Math.floor(Math.random() * 90000) + 10000));
                            }}
                          >
                            <label>
                              <input
                                className="multiselect-checkbox"
                                type="checkbox"
                                id={"type_selectall"}
                                checked={selectAll["vendorType"]}
                                onChange={(e) => {
                                  e.stopPropagation();
                                }}
                              />
                              <span className="multiselect-text wh-space">
                                {language["Select all"]}
                              </span>
                            </label>
                          </div>
                          <hr />
                          <ul>
                            {props.mapdata.vendorType !== undefined && props.mapdata.vendorType.map((item, i) => (
                              <li
                                key={i + "_" + item.id}
                                id={i + "_" + item.id}
                                className={`${item.isActive ? "active dropdown-bg" : ""}`}
                                onKeyPress={(event) => handleKeyPressList(event, item, "vendorType")}
                                onClick={(e) => {
                                  setInitialStoreView(true);
                                  setSingleSelect(e, item, "vendorType"); //
                                  setChangeFilter("vendorType"+(Math.floor(Math.random() * 90000) + 10000));
                                }}
                              >
                                <label>
                                  <input
                                    id={`type_${item.id}`}
                                    className="multiselect-checkbox"
                                    type="checkbox"
                                    checked={item.isActive ===1? true : false}
                                    onChange={(e) => {
                                      e.stopPropagation();
                                    }}
                                  />
                                  <span className="multiselect-text wh-space">
                                    {language[item.values]}
                                  </span>
                                </label>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 mt-2 mb-2">
                      <label htmlFor="orderOption">
                        <small className="boldFont">{language["Order Option(s)"]}</small>
                      </label>
                      <div
                        className="multiselect-wrapper"
                        id={"order_multiSelect"}
                        ref={nodeOrder}                
                      >
                        <div 
                            className="multiselect-input-div"                          
                        >
                          <input
                            id={"order_input"}
                            className="multiselect-input"
                            autoComplete="off"
                            aria-label={"order options dropdown"}
                            aria-labelledby={"order options"}
                            readOnly="readonly"
                            type="readonly"
                            onKeyPress={(event) => handleKeyPress(event, "order")}
                            onClick={() => {
                                setOrderOpen(!openOrder);
                            }}
                            placeholder={`${openOrder ? "" : (searchProperties.orderOptions === "all" ? language["All selected"] : truncateText(props.mapdata.orderOptions.filter(item=>item.isActive === 1).map(item => item.values).join(', '),40))}`}
                          />
                          <label
                            id={"order_inputCount"}
                            className="multiselect-count"
                            htmlFor={"order_input"}
                            // style={ (navHeight==0) ? styles.bg1 : styles.bg2}
                            style={{
                              visibility:
                              props.mapdata.orderOptions.filter(item=>item.isActive === 1).length === 0 ? "hidden" : "unset",
                            }}
                          >
                            {props.mapdata.orderOptions.filter(item=>item.isActive === 1).length}
                          </label>
                          <span
                            className={`spanBlock upArrow ${openOrder ? "down" : "up"}`}
                            htmlFor={"order_input"} onClick={() => {
                              setOrderOpen(!openOrder);
                          }}
                          ></span>
                        </div>
                        <div
                          id={"order_itemList"}
                          className={`multiselect-list ${
                            openOrder ? "active" : "d-none"
                          }`}
                        >
                          <div
                            onKeyPress={(event) => handleKeyPressSelectAll(event, "order")}
                            onClick={(e) => {
                              setInitialStoreView(true);
                              setSelectAll((prevState) => ({
                                    ...prevState,
                                    orderOptions: !selectAll["orderOptions"]
                                }));
                              onSelectAllChange(e, "orderOptions");
                              setChangeFilter("orderOptions"+(Math.floor(Math.random() * 90000) + 10000));
                            }}
                          >
                            <label>
                              <input
                                className="multiselect-checkbox"
                                type="checkbox"
                                id={"order_selectall"}
                                checked={selectAll["orderOptions"]}
                                onChange={(e) => {
                                  e.stopPropagation();
                                }}
                              />
                              <span className="multiselect-text wh-space">
                                {language["Select all"]}
                              </span>
                            </label>
                          </div>
                          <hr />
                          <ul>
                            {props.mapdata.orderOptions !== undefined && props.mapdata.orderOptions.map((item, i) => (
                              <li
                                key={i + "_" + item.id}
                                id={i + "_" + item.id}
                                className={`${item.isActive ? "active dropdown-bg" : ""}`}
                                onKeyPress={(event) => handleKeyPressList(event, item, "order")}
                                onClick={(e) => {
                                  setInitialStoreView(true);
                                  setSingleSelect(e, item, "orderOptions"); //
                                  setChangeFilter("orderOptions"+(Math.floor(Math.random() * 90000) + 10000));
                                }}
                              >
                                <label>
                                  <input
                                    id={`order_${item.name}`}
                                    className="multiselect-checkbox"
                                    type="checkbox"
                                    checked={item.isActive ===1? true : false}
                                    onChange={(e) => {
                                      e.stopPropagation();
                                    }}
                                  />
                                  <span className="multiselect-text wh-space">
                                    {language[item.values]}
                                  </span>
                                </label>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 mt-2 mb-2">
                      <label htmlFor="month">
                        <small className="boldFont">{language["Month(s) of Operation"]}</small>
                      </label>
                      <div
                        className="multiselect-wrapper"
                        id={"month_multiSelect"}
                        ref={nodeMonths}                
                      >
                        <div 
                            className="multiselect-input-div"                          
                        >
                          <input
                            id={"month_input"}
                            className="multiselect-input"
                            autoComplete="off"
                            aria-label={"months of opertaions dropdown"}
                            aria-labelledby={"months of opertaions"}
                            readOnly="readonly"
                            disabled={openToday}
                            type="readonly"
                            onKeyPress={(event) => handleKeyPress(event, "monthsOfOperation")}
                            onClick={() => {
                                setMonthsOpen(!openMonths);
                            }}
                            placeholder={`${openMonths ? "" : (searchProperties.months === "all" ? language["All selected"] : truncateText(props.mapdata.months.filter(item=>item.isActive === 1).map(item => item.values).join(', '),40))}`}
                          />
                          <label
                            id={"month_inputCount"}
                            className="multiselect-count"
                            htmlFor={"months of opertaions"}
                            // style={ (navHeight==0) ? styles.bg1 : styles.bg2}
                            style={{
                              visibility:
                              props?.mapdata?.months?.filter(item=>item.isActive === 1).length === 0 ? "hidden" : "unset",
                            }}
                          >
                            {props?.mapdata?.months?.filter(item=>item.isActive === 1).length}
                          </label>
                          <span
                            className={`spanBlock upArrow ${openMonths ? "down" : "up"}`}
                            htmlFor={"months of opertaions"} onClick={() => {
                              setMonthsOpen(!openMonths);
                          }}
                          ></span>
                        </div>
                        <div
                          id={"month_itemList"}
                          className={`multiselect-list ${
                            openMonths ? "active" : "d-none"
                          }`}
                        >
                          <div
                            onKeyPress={(event) => handleKeyPressSelectAll(event, "monthsOfOperation")}
                            onClick={(e) => {
                              setInitialStoreView(true);
                              setSelectAll((prevState) => ({
                                    ...prevState,
                                    months: !selectAll["months"]
                              }));
                              onSelectAllChange(e, "months");
                              setChangeFilter("months"+(Math.floor(Math.random() * 90000) + 10000));
                            }}
                          >
                            <label>
                              <input
                                className="multiselect-checkbox"
                                type="checkbox"
                                id={"month_selectall"}
                                checked={selectAll["months"]}
                                onChange={(e) => {
                                  e.stopPropagation();
                                }}
                              />
                              <span className="multiselect-text wh-space">
                              {language["Select all"]}
                              </span>
                            </label>
                          </div>
                          <hr />
                          <ul>
                            {props.mapdata.months !== undefined && props.mapdata.months.map((item, i) => (
                              <li
                                key={i + "_" + item.id}
                                id={i + "_" + item.id}
                                className={`${item.isActive ? "active dropdown-bg" : ""}`}
                                onKeyPress={(event) => handleKeyPressList(event, item,"monthsOfOperation")}
                                onClick={(e) => {
                                  setInitialStoreView(true);
                                  setSingleSelect(e, item, "months"); //
                                  setChangeFilter("months"+(Math.floor(Math.random() * 90000) + 10000));
                                }}
                              >
                                <label>
                                  <input
                                    id={`month_${item.name}`}
                                    className="multiselect-checkbox"
                                    type="checkbox"
                                    checked={item.isActive ===1? true : false}
                                    onChange={(e) => {
                                      e.stopPropagation();
                                    }}
                                  />
                                  <span className="multiselect-text wh-space">
                                    {language[item.values]}
                                  </span>
                                </label>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 mt-2 mb-2">
                      <label htmlFor="day">
                        <small className="boldFont">{language["Day(s) of Operation"]}</small>
                      </label>
                      <div
                        className="multiselect-wrapper"
                        id={"day_multiSelect"}
                        ref={nodeDays}                
                      >
                        <div 
                            className="multiselect-input-div"                          
                        >
                          <input
                            id={"day_input"}
                            className="multiselect-input"
                            autoComplete="off"
                            aria-label={"days of opertaion dropdown"}
                            aria-labelledby={"days of opertaion"}
                            readOnly="readonly"
                            type="readonly"
                            disabled={openToday}
                            onKeyPress={(event) => handleKeyPress(event, "daysOfOperation")}
                            onClick={() => {
                                setDaysOpen(!openDays);
                            }}
                            placeholder={`${openDays ? "" : (searchProperties.days === "all" ? language["All selected"] : truncateText(props.mapdata.days.filter(item=>item.isActive === 1).map(item => item.values).join(', '),40))}`}
                          />
                          <label
                            id={"day_inputCount"}
                            className="multiselect-count"
                            htmlFor={"days of opertaion"}
                            // style={ (navHeight==0) ? styles.bg1 : styles.bg2}
                            style={{
                              visibility:
                              props?.mapdata?.days?.filter(item=>item.isActive === 1).length === 0 ? "hidden" : "unset",
                            }}
                          >
                            {props?.mapdata?.days?.filter(item=>item.isActive === 1).length}
                          </label>
                          <span
                            className={`spanBlock upArrow ${openDays ? "down" : "up"}`}
                            htmlFor={"days of opertaion"} onClick={() => {
                              setDaysOpen(!openDays);
                            }}
                          ></span>
                        </div>
                        <div
                          id={"day_itemList"}
                          className={`multiselect-list ${
                            openDays ? "active" : "d-none"
                          }`}
                        >
                          <div
                            onKeyPress={(event) => handleKeyPressSelectAll(event, "daysOfOperation")}
                            onClick={(e) => {
                              setInitialStoreView(true);
                              setSelectAll((prevState) => ({
                                    ...prevState,
                                    days: !selectAll["days"]
                              }));
                              onSelectAllChange(e, "days");
                              setChangeFilter("days"+(Math.floor(Math.random() * 90000) + 10000));
                            }}
                          >
                            <label>
                              <input
                                className="multiselect-checkbox"
                                type="checkbox"
                                id={"day_selectall"}
                                checked={selectAll["days"]}
                                onChange={(e) => {
                                  e.stopPropagation();
                                }}
                              />
                              <span className="multiselect-text wh-space">
                              {language["Select all"]}
                              </span>
                            </label>
                          </div>
                          <hr />
                          <ul>
                            {props.mapdata.days !== undefined && props.mapdata.days.map((item, i) => (
                              <li
                                key={i + "_" + item.id}
                                id={i + "_" + item.id}
                                className={`${item.isActive ? "active dropdown-bg" : ""}`}
                                onKeyPress={(event) => handleKeyPressList(event, item, "daysOfOperation")}
                                onClick={(e) => {
                                  setInitialStoreView(true);
                                  setSingleSelect(e, item, "days"); //
                                  setChangeFilter("days"+(Math.floor(Math.random() * 90000) + 10000));
                                }}
                              >
                                <label>
                                  <input
                                    id={`month_${item.name}`}
                                    className="multiselect-checkbox"
                                    type="checkbox"
                                    checked={item.isActive ===1? true : false}
                                    onChange={(e) => {
                                      e.stopPropagation();
                                    }}
                                  />
                                  <span className="multiselect-text wh-space">
                                    {language[item.values]}
                                  </span>
                                </label>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>

                    </div>
                    <div className="col-md-3 mt-2 mb-2">
                      <label htmlFor="deliveryCity">
                        <small className="boldFont" >{language["Vendor Name"]}</small>
                      </label>
                      <SelectDropdown 
                        // placeholder="Vendor Name"
                        value={selectedVendors}
                        ariaLabel = 'Vendor Name'
                        onChange={(v) => {
                          setInitialStoreView(true);
                          setChangeFilter("vendor"+(Math.floor(Math.random() * 90000) + 10000));
                          setSelectedVendors(v);
                          // setSearchProperties((prevState) => ({
                          //   ...prevState,
                          //   vendor_name: v.name,
                          // }));
                        }}
                        options={vendorList}
                        isSearch={true}
                        // keyValue={{ key: "vendor_name", value: "vendor_name" }}
                        defaultValueProps={{
                          default: true,
                          defaultOption: { selectedVendors },
                        }}
                      />
                    </div>
                    <div className="col-md-3 mt-2 mb-2">
                      <div className="row">
                        <div className="col-md-4">
                          <label className="d-block">
                            <small className="boldFont d-block mt-2">
                              {language["Open Today"]}
                            </small>{" "}
                          </label>
                          <input
                            type="checkbox"
                            id="availableShop"
                            checked={openToday}
                            aria-label="Open Today"
                            aria-labelledby="Open Today"
                            onKeyPress={(event)=>{
                              if (event.key === 'Enter') {
                                setInitialStoreView(true);
                                setChangeFilter("openToday"+(Math.floor(Math.random() * 90000) + 10000));
                                setOpenToday(!openToday);
                                // setSearchProperties((prevState) => ({
                                //     ...prevState,
                                //     open_today: !openToday
                                // }));                                
                                // setSearchProperties((prevState) => ({
                                //     ...prevState,                                        
                                //     // months: "all",
                                //     days: "all"
                                // }));
                                props.mapdata.days = props.mapdata.days.map((prevState) => ({
                                    ...prevState,
                                    isActive: 1
                                }));
                                props.mapdata.months = props.mapdata.months.map((prevState) => ({
                                    ...prevState,
                                    isActive: 1
                                }));
                                setSelectAll((prevState) => ({
                                    ...prevState,
                                    days: true,
                                    months: true
                                }));
                            }} }
                              onChange={()=>{
                                setInitialStoreView(true);
                                setChangeFilter("openToday"+(Math.floor(Math.random() * 90000) + 10000));
                                setOpenToday(!openToday);
                                props.mapdata.days = props.mapdata.days.map((prevState) => ({
                                    ...prevState,
                                    isActive: 1
                                }));
                                props.mapdata.months = props.mapdata.months.map((prevState) => ({
                                    ...prevState,
                                    isActive: 1
                                }));
                                setSelectAll((prevState) => ({
                                    ...prevState,
                                    days: true,
                                    months: true
                                }));
                                // onSelectAllChange("", "days");
                                // onSelectAllChange("", "months");
                            }}
                          />
                        </div>                         
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> 
          : "" }
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 p-0">
          <div className="mapContent">            
            <MapKeyComponent 
                isOpened={showMapKeyList}
                setIsOpened={setShowMapKeyList}
                onMapKeyClick={(v) => {
                  console.log(searchProperties);
                    setSearchProperties((prevState) => ({
                        ...prevState,
                        vendorType: [v]
                    }));
                    setSelectAll((prevState) => ({
                        ...prevState,
                        vendorType: false
                    }));
                    let selectedOptions = {};
                    props.mapdata.vendorType = props.mapdata.vendorType.map((obj) => {
                        obj.isActive = 0;
                        if(obj.name === v) {
                            obj.isActive = 1;  
                        }
                        selectedOptions[obj.name] = obj.isActive === 1 ? "Y" : "N";
                        return obj;
                    });
                    setSelectedType(selectedOptions);
                    setChangeFilter("vendorType"+(Math.floor(Math.random() * 90000) + 10000))
                }}
            />
            <FilterMapDataComponent
              initialStoreView={initialStoreView}
              reload={true}
              searchProperties={searchProperties}
              loading={props.mapdata.loading}
              mapLoad={mapLoad}
              setMapLoad={setMapLoad}
              coordinates={coordinates}
              mapResult = { props.mapdata.storesList }
              changeFilter={changeFilter}
              language={language}
              isResultCollapsed = {isResultCollapsed}
              setResultCollapsed={setIsResultCollapsed}
              //   resultMsg={resultMsg}
            />
            {/* loadingStatus={loadingStatus} onLoadingStatus={(e)=>{
                setLoadingStatus(e);
                // console.log(e);
                }} */}
          </div>
        </div>
      </div>
    </>
  );
};

SearchComponent.propTypes = {
    mapdata: PropTypes.object.isRequired,
    loaddata: PropTypes.object,
    getMapData: PropTypes.func.isRequired,
    getCityList: PropTypes.func.isRequired,
    getMapStoresList: PropTypes.func.isRequired,
    mapSearchPayload: PropTypes.func.isRequired,
    loading: PropTypes.object,
    city: PropTypes.object,
    getArea: PropTypes.func.isRequired,
    area: PropTypes.object,
    getType: PropTypes.func.isRequired,
    vendorType: PropTypes.object,
    getOrderOption: PropTypes.func.isRequired,
    orderOptions: PropTypes.object,
    getMonthOption: PropTypes.func.isRequired,
    months: PropTypes.object,
    getDayOption: PropTypes.func.isRequired,
    days: PropTypes.object,
    getVendorList: PropTypes.func.isRequired,
    vendor: PropTypes.object.isRequired,
    coordinates: PropTypes.object,
    language: PropTypes.object,
    selectedLanguage: PropTypes.string,
    userAuth: PropTypes.object
    // cookies: instanceOf(Cookies).isRequired
};

const mapDispatchToProps = (dispatch) => {
   return {
    getMapData: (searchProperties) => {
        dispatch({ type: GET_MAPDATA, search: searchProperties });
    },
    getCityList: (city_county) => {
      dispatch({ type: GET_COUNTYCITY, city:city_county });
    },
     getArea: () => {
       dispatch({ type: GET_AREA });
     },
     getMapStoresList: (payload) =>
        dispatch({ type: GET_STORE_LIST, payload: payload }),
    mapSearchPayload: (payload) =>
        dispatch({ type: SET_MAP_SEARCH, payload: payload }),
     getType: () => {
       dispatch({ type: GET_TYPE });
     },
     getOrderOption: () => {
       dispatch({ type: GET_ORDER_OPTION });
     },
     getMonthOption: () => {
       dispatch({ type: GET_MONTH_OPTION });
     },
     getDayOption: () => {
       dispatch({ type: GET_DAY_OPTION });
     },
     getVendorList: (payload) => {
        dispatch({ type: GET_VENDOR_LIST, payload:payload });
      },
   }; 
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(SearchComponent);
