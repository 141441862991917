// import * as Names from "../../../../common/constants/names";
import * as Types from "../../../../common/constants/types";
import * as Props from "../../../../common/constants/data/shared";
import * as OptionsProps from "../../../../common/constants/data/options";
import * as InputProps from "../../../../common/constants/data/input";
import * as SelectProps from "../../../../common/constants/data/select";
import { signupLanguageLabels } from "./signup-multiLanguage-labels";

let language = localStorage.getItem("selectedLanguage");
if (language === null) {
  language = "en-US";
}

const common = [   
    {
        [Props.NAME]: "mapShow",
        [Props.LABEL]: "",
        [Props.TYPE]: "toggle",
        ["className"]: "pure-u-md-1-2 pad-right-half"
    },
    {
        [Props.NAME]: "map",
        [Props.LABEL]: "",
        [Props.TYPE]: "map"
    },
    {
        [Props.NAME]: "special_instructions",
        [Props.LABEL]: signupLanguageLabels(language).specialInstr,
        [Props.TYPE]: Types.INPUT_TEXT_AREA,
        [Props.REQUIRED]: false,
        [Props.ERROR]: false,
        ["readonly"]:false,
        ["className"]: "",
        [InputProps.PLACEHOLDER]: "What details should customers know about your CSA? (for example, CSA sizes and types available, when your enrollment is open, delivery range if you deliver)"
    },
    {
        [Props.NAME]: "months_operations",
        [Props.LABEL]: "Month(s) of Operation",
        [Props.TYPE]: Types.CHECKBOX,
        ["readonly"]:false,
        ["className"]: "pure-u-md-3-4 pad-right-half",
        [OptionsProps.OPTIONS]: [{
            "isenabled":false,
            "value": "January",
          },
          {
            "isenabled": false,
            "value": "February",
          }, {
            "isenabled": false,
            "value": "March",
          },
          {
            "isenabled": false,
            "value": "April",
          }, 
          {
            "isenabled": false,
            "value": "May",
          },
          {
            "isenabled": false,
            "value": "June",
          }, {
            "isenabled": false,
            "value": "July",
          },
          {
            "isenabled": false,
            "value": "August",
          }, {
            "isenabled": false,
            "value": "September",
          },
          {
            "isenabled": false,
            "value":  "October",
          }, {
            "isenabled": false,
            "value": "November",
          },
          {
            "isenabled": false,
            "value":  "December",
          }],
        [Props.REQUIRED]: true,
        [Props.ERROR]: false,
        // [Props.ERROR_MESSAGE]: incomeMultilanguageLabels(language).optionErrMsg//'Please select an option below'
      },
      {
        [Props.NAME]: "distribution_option",
        [Props.LABEL]: "Order Option(s)",
        [Props.TYPE]: Types.CHECKBOX,
        ["className"]: "pure-u-md-1-4 pad-right-half",
        // [OptionsProps.OPTIONS]: [{
        //   [OptionsProps.OPTION_VALUE]:1,
        //   [OptionsProps.OPTION_DISPLAY]: "Delivery",
        // },
        // {
        //   [OptionsProps.OPTION_VALUE]: false,
        //   [OptionsProps.OPTION_DISPLAY]: "Curbside pickup",
        // },
        // {
        //     [OptionsProps.OPTION_VALUE]: false,
        //     [OptionsProps.OPTION_DISPLAY]: "Online pre-selection",
        //   },
        //   {
        //     [OptionsProps.OPTION_VALUE]:false,
        //     [OptionsProps.OPTION_DISPLAY]: "Phone pre-selection",
        //   },
        //   {
        //     [OptionsProps.OPTION_VALUE]: false,
        //     [OptionsProps.OPTION_DISPLAY]: "In-Person",
        //   },
        //   {
        //     [OptionsProps.OPTION_VALUE]: false,
        //     [OptionsProps.OPTION_DISPLAY]: "Other",
        //   }            
        // ],
        [Props.REQUIRED]: true,
        ["readonly"]:false,
        [Props.ERROR]: false,
      },
     {
        [Props.NAME]: "working_hours",
        [Props.LABEL]: signupLanguageLabels(language).workingHours,
        [Props.TYPE]: "workhour_select_radio",
        [Props.REQUIRED]: false,
        [Props.ERROR]: false,
        ["readonly"]:false,
        ["fieldArray"]: true,
        ["className"]: "",
        ["workHourFields"]: [
            {
                "day": [{name:"Sunday", isClosed:true},{name:"Monday", isClosed:false},{name:"Tuesday", isClosed:false},{name:"Wednesday", isClosed:false},{name:"Thursday", isClosed:false},{name:"Friday", isClosed:false},{name:"Saturday", isClosed:true}],
                "open_time": [{"name":"12:00","values":"12:00"},{"name":"12:30","values":"12:30"},{"name":"1:00","values":"1:00"},{"name":"1:30","values":"1:30"},{"name":"2:00","values":"2:00"},{"name":"2:30","values":"2:30"},{"name":"3:00","values":"3:00"},{"name":"3:30","values":"3:30"},{"name":"4:00","values":"4:00"},{"name":"4:30","values":"4:30"},{"name":"5:00","values":"5:00"},{"name":"5:30","values":"5:30"},{"name":"6:00","values":"6:00"},{"name":"6:30","values":"6:30"},{"name":"7:00","values":"7:00"},{"name":"7:30","values":"7:30"},{"name":"8:00","values":"8:00"},{"name":"8:30","values":"8:30"},{"name":"9:00","values":"9:00"},{"name":"9:30","values":"9:30"},{"name":"10:00","values":"10:00"},{"name":"10:30","values":"10:30"},{"name":"11:00","values":"11:00"},{"name":"11:30","values":"11:30"}],
                "open_time_meridian":[{values: "AM", name:"am"}, {values: "PM", name:"pm"}],
                "close_time": [{"name":"12:00","values":"12:00"},{"name":"12:30","values":"12:30"},{"name":"1:00","values":"1:00"},{"name":"1:30","values":"1:30"},{"name":"2:00","values":"2:00"},{"name":"2:30","values":"2:30"},{"name":"3:00","values":"3:00"},{"name":"3:30","values":"3:30"},{"name":"4:00","values":"4:00"},{"name":"4:30","values":"4:30"},{"name":"5:00","values":"5:00"},{"name":"5:30","values":"5:30"},{"name":"6:00","values":"6:00"},{"name":"6:30","values":"6:30"},{"name":"7:00","values":"7:00"},{"name":"7:30","values":"7:30"},{"name":"8:00","values":"8:00"},{"name":"8:30","values":"8:30"},{"name":"9:00","values":"9:00"},{"name":"9:30","values":"9:30"},{"name":"10:00","values":"10:00"},{"name":"10:30","values":"10:30"},{"name":"11:00","values":"11:00"},{"name":"11:30","values":"11:30"}],
                "close_time_meridian":[{values: "AM", name:"am"}, {values: "PM", name:"pm"}],
                "holiday": [{values:"Open", value:true},{values:"Close", value:false}]
            }
        ]
     },
     {
        [Props.NAME]: "is_specialhours",
        [Props.LABEL]: signupLanguageLabels(language).specialWorkTime,
        [Props.TYPE]: "single_checkbox",
        [Props.REQUIRED]: false,
        [Props.ERROR]: false,
        ["readonly"]:false,
        ["className"]: "",
        [InputProps.PLACEHOLDER]: "What details should customers know about your CSA? (for example, CSA sizes and types available, when your enrollment is open, delivery range if you deliver)"
    },
    {
        [Props.NAME]: "special_hoursOfOperation",
        [Props.LABEL]: "",
        [Props.TYPE]: "special_hoursOfOperation",
        [Props.REQUIRED]: false,
        [Props.ERROR]: false,
        ["readonly"]:false,
        ["fieldArray"]: true,
        ["className"]: "",
        ["specialHoursOperation"]: [
            {
                "status":["Available","Not Available"],
                "week":[{values:"1st",name:"1"},{values:"2nd",name:"2"},{values:"3rd",name:"3"},{values:"4th",name:"4"},{values:"5th",name:"5"} ],
                "day": [{name:"Sunday",values:"Sunday"},{name:"Monday",values:"Monday"}, {name:"Tuesday",values:"Tuesday"},{name:"Wednesday",values:"Wednesday"},{name:"Thursday",values:"Thursday"},{name:"Friday",values:"Friday"},
                {name:"Saturday",values:"Saturday"}],
                "month": [{values:"January",name:"1"},{values:"February",name:"1"},{values:"March",name:"1"},{values:"April",name:"1"},{values:"May",name:"1"},{values:"June",name:"1"},{values:"July",name:"1"},{values:"August",name:"1"},{values:"September",name:"1"},{values:"October",name:"1"},{values:"November",name:"1"},{values:"December",name:"1"}],
                "open_time": [{"name":"12:00","values":"12:00"},{"name":"12:30","values":"12:30"},{"name":"1:00","values":"1:00"},{"name":"1:30","values":"1:30"},{"name":"2:00","values":"2:00"},{"name":"2:30","values":"2:30"},{"name":"3:00","values":"3:00"},{"name":"3:30","values":"3:30"},{"name":"4:00","values":"4:00"},{"name":"4:30","values":"4:30"},{"name":"5:00","values":"5:00"},{"name":"5:30","values":"5:30"},{"name":"6:00","values":"6:00"},{"name":"6:30","values":"6:30"},{"name":"7:00","values":"7:00"},{"name":"7:30","values":"7:30"},{"name":"8:00","values":"8:00"},{"name":"8:30","values":"8:30"},{"name":"9:00","values":"9:00"},{"name":"9:30","values":"9:30"},{"name":"10:00","values":"10:00"},{"name":"10:30","values":"10:30"},{"name":"11:00","values":"11:00"},{"name":"11:30","values":"11:30"}],
                "open_time_meridian":[{values: "AM", name:"am"}, {values: "PM", name:"pm"}],
                "close_time": [{"name":"12:00","values":"12:00"},{"name":"12:30","values":"12:30"},{"name":"1:00","values":"1:00"},{"name":"1:30","values":"1:30"},{"name":"2:00","values":"2:00"},{"name":"2:30","values":"2:30"},{"name":"3:00","values":"3:00"},{"name":"3:30","values":"3:30"},{"name":"4:00","values":"4:00"},{"name":"4:30","values":"4:30"},{"name":"5:00","values":"5:00"},{"name":"5:30","values":"5:30"},{"name":"6:00","values":"6:00"},{"name":"6:30","values":"6:30"},{"name":"7:00","values":"7:00"},{"name":"7:30","values":"7:30"},{"name":"8:00","values":"8:00"},{"name":"8:30","values":"8:30"},{"name":"9:00","values":"9:00"},{"name":"9:30","values":"9:30"},{"name":"10:00","values":"10:00"},{"name":"10:30","values":"10:30"},{"name":"11:00","values":"11:00"},{"name":"11:30","values":"11:30"}],
                "close_time_meridian":[{values: "AM", name:"am"}, {values: "PM", name:"pm"}],
                "time_filter": ["All Day","Custom"]
            }
        ]
     }];

export const sections = [
  {
    id: "csaPickupSite",
    title: signupLanguageLabels(language).csaPickupSite,
    header: signupLanguageLabels(language).csaPickupSite,
    questions: [
        {
            [Props.NAME]: "pickup_site_name",
            [Props.LABEL]: signupLanguageLabels(language).pickup_site_name,
            [Props.TYPE]: Types.INPUT_TEXT,
            [Props.REQUIRED]: false,
            [Props.ERROR]: false,
            // [Props.ERROR_MESSAGE]: signupLanguageLabels(language).emailErrormsg,
            [Props.MAX_LENGTH]:100,
            ["className"]: ""
        },
        {
            [Props.NAME]: "address_1",
            [Props.LABEL]: signupLanguageLabels(language).address1,
            [Props.TYPE]: Types.INPUT_TEXT,
            [Props.REQUIRED]: false,
            [Props.ERROR]: false,
            ["readonly"]:false,
            // [Props.ERROR_MESSAGE]: signupLanguageLabels(language).emailErrormsg,
            [Props.MAX_LENGTH]:100,
            ["className"]: "pure-u-md-1-2 pad-right-half"
        },
        {
            [Props.NAME]: "address_2",
            [Props.LABEL]: signupLanguageLabels(language).address2,
            [Props.TYPE]: Types.INPUT_TEXT,
            [Props.REQUIRED]: false,
            [Props.ERROR]: false,
            ["readonly"]:false,
            // [Props.ERROR_MESSAGE]: signupLanguageLabels(language).emailErrormsg,
            [Props.MAX_LENGTH]:100,
            ["className"]: "pure-u-md-1-2"
        },
        {
            [Props.NAME]: "city",
            [Props.LABEL]: signupLanguageLabels(language).city,
            [Props.TYPE]: Types.SELECT,
            // [SelectProps.OPTIONS]: signupLanguageLabels(language).suffixOption,
            [Props.REQUIRED]: false,
            [Props.ERROR]: false,
            ["readonly"]:false,
            [Props.ERROR_MESSAGE]: "choose suffix",
            ["className"]: "pure-u-md-1-2 pad-right-half"
        },
        {
            [Props.NAME]: "state",
            [Props.LABEL]: signupLanguageLabels(language).state,
            [Props.TYPE]: Types.SELECT,
            [SelectProps.OPTIONS]: ["MA"],
            [Props.REQUIRED]: false,
            [Props.ERROR]: false,
            ["readonly"]:false,
            [Props.ERROR_MESSAGE]: "choose suffix",
            ["className"]: "pure-u-md-1-2"
        },
        {
            [Props.NAME]: "zipcode",
            [Props.LABEL]: signupLanguageLabels(language).zipcode,
            [Props.TYPE]: Types.NUMBERS,
            [Props.REQUIRED]: false,
            [Props.ERROR]: false,
            ["readonly"]:false,
            ["className"]: "pure-u-md-1-2 pad-right-half"
        },
        ...common
    ],
  },
  {
    id: "farmStand",
    title: signupLanguageLabels(language).farmStand,
    header: signupLanguageLabels(language).farmStand,
    questions:
    [
        {
            [Props.NAME]: "address_1",
            [Props.LABEL]: signupLanguageLabels(language).address1,
            [Props.TYPE]: Types.INPUT_TEXT,
            [Props.REQUIRED]: false,
            [Props.ERROR]: false,
            ["readonly"]:false,
            // [Props.ERROR_MESSAGE]: signupLanguageLabels(language).emailErrormsg,
            [Props.MAX_LENGTH]:100,
            ["className"]: "pure-u-md-1-2 pad-right-half"
        },
        {
            [Props.NAME]: "address_2",
            [Props.LABEL]: signupLanguageLabels(language).address2,
            [Props.TYPE]: Types.INPUT_TEXT,
            [Props.REQUIRED]: false,
            [Props.ERROR]: false,
            ["readonly"]:false,
            // [Props.ERROR_MESSAGE]: signupLanguageLabels(language).emailErrormsg,
            [Props.MAX_LENGTH]:100,
            ["className"]: "pure-u-md-1-2"
        },
        {
            [Props.NAME]: "city",
            [Props.LABEL]: signupLanguageLabels(language).city,
            [Props.TYPE]: Types.SELECT,
            // [SelectProps.OPTIONS]: signupLanguageLabels(language).suffixOption,
            [Props.REQUIRED]: false,
            [Props.ERROR]: false,
            ["readonly"]:false,
            [Props.ERROR_MESSAGE]: "choose suffix",
            ["className"]: "pure-u-md-1-2 pad-right-half"
        },
        {
            [Props.NAME]: "state",
            [Props.LABEL]: signupLanguageLabels(language).state,
            [Props.TYPE]: Types.SELECT,
            [SelectProps.OPTIONS]: ["MA"],
            [Props.REQUIRED]: false,
            [Props.ERROR]: false,
            ["readonly"]:false,
            [Props.ERROR_MESSAGE]: "choose suffix",
            ["className"]: "pure-u-md-1-2"
        },
        {
            [Props.NAME]: "zipcode",
            [Props.LABEL]: signupLanguageLabels(language).zipcode,
            [Props.TYPE]: Types.NUMBERS,
            [Props.REQUIRED]: false,
            [Props.ERROR]: false,
            ["readonly"]:false,
            ["className"]: "pure-u-md-1-2 pad-right-half"
        },
        ...common
    ]    
  },
  {
    id: "farmersMarketBooth",
    title: signupLanguageLabels(language).farmersMarketBooth,
    header: signupLanguageLabels(language).farmersMarketBooth,   
    questions: [
        {
            [Props.NAME]: "farmer_market_name",
            [Props.LABEL]: signupLanguageLabels(language).farmer_market_name,
            [Props.TYPE]: Types.SELECT,
            [Props.REQUIRED]: false,
            [Props.ERROR]: false,
            // [Props.ERROR_MESSAGE]: signupLanguageLabels(language).emailErrormsg,
            [Props.MAX_LENGTH]:100,
            ["className"]: ""
        },
        {
            [Props.NAME]: "address_1",
            [Props.LABEL]: signupLanguageLabels(language).address1,
            [Props.TYPE]: Types.INPUT_TEXT,
            [Props.REQUIRED]: false,
            [Props.ERROR]: false,
            ["readonly"]:true,
            // [Props.ERROR_MESSAGE]: signupLanguageLabels(language).emailErrormsg,
            [Props.MAX_LENGTH]:100,
            ["className"]: "pure-u-md-1-2 pad-right-half"
        },
        {
            [Props.NAME]: "address_2",
            [Props.LABEL]: signupLanguageLabels(language).address2,
            [Props.TYPE]: Types.INPUT_TEXT,
            [Props.REQUIRED]: false,
            [Props.ERROR]: false,
            ["readonly"]:true,
            // [Props.ERROR_MESSAGE]: signupLanguageLabels(language).emailErrormsg,
            [Props.MAX_LENGTH]:100,
            ["className"]: "pure-u-md-1-2"
        },
        {
            [Props.NAME]: "city",
            [Props.LABEL]: signupLanguageLabels(language).city,
            [Props.TYPE]: Types.SELECT,
            // [SelectProps.OPTIONS]: signupLanguageLabels(language).suffixOption,
            [Props.REQUIRED]: false,
            [Props.ERROR]: false,
            ["readonly"]:true,
            [Props.ERROR_MESSAGE]: "choose suffix",
            ["className"]: "pure-u-md-1-2 pad-right-half"
        },
        {
            [Props.NAME]: "state",
            [Props.LABEL]: signupLanguageLabels(language).state,
            [Props.TYPE]: Types.SELECT,
            [SelectProps.OPTIONS]: ["MA"],
            [Props.REQUIRED]: false,
            [Props.ERROR]: false,
            ["readonly"]:true,
            [Props.ERROR_MESSAGE]: "choose suffix",
            ["className"]: "pure-u-md-1-2"
        },
        {
            [Props.NAME]: "zipcode",
            [Props.LABEL]: signupLanguageLabels(language).zipcode,
            [Props.TYPE]: Types.NUMBERS,
            [Props.REQUIRED]: false,
            [Props.ERROR]: false,
            ["readonly"]:true,
            ["className"]: "pure-u-md-1-2 pad-right-half"
        },
        ...common
    ]
  },
  {
    id: "mobileMarket",
    title: signupLanguageLabels(language).mobileMarket,
    header: signupLanguageLabels(language).mobileMarket,
    questions:[
        {
            [Props.NAME]: "location_name",
            [Props.LABEL]: signupLanguageLabels(language).location_name,
            [Props.TYPE]: Types.INPUT_TEXT,
            [Props.REQUIRED]: false,
            [Props.ERROR]: false,
            // [Props.ERROR_MESSAGE]: signupLanguageLabels(language).emailErrormsg,
            [Props.MAX_LENGTH]:100,
            ["className"]: ""
        },
        {
            [Props.NAME]: "address_1",
            [Props.LABEL]: signupLanguageLabels(language).address1,
            [Props.TYPE]: Types.INPUT_TEXT,
            [Props.REQUIRED]: false,
            [Props.ERROR]: false,
            // [Props.ERROR_MESSAGE]: signupLanguageLabels(language).emailErrormsg,
            [Props.MAX_LENGTH]:100,
            ["className"]: "pure-u-md-1-2 pad-right-half"
        },
        {
            [Props.NAME]: "address_2",
            [Props.LABEL]: signupLanguageLabels(language).address2,
            [Props.TYPE]: Types.INPUT_TEXT,
            [Props.REQUIRED]: false,
            [Props.ERROR]: false,
            // [Props.ERROR_MESSAGE]: signupLanguageLabels(language).emailErrormsg,
            [Props.MAX_LENGTH]:100,
            ["className"]: "pure-u-md-1-2"
        },
        {
            [Props.NAME]: "city",
            [Props.LABEL]: signupLanguageLabels(language).city,
            [Props.TYPE]: Types.SELECT,
            // [SelectProps.OPTIONS]: signupLanguageLabels(language).suffixOption,
            [Props.REQUIRED]: false,
            [Props.ERROR]: false,
            [Props.ERROR_MESSAGE]: "choose suffix",
            ["className"]: "pure-u-md-1-2 pad-right-half"
        },
        {
            [Props.NAME]: "state",
            [Props.LABEL]: signupLanguageLabels(language).state,
            [Props.TYPE]: Types.SELECT,
            [SelectProps.OPTIONS]: ["MA"],
            [Props.REQUIRED]: false,
            [Props.ERROR]: false,
            [Props.ERROR_MESSAGE]: "choose suffix",
            ["className"]: "pure-u-md-1-2"
        },
        {
            [Props.NAME]: "zipcode",
            [Props.LABEL]: signupLanguageLabels(language).zipcode,
            [Props.TYPE]: Types.NUMBERS,
            [Props.REQUIRED]: false,
            [Props.ERROR]: false,
            ["className"]: "pure-u-md-1-2 pad-right-half"
        },
        ...common
    ]
  },
  {
    id: "farmersMarket",
    title: signupLanguageLabels(language).farmersMarket,
    header: signupLanguageLabels(language).farmersMarket,
    questions: [
        {
            [Props.NAME]: "farmer_market_name",
            [Props.LABEL]: signupLanguageLabels(language).farmer_market_name,
            [Props.TYPE]: Types.SELECT,
            [Props.REQUIRED]: false,
            [Props.ERROR]: false,
            // [Props.ERROR_MESSAGE]: signupLanguageLabels(language).emailErrormsg,
            [Props.MAX_LENGTH]:100,
            ["className"]: ""
        },
        {
            [Props.NAME]: "address_1",
            [Props.LABEL]: signupLanguageLabels(language).address1,
            [Props.TYPE]: Types.INPUT_TEXT,
            [Props.REQUIRED]: false,
            [Props.ERROR]: false,
            ["readonly"]:true,
            // [Props.ERROR_MESSAGE]: signupLanguageLabels(language).emailErrormsg,
            [Props.MAX_LENGTH]:100,
            ["className"]: "pure-u-md-1-2 pad-right-half"
        },
        {
            [Props.NAME]: "address_2",
            [Props.LABEL]: signupLanguageLabels(language).address2,
            [Props.TYPE]: Types.INPUT_TEXT,
            [Props.REQUIRED]: false,
            [Props.ERROR]: false,
            ["readonly"]:true,
            // [Props.ERROR_MESSAGE]: signupLanguageLabels(language).emailErrormsg,
            [Props.MAX_LENGTH]:100,
            ["className"]: "pure-u-md-1-2"
        },
        {
            [Props.NAME]: "city",
            [Props.LABEL]: signupLanguageLabels(language).city,
            [Props.TYPE]: Types.SELECT,
            // [SelectProps.OPTIONS]: signupLanguageLabels(language).suffixOption,
            [Props.REQUIRED]: false,
            [Props.ERROR]: false,
            ["readonly"]:true,
            [Props.ERROR_MESSAGE]: "choose suffix",
            ["className"]: "pure-u-md-1-2 pad-right-half"
        },
        {
            [Props.NAME]: "state",
            [Props.LABEL]: signupLanguageLabels(language).state,
            [Props.TYPE]: Types.SELECT,
            [SelectProps.OPTIONS]: ["MA"],
            [Props.REQUIRED]: false,
            [Props.ERROR]: false,
            ["readonly"]:true,
            [Props.ERROR_MESSAGE]: "choose suffix",
            ["className"]: "pure-u-md-1-2"
        },
        {
            [Props.NAME]: "zipcode",
            [Props.LABEL]: signupLanguageLabels(language).zipcode,
            [Props.TYPE]: Types.NUMBERS,
            [Props.REQUIRED]: false,
            [Props.ERROR]: false,
            ["readonly"]:true,
            ["className"]: "pure-u-md-1-2 pad-right-half"
        },
        ...common
    ]
  }
];

export default sections;
