import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { mapStateToProps } from "../../redux/reducers";
import { connect } from "react-redux";
import { Grid } from '@mui/material';
import { getStoreHistoryList } from '../../redux/actionTypes/vendordetails';
import PageHeaderComponent from "../common/components/pageHeader";
import TableComponent from "../common/components/table";
import SearchComponent from "../common/components/searchComponent";
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import StoreHistoryVendorDetails from './storeHistoryVendorDetails';
import { convertUTCDateToLocalDate } from "../../utils/globalMethods";

function createStoreHistoryData(
    id,
    locationType,
    locationDetails,
    addressDetail,
    siteNameLabel,
    siteName,
    storeAvailFlag,
    lastUpdtUidNam,
    adminLastUpdtTs,
    lastUpdtTs,
    monthsOfOperation,
    orderOperations,
    storeHours,
    specialHoursOfOperation
){
    const isStoreActive = () => {
      if (storeAvailFlag === 'Y') {
        return(<span className="badge badge-success">Show</span>) 
      } else {
      return(<span className="badge badge-danger">Hide</span>)
      }
    }
    return{
        id,
        locationType,
        locationDetails,
        addressDetail,
        siteNameLabel,
        siteName,
        storeAvailFlag:isStoreActive(),
        lastUpdtUidNam, 
        adminLastUpdtTs: adminLastUpdtTs ? convertUTCDateToLocalDate(adminLastUpdtTs) : "-",
        lastUpdtTs: convertUTCDateToLocalDate(lastUpdtTs),
        monthsOfOperation,
        orderOperations,
        storeHours,
        specialHoursOfOperation
    }
  }

  const AuditStoreHistoryComponent = (props) => {
    const {row} = props;
    const [rows, setRows] = useState([]);
    const [size, setSize] = useState(10);
    const [from, setFrom] = useState(0);
    const [search, setSearch] = useState("");
    const [storeHistoryListTotal,setStoreHistoryListTotal] = useState([]);
    const [tableSort, setTableSort] = useState({"lastUpdtTs":"DESC"});
    const [showLoader, setShowLoader] = useState(true);
    const [refreshKey, setRefreshKey] = useState(0);

    const handleNext = (page, rowSize) => {
        (size != rowSize) && setSize(rowSize);
        setFrom(rowSize * page);
      }

      const handleSearch = (e) => {
        setSearch(e.target.value.toLowerCase());
        setFrom(0);
      }

      const handleSort = (item) => {
        let sortitem={}
        if(item.sort=="" || item.sort=="DESC"){
          sortitem[item.id] = "ASC"
        }else{
          sortitem[item.id] = "DESC"
        }
        setTableSort(sortitem)
      }
      
    useEffect(() => {
      const payload = {
      "vendorId": row.id,
      "lastUpdtUidNam": search.trim(),
      "size": size,
      "from": from,
      "lastUpdtTsSort": "lastUpdtTs" in tableSort ? tableSort["lastUpdtTs"]:"",
      "adminLastUpdtTsSort":"adminLastUpdtTs" in tableSort ? tableSort["adminLastUpdtTs"]:"",
      }
      props.getStoreHistoryList(payload);
    }, [size, from, search, refreshKey,tableSort]);
  
    useEffect(() => {
      const { storeHistoryList } = props.vendorDetails;
      console.log(storeHistoryList);
      let storeHistoryListData = [];
      setRows([]);
      setTimeout(()=>{
        storeHistoryList?.list?.forEach(element => {
            let locationType;
            let siteNameLabel;
            
            if (element.farmStand==='Y') {
                locationType = 'HIP Farm Stand';
                siteNameLabel = 'Farm Stand Name';
            } else if (element.farmersMarket ==='Y') {
                locationType = 'HIP Farmers\' Market Booth';
                siteNameLabel = 'Farmers\' Market Name';
            }else if (element.farmersMarketManagers ==='Y') {
                locationType = 'HIP Farmers Market';
                siteNameLabel = 'Farmers\' Market Name';
            } else if (element.mobileMarket ==='Y') {
                locationType = 'HIP Mobile Market Stop';
                siteNameLabel = 'Mobile Market Stop Name';
            }else if (element.csaPickupSite === 'Y'){
                locationType='HIP CSA Program'
                siteNameLabel = 'Pickup Site Name';
            }else {
                locationType = '';
                siteNameLabel = '';
            }

            var locationDetails = element.addressDetail.addressLine1 + ", " + element.addressDetail.city + ", " +  
            element.addressDetail.state + ", " + element.addressDetail.zipcode;

            var monthsOfOperation = [];

            
            if (element.storeOperationMonth.janOperation ==='Y'){ 
                monthsOfOperation.push("January");
            }
            if (element.storeOperationMonth.febOperation ==='Y'){
                monthsOfOperation.push("February");
            }
            if (element.storeOperationMonth.marOperation ==='Y'){
                monthsOfOperation.push("March");
            }
            if (element.storeOperationMonth.aprOperation ==='Y'){
                monthsOfOperation.push("April");
            }
            if (element.storeOperationMonth.mayOperation ==='Y'){
                monthsOfOperation.push("May");
            }
            if (element.storeOperationMonth.junOperation ==='Y'){
                monthsOfOperation.push("June");
            }
            if (element.storeOperationMonth.julOperation ==='Y'){
                monthsOfOperation.push("July");
            }
            if (element.storeOperationMonth.augOperation ==='Y'){
                monthsOfOperation.push("August");
            }
            if (element.storeOperationMonth.sepOperation ==='Y'){
                monthsOfOperation.push("September");
            }
            if (element.storeOperationMonth.octOperation ==='Y'){
                monthsOfOperation.push("October");
            }
            if (element.storeOperationMonth.novOperation ==='Y'){
                monthsOfOperation.push("November");
            }
            if (element.storeOperationMonth.decOperation ==='Y'){
                monthsOfOperation.push("December");
            }

            var orderOperations = [];
            if (element.storeOrderTypeDetail.delivery ==='Y'){
                orderOperations.push("Delivery");
            }
            if (element.storeOrderTypeDetail.curbsidePickup ==='Y'){
                orderOperations.push("Curbside pickup");
            }
            if (element.storeOrderTypeDetail.onlinePreselection ==='Y'){
                orderOperations.push("Online pre-selection");
            }
            if (element.storeOrderTypeDetail.phonePreselection ==='Y'){
                orderOperations.push("Phone pre-selection");
            }
            if (element.storeOrderTypeDetail.inPerson ==='Y'){
                orderOperations.push("In person");
            }
            if (element.storeOrderTypeDetail.otherType ==='Y'){
                orderOperations.push("Other");
            }
            var storeHours = [...element.storehours];

            var dayInfo = [];
            var monthInfo = [];
            
            var specialHoursOfOperation = {};

            if (element.storeSpecialOperation.length>0){

                if (element.storeSpecialOperation[0].dayInfo.includes('02')){
                    dayInfo.push("Monday");
                }
                if (element.storeSpecialOperation[0].dayInfo.includes('03')){
                    dayInfo.push("Tuesday");
                }
                if (element.storeSpecialOperation[0].dayInfo.includes('04')){
                    dayInfo.push("Wednesday");
                }
                if (element.storeSpecialOperation[0].dayInfo.includes('05')){
                    dayInfo.push("Thursday");
                }
                if (element.storeSpecialOperation[0].dayInfo.includes('06')){
                    dayInfo.push("Friday");
                }
                if (element.storeSpecialOperation[0].dayInfo.includes('07')){
                    dayInfo.push("Saturday");
                }
                if (element.storeSpecialOperation[0].dayInfo.includes('01')){
                    dayInfo.push("Sunday");
                }
                
                if (element.storeSpecialOperation[0].monthInfo.includes('1')){
                    monthInfo.push("January");
                }
                if (element.storeSpecialOperation[0].monthInfo.includes('2')){
                    monthInfo.push("February");
                }
                if (element.storeSpecialOperation[0].monthInfo.includes('3')){
                    monthInfo.push("March");
                }
                if (element.storeSpecialOperation[0].monthInfo.includes('4')){
                    monthInfo.push("April");
                }
                if (element.storeSpecialOperation[0].monthInfo.includes('5')){
                    monthInfo.push("May");
                }
                if (element.storeSpecialOperation[0].monthInfo.includes('6')){
                    monthInfo.push("June");
                }
                if (element.storeSpecialOperation[0].monthInfo.includes('7')){
                    monthInfo.push("July");
                }
                if (element.storeSpecialOperation[0].monthInfo.includes('8')){
                    monthInfo.push("August");
                }
                if (element.storeSpecialOperation[0].monthInfo.includes('9')){
                    monthInfo.push("September");
                }
                if (element.storeSpecialOperation[0].monthInfo.includes('10')){
                    monthInfo.push("October");
                }
                if (element.storeSpecialOperation[0].monthInfo.includes('11')){
                    monthInfo.push("November");
                }
                if (element.storeSpecialOperation[0].monthInfo.includes('12')){
                    monthInfo.push("December");
                }
            

                specialHoursOfOperation =
                {
                    status : element.storeSpecialOperation[0].specialStatusDescription,
                    week : element.storeSpecialOperation[0].weekInfo,
                    dayInfo : [...dayInfo],
                    monthInfo : [...monthInfo],
                    time : element.storeSpecialOperation[0].startTime + " - " + element.storeSpecialOperation[0].endTime
                };
            }
                
            storeHistoryListData.push(createStoreHistoryData(
                element.id, 
                locationType,
                locationDetails,
                element.addressDetail,
                siteNameLabel,
                element.siteName,
                element.storeAvailFlag,
                element.lastUpdtUidNam,
                element.adminLastUpdtTs,
                element.lastUpdtTs,
                monthsOfOperation,
                orderOperations,
                storeHours,
                specialHoursOfOperation
            ))
        });
        setRows(storeHistoryListData);
      },300);
      setStoreHistoryListTotal(storeHistoryList.total);
      setShowLoader(storeHistoryList.loading);
    }, [props.vendorDetails.storeHistoryList]);

    const storeHistoryColumns = [
        { 
            id: 'details', label: 'Details' 
        },
        {
            id: 'locationType', label: 'Location Type'
         },
        {
            id: 'locationDetails', label: 'Location Details',
            cell: (item) => {
                const addressDetail = item?.addressDetail;
                const address = [
                addressDetail?.addressLine1,
                addressDetail?.addressLine2,
                addressDetail?.city,
                addressDetail?.state,
                addressDetail?.zipcode
                ].filter(part => typeof part !== "undefined").join(",");

                return (
                <>
                    <div>{address}</div>
                </>
                );
            }
        },
        {
            id: 'storeAvailFlag', label: 'Status'
         },
        {
            id: 'lastUpdtUidNam',label: 'Last Updated By',
        },
        {
            id: 'lastUpdtTs',label: 'Vendor Last Updated Time',
            sort:"lastUpdtTs" in tableSort ? tableSort["lastUpdtTs"]:"",
        },
        {
            id: 'adminLastUpdtTs',label: 'Admin Last Updated Time',
            sort:"adminLastUpdtTs" in tableSort ? tableSort["adminLastUpdtTs"]:"",
         },
    ];
        
    const renderDetails = (row) => {
        return (<StoreHistoryVendorDetails 
        row= {row}
        />)
    }

    return(
        <Grid container className="container-block" rowSpacing={2}>
        <Grid item xs={6}>
          <PageHeaderComponent
            title={"STORE HISTORY"}
            showSubtitle={true}
            subtitle={"Total"}
            count={storeHistoryListTotal}
          />
        </Grid>
         <Grid item xs={6} sx={{ flexGrow: 1 }}>
        <Stack direction="row" spacing={0.5} justifyContent="flex-end">
        <Button variant="outlined" sx={{ height: '38px', border: "1px solid #006e52", textTransform:'capitalize', color: '#006e52', ':hover': { backgroundColor: '#006e52', color: 'white' }}} startIcon={<i className="fa fa-refresh" aria-hidden="true"></i>} onClick={() => {
            setRefreshKey(refreshKey+1);
            setShowLoader(true);
            setSearch("");
            }}>
              Refresh
        </Button>
        </Stack>
    </Grid>
    <Grid item xs={12} sx={{ flexGrow: 1 }} >
          <SearchComponent 
            placeholder={"Last Updated By"}
            handleChange={handleSearch}
            searchText={search}
          />
    </Grid>
    <Grid item xs={12}>
        <TableComponent 
        columns={storeHistoryColumns}
        rows = {rows}
        showDetails = {true}
        renderDetails = {renderDetails}
        handlePagination={handleNext}
        total={storeHistoryListTotal}
        handleSort={handleSort}
        page={Math.floor(from/size)}
        rowsPerPage={size}
        dataLoading={showLoader}
        />
        </Grid>
</Grid>
    )
  }

  AuditStoreHistoryComponent.propTypes = {
    vendorDetails:PropTypes.object,
    getStoreHistoryList: PropTypes.func.isRequired,
    row: PropTypes.object,
    // onAudit: PropTypes.bool,
    // getVendorInfo: PropTypes.func,
    // getVendorInfoList:PropTypes.func.isRequired,
  };
  
  function mapDispatchToProps(dispatch) {
    return {
      getStoreHistoryList:(payload) => dispatch(getStoreHistoryList(payload)),
    //  getVendorInfoList:(payload) => dispatch(getVendorInfoList(payload)),
    };
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(AuditStoreHistoryComponent);