import React from "react";
// import Header from "./common/header/Header";
// import Footer from "./common/footer/Footer";
// import { connect } from "react-redux";
// import { GET_USERS } from "../../redux/actionTypes";
// import PropTypes from "prop-types";
// import { mapStateToProps } from "../../redux/reducers";
import SearchComponent from "./search-component";

// import MapComponent from "./MapComponent";
const DashboardComponent = () => {
 
  return (
    <>
      <div className="container-fluid bg-lightgreen map-area">
        <SearchComponent  /> 
        {/* <MapComponent /> */}
      </div>
    </>
  );
};

// const mapDispatchToProps = (dispatch) => {
//     return {
//         getUsers: () => dispatch({type: GET_USERS}),
//     };
// };

// DashboardComponent.propTypes = {
//     // users: PropTypes.array.isRequired,
//     getUsers: PropTypes.func.isRequired,
// };

export default DashboardComponent;