import React, { useState, useEffect } from "react";
import { useLocation,useNavigate } from 'react-router-dom'; 
import { Row, Col, Container, Tabs, Tab, Badge } from "react-bootstrap";
import VendorDetailsTab from "./tabs/vendor";
import PublicDetailsTab from "./tabs/public";
import { mapStateToProps } from "../../redux/reducers";
import { startOfDay } from 'date-fns'
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
    GET_USER_DETAILS,
    GET_VENDOR_LOC_DETAILS,
    getVendorInfoList,
    getMyStoreDetails,
    updateVendorBulk,
    verifyVendor,
    removeVendorStore
} from "../../redux/actionTypes/vendordetails";
import VendorLocDetails from "./vendor-loc-details";
import { useSearchParams } from "react-router-dom";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import { GET_COUNTYCITY }   from "../../redux/actionTypes";
import { getFarmersMarket } from "../../redux/actionTypes/administration";
import "../../styles/registration.scss";
import ModalComponent from "../common/components/modal";
import CommentModal from "./commentModal";
import VerifyVendorComponent from "./verifyVendor";
import {popUpAlert, pageRefresh, convertUTCDateToLocalDate, CalculateUTCTzToUserTz, CalculateDaysBwTwoDates} from "../../utils/globalMethods";

let language = localStorage.getItem("selectedLanguage");
if (language === null) {
    language = "en-US";
}


const Mystore = (props) => { 
    const navigate = useNavigate();   
    const { state } = useLocation();
    const [key, setKey] = useState("vendor");
    const [searchParams] = useSearchParams();
    let vendorId = searchParams.get("vendor_id");
    const [vendorInfo, setVendorInfo] = useState({});
    const [userRole, setUserRole] = useState('');
    const [storeDetails, setStoreDetails] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [showVerifyModal, setShowVerifyModal] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [init, setInit] = useState(true);
    const [farmersMarketData, setFarmersMarketData] = useState(null);
    if(!vendorId && props.userAuth.user.vendorId){
        vendorId = props.userAuth.user.vendorId;
    }

    useEffect(() => {
        const userInfo = props?.userAuth?.user ? props?.userAuth?.user : JSON.parse(sessionStorage.getItem("userData"));
        if (props?.allowedRoles && userInfo && userInfo !== null && Object.keys(userInfo).length != 0) {
            const checkRole = props.allowedRoles.find((role) => userInfo?.Role.includes(role));
            if (!checkRole) {
                navigate("/dtahip");
                return;
            }
        }
        props.getCityList();
        const farmersMarketPayload = {
            "name": "",
            "size": 5000,
            "from": 0
        }
        props.getFarmersMarket(farmersMarketPayload);
        const payload = {
            "vendorId": state?.hipVendorIdk ? state?.hipVendorIdk : vendorId
        }
        props.getMyStoreDetails(payload);
        props.getVendorInfoList(payload);
    }, [props.administration.refreshKey]);

    useEffect(() => {

        if(props.administration.farmersMarket.list){
            const farmerMarketObj = props.administration.farmersMarket.list;
            farmerMarketObj.forEach(item => {
                item.name = item.name.replace("&#38;", "'");
            });
            setFarmersMarketData(farmerMarketObj)
        }

    },[props.administration.farmersMarket.list])
    
    useEffect(() => {
        setVendorInfo(props.vendorDetails.vendorInfoList);
        setIsActive(props?.vendorDetails?.vendorInfoList?.isActive === "Y" ? true : false);
        const userData = props?.userAuth?.user ? props?.userAuth?.user : JSON.parse(sessionStorage.getItem("userData")); //JSON.parse(sessionStorage.getItem("userData"));
        setUserRole(userData.Role);
        if (props.vendorDetails.vendorInfoList.lastUpdtTs && userRole === "VENDOR") {
            const days = CalculateDaysBwTwoDates(CalculateUTCTzToUserTz(props.vendorDetails.vendorInfoList.lastUpdtTs),startOfDay(new Date()));
            if (days >= 75) {
                if (init && props.vendorDetails.vendorInfoList) {
                    popUpAlert({
                        title: "",
                        html: `<p>Time to review! Your location(s) and contact info have not been updated in ${days} days.</p><p style='margin-bottom:0 !important;'>You need to update or confirm your info.</p>`,
                        icon: "warning",
                        showCancelButton: false,
                        showConfirmButton: true,
                        confirmButtonClass: "btn-danger",
                        confirmButtonText: "Continue",
                        closeOnConfirm: false,       
                    });
                    setInit(false);
                }
            }
        }
    }, [props.vendorDetails.vendorInfoList]);

    const renderModal = () => {
        return (<CommentModal vendorId={vendorInfo.vendorId}/>)
    }

    useEffect(() => {
        setStoreDetails(props.vendorDetails.myStoreDetailsList);
    }, [props.vendorDetails.myStoreDetailsList]);
    
    const verifyVendorSubmit = (data) => {
        let payload = {
            "vendorId": vendorInfo.vendorId,
            "staffName": data.staffName,
            "vendorVerfNotes": data.vendorVerfNotes,
            "isVerified": "Y",
            "isActive": "Y",
            "mail_obj": {
                "primaryEmail": vendorInfo.primaryEmail,
                "firstName": vendorInfo.firstName,
                "lastName": vendorInfo.lastName
            }
        }
        props.verifyVendor(payload);
        setShowVerifyModal(false);
      };

    const renderVerifyModal = () => {
        return (<VerifyVendorComponent verifyVendorSubmit={verifyVendorSubmit}/>)
    }

    const handleActivate = (e) => {
        popUpAlert({
            title: "Are you sure?",
            text: `Your location will ${e ? "" : "not"} be shown in the map`,
            icon: "warning",
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonClass: "btn-danger",
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            closeOnConfirm: false,
            onConfirmed: true,
            handleDismiss: () => {
                setVendorInfo(props.vendorDetails.vendorInfoList);
                setIsActive(props?.vendorDetails?.vendorInfoList?.isActive === "Y" ? true : false)
            },
            handleConfirm:() => {
                let payload = [];
                payload.push({
                    id: vendorInfo.vendorId,
                    isActive: e ? "Y" : "N",
                    lastUpdateTime:new Date().getTime(),
                    primaryEmail: vendorInfo.primaryEmail
                });
                props.updateVendorBulk(payload);
            },
        });
    }

    const handleDeleteStore = (data) => {
        if (data) {
            const payload = {
                "vendorId": data?.name?.vendorId,
                "csaPickupSite": data?.name?.csaPickupSite,
                "farmStand": data?.name?.farmStand,
                "farmersMarket": data?.name?.farmersMarket,
                "farmersMarketManagers": data?.name?.farmersMarketManagers,
                "mobileMarket": data?.name?.mobileMarket,
                "storeId": data?.name?.id,
            }
            props.removeVendorStore(payload);       
        }
    }

    return (
        <div style={{ marginTop: "25px" }}>
            <Container fluid>
                {showModal &&
                    <ModalComponent
                        shown = {showModal}
                        close = {() => {
                            setShowModal(false);
                        }}
                     
                        title = {"COMMENT(S)"}
                        modalBody = {renderModal}
                        size ={"lg"}
                        id={"comment-modal"}
                    />
                }
                {showVerifyModal &&
                    <ModalComponent
                        shown = {showVerifyModal}
                        close = {() => {
                            setShowVerifyModal(false);
                        }}
                        title = {"VERIFY VENDOR"}
                        modalBody = {renderVerifyModal}
                        size ={"md"}
                    />
                }
                <Row>
                    <Col md={6} xs={12} sm={9} xl={6} lg={6}>
                        <span className="resText">VENDOR STORE</span>
                        {vendorInfo.isVerified==="Y" ? <><Badge style={{ marginLeft: "20px" }} bg="success">{<><i className="fa fa-check"></i><span className="m-l-5">Verified</span></>}</Badge>
                                <small className="text-muted font-8" > <i className="fa fa-clock-o"></i> {convertUTCDateToLocalDate(vendorInfo.verifiedTs)}</small></>:
                            <Badge style={{ marginLeft: "20px" }} bg="danger">{<><i className="fa fa-check"></i><span className="m-l-5">Pending Verification</span></>}</Badge>}
                    </Col>
                    <Col md={6} xs={12} sm={3} xl={6} lg={6} className="sm-mt" style={{ textAlign: "right" }}>
                        {(userRole == "ADMIN" || userRole == "HIPCRD") && <button aria-label="button" className="btn link" onClick={() => setShowModal(true)}><i className="fa fa-commenting-o"></i> Add Comment</button>}
                        {(userRole == "ADMIN" || userRole == "HIPCRD") && Object.keys(vendorInfo).length ? vendorInfo.isVerified==="Y" ? <small className="text-muted" > <i className="fa fa-user"></i> Vendor Status : 
                            <BootstrapSwitchButton
                                    checked={isActive}
                                    onlabel="Active"
                                    offlabel="Inactive"
                                    width={78}
                                    style={"switch-margin"}
                                    onstyle="success"
                                    offstyle="danger"
                                    onChange={(e)=>{
                                        setVendorInfo({isActive: e ? "Y":"N", ...vendorInfo});
                                        handleActivate(e);
                                        setIsActive(e);
                                    }}
                                />
                            </small> : <button aria-label="button" className="btn btn-primary mr-1" onClick={() => setShowVerifyModal(true)}><i className="fa fa-check"></i> Verify</button>: ""}
                         <button aria-label="button" className="btn btn-outline-primary sm-mt" onClick={pageRefresh}><i className="fa fa-refresh"></i> Refresh</button>
                    </Col>
                </Row>
                <Row style={{ marginTop: "10px" }}>
                    <Col>
                        <Tabs 
                        
                            id="controlled-tab"
                            activeKey={key}
                            onSelect={(k) => setKey(k)}
                            style={{background:"#fff"}}
                          
                            >
                            <Tab  eventKey="vendor" title={<div className="tab-header"><i className="fa fa-user"></i><span className="m-l-5">Vendor Details</span></div>}>
                                {vendorId && (
                                    <VendorDetailsTab
                                        vendorInfo={vendorInfo}
                                        userId={props.userAuth.user.id}
                                    />
                                )}
                            </Tab>
                            <Tab  eventKey="public" title={<div className="tab-header"><i className="fa fa-eye"></i><span className="m-l-5">Public Info</span></div>}>
                                {vendorId && (
                                    <PublicDetailsTab 
                                        vendorInfo={vendorInfo}
                                        userId={props.userAuth.user.id}
                                    />
                                )}
                            </Tab>
                        </Tabs>
                    </Col>
                </Row>
                <Row style={{ marginTop: "10px" }}>
                    <Col>
                        <VendorLocDetails
                            myStoreDetails={'data' in storeDetails ? storeDetails.data : []}
                            isLoading={('isLoading' in storeDetails && 'vendorId' in vendorInfo) ? storeDetails.isLoading : true}
                            vendorInfo={vendorInfo}
                            farmersList={farmersMarketData}
                            city={props.mapdata.city}
                            deleteStore={handleDeleteStore}  
                        />
                    </Col>
                </Row>
            </Container>
        </div>
    );
};
Mystore.propTypes = {
    vendorDetails: PropTypes.object,
    administration: PropTypes.object,
    mapdata:PropTypes.object,
    getUserDetails: PropTypes.func,
    // vendorLocDetails: PropTypes.object,
    getVendorLocDetails: PropTypes.func,
    getVendorInfoList: PropTypes.func,
    getMyStoreDetails: PropTypes.func,
    getCityList: PropTypes.func.isRequired,
    getFarmersMarket: PropTypes.func.isRequired,
    userAuth:PropTypes.object,
    updateVendorBulk: PropTypes.func.isRequired,
    verifyVendor: PropTypes.func.isRequired,
    removeVendorStore: PropTypes.func,
    refresh: PropTypes.func,
    allowedRoles: PropTypes.object
};
const mapDispatchToProps = (dispatch) => {
    return {
        getVendorInfoList:(payload) => dispatch(getVendorInfoList(payload)),
        getMyStoreDetails:(payload) => dispatch(getMyStoreDetails(payload)),
        removeVendorStore:(payload) => dispatch(removeVendorStore(payload)),
        verifyVendor:(payload) => dispatch(verifyVendor(payload)),
        getUserDetails: (vendorId) => {
            dispatch({ type: GET_USER_DETAILS, value: vendorId });
        },
        getVendorLocDetails: (vendorId) => {
            dispatch({ type: GET_VENDOR_LOC_DETAILS, value: vendorId });
        },
        getCityList: (city_county) => {
            dispatch({ type: GET_COUNTYCITY, city:city_county });
        },
        getFarmersMarket: (payload) => dispatch(getFarmersMarket(payload)),
        updateVendorBulk:(payload) => dispatch(updateVendorBulk(payload)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Mystore);

// export default Mystore;