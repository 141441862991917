import React, { useState, Fragment, useEffect, useCallback } from "react"; // , useEffect
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useForm, useFieldArray } from "react-hook-form"; //, Controller
// import { bindActionCreators } from "redux";
import { NavLink } from "react-router-dom";
import SignupForm from "./signup-form";
import getBase64 from "./getbase64";
import { ProgramSectionData } from "./data/program-section-format";
import {
  SET_USER,
  PUT_USER,
  GET_USER,
  EDIT_USER,
  SET_PROGRAM,
  GET_ORDER_OPTION, GET_MONTH_OPTION, GET_DAY_OPTION, clearRegistrationStatus
} from "../../../redux/actionTypes/registration";
import SlideToggle from "react-slide-toggle";
import { mapStateToProps } from "../../../redux/reducers";
import { signupLanguageLabels } from "./data/signup-multiLanguage-labels";
import SidebarNavigation from "../../common/sidebar/sidebar-navigation";
import { GET_LOC_TYPE, GET_LOC_TYPE_QUES } from "../../../redux/actionTypes/dropdown";
import sections from "./data/signup-section";
import { locTypeProgramQuestionsSections } from "./signup-actions";
import styled from "styled-components";
import { GET_COUNTYCITY } from "../../../redux/actionTypes";
import { sortName, isNumber, popUpAlert, maskMobile } from "../../../utils/globalMethods";
import { getFarmersMarket } from "../../../redux/actionTypes/administration";
import CommonLoading from "../../common/components/commonLoading";
import "../../../styles/registration.scss";


const StyledImageUpload = styled.div`
height: inherit;
width: inherit;
`;

const StyledImage = styled.img`
height: 180px;
object-fit: contain;
width: 310px;
`;

let language = localStorage.getItem("selectedLanguage");
if (language === null) {
  language = "en-US";
}

const dynamicMenuList = [{ id: "csaPickupSite", menu: "menuStep4" },
{ id: "farmStand", menu: "menuStep5" },
{ id: "mobileMarket", menu: "menuStep6" },
{ id: "farmersMarketBooth", menu: "menuStep7" },
{ id: "farmersMarket", menu: "menuStep8" }];

const locationTypeCollection = [
  {
    id: "csaPickupSite",
    value: "HIP CSA Program"
  }, {
    id: "farmStand",
    value: "HIP Farm Stand"
  }, {
    id: "farmersMarketBooth",
    value: "HIP Farmers Market Booth"
  }, {
    id: "farmersMarket",
    value: "HIP Farmers Market"
  }, {
    id: "mobileMarket",
    value: "HIP Mobile Market Stop"
  }
];

let staticMenu = [];

function UserRegistrationComponent(props) { 
  // const [successMsg, setSuccessMsg] = useState("");
  //   const [loadingStatus, setLoadingStatus] = useState(false); //console.log(loadingStatus);
  
  const [selectedImage, setSelectedImage] = useState({ file: null, base64URL: "" });
  const [locType, setLocType] = useState({
    "csaPickupSite": false,
    "farmStand": false,
    "farmersMarketBooth": false,
    "mobileMarket": false,
    "farmersMarket": false
  });
  const [isDisblOthrChkbx, setIsDisblOthrChkbx] = useState(false);
  const [isDisblMrkMngrChkbx, setIsDisblMrkMngrChkbx] = useState(false);
  const [activeTab, setActiveTab] = useState("menuStep1");
  const [activeTabId, setActiveTabId] = useState();
  const [enableMenuList, setEnableMenuList] = useState([]);
  const [newmenus, setNewMenus] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [fnsNumber, setFnsNumber] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState("");
  const [isLoading, setIsLoading]=useState(false);


  staticMenu = [
    {
      label: signupLanguageLabels(language).basicReg,
      className: "list-group-item menu-list",
      liClassName: "",
      id: "menuStep1",
      name: "basic_reg",
      activeTab: activeTab,
    },
    {
      label: signupLanguageLabels(language).publicInfo,
      className: "list-group-item menu-list",
      liClassName: "disabled",
      id: "menuStep2",
      name: "public_info",
      activeTab: activeTab,
    },
    {
      label: signupLanguageLabels(language).locType,
      className: "list-group-item menu-list disabled",
      id: "menuStep3",
      name: "loc_type",
      liClassName: "disabled",
      activeTab: activeTab,
    }];

  useEffect(() => {
    props.getCityList();
    const payload = {
      "name": "",
      "size": 5000,
      "from": 0
    }
    props.getFarmersMarket(payload);
    props.locTypeProgramQuestionsSections(sections);
  }, []);



  useEffect(() => {
    if (props.user.registrationResponse && props.user.registrationResponse.status === "success") {
      setActiveTab("menuStep2");
      props.clearRegistrationStatus();
      popUpAlert({
        title: "Successfully",
        text: "Basic registration completed successfully!",
        icon: "success",
        timer:1500,
        showCancelButton: false,
        showConfirmButton: false,
      });
    }else if(props.user.registrationResponse && props.user.registrationResponse.status === "error"){
      popUpAlert({
        title: "Failed!",
        text: props.user.registrationResponse.message,
        icon: "error",
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonClass: "btn-danger",
        confirmButtonText: "Ok!",
        closeOnConfirm: false,
      });
      props.clearRegistrationStatus();
    }
    if (enableMenuList) {
      let dummy = [];
      dummy = locationTypeCollection.filter((item) => enableMenuList.includes(item.id));
      dummy = dummy.map((item, i) => {
        let menus = {
          id: item.id,
          menu: `menuStep${i + 4}`,
          values: locationTypeCollection.filter((val) => val.id === item.id)
        };
        return menus;
      });
      setNewMenus(dummy);
    }
  }, [props.user.registrationResponse, enableMenuList]);
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      vendorName: "",
      primaryEmail: "",
      primaryMobile: "",
      password: "",
      confirm_password: "",
      businessDesc: "",
      fnsNum: [{ id: 0, name: "" }],
      products: "",
      publicEmail: "",
      publicMobile: "",
      publicWebsite: "",
      additionalContactInfo: "",
      publicPhoto: "",
    },
  });

  const {
    register: register2,
    // formState: { errors: errors2 },
    handleSubmit: handleSubmit2,
    getValues,
    // control,
    // watch,
    // formState: { errors },
  } = useForm({
    defaultValues: locType
  });

  const [mobile, setMobile] = useState("");
  const [publicMobile, setPublicMobile] = useState("");
  const { fields, append, remove } = useFieldArray({
    control,
    name: "fnsNum"
  });
  const watchFieldArray = watch("fnsNum");
  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index],
    };
  });

  const onBasicSubmit = (data) => {
    const timeStamp = new Date().getTime();
    if (data.fnsNum && data.fnsNum.length > 0) {
      data.fnsNum = data.fnsNum.map(item => item.name).toString()
    }
    if (data.primaryMobile) {
      data.primaryMobile = data.primaryMobile.replace(/\D/g, '')
    }
    data.password= "Welcome123";
    data.confirm_password= "Welcome123";
    data = Object.assign(data, {
      "role": "VENDOR",
      "isVerified": "N",
      "isActive": "N",
      "forceLogin": "N",
      "createTimeStamp": timeStamp,
      submitForm: "Vendor"
    });
    props.addUser(data);
  };

  const onPublicInfoSubmit = (data) => {
    if (data.fnsNum && data.fnsNum.length > 0) {
      data.fnsNum = data.fnsNum.map(item => item.name).toString()
    }
    data.password= "Welcome123";
    data.confirm_password= "Welcome123";

    const userData = props.user.userinfo;
    let lastUpdtUidNam = "VDR_"+userData.userId;
    const timeStamp = new Date().getTime();
    const systemInfo = sessionStorage.getItem("systemInfo");
    const systemData = JSON.parse(systemInfo);
    data = Object.assign(data, {
      "role": "VENDOR",
      "isVerified": "N",
      "isActive": "N",
      "forceLogin": "N",
      "userId": userData.userId,
      "id": userData.id,
      "primaryMobile": data.primaryMobile.replace(/\D/g, ''),
      "publicMobile": data.publicMobile.replace(/\D/g, ''),
      "lastUpdtUidNam":lastUpdtUidNam,
      "createTimeStamp": timeStamp,
      "lastUpdtTimeStamp": timeStamp,
      // "loginId": userData.userId,
      "ipAddress": systemData.ip,
      submitForm: "Public"
    });
    data.publicPhoto = selectedImage.base64URL ? selectedImage.base64URL : "";
    props.updateUser(data);
    setActiveTab("menuStep3");
  };

  const onLocTypeSubmit = () => {
    setActiveTab(newmenus?.[0]?.menu);
    setActiveTabId(newmenus?.[0]?.id);
  };

  const onSaveAndNext = () => {
    const menuIndex = newmenus?.findIndex((list) => list?.menu === activeTab);
    if (menuIndex >= 0) {
      setActiveTab(newmenus[menuIndex + 1]?.menu);
      setActiveTabId(newmenus[menuIndex + 1]?.id);
    }
  };
  const [addMore, setAddMore] = useState(1);
  const handleAddFields = () => {
    setAddMore(addMore + 1)
    append({ name: "" });
    
  };
 

  const handleRemove = (i) => {
    setAddMore(addMore - 1)
    remove(i);
  }

  const getLocType = (e) => {
    const values = getValues();
    values[e.target.name] = e.target.value === "true" || e.target.value === true ? true : false;
    setLocType(values);
    if (e.target.name === "farmersMarket") {
      if (e.target.value === true || e.target.value === "true") {
        setIsDisblOthrChkbx(true);
        setIsDisblMrkMngrChkbx(false);
      } else {
        setIsDisblOthrChkbx(false);
        setIsDisblMrkMngrChkbx(false);
        setEnableMenuList([]);
      }
      setEnableMenuList([e.target.name]);
    } else {
      const menuItem = Object.entries(values).filter(([, v]) => (v === true || v === "true")).map(([k]) => k);
      if (menuItem.length > 0 && menuItem.indexOf("farmersMarket") === -1) {
        setIsDisblMrkMngrChkbx(true);
      } else {
        setIsDisblMrkMngrChkbx(false);
      }
      if (e.target.value === true || e.target.value === "true") {
        setIsDisblMrkMngrChkbx(true);
      }
      setEnableMenuList(menuItem);
    }
  };

  const getSelectedSection = (id) => {
    const section = sections.find(item => item.id === id);
    return section;
  };


  const handleFileInputChange = useCallback((e) => {
    let { file } = selectedImage;
    file = e.target.files[0];
    getBase64(file)
      .then((result) => {
        file["base64"] = result;
        setSelectedImage({
          base64URL: result,
          file
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, [selectedImage]);

  const addProgramData = (data) => {
    props.addProgram({ vendorStores: data });
    setIsLoading(true);
    // if (type === "finish") {
    //   navigate("/");
    // } else {
    //   onSaveAndNext();
    // }
  };
  // const handleChange = (event) => {
  //   const inputValue = event.target.value;
  //   setFnsNumber(inputValue);

  //   if (/^\d{0,6}$/.test(inputValue)) {
  //     setError("FNS number is required");
  //   } else {
  //     setError("");
  //   }
  // };
  useEffect(()=>{
    if (props?.user?.program && props?.user?.program?.code === "200") {
      setIsLoading(false);
    }
  },[props?.user])

  const cityList = sortName(props.mapdata.city.map((item) => ({ "name": item.cityName, "values": item.cityName })));

  return (
    <div className="container-fluid ven-reg-pg">
    { isLoading && 
      <CommonLoading />
    }
      <div className="row">
        <div className="col-md-12">
          {/* {props.mode === "add" && ( */}
          <div className="row">
            <div className="col-md-12">
              <h3 className="resText">
                {signupLanguageLabels(language).reg}
              </h3>
              <SlideToggle
                duration={600}
                collapsed={true}
                render={({ toggle, setCollapsibleElement, progress }) => (
                  <>
                    <p>
                      {signupLanguageLabels(language).regCnt}
                      <NavLink
                        to="#"
                        className="moreless-button"
                        onClick={toggle}
                      >
                        {signupLanguageLabels(language).needHelp}
                      </NavLink>
                    </p>
                    <div
                      style={{ display: "none" }}
                      ref={setCollapsibleElement}
                    >
                      <div
                        className="alert alert-success moretext"
                        style={{
                          transform: `translateY(${Math.round(
                            20 * (-1 + progress)
                          )}px)`,
                        }}
                      >
                        <i className="fa fa-info-circle"></i>
                        &nbsp;{signupLanguageLabels(language).needHelpCnt}
                        <div>
                          {signupLanguageLabels(language).needHelpCnt1}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              />
            </div>
          </div>
          {/* )} */}

          <div className="row">
            <SidebarNavigation
              staticMenu={staticMenu}
              newmenus={newmenus}
              dynamicMenu={props.loctype}
              dynamicMenuEnable={locType}
              activeTab={activeTab}
              activeTabId={activeTabId}
              onMenuClick={(v, id, activeMenus) => {
                setActiveTab(v);
                setActiveTabId(id);
                if (activeMenus) {
                  setEnableMenuList(activeMenus);
                }
              }}
            />
            <div className="col-md-9">
              {/* {props.mode === "add" && ( */}
              <>
                <section
                  className={`dh-reg-wizards dh-reg-step-1 mt-3 mt-sm-0 mt-md-0 mt-lg-0 ${activeTab === "menuStep1" ? "" : "hide"
                    }`}
                  id="reg-menuStep1"
                >
                  <form onSubmit={handleSubmit(onBasicSubmit)}>
                    <div className="dh-head">
                      <div className="row">
                        <div className="col-md-12">
                          <h4>Basic Registration</h4>
                        </div>
                      </div>
                    </div>

                    <div className="dh-content">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="regValidationFdbk"></div>

                          <div className="dh-box-mr">
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label
                                    className="dh-form-label dh-title-lb"
                                    htmlFor="firstName"
                                  >
                                    First Name *
                                  </label>
                                  <input
                                    {...register("firstName", {
                                      required: "First name is required",
                                    })}
                                    className={`form-control form-control-lg required dh-input-box ${errors.firstName ? "dh-error-box" : ""
                                      }`}
                                    placeholder=""
                                  />
                                  {errors.firstName && (
                                    <span className="dh-txt-danger">
                                      {errors.firstName.message}
                                    </span>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label
                                    className="dh-form-label dh-title-lb"
                                    htmlFor="lastName"
                                  >
                                    Last Name *
                                  </label>
                                  <input
                                    {...register("lastName", {
                                      required: "Last name is required",
                                    })}
                                    className={`form-control form-control-lg required dh-input-box ${errors.lastName ? "dh-error-box" : ""
                                      }`}
                                    placeholder=""
                                  />
                                  {errors.lastName && (
                                    <span className="dh-txt-danger">
                                      {errors.lastName.message}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-12">
                                <div className="form-group">
                                  <label
                                    className="dh-form-label dh-title-lb"
                                    htmlFor="farmVendorName"
                                  >
                                    Farm / Vendor Name *
                                  </label>
                                  <input
                                    {...register("vendorName", {
                                      required: "Farm / Vendor name is required",
                                    })}
                                    className={`form-control form-control-lg required dh-input-box ${errors.vendorName ? "dh-error-box" : ""
                                      }`}
                                    placeholder=""
                                  />
                                  {errors.vendorName && (
                                    <span className="dh-txt-danger">
                                      {errors.vendorName.message}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="row" id="fnsNumbersList">
                              {controlledFields.map((item, i) => {
                                return (
                                  <Fragment key={`fnsNum.${i}`}>
                                    <div
                                      className="col-md-6"
                                      id={`fnsNum.${i}`}
                                    >
                                      <div className="form-group">
                                        <p
                                          className="dh-form-label dh-title-lb"
                                          htmlFor={`fnsNum.${i}`}
                                        >
                                          FNS Number *
                                          {i > 0 ? (
                                            <NavLink
                                              to="#"
                                              onClick={() => {
                                                handleRemove(i);
                                              }}
                                              className="dh-remove"
                                            >
                                              <i className="fa fa-trash-o"></i>{" "}
                                              Remove
                                            </NavLink>
                                          ) : (
                                            ""
                                          )}
                                        </p>

                                        <input
                                          {...register(`fnsNum.${i}.name`, {
                                            required: "FNS number is required",
                                            pattern: {
                                              value:
                                              /^(\d{7})$/,
                                              message: "FNS number should be 7 digits",
                                            },
                                          })}
                                          // pattern="d{0,9}"
                                          maxLength={7}
                                          // minLength={7}
                                          onKeyDown={isNumber}
                                          type="text"
                                          className={`form-control form-control-lg fns-number-input dh-input-box required ${errors.fnsNum?.[i]?.name ? "dh-error-box" : ""
                                            }`}
                                        /> 
                                        {errors.fnsNum?.[i]?.name && (
                                          <span className="dh-txt-danger">
                                            {errors.fnsNum[i].name.message}
                                          </span>
                                        )} 
                                        {/* <input
                                          {...register("fnsNum", {
                                            required: "FNS Number is required",
                                          })}
                                          type="text"
                                          value={fnsNumber}
                                          onChange={handleChange}
                                          maxLength={7}
                                          
                                          className={`form-control form-control-lg required dh-input-box ${errors.vendorName ? "dh-error-box" : ""
                                        }`}
                                          
                                        />
                                        {errors.fnsNum && (
                                          <span className="dh-txt-danger">
                                            {errors.fnsNum.message}
                                          </span>
                                        )} */}
                                      </div>
                                    </div>
                                  </Fragment>
                                );
                              })}
                            </div>
                            {addMore < 10 && (
                              <div
                                className="form-text text-muted dh-short-title"
                                id="addMoreFNSNumber"
                              >
                                <span>Do you have an additional FNS number? </span>
                                <span className={"text-decoration-underline cursor-pointer green"} onClick={() => handleAddFields()}>
                                  <i className="fa fa-plus"></i> Add More
                                </span>
                              </div>
                            )}

                           

                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label
                                    className="dh-form-label dh-title-lb"
                                    htmlFor="primaryEmailID"
                                  >
                                    Primary Email Address *
                                  </label>
                                  <input
                                    {...register("primaryEmail", {
                                      required: "Primary email is required",
                                      pattern: {
                                        value:
                                          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: "Invalid email address",
                                      },
                                    })}
                                    type="email"
                                    maxLength="100"
                                    className={`form-control form-control-lg required dh-input-box lowercase ${errors.primaryEmail ? "dh-error-box" : ""
                                      }`}
                                    id="primaryEmailID"
                                  />
                                  {errors.primaryEmail && (
                                    <span className="dh-txt-danger">
                                      {errors.primaryEmail.message}
                                    </span>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label
                                    className="dh-form-label dh-title-lb"
                                    htmlFor="primaryPhoneNumber"
                                  >
                                    Primary Phone Number *
                                  </label>
                                  <input
                                    {...register("primaryMobile", {
                                      required:
                                        "Primary phone number is required",
                                      pattern: {
                                        value:
                                          /^\s*[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})\s*$/,
                                        message: "Invalid phone number",
                                      },
                                    })}
                                    type="tel"
                                    className={`form-control form-control-lg required dh-input-box ${errors.primaryMobile ? "dh-error-box" : ""
                                      }`}
                                    value={mobile}
                                    onChange={(e) =>{
                                      setMobile(maskMobile(e.target.value));
                                      if(errors.primaryMobile){
                                        delete errors.primaryMobile;
                                      }
                                    }}
                                    placeholder="(___) ___-____"
                                    id="primaryPhoneNumber"
                                    aria-label="primaryPhoneNumber"
                                    aria-labelledby="primaryPhoneNumber"
                                    aria-describedby="primaryPhoneNumber"
                                    required=""
                                  // im-insert="true"
                                  />
                                  {errors.primaryMobile && (
                                    <span className="dh-txt-danger">
                                      {errors.primaryMobile.message}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            {/* <div className="row">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label
                                    className="dh-form-label dh-title-lb"
                                    htmlFor="vendorPassword"
                                  >
                                    Create Password *
                                  </label>
                                  <input
                                    {...register("password", {
                                      required: "You must specify a password",
                                      maxLength: 20,
                                      minLength: {
                                        value: 8,
                                        message:
                                          "Password must have at least 8 characters",
                                      },
                                      validate: (value) => {
                                        return (
                                          value === watch("confirm_password") || (
                                            <span>Password should match</span>
                                          )
                                        );
                                      },
                                    })}
                                    type="password"
                                    className={`form-control form-control-lg required dh-input-box  ${errors.password ? "dh-error-box" : ""
                                      }`}
                                    maxLength="100"
                                    id="vendorPassword"
                                    aria-label="vendorPassword"
                                    aria-labelledby="vendorPassword"
                                    aria-describedby="vendorPassword"
                                    required=""
                                  />
                                  {errors.password && (
                                    <span className="dh-txt-danger">
                                      {errors.password.message}
                                    </span>
                                  )}
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="form-group">
                                  <label
                                    className="dh-form-label dh-title-lb"
                                    htmlFor="confirmPassword"
                                  >
                                    Confirm Password *
                                  </label>
                                  <input
                                    {...register("confirm_password", {
                                      required:
                                        "You must specify a confirm password",
                                      maxLength: 20,
                                      minLength: {
                                        value: 8,
                                        message:
                                          "Password must have at least 8 characters",
                                      },
                                    })}
                                    type="password"
                                    className={`form-control form-control-lg required dh-input-box  ${errors.confirm_password
                                      ? "dh-error-box"
                                      : ""
                                      }`}
                                    maxLength="100"
                                    autoComplete="password"
                                    id="confirmPassword"
                                    aria-label="confirmPassword"
                                    aria-labelledby="confirmPassword"
                                    aria-describedby="confirmPassword"
                                    required=""
                                  />
                                  {errors.confirm_password && (
                                    <span className="dh-txt-danger">
                                      {errors.confirm_password.message}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div> */}

                            <div className="row">
                              <div className="col-md-12">
                                <div className="form-group">
                                  <label
                                    className="dh-title-lb"
                                    htmlFor="additionalContactInfo"
                                  >
                                    Additional Contact Info
                                  </label>
                                  <textarea
                                    {...register("additionalContactInfo", {
                                      maxLength: {
                                        value: "3000",
                                        message: "",
                                      },
                                    })}
                                    // ref={register}
                                    className={`form-control form-control-lg  ${errors.additionalContactInfo
                                      ? "dh-error-box"
                                      : ""
                                      }`}
                                    id="additionalContactInfo"
                                    rows="3"
                                    // name="additionalinfo"
                                    aria-label="additionalContactInfo"
                                    aria-labelledby="additionalContactInfo"
                                    aria-describedby="additionalContactInfo"
                                  ></textarea>
                                  <span className="float-end text-muted mt-1 ">
                                    <i
                                      className="fa fa-info-circle mr-1"
                                      aria-hidden="true"
                                    ></i>
                                    Max character : 3000
                                  </span>
                                </div>
                              </div>
                            </div>
                            {/* </form> */}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="dh-footer">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="dh-disp text-center">
                            <button
                              type="submit"
                              className="btn btn-primary btn-lg reg-btns-style reg-btns"
                            >
                              Register and Next{" "}
                              <i className="fa fa-angle-right"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </section>
                <section
                    className={`dh-reg-wizards dh-reg-step-2 mt-3 mt-sm-0 mt-md-0 mt-lg-0 ${activeTab === "menuStep2" ? "" : "hide"
                      }`}
                    id="reg-menuStep2"
                  >
                    <form onSubmit={handleSubmit(onPublicInfoSubmit)}>
                      <div className="dh-head">
                        <div className="row">
                          <div className="col-md-12">
                            <h4>Public Info</h4>
                          </div>
                        </div>
                      </div>

                      <div className="dh-content">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="regValidationFdbk"></div>

                            <div className="dh-box-mr">
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label
                                      className="dh-form-label dh-title-lb"
                                      htmlFor="infoEmailID"
                                    >
                                      Public Email Address
                                    </label>
                                    <input
                                      {...register("publicEmail", {
                                        pattern: {
                                          value:
                                            /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                          message: "Invalid email address",
                                        },
                                      })}
                                      type="text"
                                      //   className="form-control form-control-lg required dh-input-box"
                                      className={`form-control form-control-lg required dh-input-box lowercase ${errors.primaryEmail ? "dh-error-box" : ""
                                        }`}
                                      maxLength="100"
                                      id="infoEmailID"
                                      aria-label="infoEmailID"
                                      aria-labelledby="infoEmailID"
                                      aria-describedby="infoEmailID"
                                    />
                                    {errors.publicEmail && (
                                      <span className="dh-txt-danger">
                                        {errors.publicEmail.message}
                                      </span>
                                    )}
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label
                                      className="dh-form-label dh-title-lb"
                                      htmlFor="infoPhoneNumber"
                                    >
                                      Phone
                                    </label>
                                    <input
                                      {...register("publicMobile", {
                                        pattern: {
                                          value:
                                            /^\s*[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})\s*$/,
                                          message: "Invalid phone number",
                                        },
                                      })}
                                      type="tel"
                                      className={`form-control form-control-lg required dh-input-box ${errors.publicMobile ? "dh-error-box" : ""
                                        }`}
                                      value={publicMobile}
                                      onChange={(e) =>
                                        setPublicMobile(maskMobile(e.target.value))
                                      }
                                      placeholder="(___) ___-____"
                                      id="publicMobile"
                                      aria-label="infoPhoneNumber"
                                      aria-labelledby="infoPhoneNumber"
                                      aria-describedby="infoPhoneNumber"
                                    />
                                    {errors.publicMobile && (
                                      <span className="dh-txt-danger">
                                        {errors.publicMobile.message}
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-md-6">
                                  <div className="row">
                                    <div className="col-md-12">
                                      <div className="form-group">
                                        <label
                                          className="dh-form-label dh-title-lb"
                                          htmlFor="infoWebsite"
                                        >
                                          Website
                                        </label>
                                        <input
                                          {...register("publicWebsite", {
                                            pattern: {
                                              value:
                                                /^((ftp|http|https):\/\/)?(www\.)?([A-z]+)\.([A-z]{2,})/,
                                              message: "Invalid website",
                                            },
                                          })}
                                          type="text"
                                          maxLength="100"
                                          //   className="form-control form-control-lg required dh-input-box"
                                          className={`form-control form-control-lg required dh-input-box ${errors.publicWebsite ? "dh-error-box" : ""
                                            }`}
                                          id="infowebsite"
                                          aria-label="infoWebsite"
                                          aria-labelledby="infoWebsite"
                                          aria-describedby="infoWebsite"
                                        />
                                        {errors.publicWebsite && (
                                          <span className="dh-txt-danger">
                                            {errors.publicWebsite.message}
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-md-12">
                                      <div className="form-group">
                                        <label
                                          className="dh-form-label dh-title-lb"
                                          htmlFor="businessDesc"
                                        >
                                          Business Description
                                        </label>
                                        <textarea
                                          {...register("businessDesc", {
                                            maxLength: 3000,
                                          })}
                                          className="form-control form-control-lg"
                                          maxLength="3000"
                                          id="businessDesc"
                                          rows="3"
                                          aria-label="businessDesc"
                                          aria-labelledby="businessDesc"
                                          aria-describedby="businessDesc"
                                        ></textarea>
                                        <span className="float-end  text-muted mt-1 ">
                                          <i
                                            className="fa fa-info-circle mr-1"
                                            aria-hidden="true"
                                          ></i>
                                          Max character : 3000
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div
                                    id="edit_public_photo_box"
                                    className="dh-photo-cover"
                                  >
                                    <p className="dh-form-label dh-title-lb">
                                      Vendor Photo
                                    </p>
                                    <div
                                      className="dh-store-photo"
                                      id="edit_publicPhoto"
                                    >
                                      {selectedImage.base64URL && (
                                        <StyledImageUpload>
                                          <StyledImage alt="image not found" src={selectedImage.base64URL} />
                                        </StyledImageUpload>
                                      )}
                                      {!selectedImage.base64URL && (
                                        <div>
                                          <h1>
                                            <i className="fa fa-picture-o fa-3"></i>
                                          </h1>
                                          <div className="smallTitle">
                                            Not Available
                                          </div>
                                          <div>Click edit to upload vendor photo</div>
                                        </div>
                                      )}
                                    </div>
                                    <div className="form-group ">
                                      <input
                                        {...register("publicPhoto", {
                                          validate: {
                                            lessThan3MB: (files) => files.length == 0 || files[0]?.size/1024 <= 3072 || "File should be less than 3MB",
                                            acceptedFormats: (files) =>
                                            files.length == 0 || ["image/jpeg", "image/png", "image/gif"].includes(
                                                files[0]?.type
                                              ) || "File format should be PNG, JPEG or GIF"
                                          }
                                        })}
                                        accept="image/*"
                                        type="file"
                                        maxLength="100"
                                        className="form-control required uploadImage-style"
                                        placeholder="Upload Photo"
                                        id="infoPhoto"
                                        required=""
                                        onChange={(e) => {
                                          handleFileInputChange(e);
                                        }}
                                      />
                                      {errors.publicPhoto && (
                                          <span className="dh-txt-danger">
                                              {errors.publicPhoto.message}
                                          </span>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="form-group">
                                    <label
                                      className="dh-form-label dh-title-lb"
                                      htmlFor="infoProductDescription"
                                    >
                                      Products
                                    </label>
                                    <textarea
                                      {...register("products", {
                                        maxLength: 3000,
                                      })}
                                      className="form-control form-control-lg"
                                      maxLength="3000"
                                      id="infoProductDescription"
                                      rows="3"
                                      aria-label="infoProductDescription"
                                      aria-labelledby="infoProductDescription"
                                      aria-describedby="infoProductDescription"
                                    ></textarea>
                                    <span className="float-end  text-muted mt-1 ">
                                      <i
                                        className="fa fa-info-circle mr-1"
                                        aria-hidden="true"
                                      ></i>
                                      Max character : 3000
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="dh-footer">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="dh-disp text-center">
                              <button
                                type="submit"
                                className="btn btn-primary btn-lg save-btn-style reg-btns"
                              >
                                Save and Next <i className="fa fa-angle-right"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </section>
              </>
              <section
                className={`dh-reg-wizards dh-reg-step-2 mt-3 mt-sm-0 mt-md-0 mt-lg-0 ${activeTab === "menuStep3" ? "" : "hide"
                  }`}
                id="reg-menuStep3"
              >
                <form onSubmit={handleSubmit2(onLocTypeSubmit)}>
                  <div className="dh-head">
                    <div className="row">
                      <div className="col-md-12">
                        <h4>Location Types</h4>
                      </div>
                    </div>
                  </div>

                  <div className="dh-content">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="dh-box-mr">
                          {props.mapdata.vendorType &&
                            props.mapdata.vendorType.map((ques, i) => (
                              <div
                                key={`ques_${i}`}
                                className="form-text text-muted dh-short-title"
                              >
                                {ques.dec}
                                <br />
                                <div className="hip-pgm-btn">
                                  <div
                                    className={`dh-opt-round csa-program-btn store-program ${(isDisblOthrChkbx &&
                                      ques.name !==
                                      "farmersMarket") ||
                                      (isDisblMrkMngrChkbx &&
                                        ques.name === "farmersMarket")
                                      ? "dh-opt-disabled"
                                      : ""
                                      }  ${locType[ques.name] === "true" ||
                                        locType[ques.name] === true
                                        ? "dh-opt-active"
                                        : ""
                                      }`}
                                    id={`btn_${ques.name}_yes`}
                                  >
                                    <div className="custom-control custom-radio custom-control-inline p-0 m-0">
                                      <input
                                        {...register2(ques.name)}
                                        onClick={getLocType}
                                        type="radio"
                                        value={true}
                                        id={`${ques.name}_yes`}
                                        className="form-check-input"
                                        aria-label={ques.name}
                                        disabled={
                                          (isDisblOthrChkbx &&
                                            ques.name !==
                                            "farmersMarket") ||
                                          (isDisblMrkMngrChkbx &&
                                            ques.name ===
                                            "farmersMarket")
                                        }
                                      />
                                      <label
                                        className="custom-control-label"
                                        htmlFor={`${ques.name}_yes`}
                                      >
                                        Yes
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    className={`dh-opt-round ${ques.name
                                      }_btn store-program  ${(isDisblOthrChkbx &&
                                        ques.name !==
                                        "farmersMarket") ||
                                        (isDisblMrkMngrChkbx &&
                                          ques.name === "farmersMarket")
                                        ? "dh-opt-disabled"
                                        : ""
                                      } ${locType[ques.name] === "false" ||
                                        locType[ques.name] === false
                                        ? "dh-opt-active"
                                        : ""
                                      }`}
                                    id={`btn_${ques.name}_no`}
                                  >
                                    <div className="custom-control custom-radio custom-control-inline p-0 m-0">
                                      <input
                                        {...register2(ques.name)}
                                        onClick={getLocType}
                                        type="radio"
                                        id={`${ques.name}_no`}
                                        className="form-check-input"
                                        value={false}
                                        aria-label={ques.name}
                                        disabled={
                                          (isDisblOthrChkbx &&
                                            ques.name !==
                                            "farmersMarket") ||
                                          (isDisblMrkMngrChkbx &&
                                            ques.name ===
                                            "farmersMarket")
                                        }
                                      />
                                      <label
                                        className="custom-control-label"
                                        htmlFor={`${ques.name}_no`}
                                      >
                                        No
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="dh-footer">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="dh-disp text-center">
                          <button
                            className="btn btn-outline-primary btn-lg reg-btns ml-lg-3 ml-md-3 ml-sm-0 mt-lg-0 mt-2 mt-md-0 mt-sm-0 hide"
                            id="hipProgramFinishBtn"
                          >
                            <i className="fa fa-check"></i> Save and Finish
                          </button>
                          <button
                            className={`btn btn-primary btn-lg reg-btns save-btn-style ${Object.values(locType).indexOf(true) !== -1
                              ? ""
                              : "hide"
                              }`}
                            id="hipProgramNextBtn"
                          // onClick={onSaveAndNext}
                          >
                            Save and Next <i className="fa fa-angle-right"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </section>
              {
                enableMenuList && enableMenuList.length > 0 && enableMenuList.map((item, i) => (
                  <section
                    key={i}
                    className={`dh-reg-wizards dh-reg-step-2 mt-3 mt-sm-0 mt-md-0 mt-lg-0 ${dynamicMenuList.find(data => data.id === item).id === activeTabId ? "" : "hide"
                      }`}
                    id={`reg-${dynamicMenuList.find(data => data.id === item).menu}`}
                  >
                   
                    {
                      props.sections && props.sections.length > 0 ?
                        <SignupForm
                          type={"add"}
                          vendorType={props.mapdata.vendorType}
                          location_types_name={item}
                          programData={ProgramSectionData}
                          enableMenuList={enableMenuList}
                          section={getSelectedSection(item)}
                          city={cityList}
                          days={props.mapdata.days}
                          months={props.mapdata.months}
                          order_option={props.mapdata.orderOptions}
                          onSaveAndNext={onSaveAndNext}
                          activeTabId={activeTabId}
                          user={props.user.userinfo}
                          addProgramData={addProgramData}
                          farmermarkets={props.administration.farmersMarket.list}
                        /> : ""
                    }
                  </section>
                ))
              }

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

UserRegistrationComponent.propTypes = {
  // mode: PropTypes.string,
  addUser: PropTypes.func.isRequired,
  getFarmersMarket: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
  // registrationSuccess: PropTypes.func.isRequired,
  user: PropTypes.object,
  userAuth: PropTypes.object.isRequired,
  getLocTypeQues: PropTypes.func.isRequired,
  locTypeQues: PropTypes.object.isRequired,
  mapdata: PropTypes.object.isRequired,
  getLocTypeValues: PropTypes.func.isRequired,
  loctype: PropTypes.object.isRequired,
  // loctype programs
  selectedLanguage: PropTypes.string,
  sections: PropTypes.array,
  locTypeProgramQuestionsSections: PropTypes.func.isRequired,
  getUser: PropTypes.func.isRequired,
  userinfo: PropTypes.object.isRequired,
  getCityList: PropTypes.func.isRequired,
  regCity: PropTypes.object,
  getMonthOption: PropTypes.func.isRequired,
  regDays: PropTypes.object,
  getDayOption: PropTypes.func.isRequired,
  regMonths: PropTypes.object,
  getOrderOption: PropTypes.func.isRequired,
  reg_orderoption: PropTypes.object,
  addProgram: PropTypes.func.isRequired,
  administration: PropTypes.object,
  clearRegistrationStatus: PropTypes.func,
  userId: PropTypes.number
};

const mapDispatchToProps = (dispatch) => {

  // return bindActionCreators({
  //     registrationSuccess: ()=>({ type: GET_USER }),
  // addUser: (user)=>({ type: SET_USER, value: user }),
  //     editUser: (userId) =>({ type: EDIT_USER, value: userId }),
  //     updateUser: (user) =>({ type: PUT_USER, value: user }),
  //     getLocTypeValues: ()=>({ type: GET_LOC_TYPE}),
  //     getLocTypeQues: ()=>({ type: GET_LOC_TYPE_QUES}),
  //     locTypeProgramQuestionsSections:locTypeProgramQuestionsSections
  //     // locTypeProgramQuestionsSections: locTypeProgramQuestionsSections
  // }, dispatch);
  //working
  return {
    locTypeProgramQuestionsSections: (val) => dispatch(locTypeProgramQuestionsSections(val)),
    // registrationSuccess: () => {
    //   dispatch({ type: GET_USER });
    // },
    getFarmersMarket: (payload) => dispatch(getFarmersMarket(payload)),
    addUser: (user) => {
      dispatch({ type: SET_USER, value: user });
    },
    getUser: () => {
      dispatch({ type: GET_USER });
    },
    editUser: (userId) => {
      dispatch({ type: EDIT_USER, value: userId });
    },
    updateUser: (user) => {
      //   console.log("dispatch update", user);
      dispatch({ type: PUT_USER, value: user });
    },
    getLocTypeValues: () => {
      dispatch({ type: GET_LOC_TYPE });
    },
    getLocTypeQues: () => {
      dispatch({ type: GET_LOC_TYPE_QUES });
    },
    getCityList: (city_county) => {
      dispatch({ type: GET_COUNTYCITY, city: city_county });
    },
    getOrderOption: () => {
      dispatch({ type: GET_ORDER_OPTION });
    },
    getMonthOption: () => {
      dispatch({ type: GET_MONTH_OPTION });
    },
    getDayOption: () => {
      dispatch({ type: GET_DAY_OPTION });
    },
    addProgram: (program) => {
      dispatch({ type: SET_PROGRAM, value: program });
    },
    clearRegistrationStatus: () => {dispatch(clearRegistrationStatus(""))},
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserRegistrationComponent);
