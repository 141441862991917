import React, {useEffect,useState} from "react";
import PropTypes from "prop-types";
import { LoadMapComponent } from "./loadmap-component";

const getMiles = (currentObj, searchCoordinates) => {
  /// Calculating miles between two latlng
  const R = 6371e3; // metres
  const φ1 = (searchCoordinates.lat * Math.PI) / 180; // φ, λ in radians
  const φ2 = (currentObj.latitude * Math.PI) / 180;
  const Δφ = ((currentObj.latitude - searchCoordinates.lat) * Math.PI) / 180;
  const Δλ = ((currentObj.longitude - searchCoordinates.lng) * Math.PI) / 180;

  const a =
    Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
    Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  var miles = R * c; // in metres
  miles =
    (miles * 0.000621371).toFixed(0) == 0
      ? (miles * 0.000621371).toFixed(0)
      : (miles * 0.000621371).toFixed(1); // in miles
  return miles;
};

const getLocationType = (data) => {
  data.map(item => {
    if (item.store.storeType==="HIP Farmers Market Booth")
      item["location_types_name"] = "farmersMarket";
    else if (item.store.storeType==="HIP Farmers Market")
      item["location_types_name"] = "farmersMarketManagers";
    else if(item.store.storeType ==="HIP CSA PROGRAM")
      item["location_types_name"] = "csaPickupSite";
    else if (item.store.storeType==="HIP Farm Stand")
      item["location_types_name"] = "farmStand";
    else if (item.store.storeType === "HIP Mobile Market Stop")
      item["location_types_name"] = "mobileMarket";
  })
  return data
};

const FilterMapDataComponent = (props) => {
  const [result,setResult] = useState([]);
  // const [mapLoad, setMapLoad] = useState();
  useEffect(() => {
        let data = props.mapResult;
      //  if (props.changeFilter) {
      //   if (props.searchProperties.area.name !== "") {
      //     data = getFilterAreaWithin(data);
      //   }  
      //  }
      setResult(getLocationType(data));
    }, [props.mapResult,props.searchProperties.area,props.searchProperties.city]);

    // useEffect(() => {
    //   setMapLoad(true);
    // },[props.searchProperties]);

  // eslint-disable-next-line no-unused-vars
  const getFilterAreaWithin = (data) => { 
    // filterAreaWithin
    const selectedMiles = parseFloat(props.searchProperties.area.name);

    const filteredData = data?.filter((obj) => {
      const data = getMiles(obj, props.coordinates);
      if (data <= selectedMiles) {
        return data;
      }
    });
    return filteredData;
  };

  return (
      <>
        <LoadMapComponent
          mapResult={ result}
          loading={props.loading}
          mapLoad={props.mapLoad}
          setMapLoad={props.setMapLoad}
          searchProperties={props.searchProperties}
          changeFilter={props.changeFilter}
          language={props.language}
          initialStoreView={props.initialStoreView}
          isResultCollapsed={props.isResultCollapsed}
          setResultCollapsed={props.setResultCollapsed}
        />    
      </>
  );
};

FilterMapDataComponent.propTypes = {
  loading: PropTypes.bool,
  mapLoad: PropTypes.object,
  setMapLoad: PropTypes.func,
  changeFilter: PropTypes.string,
  coordinates: PropTypes.object,
  mapResult: PropTypes.array,
  searchProperties: PropTypes.object.isRequired,
  language:PropTypes.object,
  initialStoreView: PropTypes.bool,
  isResultCollapsed: PropTypes.bool,
  setResultCollapsed: PropTypes.func
};

export default FilterMapDataComponent;
