import * as React from 'react';
import spin_loader from "../../../assets/images/spin-loader.svg";
import "../../../styles/home.scss";

export default function TableLoading() {
    
  return (
    <div className="alert alert-info"  style={{
        position: "absolute",
        border: "1px solid rgba(0,0,0,.125)",
        backgroundColor: "#fff",
      }}>
              <div className="loading">
                  <img  src={spin_loader} alt="spin-loader"/>
              </div>{" "}
              <div className="loading-process">
                Processing...
              </div>
          </div>
  );
}