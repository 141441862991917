export const multiLang = (lang) => {
    if(lang === "en-US"){
        return {
            "alerts": "ALERTS",
            "showAlerts": "SHOW ALERTS",
            "hideAlerts": "HIDE ALERTS",
            "alertHeading":"Mobile Version Update",
            'alertMessage':"The mobile version of DTA Finder is currently being updated to improve the user experience of all clients.  Please check back soon.",
            "Services":"Services",
            "DTA Finder": "DTA Finder",
            "Vendor":"Vendor",
            "Vendor Name": "Vendor Name",
            "VendorLogin": "HIP VENDOR",
            "For Providers":"For Providers",
            "AdminLogin": "Admin Log In",
            "AdminLoginHeading": "ADMIN LOG IN",
            "EnterPrimaryEmail":"Please enter your Primary Email Address",
            "EnterPrimaryPhone":"Please enter your Primary Phone Number",
            "EnterHIPOrg": "Please enter your HIP Organization ID",
            "English":"English",
            "Logout":"Logout",
            "My Business":"My Business",
            "Manage Users":"Manage Users",
            "Manage Farmer Markets":"Manage Farmer Markets",
            "Audit Logs":"Audit Logs",
            "Manage Coordinates": "Manage Coordinates",
            "Administration":"Administration",
            "Dashboard":"Dashboard",
            "Vendor(s)":"Vendor(s)",
            "HIP":"HIP",
            "hip": "(HIP)",
            "HealthyIncentivesProgram":"Healthy Incentives Program",
            "DTA":"DTA",
            "Search":"Search",
            "Search Results":"Search Results",
            "View Results":"View Results",
            "View List":"View List",
            "County":"County",
            "City":"City",
            "Area Within":"Area Within",
            "Statewide":"Statewide",
            "Type":"Type",
            "from":"from",
            "at":"at",
            "hide": "Hide",
            "locations":"location(s)",
            "Order Option(s)":"Order Option(s)",
            "Month(s) of Operation":"Month(s) of Operation",
            "Day(s) of Operation":"Day(s) of Operation",
            "Regular Hour(s) of Operation":"Regular Hour(s) of Operation",
            "Open Today":"Open Today",
            "My Location":"My Location",
            "Results":"Results",
            "Export":"Export",
            "Clear Search":"Clear Search",
            "Clear Zoom":"Clear Zoom",
            "Back": "Back",
            "Legends":"Legends",
            "available":"available",
            "Within":"Within",
            "miles":"miles",
            "mile":"miles",
            "Massachusetts":"Massachusetts",
            "no vendor":"no vendor",
            "no store":"no store",
            "vendors are":"vendors are",
            "HIP results":"HIP result(s)",
            "No stores found":"No stores found",
            "HIP Farmer's Market":"HIP Farmers' Market",
            "HIP Farmer's Market Booth":"HIP Farmers' Market Booth",
            "All Cities":"All Cities",
            "All Vendors":"All Vendors",
            "Something went wrong! Please try again later":"Something went wrong! Please try again later",
            "miles away":"miles away",
            "Back to Results":"Results",
            "View on Map":"View on Map",
            "Navigate to market":"Navigate to market",
            "Select all":"Select all",
            "Monday":"Monday",
            "Tuesday":"Tuesday",
            "Wednesday":"Wednesday",
            "Thursday":"Thursday",
            "Friday":"Friday",
            "Saturday":"Saturday",
            "Sunday":"Sunday",
            "January":"January",
            "February":"February",
            "March":"March",
            "April":"April",
            "May":"May",
            "June":"June",
            "July":"July",
            "August":"August",
            "September":"September",
            "October":"October",
            "November":"November",
            "December":"December",
            "HIP CSA Program":"HIP CSA Program",
            "HIP Farm Stand":"HIP Farm Stand",
            "HIP Mobile Market Stop":"HIP Mobile Market Stop",
            "Store Description":"Store Description",
            "Vendor Description": "Vendor Description",
            "am":"am",
            "pm":"pm",
            "Closed":"Closed",
            "Products":"Products",
            "Open Now":"Open Now",
            "Closed Now":"Closed Now",
            "Close":"Close",
            "Contact Us":"Contact Us",
            "Site Policies":"Site Policies",
            "Accessibility":"Accessibility",
            "Feedback":"Feedback",
            "Navigate to store":"Navigate to store",
            "Navigate to location": "Navigate to location",
            "Go to store website":"Go to store website",
            "TheMission":"The mission of the Department of Transitional Assistance is to assist and empower low-income individuals and families to meet their basic needs, improve their quality of life, and achieve long-term economic self- sufficiency",
            "Commonwealth of Massachusetts":"Commonwealth of Massachusetts",
            "Login":"HIP Vendor Access",
            "Forgot Password":"Forgot Password?",
            "Register":"Register HIP Vendor",
            "Update":"Update HIP Vendor",
            "noAccount":"Are you a HIP vendor without an account?",
            "Show":"Show",
            "Password":"Password",
            "Email":"Email",
            "username":"Username",
            "Processing":"Processing",
            "Closed Today":"Closed today",
            "Brasil":"Brasil",
            "Chinese":"Chinese",
            "Spanish":"Spanish",
            "Portuguese":"Portuguese",
            "Vietnamese":"Vietnamese",
            "Curbside pickup":"Curbside pickup",
            "Delivery":"Delivery",
            "In-Person":"In-Person",
            "Online Pre-selection":"Online Pre-selection",
            "Uses Location Services": "Uses Location Services",
            "LOC_PERMISSION_DENIED": "Please enable location services in your browser to search near your current location",
            "Phone Pre-selection":"Phone Pre-selection",
            "Special Instructions": "Special Instructions",
            "Store Photos": "Store Photos",
            "Vendor photos": "Vendor photos",
            "All selected": "All selected",
            "All Counties & Cities":"All Counties and Cities",
            "Help": "Information",
            "Result Pop":"Information",
            "Help Title":"Do you get SNAP* in MA? You're already signed up for HIP!",
            "Help Text1":"HIP puts money back on your EBT card when you use SNAP to buy fruits and vegetables from HIP farm vendors",
            "Help Text2":"You can use up to $40-80 of HIP each month, depending on your SNAP household size",
            "Help Text3":"You must have SNAP benefits left on your EBT card to use HIP",
            "Help Text4":"Learn more at",
            "gov link":"mass.gov/hip",
            "Help Note":"*SNAP =Supplemental Nutrition Assistance Program (formerly food stamps)",
            "CSA Program Tooltip":"A place where you can pick up farm-fresh HIP produce through a weekly membership program. You must sign up with the farm in advance.",
            "Farm Stand Tooltip":"A stand or store where a farm sells HIP produce, usually located at or near the farm",
            "Market Booth Tooltip":"A table at a farmers' market where you can buy HIP produce directly from a farm. Some booths at the market may not process HIP – check to make sure!",
            "Farmer Market Tooltip":"A place where you can buy HIP produce from multiple farm tables by checking out at a central location",
            "Market Stop Tooltip":"A pop-up location where you can buy fresh HIP produce delivered by a farm retailer",
            "Legend":"Map Key",
            "Number of HIP vendor locations":"Number of HIP vendor locations",
            "Information":"Information",
            "Result Info": "Farmers' markets can have multiple HIP vendors. Click on each farmers' market result to see how many HIP vendors sell there.",
            "your location": "your location",
            "Continue": "Continue",
            "Authenticating": "Authenticating",
            "Use My Location": "Use My Location",
            "Special Hour(s) of Operation": "Special Hour(s) of Operation",
            "Available": "Available",
            "Not Available": "Not Available",
            "Week": "Week",
            "Day(s)": "Day(s)",
            "Month(s)": "Month(s)",
            "Time": "Time",
            "Description":"Description",
            "Please provide the details listed below, so that we can help you better" : "Please provide the details listed below, so that we can help you better",
            "User Contact Info" : "User Contact Info",
            "First Name":"First Name",
            "Phone Number":"Phone Number",
            "Email Address":"Email Address",
            "Last Name":"Last Name",
            "Submit":"Submit",
            "Need additional help?":"Need additional help?",
            "Device Info":"Device Info",
            "Device":"Device",
            "Browser":"Browser",
            "Select":"Select",
            "Date and time of visit":"Date and time of visit",
            "Please provide the location and a brief description":"Please provide the location and a brief description",
            "ForInformationRegardingYourDTACase":"For information regarding your DTA case, you may log in to the DTA Connect mobile app or ",
            "mass link":"https://dtaconnect.eohhs.mass.gov/.",
            "You may also call the DTA Assistance Line at":" You may also call the DTA Assistance Line at",
            "and speak with a case manager.":"and speak with a case manager.",
            "Choose Category":"Choose Category",
            "How to use the map":"How to use the map",
            "How to use HIP":"How to use HIP",
            "Report a bug":"Report a bug",
            "Report inaccurate information":"Report inaccurate information",
            "Question about DTA case":"Question about DTA case",
            "Help for HIP vendors":"Help for HIP vendors",
            "Other":"Other",
            "help":"Help",
            "helpHeading":"HELP",
            "iPhone":"iPhone",
            "iPad":"iPad",
            "iOS laptop or desktop":"iOS laptop or desktop",
            "Android phone":"Android phone",
            "Android tablet":"Android tablet",
            "PC":"PC",
            "I don't know":"I don't know",
            "Chrome":"Chrome",
            "Edge":"Edge",
            "Firefox":"Firefox",
            "Internet Explorer":"Internet Explorer",
            "Safari":"Safari",
            "MobileView":"I4LvbKcn92g",
            "DesktopView":"KlaPKfIqt7Q",
            "Vendor Info" : "Vendor Info",
            "Device name is required" : "Device name is required",
            "Browser name is required": "Browser name is required",
            "Description is required": "Description is required",
            "Vendor name is required": "Vendor name is required",
            "Email sent successfully": "Email sent successfully",
            "Success": "Success",
            "Ok": "Ok",
            "Other/I’m not sure": "Other/I’m not sure",
            "Max character": "Max character",
            "Invalid Email Address": "Invalid Email Address",
            "Invalid phone number": "Invalid phone number",
            "Apply":"Apply",
            "Clear":"Clear",
            "Su":"Su",
            "Mo":"Mo",
            "Tu":"Tu",
            "We":"We",
            "Th":"Th",
            "Fr":"Fr",
            "Sa":"Sa",
            "bannerTitle": "An official website of the Commonwealth of Massachusetts.",
            "bannerSubTitle": "Here's how you know",
            "OfficialWebsitesUseMassGov": "Official websites use .mass.gov",
            "AMassGovWebsiteBelongsToAnOfficialGovernmentOrganizationInMassachusetts": "A .mass.gov website belongs to an official government organization in Massachusetts.",
            "SecureWebsitesUseHTTPSCertificate": "Secure websites use HTTPS certificate",
            "ALockIcon": "A lock icon (",
            "ALockIcon1": ") or https:// means you’ve safely connected to the official website. Share sensitive information only on official, secure websites.",
            "Registration":"Vendor Registration",
            "Eatingfruits" : "Eating fruits and vegetables each day is important for your health. The Healthy Incentives Program (HIP) helps SNAP clients buy more fruits and vegetables for their household."
        }
    }else if(lang === "es-ES"){
        return {
            "alerts": "ALERTAS",
            "showAlerts": "MOSTRAR ALERTAS",
            "hideAlerts": "OCULTAR ALERTAS",
            "alertHeading":"Actualización de la versión móvil",
            'alertMessage':"La versión móvil de DTA Finder se está actualizando actualmente para mejorar la experiencia de usuario de todos los clientes. Por favor, revise luego.",
            "Services": "Servicios",
            "DTA Finder": "Buscador de DTA",
            "Vendor": "Proveedor",
            "Vendor Name": "Nombre del proveedor",
            "VendorLogin": "Proveedor HIP",
            "For Providers":"Para proveedores",
            "AdminLogin": "Inicio de sesión de administrador",
            "AdminLoginHeading": "INICIAR SESIÓN DE ADMIN",
            "EnterPrimaryEmail":"Por favor, introduzca su dirección de correo electrónico principal",
            "EnterPrimaryPhone":"Ingrese su número de teléfono principal",
            "EnterHIPOrg": "Ingrese su ID de organización de HIP",
            "English": "Inglés",
            "Spanish":"Español",
            "Portuguese":"Portuguesa",
            "Vietnamese":"vietnamita",
            "Chinese":"Chino",
            "Logout": "Cerrar sesión",
            "My Business": "Mi negocio",
            "Manage Users": "Gestión de usuarios",
            "Manage Farmer Markets": "Gestión de mercados agrícolas",
            "Audit Logs": "Registros de auditorías",
            "Manage Coordinates": "Administrar coordenadas",
            "Administration": "Administración",
            "Dashboard": "Tablero",
            "Vendor(s)": "Proveedor(es)",
            "HIP": "HIP",
            "hip": "(HIP)",
            "HealthyIncentivesProgram": "Programa de incentivos saludables",
            "DTA": "Departamento de Asistencia Transitoria (DTA, por sus siglas en inglés).",
            "Search": "Buscar",
            "Search Results": "Resultados de la búsqueda",
            "View Results": "Ver resultados",
            "View List":"Ver lista",
            "County": "Condado",
            "City": "ciudad",
            "Area Within": "Área abarcada",
            "Statewide": "Todo el estado",
            "Type": "Tipo",
            "from": "desde ",
            "at":"en",
            "locations":"ubicación(es)",
            "HIP results":"resultado(s) de HIP",
            "Order Option(s)": "Opciones de pedido",
            "Month(s) of Operation": "Mes(es) de operación",
            "Day(s) of Operation": "Día(s) de operación",
            "Regular Hour(s) of Operation": "Horas regulares de funcionamiento",
            "Open Today": "Abierto hoy",
            "My Location": "Mi ubicación",
            "Results": "Resultados",
            "Export": "Exportar",
            "Clear Search": "Borrar búsqueda",
            "Clear Zoom":"Zoom claro",
            "Back": "Atrás",
            "Legends": "Clave del mapa",
            "available": "disponible",
            "Within": "Radio de",
            "mile":"milla",
            "miles": "millas",
            "Massachusetts": "Massachusetts",
            "no vendor": "ningún proveedor",
            "no store": "ninguna tienda",
            "vendors are": "los proveedores son",
            "No vendors found": "No se encontraron proveedores",
            "HIP Farmer's Market": "Mercado de agricultores del HIP",
            "HIP Farmer's Market Booth": "Módulo en mercado de agricultores del HIP",
            "All Cities": "Todas las ciudades",
            "All Vendors": "Todos los proveedores",
            "Something went wrong! Please try again later": "¡Algo salió mal! Inténtelo de nuevo más tarde",
            "miles away": "millas de distancia",
            "Back to Results": "Resultados",
            "View on Map": "Ver en el mapa",
            "Navigate to market": "Navegar hacia el mercado",
            "Select all": "Seleccionar todos",
            "Monday": "Lunes",
            "Tuesday": "Martes",
            "Wednesday": "Miércoles",
            "Thursday": "Jueves",
            "Friday": "Viernes",
            "Saturday": "Sábado",
            "Sunday": "Domingo",
            "January": "Enero",
            "February": "Febrero",
            "March": "Marzo",
            "April": "Abril",
            "May": "Mayo",
            "June": "Junio",
            "July": "Julio",
            "August": "Agosto",
            "September": "Septiembre",
            "October": "Octubre",
            "November": "Noviembre",
            "December": "Diciembre",
            "HIP CSA Program": "Programa CSA del HIP",
            "HIP Farm Stand": "Puesto de agricultores del HIP",
            "HIP Mobile Market Stop": "Parada de mercado móvil del HIP",
            "Store Description": "Descripción de la tienda",
            "Vendor Description": "Descripción del proveedor",
            "am": "a. m.",
            "pm": "p. m.",
            "Closed": "Cerrado",
            "Products": "Productos",
            "Open Now": "Abierto en este momento",
            "Closed Now": "Cerrado en este momento",
            "Close": "Cerrar",
            "Contact Us" : "Contáctenos",
            "Site Policies": "Políticas del lugar",
            "Accessibility": "Accesibilidad",
            "Feedback":"Comentario",
            "Go to vendor website": "Ir al sitio web del proveedor",
            "TheMission": "La misión del Departamento de Asistencia Transitoria es asistir y empoderar a las familias e individuos de bajos ingresos para que cubran sus necesidades básicas, mejoren su calidad de vida y alcancen la autosuficiencia económica a largo plazo",
            "Commonwealth of Massachusetts": "Estado de Massachusetts",
            "Curbside pickup":"Retiro al borde de la acera",
            "Delivery":"Entrega",
            "In-Person":"En persona",
            "Online Pre-selection":"Preselección en línea",
            "Phone Pre-selection":"Preselección telefónica",
            "Special Instructions": "Instrucciones especiales",
            "Store Photos": "Almacenar fotos",
            "Vendor photos": "Fotos del proveedor",
            "All selected": "Todo seleccionado",
            "Other": "Otra",
            "Uses Location Services": "Utiliza servicios de ubicación",
            "LOC_PERMISSION_DENIED": "Active los servicios de ubicación en su navegador para buscar cerca de su ubicación actual.",
            "Go to store website": "Ir al sitio web de la tienda",
            "Navigate to store": "Navegar a la tienda",
            "All Counties & Cities":"Todos los condados y ciudades",
            "Help": "INFORMACIÓN",
            "Result Pop":"INFORMACIÓN",
            "Help Title":"¿Recibe SNAP* en MA? ¡Ya está registrado/a para el HIP!",
            "Help Text1":"HIP le devuelve dinero en su tarjeta EBT cuando compra frutas y vegetales a proveedores agrícolas del HIP.",
            "Help Text2":"Puede usar hasta $40-80 en fondos del HIP cada mes, según el tamaño de su grupo familiar en el SNAP.",
            "Help Text3":"Debe tener beneficios del SNAP disponibles en su tarjeta EBT para poder usar el HIP.",
            "Help Text4":"Obtenga más información en",
            "gov link":"mass.gov/hip",
            "Help Note":"*SNAP =Programa de asistencia nutricional suplementaria (antes, cupones para alimentos)",
            "CSA Program Tooltip":"Un lugar donde puede comprar productos agrícolas frescos del HIP a través de un programa de membresía semanal. Debe registrarse con la granja con anticipación.",
            "Farm Stand Tooltip":"Un puesto o tienda donde una granja vende productos del HIP, generalmente ubicado cerca de la granja.",
            "Market Booth Tooltip":"Una mesa en un mercado de agricultores donde puede comprar productos del HIP directamente a una granja. Algunos módulos en el mercado podrían no procesar el HIP. ¡Consulte para asegurarse!",
            "Farmer Market Tooltip":"Un lugar donde puede comprar productos del HIP de varios módulos de agricultores pagando en una ubicación central.",
            "Market Stop Tooltip":"Un lugar temporal donde puede comprar productos del HIP suministrados por un productor minorista.",
            "Legend":"Referencias del mapa",
            "Number of HIP vendor locations":"Número de ubicaciones de proveedores del HIP",
            "Information":"Información",
            "your location": "su ubicación",
            "Login":"Iniciar sesión proveedores HIP",
            "Continue": "Continuar",
            "Email": "Correo electrónico",
            "username":"Nombre de usuario",
            "Forgot Password":"¿Olvidó su contraseña?",
            "Register":"Registrar proveedor de HIP",
            "Update":"Actualizar proveedor de HIP",
            "noAccount":"¿Es un proveedor del HIP sin una cuenta?",
            "Show":"Mostrar",
            "hide": "Ocultar",
            "Password":"Contraseña",
            "Closed Today":"Cerrado hoy",
            "Navigate to location": "Navegar a la ubicación",
            "Authenticating": "Autenticando",
            "Use My Location": "Usar mi ubicación",
            "Result Info": "Los mercados de agricultores pueden tener varios proveedores del HIP. Haga clic en el resultado del mercado de agricultores para ver cuántos proveedores del HIP venden allí.",
            "Special Hour(s) of Operation": "Horario(s) especial(es) de atención",
            "Available": "Disponible",
            "Not Available": "No disponible",
            "Week": "Semana",
            "Day(s)": "Día(s)",
            "Month(s)": "Mes(es)",
            "Time": "Hora",
            "MobileView":"trOBAK09mNk",
            "DesktopView":"5_n1K7RwkdU",
            "help":"Ayuda",
            "helpHeading":"AYUDA",
            "Choose Category":"Seleccionar categoría",
            "Select":"Seleccionar",
            "How to use the map":"Cómo usar el mapa",
            "How to use HIP":"Cómo usar el HIP",
            "Report a bug":"Informar un error",
            "Report inaccurate information":"Reportar información inexacta",
            "Question about DTA case":"Pregunta sobre el caso del DTA",
            "Help for HIP vendors":"Ayuda para proveedores de HIP",
            "Need additional help?":"¿Necesita ayuda adicional?",
            "Description":"Descripción",
            "Please provide the details listed below, so that we can help you better" : "Indique los detalles que aparecen a continuación, a fin de que podamos ayudarlo/a mejor.",
            "User Contact Info" : "Información de contacto del/de la usuario/a",
            "First Name":"Nombre",
            "Phone Number":"Número de teléfono",
            "Email Address":"Dirección de correo electrónico",
            "Last Name":"Apellido",
            "Submit":"Enviar",
            "Device Info":"Información del dispositivo",
            "Device":"Dispositivo",
            "Browser":"Navegador",
            "Date and time of visit":"Fecha y hora de la visita",
            "Please provide the location and a brief description":"Indique la ubicación y una breve descripción",
            "ForInformationRegardingYourDTACase":"Para información con respecto a su caso del DTA, puede iniciar sesión en la aplicación móvil DTA Connect o en ",
            "mass link":"https://dtaconnect.eohhs.mass.gov/.",
            "You may also call the DTA Assistance Line at":" También puede llamar a la línea de asistencia del DTA al",
            "and speak with a case manager.":"y hablar con un administrador de casos.",
            "iPhone":"iPhone",
            "iPad":"iPad",
            "iOS laptop or desktop":"Computadora portátil o de escritorio con iOS",
            "Android phone":"Teléfono Android",
            "Android tablet":"Tableta Android",
            "PC":"PC",
            "I don't know":"No sé",
            "Chrome":"Chrome",
            "Edge":"Edge",
            "Firefox":"Firefox",
            "Internet Explorer":"Internet Explorer",
            "Safari":"Safari",
            "Vendor Info" : "Información del proveedor",
            "Device name is required" : "El nombre del dispositivo es obligatorio",
            "Browser name is required": "El nombre del navegador el obligatorio",
            "Description is required": "La descripción es obligatoria",
            "Vendor name is required": "El nombre del proveedor es obligatorio",
            "Email sent successfully": "El correo electrónico se envió correctamente",
            "Success": "Correcto",
            "Ok": "Ok",
            "Other/I’m not sure": "Otro/No estoy seguro/a",
            "Processing": "Procesando",
            "Max character": "Máximo de caracteres",
            "Invalid Email Address": "Correo electrónico no válido",
            "Invalid phone number": "Número de teléfono no válido",
            "Apply":"Solicitar",
            "Clear":"Clara",
            "Su":"Lu",
            "Mo":"Ma",
            "Tu":"Mi",
            "We":"Ju",
            "Th":"Vi",
            "Fr":"Sa",
            "Sa":"Do",
            "bannerTitle":"Un sitio web oficial del Estado de Massachusetts.",
            "bannerSubTitle":"Así es como usted puede verificarlo",
            "OfficialWebsitesUseMassGov":"Los sitios web oficiales usan .mass.gov",
            "AMassGovWebsiteBelongsToAnOfficialGovernmentOrganizationInMassachusetts":"Un sitio web .mass.gov pertenece a una organización oficial del gobierno de Massachusetts.",
            "SecureWebsitesUseHTTPSCertificate":"Los sitios web seguros usan un certificado HTTPS",
            "ALockIcon":"El ícono de un candado (",
            "ALockIcon1":") o https:// significa que usted se conectó de forma segura al sitio web oficial. Comparta información confidencial solo en sitios web oficiales y seguros.",
            "Registration":"Registro de comerciante",
            "Eatingfruits" : "Comer frutas y vegetales todos los días es importante para su salud. El Programa de incentivos saludables (HIP, por sus siglas en inglés) ayuda a los clientes del SNAP a comprar más frutas y vegetales para su grupo familiar."
        }
    }else if(lang === "pt-BR"){
        return {
            "alerts": "ALERTAS",
            "showAlerts": "MOSTRAR ALERTAS",
            "hideAlerts": "OCULTAR ALERTAS",
            "alertHeading":"Atualização da versão móvel",
            'alertMessage':"A versão móvel do DTA Finder está sendo atualizada para melhorar a experiência do usuário de todos os clientes. Por favor, volte em breve.",
            "Services": "Serviços",
            "DTA Finder": "Localizador DTA",
            "Vendor": "Fornecedor",
            "Vendor Name": "Nome do fornecedor",
            "VendorLogin": "Fornecedor HIP",
            "username": "Nome de usuário",
            "Update": "Atualizar Fornecedor de HIP",
            "For Providers": "Para Provedores",
            "Registration":"Cadastro de Fornecedor",
            "EnterHIPOrg": "Insira seu ID de organização HIP",
            "EnterPrimaryEmail": "Insira seu endereço de e-mail principal",
            "EnterPrimaryPhone": "Insira seu número de telefone principal",
            "Eatingfruits": "Comer frutas e legumes todos os dias é importante para sua saúde. O Programa de Incentivo à Saúde (HIP) ajuda clientes SNAP a comprar mais frutas e legumes para suas casas.",
            "AdminLogin": "Login de administrador",
            "AdminLoginHeading": "LOGIN DO ADMINISTRADOR",
            "English": "inglês",
            "Spanish": "Espanhol",
            "Portuguese": "Português",
            "Vietnamese": "vietnamita",
            "Chinese": "chinês",
            "Logout": "Sair",
            "My Business": "Meu negócio",
            "Vendor photos": "Fotos de fornecedores",
            "Manage Users": "Administrar usuários",
            "Manage Farmer Markets": "Administrar Feiras",
            "Audit Logs": "Logs de auditoria",
            "Manage Coordinates": "Gerenciar Coordenadas",
            "Administration": "Administração",
            "Dashboard": "Painel",
            "Vendor(s)": "Fornecedor(es)",
            "HIP": "HIP",
            "hip": "(HIP)",
            "HealthyIncentivesProgram": "Programa de Incentivos à Saúde",
            "DTA": "DTA",
            "Search": "Pesquisar",
            "Search Results": "Procurar Resultados",
            "View Results": "Exibir resultados",
            "View List": "Ver lista",
            "County": "Condado",
            "City": "Cidade",
            "Area Within": "Área dentro dos limites de",
            "Use My Location": "Usar minha localização",
            "Statewide": "Âmbito estadual",
            "Type": "Tipo",
            "from": "a partir de",
            "at": "em",
            "At": "Em",
            "locations": "local(is)",
            "HIP results": "resultado(s) HIP",
            "Order Option(s)": "Opção(ões) de pedido",
            "Month(s) of Operation": "Mês(es) de funcionamento",
            "Day(s) of Operation": "Dia(s) de funcionamento",
            "Regular Hour(s) of Operation": "Hora (s) regular (is) de operação",
            "Open Today": "Aberto hoje",
            "My Location": "Minha localização",
            "Results": "Resultados",
            "Export": "Exportar",
            "Clear Search": "Limpar pesquisa",
            "Clear Zoom": "Zoom claro",
            "Back": "Voltar",
            "Legends": "Chave do mapa",
            "available": "disponível",
            "Within": "Dentro de ",
            "mile": "milha",
            "miles": "milhas",
            "Massachusetts": "Massachusetts",
            "no vendor": "sem fornecedor",
            "no store": "sem loja",
            "vendors are": "fornecedores são",
            "No vendors found": "Nenhum fornecedor encontrado",
            "HIP Farmer's Market": "Feira agrícola HIP",
            "HIP Farmer's Market Booth": "Banca de feira agrícola HIP",
            "All Cities": "Todas as cidades",
            "All Vendors": "Todos os fornecedores",
            "Something went wrong! Please try again later": "Algo deu errado! Tente novamente mais tarde",
            "miles away": "milhas de distância",
            "Back to Results": "Resultados",
            "View on Map": "Visualizar no mapa",
            "Navigate to market": "Navegar para a feira",
            "Select all": "Selecionar todos",
            "Monday": "Segunda-feira",
            "Tuesday": "Terça-feira",
            "Wednesday": "Quarta-feira",
            "Thursday": "Quinta-feira",
            "Friday": "Sexta-feira",
            "Saturday": "Sábado",
            "Sunday": "Domingo",
            "January": "Janeiro",
            "February": "Fevereiro",
            "March": "Março",
            "April": "Abril",
            "May": "Maio",
            "June": "Junho",
            "July": "Julho",
            "August": "Agosto",
            "September": "Setembro",
            "October": "Outubro",
            "November": "Novembro",
            "December": "Dezembro",
            "HIP CSA Program": "Programa HIP CSA",
            "HIP Farm Stand": "Loja agrícola HIP",
            "HIP Mobile Market Stop": "Mercadinho móvel HIP",
            "Store Description": "Descrição da loja",
            "Vendor Description": "Descrição do Fornecedor",
            "am": "manhã",
            "pm": "tarde",
            "Closed": "Fechado",
            "Products": "Produtos",
            "Open Now": "Aberto no momento",
            "Closed Now": "Fechado no momento",
            "Closed Today": "Fechado hoje",
            "Close": "Fechar",
            "Site Policies": "Políticas do site",
            "Accessibility": "Acessibilidade",
            "Navigate to location": "Navegar até o local",
            "Go to vendor website": "Ir para o site do fornecedor",
            "TheMission": "A missão do Departamento de Assistência Transicional é ajudar e capacitar famílias e indivíduos de baixa renda a conseguirem suas necessidades básicas, melhorar sua qualidade de vida e alcançar autossuficiência econômica no longo prazo",
            "Commonwealth of Massachusetts": "Comunidade de Massachusetts",
            "Curbside pickup": "Coleta na calçada",
            "Delivery": "Entrega",
            "In-Person": "Presencial",
            "Online Pre-selection": "Pré-seleção on-line",
            "Phone Pre-selection": "Pré-seleção via telefone",
            "Special Instructions": "Instruções Especiais",
            "Store Photos": "Loja de fotos",
            "All selected": "Todos selecionados",
            "Other": "Outra",
            "Uses Location Services": "Uso de serviços de localização",
            "LOC_PERMISSION_DENIED": "Ative os serviços de localização no seu navegador para pesquisar por perto da sua localização atual",
            "Go to store website": "Ir para o site da loja",
            "Navigate to store": "Navegue até a loja",
            "All Counties & Cities": "Todos os condados e cidades",
            "Help": "INFORMAÇÃO",
            "Result Pop": "INFORMAÇÕES",
            "Help Title": "Você recebe o SNAP em MA? Então já tem cadastro no HIP!",
            "Help Text1": "O HIP devolve dinheiro ao seu cartão EBT quando você usa o SNAP para comprar frutas e legumes de fornecedores agricultores HIP",
            "Help Text2": "Você pode usar até $40-80 do HIP por mês, dependendo do tamanho da sua residência SNAP",
            "Help Text3": "Você deve ter benefícios SNAP no cartão para usar o HIP",
            "Help Text4": "Saiba mais em",
            "gov link": "mass.gov/hip",
            "Help Note": "*SNAP = Supplemental Nutrition Assistance Program (antigo vale-alimentação)",
            "CSA Program Tooltip": "Um lugar onde você pode retirar produtos agrícolas HIP frescos com um programa de filiação semanal. Você precisa se cadastrar na fazenda com antecedência.",
            "Farm Stand Tooltip": "Um estande ou loja onde uma fazenda vende os produtos HIP, normalmente localizado na fazenda ou perto dela",
            "Market Booth Tooltip": "Uma banca em uma feira agrícola onde você pode comprar produtos HIP diretamente da fazenda. Algumas bancas podem não trabalhar com HIP, verifique para garantir!",
            "Farmer Market Tooltip": "Um local em que você pode comprar produtos HIP de diversas mesas agrícolas em um local central",
            "Market Stop Tooltip": "Um local móvel onde você pode comprar produtos HIP frescos entregues por um revendedor agrícola",
            "Legend": "Legenda do mapa",
            "Number of HIP vendor locations": "Número de locais com fornecedores HIP",
            "Information": "Em formação",
            "your location": "sua localização",
            "Login": "Acessar fornecedores HIP",
            "Continue": "Continuar",
            "Email": "E-mail",
            "Authenticating": "Autenticando",
            "Forgot Password": "Esqueceu a senha",
            "Register": "Cadastrar-se",
            "noAccount": "Você é fornecedor HIP mas não tem conta?",
            "Show": "Exibir",
            "hide": "ocultar",
            "Password": "Senha",
            "Result Info": "As feiras agrícolas podem ter diversos fornecedores HIP. Clique no resultado de feira agrícola para ver quantos fornecedores HIP vendem ali.",
            "Special Hour(s) of Operation": "Horário(s) especial(is) de funcionamento",
            "Available": "Disponível",
            "Not Available": "Indisponível",
            "Week": "Semana",
            "Day(s)": "Dia(s)",
            "Month(s)": "Mês(es)",
            "Time": "Hora",
            "MobileView": "I4LvbKcn92g",
            "DesktopView": "KlaPKfIqt7Q",
            "help": "Ajuda",
            "helpHeading": "AJUDA",
            "Choose Category": "Escolha a categoria",
            "Select": "Selecionar",
            "How to use the map": "Como usar o mapa",
            "How to use HIP": "Como usar o HIP",
            "Report a bug": "Relatar um erro",
            "Report inaccurate information": "Relatar informações incorretas",
            "Question about DTA case": "Dúvidas sobre o caso do DTA",
            "Help for HIP vendors": "Ajuda para fornecedores do HIP",
            "Need additional help?": "Precisa de ajuda adicional?",
            "Description": "Descrição",
            "Please provide the details listed below, so that we can help you better": "Forneça os detalhes listados abaixo, para que possamos melhor atendê-lo(a)",
            "User Contact Info": "Informações de contato do usuário",
            "First Name": "Nome",
            "Phone Number": "Telefone",
            "Email Address": "E-mail",
            "Last Name": "Sobrenome",
            "Submit": "Enviar",
            "Device Info": "Informações do dispositivo",
            "Device": "Dispositivo",
            "Browser": "Navegador",
            "Date and time of visit": "Data e hora de acesso",
            "Please provide the location and a brief description": "Forneça a localização e uma breve descrição",
            "ForInformationRegardingYourDTACase": "Para obter informações sobre o seu caso do DTA, faça login no aplicativo móvel DTA Connect ou acesse ",
            "mass link": "https://dtaconnect.eohhs.mass.gov/.",
            "You may also call the DTA Assistance Line at": " Você também pode ligar para a Central de Atendimento do DTA no número",
            "and speak with a case manager.": "e falar com um gerente de caso.",
            "iPhone": "iPhone",
            "iPad": "iPad",
            "iOS laptop or desktop": "iOS notebook ou desktop",
            "Android phone": "Telefone Android",
            "Android tablet": "Tablet Android",
            "PC": "PC",
            "I don't know": "Não sei",
            "Chrome": "Chrome",
            "Edge": "Edge",
            "Firefox": "Firefox",
            "Internet Explorer": "Internet Explorer",
            "Safari": "Safari",
            "Vendor Info": "Informações do fornecedor",
            "Device name is required": "O nome do dispositivo é obrigatório",
            "Browser name is required": "O nome do navegador é obrigatório",
            "Description is required": "A descrição é obrigatória",
            "Vendor name is required": "O nome do fornecedor é obrigatório",
            "Email sent successfully": "E-mail enviado com sucesso",
            "Success": "Sucesso",
            "Ok": "Ok",
            "Other/I’m not sure": "Outra resposta / Não tenho certeza",
            "Processing": "Processando",
            "Max character": "Caractere máximo",
            "Invalid Email Address": "Endereço de e-mail inválido",
            "Invalid phone number": "Número de telefone inválido",
            "Apply": "Aplicar",
            "Clear": "Clara",
            "Su": "Dom",
            "Mo": "Seg",
            "Tu": "Ter",
            "We": "Qua",
            "Th": "Qui",
            "Fr": "Sex",
            "Sa": "Sáb",
            "bannerTitle":"Um site oficial da Comunidade de Massachusetts.",
            "bannerSubTitle":"Saiba diferenciar",
            "An official website of the Commonwealth of Massachusetts": "Um site oficial da Commonwealth de Massachusetts.",
            "OfficialWebsitesUseMassGov": "Os sites oficiais usam o sufixo .mass.gov",
            "AMassGovWebsiteBelongsToAnOfficialGovernmentOrganizationInMassachusetts": "Um site com sufixo .mass.gov pertence a uma organização governamental oficial em Massachusetts.",
            "SecureWebsitesUseHTTPSCertificate": "Sites seguros usam certificado HTTPS",
            "ALockIcon": "O ícone de cadeado (",
            "ALockIcon1": " ) ou https:// significa que você está conectado de forma segura a um site oficial. Compartilhe informações confidenciais apenas em sites oficiais e seguros."
        }
    }else if(lang === "zh-Hans"){
        return {
            "alerts": "警報",
            "showAlerts": "顯示提醒",
            "hideAlerts": "隱藏提醒",
            "alertHeading":"手機版本更新",
            'alertMessage':"DTA Finder 的行動版本目前正在更新，以改善所有客戶端的使用者體驗。請盡快回來查看。",
            "Services": "服务",
            "DTA Finder": "过渡援助部 (DTA) 查找工具",
            "Vendor": "供应商",
            "Vendor Name":"供应商名称",
            "English": "英语",
            "Logout": "登出",
            "Spanish":"西班牙语",
            "Vietnamese":"越南文",
            "Chinese":"中文",
            "Portuguese":"葡萄牙语",
            "My Business": "我的业务",
            "Manage Users": "管理用户",
            "Manage Farmer Markets": "管理农贸市场",
            "Audit Logs": "审计日志",
            "Manage Coordinates": "管理座标",
            "Administration": "行政部门",
            "Dashboard": "控制面板",
            "Vendor(s)": "供应商（们）",
            "VendorLogin": "HIP 小贩",
            "For Providers":"对于供应商",
            "AdminLogin": "管理员登录",
            "AdminLoginHeading": "管理員登入",
            "EnterPrimaryEmail":"请输入您的主要电子邮件地址",
            "EnterPrimaryPhone":"请输入您的主要电话号码",
            "EnterHIPOrg": "请输入您的 HIP 组织 ID",
            "HIP": "HIP",
            "hide": "隐藏",
            "HealthyIncentivesProgram": "健康激励计划",
            "DTA": "过渡援助部 （DTA)",
            "Search": "搜索",
            "Search Results": "搜索结果",
            "View Results": "查看结果",
            "View List": "查看列表",
            "County": "县",
            "City": "市",
            "Area Within": "区域范围",
            "Statewide": "全州",
            "Type": "类型",
            "from": "内在",
            "at":"有",
            "locations":"个 HIP 结果",
            "Use My Location": "使用我的位置",
            "HIP results":"个位置上",
            "Order Option(s)": "订购选项",
            "Month(s) of Operation": "营业月份",
            "Day(s) of Operation": "营业日",
            "Regular Hour(s) of Operation": "正常营业时间",
            "Open Today": "今日开放",
            "My Location": "我的位置",
            "Results": "结果",
            "Export": "导出",
            "Clear Search": "清除搜索",
            "Clear Zoom":"清除缩放",
            "Back": "返回",
            "Legends": "地图索引",
            "available": "可用",
            "Within": "方圆",
            "Massachusetts": "马萨诸塞州",
            "no vendor": "无供应商",
            "no store": "无店铺",
            "vendors are": "供应商是",
            "No stores found": "没有发现供应商",
            "HIP Farmer's Market": "HIP 农贸市场",
            "HIP Farmer's Market Booth": "HIP 农贸市场摊位",
            "All Cities": "所有城市",
            "All Vendors": "所有供应商",
            "Something went wrong! Please try again later": "出错! 请稍后再试",
            "miles away": "数里之外",
            "mile":"英里",
            "miles":"英里",
            "away":"距离",
            "Back to Results": "结果",
            "View on Map": "在地图上查看",
            "Navigate to market": "导航到市场",
            "Select all": "全选",
            "Monday": "星期一",
            "Tuesday": "星期二",
            "Wednesday": "星期三",
            "Thursday": "星期四",
            "Friday": "星期五",
            "Saturday": "星期六",
            "Sunday": "星期天",
            "January": "一月",
            "February": "二月",
            "March": "三月",
            "April": "四月",
            "May": "五月",
            "June": "六月",
            "July": "七月",
            "August": "八月",
            "September": "九月",
            "October": "十月",
            "November": "十一月",
            "December": "十二月",
            "HIP CSA Program": "健康激励计划（HIP）社区支持农业（CSA）项目",
            "HIP Farm Stand": "HIP 农产品直销店",
            "HIP Mobile Market Stop": "健康激励计划（HIP) 移动市场站点",
            "Store Description": "店铺介绍",
            "Vendor Description": "供应商说明",
            "am": "上午",
            "pm": "下午",
            "Closed": "关闭",
            "Products": "产品",
            "Open Now": "目前开放",
            "Closed Now": "目前关闭",
            "Close": "关闭",
            "Contact Us":"联系我们",
            "Site Policies": "网站政策",
            "Accessibility": "辅助功能",
            "Feedback":"反馈",
            "Navigate to store": "导航至地点",
            "Go to store website": "前往供应商网站",
            "TheMission": "过渡援助部的任务是帮助低收入个人和家庭满足其基本需求，提高其生活质量，长期实现经济自足。",
            "Commonwealth of Massachusetts": "马萨诸塞州",
            "Curbside pickup": "路边提货",
            "Delivery": "配送",
            "In-Person": "当面",
            "Online Pre-selection": "在线预选",
            "Phone Pre-selection": "电话预选",
            "Special Instructions": "特殊说明",
            "Store Photos": "店铺照片",
            "Vendor photos": "供应商照片",
            "All selected": "全部选中",
            "Other": "其他",
            "hip": "(HIP)",
            "Uses Location Services": "使用定位服务",
            "LOC_PERMISSION_DENIED": "请在浏览器中启用位置服务，以在当前位置附近进行搜索",
            "All Counties & Cities":"所有县和市",
            "Help": "信息",
            "Result Pop":"信息",
            "Help Title":"您在马萨诸塞州获得 SNAP*了吗？ 您已经注册了HIP！",
             "Help Text1":"当您使用 SNAP 向 HIP 农产品供应商购买水果和蔬菜时，HIP 会退款到您的 EBT 卡上",
             "Help Text2":"您每个月最多可以使用 40-80 美元 HIP，具体取决于您的SNAP 家庭规模",
             "Help Text3":"您的 EBT 卡上必须有剩余的 SNAP 福利才能使用 HIP",
             "Help Text4":"要了解更多信息，请访问 ",
             "gov link":"mass.gov/hip",
            "Help Note":"*SNAP = 补充营养援助计划（以前称为食品券）",
            "CSA Program Tooltip":"您可以在这里通过每周会员计划购买新鲜的 HIP 农产品。您必须提前在农场注册。",
            "Farm Stand Tooltip":"农场出售 HIP 水果蔬菜的摊位或商店，通常在农场上或农场附近",
            "Market Booth Tooltip":"让您可以直接向农场购买 HIP 农产品的农贸市场摊位。农贸市场上的一些摊位不经营 HIP 农产品。请务必核实和确认！",
            "Farmer Market Tooltip":"您可以在这里向多个农贸摊位购买 HIP 农产品，然后集中结帐即可",
            "Market Stop Tooltip":"让您可以买到农场零售商配送的新鲜 HIP 农产品的流动售卖点",
            "Legend":"图例",
            "Number of HIP vendor locations":"HIP供应商地点数量",
            "Information":"信息",
            "Result Info": "一个农贸市场可以有多个 HIP 供应商。请点击每个农贸市场的搜索结果，查看该处有多少个 HIP 供应商。",
            "your location": "您所在的地点",
            "Login":"HIP 供应商 登录",
            "Closed Today":"今日关闭",
            "Email": "电子邮件",
            "username":"用户名",
            "Forgot Password":"忘记密码",
            "Register":"注册 HIP 供应商",
            "Update":"更新 HIP 供应商",
            "noAccount":"您是没有帐户的HIP供应商吗",
            "Show":"显示",
            "Password":"密码",
            "Continue": "继续",
            "Authenticating": "验证中",
            "Navigate to location": "导航到位置",
            "Special Hour(s) of Operation": "特殊工作时间",
            "Available": "可用",
            "Not Available": "不可用",
            "Week": "周",
            "Day(s)": "日",
            "Month(s)": "月",
            "Time": "时间",
            "MobileView":"I4LvbKcn92g",
            "DesktopView":"KlaPKfIqt7Q",
            "help":"帮助",
            "Choose Category":"选择类别",
            "Select":"选择",
            "How to use the map":"如何使用地图",
            "How to use HIP":"如何使用 HIP",
            "Report a bug":"报告网站错误",
            "Report inaccurate information":"报告不准确信息",
            "Question about DTA case":"关于 DTA 案例的问题",
            "Help for HIP vendors":"为 HIP 供应商提供的帮助",
            "Need additional help?":"需要更多帮助吗？",
            "Description":"说明",
            "Please provide the details listed below, so that we can help you better" : "请提供下方所列详细信息，以便我们为您提供更好的帮助",
            "User Contact Info" : "用户联系信息",
            "First Name":"名字",
            "Phone Number":"电话号码",
            "Email Address":"电子邮箱",
            "Last Name":"姓氏",
            "Submit":"提交",
            "Device Info":"设备信息",
            "Device":"设备",
            "Browser":"浏览器",
            "Date and time of visit":"访问日期和时间",
            "Please provide the location and a brief description":"请提供位置和简要说明",
            "ForInformationRegardingYourDTACase":"有关您的 DTA 案例的信息，可以登录 DTA Connect 手机应用程序或访问",
            "mass link":"https://dtaconnect.eohhs.mass.gov/.",
            "You may also call the DTA Assistance Line at":" 或者也可以拨打 DTA 援助热线",
            "and speak with a case manager.":"，向案例经理咨询.",
            "iPhone":"iPhone",
            "iPad":"iPad",
            "iOS laptop or desktop":"iOS 笔记本电脑或台式机",
            "Android phone":"Android 手机",
            "Android tablet":"Android 平板",
            "PC":"个人电脑",
            "I don't know":"不知道",
            "Chrome":"Chrome",
            "Edge":"Edge",
            "Firefox":"Firefox",
            "Internet Explorer":"Internet Explorer",
            "Safari":"Safari",
            "Vendor Info" : "供应商信息",
             "Device name is required" : "必须填写设备名称",
             "Browser name is required": "必须填写浏览器名称",
             "Description is required": "必须填写说明",
             "Vendor name is required": "必须填写供应商名称",
             "Email sent successfully": "已成功发送邮件",
             "Success": "成功",
             "Ok": "确认",
             "Other/I’m not sure": "其他/我不确定",
             "Processing": "处理中",
             "Max character": "最大字符数",
             "Invalid Email Address": "电子邮件地址无效",
             "Invalid phone number": "电话号码无效",
             "Apply":"申请",
             "Clear":"清除",
             "Su":"周日",
             "Mo":"周一",
             "Tu":"周二",
             "We":"周三",
             "Th":"周四",
             "Fr":"周五",
             "Sa":"周六  ",
             "bannerTitle":"马萨诸塞联邦官方网站。",
             "bannerSubTitle":"识别方法",
             "OfficialWebsitesUseMassGov":"官方网站使用 .mass.gov",
             "AMassGovWebsiteBelongsToAnOfficialGovernmentOrganizationInMassachusetts":".mass.gov 网站属于马萨诸塞州官方政府组织。",
             "SecureWebsitesUseHTTPSCertificate":"安全的网站使用 HTTPS 证书",
             "ALockIcon":"挂锁图标 (",
             "ALockIcon1":") 或 https:// 表明您已安全连接官方网站。只能在安全的官方网站上分享敏感信息。",
             "Registration":"供應商註冊",
             "Eatingfruits" : "每天进食水果和蔬菜对健康很重要。 健康奖励计划（HIP）帮助SNAP客户为家人购买更多的水果和蔬菜。"
         }
    }else if(lang === "vi-VI"){
        return {
            "alerts": "CẢNH BÁO",
            "showAlerts": "HIỂN THỊ CẢNH BÁO",
            "hideAlerts": "ẨN CẢNH BÁO",
            "alertHeading":"Cập nhật phiên bản di động",
            'alertMessage':"Phiên bản di động của DTA Finder hiện đang được cập nhật để cải thiện trải nghiệm người dùng của tất cả khách hàng. Làm ơn hãy kiểm tra lại sớm.",
            "Services": "Dịch vụ ",
            "DTA Finder": "Công Cụ Tìm Kiếm của DTA",
            "Vendor": "Nhà cung cấp",
            "Vendor Name": "Tên nhà cung cấp",
            "VendorLogin": "Nhà cung cấp HIP",
            "For Providers":"Dành cho nhà cung cấp",
            "AdminLogin": "Quản trị Đăng nhập",
            "AdminLoginHeading": "QUẢN TRỊ ĐĂNG NHẬP",
            "EnterPrimaryEmail":"Vui lòng nhập địa chỉ email chính của bạn",
            "EnterPrimaryPhone":"Vui lòng nhập Số điện thoại chính của bạn",
            "EnterHIPOrg": "Vui lòng nhập ID tổ chức HIP của bạn",
            "English": "Tiếng Anh",
            "Spanish":"người Tây Ban Nha",
            "Portuguese":"Người Bồ Đào Nha",
            "Vietnamese":"Tiếng Việt",
            "Chinese":"người Trung Quốc",
            "Logout": "Đăng xuất",
            "My Business": "Việc kinh doanh của tôi",
            "Manage Users": "Quản lý người dùng",
            "Manage Farmer Markets": "Quản lý Thị trường Nông dân",
            "Audit Logs": "Nhật ký kiểm tra",
            "Manage Coordinates": "Quản lý tọa độ",
            "Administration": "Hành chính",
            "Dashboard": "Bảng điều khiển",
            "Vendor(s)": "Nhà cung cấp",
            "HIP": "HIP",
            "hide": "Ẩn",
            "HealthyIncentivesProgram": "Chương trình khuyến khích lành mạnh",
            "DTA": "DTA",
            "Search": "Tìm kiếm",
            "Search Results": "kết quả tìm kiếm",
            "View Results": "Xem Kết quả",
            "View List":"Danh sách xem",
            "County": "Quận",
            "City": "Thành phố",
            "Area Within": "Khu vực trong",
            "Statewide": "Toàn tiểu bang",
            "Use My Location": "Sử Dụng Địa Điểm của Tôi",
            "Type": "Kiểu",
            "from": "từ",
            "at":"tại",
            "hip": "(HIP)",
            "Uses Location Services": "Sử dụng Dịch vụ Vị trí",
            "LOC_PERMISSION_DENIED": "Vui lòng bật dịch vụ vị trí trong trình duyệt của bạn để tìm kiếm gần vị trí hiện tại của bạn",
            "locations":"địa điểm",
            "HIP results":"Kết quả HIP",
            "Order Option(s)": "Đặt hàng tùy chọn",
            "Month(s) of Operation": "Tháng hoạt động",
            "Day(s) of Operation": "Ngày hoạt động",
            "Regular Hour(s) of Operation": "(Các) Giờ Hoạt động Thông thường",
            "Open Today": "Mở hôm nay",
            "My Location": "Địa điểm của tôi",
            "Results": "Các kết quả",
            "Export": "Xuất",
            "Clear Search": "Xóa tìm kiếm",
            "Clear Zoom":"Zoom claro",
            "Back": "Trở lại",
            "Legends": "Chìa khóa bản đồ",
            "available": "có sẵn",
            "Within": "Trong vòng",
            "Massachusetts": "Massachusetts",
            "no vendor": "không có nhà cung cấp",
            "no store": "không có cửa hàng",
            "vendors are": "nhà cung cấp là",
            "No vendors found": "Không tìm thấy nhà cung cấp",
            "HIP Farmer's Market": "Chợ nông sản HIP",
            "HIP Farmer's Market Booth": "Gian hàng chợ nông sản HIP",
            "All Cities": "Tất cả các thành phố",
            "All Vendors": "Tất cả các nhà cung cấp",
            "Something went wrong! Please try again later": "Đã xảy ra lỗi! Vui lòng thử lại sau",
            "mile":"dặm",
            "miles":"dặm",
            "away":"Cách",
            "Back to Results": "Các kết quả",
            "View on Map": "Xem trên bản đồ",
            "Navigate to market": "Điều hướng đến thị trường",
            "Select all": "Chọn tất cả",
            "Monday": "Thứ Hai",
            "Tuesday": "Thứ Ba",
            "Wednesday": "Thứ Tư",
            "Thursday": "Thứ Năm",
            "Friday": "Thứ Sáu",
            "Saturday": "Thứ Bảy",
            "Sunday": "Chủ Nhật",
            "January": "Tháng Giêng",
            "February": "Tháng Hai",
            "March": "Tháng Ba",
            "April": "Tháng Tư",
            "May": "Tháng Năm",
            "June": "Tháng Sáu",
            "July": "Tháng Bảy",
            "August": "Tháng Tám",
            "September": "Tháng Chín",
            "October": "Tháng Mười",
            "November": "Tháng Mười một",
            "December": "Tháng Mười hai",
            "HIP CSA Program": "Chương trình HIP CSA",
            "HIP Farm Stand": "Quầy Nông nghiệp HIP",
            "HIP Mobile Market Stop": "Điểm Dừng Di Động Tại Chợ HIP",
            "Store Description": "Mô tả cửa hàng",
            "Vendor Description": "Mô tả nhà cung cấp",
            "am": "buổi sáng",
            "pm": "buổi chiều",
            "Closed": "Đóng cửa",
            "Products": "Các sản phẩm",
            "Open Now": "Mở ngay bây giờ",
            "Closed Now": "Đóng ngay",
            "Close": "Đóng",
            "Contact Us":"Liên hệ chúng tôi",
            "Site Policies": "Chính sách về Trang web",
            "Accessibility": "Khả năng tiếp cận",
            "Feedback":"Sự hồi tố",
            "Navigate to location": "Điều hướng đến địa điểm",
            "Go to vendor website": "Truy cập trang web của nhà cung cấp",
            "TheMission": "Sứ Mệnh của Sở Hỗ Trợ Chuyển Tiếp là hỗ trợ và trao quyền cho các cá nhân và gia đình có thu nhập thấp đáp ứng các nhu cầu cơ bản của họ nhằm nâng cao chất lượng cuộc sống và đạt được khả năng tự cung tự cấp kinh tế lâu dài",
            "Commonwealth of Massachusetts": "Khối Thịnh Vượng Chung Massachusetts",
            "Curbside pickup": "Nhận bên lề đường",
            "Delivery": "Giao hàng",
            "In-Person": "Trực tiếp",
            "Online Pre-selection": "Lựa chọn trước trực tuyến",
            "Phone Pre-selection": "Lựa chọn trước qua điện thoại",
            "Special Instructions": "Hướng dẫn đặc biệt",
            "Store Photos": "Lưu trữ ảnh",
            "Vendor photos": "Hình ảnh nhà cung cấp",
            "All selected": "Tất cả đã chọn",
            "Other": "Khác",
            "Go to store website": "Truy cập trang web cửa hàng",
            "Navigate to store": "Điều hướng đến cửa hàng",
            "All Counties & Cities":"Tất Cả Các Quận và Thành Phố",
            "Help":"Thông tin",
            "Result Pop":"Thông tin",
            "Help Title":"Quý vị có nhận được SNAP* ở MA không? Quý vị đã đăng ký HIP!",
            "Help Text1":"HIP trả lại tiền vào thẻ EBT của quý vị khi quý vị sử dụng SNAP để mua trái cây và rau củ từ những người bán hàng nông sản thuộc HIP",
            "Help Text2":"Quý vị có thể sử dụng lên đến $40-$80 HIP mỗi tháng, tùy thuộc vào số người trong hộ gia đình SNAP của quý vị",
            "Help Text3":"Quý vị phải có số dư phúc lợi SNAP còn trong thẻ EBT của mình để sử dụng HIP",
            "Help Text4":"Tìm hiểu thêm tại",
            "gov link":"mass.gov/hip",
            "Help Note":"*SNAP =Chương Trình Hỗ Trợ Dinh Dưỡng Bổ Sung (trước đây là phiếu thực phẩm)",
            "CSA Program Tooltip":"Một nơi mà bạn có thể chọn nông sản HIP tươi sống thông qua chương trình thành viên hàng tuần. Bạn phải đăng ký trước với trang trại.",
            "Farm Stand Tooltip":"Quầy hàng hoặc cửa hàng nơi trang trại bán sản phẩm HIP, thường nằm tại hoặc gần trang trại",
            "Market Booth Tooltip":"Bàn ở chợ nông sản, nơi bạn có thể mua sản phẩm HIP trực tiếp từ trang trại. Một số gian hàng ở chợ có thể không xử lý HIP - hãy kiểm tra để đảm bảo!",
            "Farmer Market Tooltip":"Nơi bạn có thể mua nông sản HIP từ nhiều bàn trang trại bằng cách thanh toán tại một địa điểm trung tâm",
            "Market Stop Tooltip":"Một địa điểm nổi bật lên nơi bạn có thể mua sản phẩm HIP tươi do nhà bán lẻ nông trại giao",
            "Legend":"Chìa khóa bản đồ",
            "Number of HIP vendor locations":"Số lượng địa điểm nhà cung cấp HIP",
            "Information":"Thông tin",
            "your location": "vị trí của bạn",
            "Login":"Người Bán Hàng Đăng nhập HIP",
            "Continue": "Tiếp tục",
            "Closed Today":"Đóng hôm nay",
            "Email": "E-mail",
            "username":"tên tài khoản",
            "Forgot Password":"Quên mật khẩu",
            "Register":"Đăng ký nhà cung cấp HIP",
            "Update":"Cập nhật nhà cung cấp HIP",
            "noAccount":"Quý vị là người bán hàng HIP không có tài khoản?",
            "Show":"Hiển Thị",
            "Password":"Mật khẩu",
            "Authenticating": "Xác thực",
            "Result Info": "Chợ nông sản có thể có nhiều nhà cung cấp HIP. Nhấp vào từng kết quả chợ nông sản để xem có bao nhiêu nhà cung cấp HIP bán ở đó.",
            "Special Hour(s) of Operation": "Giờ làm việc đặc biệt",
            "Available": "Có mặt",
            "Not Available": "Không có mặt",
            "Week": "Tuần",
            "Day(s)": "Ngày",
            "Month(s)": "Tháng",
            "Time": "Giờ",
            "MobileView":"I4LvbKcn92g",
            "DesktopView":"KlaPKfIqt7Q",
            "help":"Trợ giúp",
            "helpHeading":"GIÚP ĐỠ",
            "Choose Category":"Chọn danh mục",
            "Select":"Chọn",
            "How to use the map":"Cách sử dụng bản đồ",
            "How to use HIP":"Cách sử dụng HIP",
            "Report a bug":"Báo cáo lỗi",
            "Report inaccurate information":"Báo cáo thông tin không chính xác",
            "Question about DTA case":"Câu hỏi về trường hợp DTA",
            "Help for HIP vendors":"Trợ giúp cho các nhà cung cấp HIP",
            "Need additional help?":"Cần trợ giúp thêm?",
            "Description":"Mô tả",
            "Please provide the details listed below, so that we can help you better" : "Vui lòng cung cấp các thông tin chi tiết được liệt kê bên dưới để chúng tôi có thể giúp bạn tốt hơn",
            "User Contact Info" : "Thông tin Liên hệ Người dùng",
            "First Name":"Tên",
            "Phone Number":"Số điện thoại",
            "Email Address":"Địa chỉ Email",
            "Last Name":"Họ",
            "Submit":"Gửi",
            "Device Info":"Thông tin thiết bị",
            "Device":"Thiết bị",
            "Browser":"Trình duyệt",
            "Date and time of visit":"Ngày và giờ đến thăm",
            "Please provide the location and a brief description":"Vui lòng cung cấp vị trí và một mô tả ngắn gọn",
            "ForInformationRegardingYourDTACase":"Để biết thông tin về trường hợp DTA của bạn, bạn có thể đăng nhập vào ứng dụng di động DTA Connect hoặc",
            "mass link":"https://dtaconnect.eohhs.mass.gov/.",
            "You may also call the DTA Assistance Line at":" Bạn cũng có thể gọi Đường dây Hỗ trợ DTA theo số",
            "and speak with a case manager.":"và nói chuyện với người quản lý hồ sơ.",
            "iPhone":"iPhone",
            "iPad":"iPad",
            "iOS laptop or desktop":"iOS máy tính xách tay hoặc máy tính để bàn",
            "Android phone":"Điện thoại Android",
            "Android tablet":"Máy tính bảng Android",
            "PC":"Máy tính PC",
            "I don't know":"Tôi không biết",
            "Chrome":"Chrome",
            "Edge":"Edge",
            "Firefox":"Firefox",
            "Internet Explorer":"Internet Explorer",
            "Safari":"Safari",
            "Vendor Info" : "Thông tin về nhà cung cấp",
            "Device name is required" : "Cần có tên thiết bị",
            "Browser name is required": "Cần có tên trình duyệt",
            "Description is required": "Cần có phần mô tả",
            "Vendor name is required": "Cần có tên nhà cung cấp",
            "Email sent successfully": "Email đã được gửi thành công",
            "Success": "Thành công",
            "Ok": "Ok",
            "Other/I’m not sure": "Khác / tôi không chắc",
            "Processing": "Xử lý",
            "Max character": "Ký tự tối đa",
            "Invalid Email Address": "Địa chỉ email không hợp lệ",
            "Invalid phone number": "Số điện thoại không hợp lệ",
            "Apply":"Nộp đơn",
            "Clear":"Thông thoáng",
            "Su":"CN",
            "Mo":"Hai",
            "Tu":"Ba",
            "We":"Tư",
            "Th":"Năm",
            "Fr":"Sáu",
            "Sa":"Bảy",
            "bannerTitle":"Trang mạng chính thức của Khối thịnh vượng chung Massachusetts. ",
            "bannerSubTitle":"Quý vị biết được là như thế này",
            "OfficialWebsitesUseMassGov":"Các trang mạng chính thức sử dụng .mass.gov",
            "AMassGovWebsiteBelongsToAnOfficialGovernmentOrganizationInMassachusetts":"Trang mạng có các chữ cuối .mass.gov là thuộc một tổ chức chính phủ chính thức tại Massachusetts.",
            "SecureWebsitesUseHTTPSCertificate":"Các trang mạng an toàn sử dụng chứng nhận HTTPS",
            "ALockIcon":"Khi thấy biểu tượng chiếc khóa (",
            "ALockIcon1":") hoặc chữ https:// quý vị biết là đã được kết nối an toàn với trang mạng chính thức. Chỉ chia sẻ thông tin riêng tư trên các trang mạng an toàn chính thức.",
            "Registration":"Đăng ký nhà cung cấp",
            "Eatingfruits" : "Ăn trái cây và rau củ mỗi ngày rất quan trọng cho sức khỏe của quý vị. Chương Trình Khuyến Khích Lành Mạnh (HIP) giúp khách hàng SNAP mua được nhiều trái cây và rau củ hơn cho hộ gia đình của họ."
        }
    }
}