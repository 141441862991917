import { SET_BANNER } from "../actionTypes/banner"; //,GET_LOC_TYPE

const bannerReducer = (state={}, action) => {   
    switch (action.type) {   
    case SET_BANNER: {
        const newState = Object.assign({}, state);
        newState.banner =action.value;
        return newState;  
    }
    default:
        return state;
    }
};

export default bannerReducer;
