import React from "react";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import PropTypes from "prop-types";
import { GOOGLE_KEY } from "../../config";

var centerLocation = { lat: 42.2625932, lng: -71.8022934, address: "Massachusetts Center", city: "Boston" };
const Map=(props)=> {
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: GOOGLE_KEY // Add your API key
    });

  return (
      <div className="address-map">
      {
        isLoaded && (
        <GoogleMap
            mapContainerStyle={{ width: "100%", height: "100%" }}
            center= { Object.keys(props.latlng).length && props.latlng.lat && props.latlng.lng ? {
                lat: parseFloat(props.latlng.lat),
                lng: parseFloat(props.latlng.lng)
            }   :centerLocation}
            zoom={16}
            options= {{
                zoomControl: true,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: true
            }}
            >
                <Marker
                key={"id"}
                position= { Object.keys(props.latlng).length ? {
                    lat: parseFloat(props.latlng.lat),
                    lng: parseFloat(props.latlng.lng)
                }   :centerLocation}
                >
                </Marker>
            </GoogleMap>
        )
      }
      </div>
   
  );
};

Map.propTypes = {
    latlng: PropTypes.object,
};

export default Map;
