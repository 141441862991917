import React, {
  useState,
  useEffect
} from "react";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import {NavLink} from "react-router-dom";
import { mapStateToProps } from "../../../redux/reducers";
import { connect } from "react-redux";
import { authenticateHIPUser, clearLoginError } from "../../../redux/actionTypes/auth";
import { maskMobile } from "../../../utils/globalMethods";
import "../../../styles/header.scss";

const LoginComponent = (props) => {
  const [modalShown] = useState(props.shown);
  const [showUpdateFields, setShowUpdateFields] = useState(false)
  const [isSubmitting, setSubmitting] = useState(false);
  const [language, setLanguage] = useState({});
  const [mobile, setMobile] = useState("");
  const [orgId,setOrgId] = useState("");

  const { register, setError, clearErrors, handleSubmit, formState: { errors }} = useForm();

  const formatOrgId = (value) => {
    return value.replace(/[^0-9]/g, '')
  }

  useEffect(() => {
    setLanguage(props.language)
  }, [props.language])
  // eslint-disable-next-line no-unused-vars
  const handleInputChange = (event) => {
    if(props.userAuth.login.status || errors.loginError){
      clearErrors(["loginError"]);
      props.clearLoginError();
    }
  };

  useEffect(() => {
    if(props.userAuth.login.status){
      props.clearLoginError();
    }
  }, []);

  useEffect(() => {
    if(isSubmitting && props.userAuth.login.status=="error"){
      setError('loginError', { type: "custom", message: props.userAuth.login.message });
      setSubmitting(false);
    }else if(props.userAuth.login.status=="success"){
      props.close();
    }
  }, [props.userAuth.login]);

  const onSubmit = (data) => {
    if(Object.keys(errors).length === 0){
      clearErrors(["loginError"]);
      setSubmitting(true);
      props.authenticateHIPUser(data.email.toLowerCase(), data.phone.replace(/\D/g, ''), data.hipid);
    }
  };

  return (
      <Modal
        show={modalShown}
        onHide={() => props.close()}
        backdrop="static"
        keyboard={false}
        animation={1}
        id={"loginModal"}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h4 className="modal-title modalTitle resText">{language.VendorLogin}</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="fullWidth">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="pt-0">
              <div className="row">
                {!showUpdateFields && <div className="col-md-12 mt-5">
                  <p>
                    <NavLink
                      to={"/dtahip/registration"}
                      className=" mt-2 boldFont btn btn-block btn-lg btn-outline-primary"
                      tabIndex="0"
                      reloadDocument="true"
                    >
                      {language.Register}{" "}
                      <i className="fa fa-pencil-square-o mt-1"></i>
                    </NavLink>
                  </p>
                  <p>
                    <button
                      onClick={() => setShowUpdateFields(true)}
                      className="boldFont btn btn-block btnModal btnSubmit btn-outline-primary btn-lg"
                      tabIndex="0"
                    >
                      {language.Update}{" "}
                    </button>
                  </p>
                </div>}
                {showUpdateFields && <>
                  <div
                    className="col-md-12 errorMsg mb-2"
                    style={{ fontSize: "19px" }}
                  >
                    {Object.keys(errors).length > 0 &&
                      <span className="text-danger"><i className="fa fa-exclamation-circle"></i>&nbsp;{errors[Object.keys(errors)[0]].message}</span>
                    }
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label className="inputLabel">
                        {language.EnterPrimaryEmail}<small>*</small>
                      </label>
                      <input
                        {...register("email", { required: "Please enter Email",
                        pattern: {
                          value: /\S+@\S+\.\S+/,
                          message: "Invalid email format"
                        }})}
                        className="form-control form-control-lg lowercase"
                        maxLength="100"
                        placeholder=""
                        name="email"
                        type="text"
                        id="username"
                        tabIndex="1"
                        required={true}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label className="inputLabel">
                        {language.EnterPrimaryPhone}<small>*</small>
                      </label>
                      <input
                        {...register("phone", { required: "Please enter Phone Number",
                          pattern: {
                            value:
                              /^\s*[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})\s*$/,
                            message: "Invalid phone number",
                          },
                        })}
                        className="form-control form-control-lg "
                        maxLength="100"
                        placeholder="(___) ___-____"
                        name="phone"
                        type="tel"
                        value={mobile}
                        id="phonenumber"
                        tabIndex="1"
                        required={true}
                        onChange={(e) =>{
                          setMobile(maskMobile(e.target.value));
                          handleInputChange();
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-12 position-relative">
                    <div className="form-group">
                      <label className="inputLabel" style={{ width: "100%" }}>
                        {language.EnterHIPOrg}<small>*</small>
                      </label>
                      <input
                        {...register("hipid", { required: "Please enter HIP Organization ID" })}
                        className="form-control form-control-lg show "
                        tabIndex="2"
                        name="hipid"
                        id="hipid"
                        type="text"
                        maxLength="6"
                        value={orgId}
                        required=""
                        onChange={(e) =>{
                          setOrgId(formatOrgId(e.target.value));
                          handleInputChange();
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 mt-3">
                    <button
                      onClick={() => setShowUpdateFields(false)}
                      className="boldFont btn btn-block btnModal btnSubmit btn-primary btn-lg"
                      tabIndex="3"
                    >{language.Back} &nbsp;</button>
                  </div>
                  <div className="col-md-6 mt-3">
                    <button
                      type="submit"
                      onClick={handleSubmit(onSubmit)}
                      className="boldFont btn btn-block btnModal btnSubmit btn-primary btn-lg"
                      tabIndex="3"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? (
                        <>
                          {" "}
                          <i className="fa fa-spinner fa-spin"></i>{" "}
                          Authenticating
                        </>
                      ) : (
                        <>
                          {language.Continue} &nbsp;
                          <i
                            style={{ verticalAlign: "inherit" }}
                            className="fa fa-arrow-right"
                          ></i>{" "}
                        </>
                      )}
                    </button>
                  </div>
                </>}
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
  );
};

LoginComponent.propTypes = {
  shown: PropTypes.bool,
  close: PropTypes.func,
  authenticateHIPUser: PropTypes.func.isRequired,
  clearLoginError: PropTypes.func.isRequired,
  language:PropTypes.object,
  userAuth: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => {
  return {
    clearLoginError: () => dispatch(clearLoginError({status:"",message:""})),
    authenticateHIPUser: (email, phone, hipid) => dispatch(authenticateHIPUser({ username: email, phone: phone, hipid: hipid })),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginComponent);
