import React, {useState,useEffect} from "react";
import PropTypes from "prop-types";
import Form from 'react-bootstrap/Form';
import {Row, Col} from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import '../../styles/home.scss';
import { connect } from "react-redux";
import { mapStateToProps } from "../../redux/reducers";
import { GET_COUNTYCITY } from "../../redux/actionTypes";
import { sortName, maskMobile, isNumber } from "../../utils/globalMethods";
import SelectDropdown from "../common/selectdropdown-component";
import { useForm } from "react-hook-form";

const FarmerMarketAddEditComponent = (props) => {
    const {mapdata,validated,handleFormSubmit,getCityList,onEdit,row} = props;
    
    const [mobile, setMobile] = useState(onEdit && row.phone ? maskMobile(row.phone):"");

    const { register, handleSubmit, setValue, clearErrors, formState: { errors }} = useForm();

    const [cityList, setCityList] = useState([]);
    const [city, setCity] = useState();

    useEffect(() => {
        let cityOption = mapdata.city.map(item => {
            return {
                name: item.id,
                values: item.cityName,
                isActive: 1
            };
        });
        let defaultData = [{name:"",values:"--Select City--"}];
        setCityList(defaultData.concat(sortName(cityOption)));
        // getCityList();
        if (onEdit) {
            const selectedCity = mapdata.city.find(item => item.cityName == row?.city);
            setValue('city', {name: selectedCity?.id, values: selectedCity?.cityName, isActive: 1});
            setCity({name: selectedCity?.id, values: selectedCity?.cityName}); 
        }
    },[mapdata,onEdit]);

    // useEffect(()=>{
    //     const selectedCity = mapdata.city.find(item => item.cityName == row?.city); 
    //     setCity({name: selectedCity?.id, values: selectedCity?.cityName});  
    // },[onEdit]);

    const onSubmit = (data)=>{
        handleFormSubmit(data);
    }

  return (
    <div className="form-block">
        {/* noValidate validated={validated} onSubmit={handleSubmit} */}
        <Form onSubmit={handleSubmit(onSubmit)}> 
            <Row>
                {Object.keys(errors).length > 0 && <Form.Text className="dh-txt-danger">
                    <b><i className="fa fa fa-info-circle ml-2" aria-hidden="true"></i> {errors[Object.keys(errors)[0]].message} </b>
                </Form.Text>}
                <Col md={12}>
                    <Form.Group as={Col} controlId="formMarketName">
                        <Form.Label className="smallTitle">Market Name *</Form.Label>
                        <Form.Control 
                        {...register("marketName", { required: "Please enter Market Name" })}
                        type="text" size="lg" defaultValue={onEdit?row.marketName:""} />
                        <Form.Control.Feedback type="invalid">
                            Please enter Market Name.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} controlId="formAddress">
                        <Form.Label className="smallTitle">Address *</Form.Label>
                        <Form.Control 
                        {...register("address", { required: "Please enter Address" })}
                        type="text" size="lg" defaultValue={onEdit?row.address:""} />
                        <Form.Control.Feedback type="invalid">
                            Please enter Address.
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <Form.Group as={Col} controlId="formCity">
                        <Form.Label className="smallTitle">City *</Form.Label>
                        {/* <Form.Control type="text" as="select" required>
                            <option value="">Select City</option>
                            {sortName(mapdata.city, 'cityName').map(item => {
                                return <option key={item.id} value={item.cityName+","+item.latitude+","+item.longitude} selected={onEdit && row.city == item.cityName}>{item.cityName}</option>
                            })}                            
                        </Form.Control> */}
                        <SelectDropdown
                            {...register("city", { required: "Please enter City" })}
                            value={city}
                            onChange={(v) => {
                                if (v.name) {
                                    setCity(v);
                                    setValue(`city`, v);
                                    clearErrors("city");
                                }
                            }}
                            options={cityList}
                            isSearch={true}
                            defaultValueProps={{
                                default: true,
                                defaultOption: {name:"",values:"--Select City--"},
                            }}
                        />
                        <Form.Control.Feedback type="invalid">
                            Please select City.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} controlId="formZipCode">
                        <Form.Label className="smallTitle">Zip Code *</Form.Label>
                        <Form.Control 
                        //  {...register("zipcode", { required: 'Please enter Zipcode',  pattern: {
                        //     value:/\b\d{5}-\d{4}\b/,
                        //     message: "Invalid zipcode",
                        //     }
                        // })}
                        {...register(`zipcode`, {
                            required: 'Please enter Zipcode',
                            pattern: {
                              value:
                              /^(\d{5})$/,
                              message: "Zipcode should be 5 digits",
                            },
                        })}
                        maxLength={5}
                        onKeyDown={isNumber}
                        type="text"
                        size="lg" defaultValue={onEdit?row.zipcode:""} />
                        <Form.Control.Feedback type="invalid">
                            Please provide a valid zip.
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <Form.Group as={Col} controlId="formState">
                        <Form.Label className="smallTitle">State *</Form.Label>
                        <SelectDropdown
                            value={{
                                values: "MA",
                                name: "MA",
                              }}
                            onChange={(v) => {}}
                            options={[{name:'MA', values:'MA'}]}
                            isSearch={false}
                            defaultValueProps={{
                                default: true,
                                defaultOption: {name:"",values:"-- Select --"},
                            }}
                        />                      
                    </Form.Group>
                    <Form.Group as={Col} controlId="formPhone">
                        <Form.Label className="smallTitle">Phone</Form.Label>
                        <Form.Control type="tel" size="lg" 
                          {...register("mobile", { pattern: {
                            value:
                              /^\s*[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})\s*$/,
                            message: "Invalid phone number",
                          },
                        })}
                        value={mobile}
                        onChange={(e) => {
                            setMobile(maskMobile(e.target.value));
                            setValue(`mobile`, e.target.value);
                        }}
                        placeholder="(___) ___-____" />
                    </Form.Group>
                </Col>
                <Col md={12}>
                    <Form.Group as={Col} controlId="formWebSite">                        
                        <Form.Label className="smallTitle">Web Site</Form.Label>
                        <Form.Control 
                        {...register('webAddress', {
                            pattern: {
                                value:
                                /^((ftp|http|https):\/\/)?(www\.)?([A-z]+)\.([A-z]{2,})/,
                                message: "Invalid website",
                            },
                        })}
                        type="text" size="lg" defaultValue={onEdit?row.webAddress:""} />
                    </Form.Group>
                    <Form.Group as={Col} controlId="formDescription">
                        <Form.Label className="smallTitle">Description</Form.Label>
                        <Form.Control
                         {...register('description')}
                            as="textarea"
                            placeholder=""
                            style={{ height: '114px' }}
                            defaultValue={onEdit?row.description:""}
                            />
                        <Form.Text id="formDescription" className="float-right" style={{ color: '#454f57!important' }} muted>
                            <i className="fa fa fa-info-circle ml-2" aria-hidden="true"></i> Max character: 3000
                        </Form.Text>
                    </Form.Group>
                </Col>
            </Row>
            {onEdit && <Form.Control type="text" value={row.id} readOnly hidden />}
            <Row className="modal-footer">
                <Col>
                    <Button variant="primary" type="submit" className="float-right">
                        {onEdit ? 'Update':'Add'}
                    </Button>
                </Col>
            </Row>
        </Form>
    </div>
  );
};

FarmerMarketAddEditComponent.propTypes = {
    validated: PropTypes.bool,
    handleFormSubmit: PropTypes.func,
    mapdata: PropTypes.object,
    getCityList: PropTypes.func.isRequired,
    onEdit: PropTypes.bool,
    row:PropTypes.object
};

const mapDispatchToProps = (dispatch) => {
    return {
        getCityList: (city_county) => {
            dispatch({ type: GET_COUNTYCITY, city:city_county });
        },
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(FarmerMarketAddEditComponent);
