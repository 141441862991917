import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { mapStateToProps } from "../../../redux/reducers";
import { connect } from "react-redux";
import { selectedLanguageAction } from "../../../redux/actionTypes/language";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { pageRefresh } from "../../../utils/globalMethods";


let selectedLanguage = localStorage.getItem("selectedLanguage");

const LanguageSelect =(props)=>{
  const [language, setLanguage] = useState({});

    const languageSelect = (e) => {
        localStorage.setItem("selectedLanguage", e.target.value);
        props.onGetLanguage(e.target.value);
        pageRefresh();
    };

    useEffect(() => {
        setLanguage(props.language)
    }, [props.language])

    const languages = [
        {
        value: "en-US",
        display: language.English
        },
        {
        value: "es-ES",
        display: language.Spanish
        },
        {
        value: "pt-BR",
        display: language.Portuguese
        },
        {
        value: "zh-Hans",
        display: language.Chinese
        },
        {
        value: "vi-VI",
        display: language.Vietnamese
        }
    ];

    return (
    //     <>
    //    <select onChange={ languageSelect }
    //         className={ props.className }
    //         aria-label="Language"
    //         defaultValue={selectedLanguage}
    //         >
    //         {languages.map((lang) => {
    //             return <option key={ lang.value }   value={ lang.value }>{ lang.display }</option>;
    //             })
    //         }
    //     </select>
       
    //     </>
        <>
         <Select
        className={ props.className }
          onChange={languageSelect}
          aria-label="Language"
          defaultValue={selectedLanguage || "en-US"}
        >
          {languages.map((lang) => {
         return <MenuItem className="language-menu-item" key={ lang.value }   value={ lang.value }>{ lang.display }</MenuItem>
        })
                }
        </Select></>
        
    );
};

// Mapping Action with dispatch
function mapDispatchToProps(dispatch) {
    //working
    return {
        onGetLanguage: (val) => dispatch(selectedLanguageAction(val))
      };
}


LanguageSelect.propTypes = {
    onGetLanguage: PropTypes.func.isRequired,
    className: PropTypes.string.isRequired,
    language: PropTypes.object,
  };

export default connect(mapStateToProps,mapDispatchToProps)(LanguageSelect);