import React, {useState, useEffect} from 'react'
import { Grid, Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import { connect } from "react-redux";
import { mapStateToProps } from "../../redux/reducers";
import PropTypes from "prop-types";


import LOGO from '../../assets/images/hip_logo.jpg'
import '../../styles/home.scss'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const BoxWrapper = (props) => {
  const [language, setLanguage] = useState({});
  const navigate = useNavigate()

  useEffect(() => {
    setLanguage(props.language)
  }, [props.language])
 
    return (
      <>
        <Grid container className='card-container'>
          <Grid item xs={12} md={7} lg={5} className='box-position'>
            <h1 className='box-header'>{language.HealthyIncentivesProgram} {language.hip} 
            <img src={LOGO} alt="Hip Logo" width={134} style={{ float: 'right' }} />&nbsp;
            </h1>
            <p style={{ fontSize: 18, marginBottom: 0, marginTop: '1.5rem' }}>{language.Eatingfruits}</p>
            <Button onClick={()=> {navigate("/dtahip");}} endIcon={<ArrowForwardIcon sx={{ fontSize: '36px !important' }}/>} variant="contained" style={{ marginBottom: '1rem', marginTop: '1rem', width: "100%", backgroundColor: "#006e52", fontSize: "21.6px",fontFamily:'Texta-bold'  }}>Find HIP vendors</Button>
          </Grid>
          </Grid>
      </>
    );
  };
  
  BoxWrapper.propTypes = {
    language: PropTypes.object,
  };
  
  export default connect( mapStateToProps, )(BoxWrapper);