import * as actionTypes from "../actionTypes/vendordetails";

const initState = {
    registerDetails: {
        isLoading: false,
        data: {},
        error: false
    },
    vendorPublicDetails: {
        isLoading: false,
        data: {},
        error: false
    },

    vendorLocDetails: {
        isLoading: false,
        data: {},
        error: false
    },

    removeVendorStore: {
        isLoading: false,
        data: {},
        error: false
    },

    updateVendorStore: {
        isLoading: false,
        data: {},
        error: false
    },

    userDetails: {
        isLoading: false,
        data: {},
        error: false
    },
    refreshKey:0,
    manageVendorList:{
      list:[],
      total:0,
      loading: true
    },
    followVendorDetails:{
        list:[],
        total:0
    },
    vendorHistoryList:{
        list:[],
        total:0
      },
      storeHistoryList:{
        list:[],
        total:0
      },
      vendorInfoList:{},
      myStoreDetailsList:{
          data:[],
          isLoading: true
      },
      updateUserInfo: {
        data : false
      }
};

const VendorDetailsReducer = (state = initState, action = {}) => {
    const newState = { ...state };

    switch (action.type) {
        
        case actionTypes.SET_PROGRAM_UPDATE: 
        return {
            ...newState, updateVendorStore:
                { data: action.value }
        };
        case actionTypes.SET_REMOVE_VENDOR_STORE: 
        return {
            ...newState, removeVendorStore:
                { data: action.value }
        };

        case actionTypes.GET_VENDOR_LOC_DETAILS_SUCCESS:
            return {
                ...newState, vendorLocDetails:
                    { data: action.value }
            };

        case actionTypes.GET_VENDOR_LOC_DETAILS_FAILURE:
            return {
                ...newState, vendorLocDetails:
                    { data: { ...action.value } }
            };        

        case actionTypes.UPDATE_VENDOR_DETAILS_SUCCESS:
            return {
                ...newState, registerDetails: { data: {...action.value} }

            };

        case actionTypes.UPDATE_VENDOR_DETAILS_FAILURE:
            return {
                ...newState, registerDetails: { data: action.value  }

            };

        case actionTypes.UPDATE_PUBLIC_DETAILS_SUCCESS:
            return {
                ...newState, vendorPublicDetails:
                    { data: action.value }
            };

        case actionTypes.UPDATE_PUBLIC_DETAILS_FAILURE:
            return {
                ...newState, vendorPublicDetails:
                    { data: { ...action.value } }
            };

        case actionTypes.GET_VENDORID_SUCCESS:
            return {
                ...newState, vendorIdDetails:
                    { data: action.value }
            };

        case actionTypes.GET_VENDORID_FAILURE:
            return {
                ...newState, vendorIdDetails:
                    { data: { ...action.value } }
            };

        case actionTypes.GET_USER_DETAILS_SUCCESS:
            return {
                ...newState, userDetails:
                    { data: action.value }
            };

        case actionTypes.GET_USER_DETAILS_FAILURE:
            return {
                ...newState, userDetails:
                    { data: { ...action.value } }
            };

            case "SET_MANAGE_VENDOR_LIST" :{
                return {...newState,manageVendorList:{
                  list:action.payload.data,
                total:action.payload.total
              }};
              }
              case "SET_FOLLOW_VENDOR_DETAILS" :{
                return {...newState,followVendorDetails:{
                  list:action.payload.data,
                total:action.payload.total
              }};
              }
              case "SET_VENDOR_HISTORY_LIST" :{
                  console.log(action.payload);
                return {...newState,vendorHistoryList:{
                  list:action.payload.data,
                total:action.payload.total
              }};
              }
              case "SET_STORE_HISTORY_LIST" :{
                  console.log(action.payload);
                return {...newState,storeHistoryList:{
                  list:action.payload.data,
                total:action.payload.total
              }};
              }
              case "SET_VENDOR_INFO_LIST" :{
                return { ...newState, vendorInfoList: { ...action.payload[0], isLoading: false } };
              }
              case "SET_MY_STORE_DETAILS" :{
                return {...newState,myStoreDetailsList:{data:action.payload, isLoading:false}};
              }
              case "REFRESH_KEY" :{
                return {...newState,refreshKey:state.refreshKey+1};
              }
            case "UPDATE_USER_SUCCESS":
                return {
                    ...newState, updateUserInfo:
                        { data: action.value }
                };

        default:
            return newState;
    }
};
export default VendorDetailsReducer;