
import * as React from 'react';
import spin_loader from "../../../assets/images/spin-loader.svg";

export default function CommonLoading() {
  return (
    <div className="fill-window">
          <div className="alert alert-info">
              <div className="loading">
                  <img src={spin_loader} alt="spin-loader"/>
              </div>{" "}
              <div className="loading-process">
                Processing...
              </div>
          </div>
        </div>
  );
}